import "./components/global.js";
import React from "react";
import { Router, Route } from "react-router";
import LOADING from "./page/Loading";
import PAGENOTFOUND from "./page/PageNotFound";
import LOGIN from "./page/Login";

//หลังบ้าน
import DASHBOARD from "./page/Dashboard/Dashboard";
import USER from "./page/User/User";
import ROLE from "./page/Role/Role";
import ROLECREATE from "./page/Role/RoleCreate";
import ROLEUPDATE from "./page/Role/RoleUpdate";
import COMPANY from "./page/Company/Company";
import MEMBER from "./page/Member/Member";
import RATEMEMBER from "./page/RateMember/RateMember";
import SALE from "./page/Sale/Sale";
import PAYMENTRECEIVE from "./page/Sale/PaymentReceive";
import SALEMEMBER from "./page/SaleMember/SaleMember";
import PRODUCT from "./page/Product/Product";
import PRODUCTCREATE from "./page/Product/ProductCreate";
import UPDATEPRICESCREEN from "./page/Product/UpDatePrice";
import PRODUCTCATEGORY from "./page/Product/Category";
import PRODUCTUPDATE from "./page/Product/ProductUpdate";
import PRODUCTIMPORT from "./page/Product/ProductImport";

//ใบรับสินค้า
import RECEIPT from "./page/Receipt/Receipt";
import RECEIPTCREATE from "./page/Receipt/ReceiptCreate";
import RECEIPTUPDATE from "./page/Receipt/ReceiptUpdate";
import RECEIPTUPDATECOSTPRICE from "./page/Receipt/ReceiptUpdateCostPrice";

import STOCKMOVE from "./page/StockMove/StockMove";
import STOCKMOVECREATE from "./page/StockMove/StockMoveCreate";

import STOCKADJUSTMENT from "./page/StockAdjustment/StockAdjustment";
import STOCKADJUSTMENTCREATE from "./page/StockAdjustment/StockAdjustmentCreate";
import STOCKADJUSTMENTUPDATE from "./page/StockAdjustment/StockAdjustmentUpdate";
import STOCKADJUSTMENTCHECKSTOCK from "./page/StockAdjustment/StockAdjustmentCheckStock";
import STOCKADJUSTMENTDETAIL from "./page/StockAdjustment/StockAdjustmentDetail";

import STOCKLOCATION from "./page/StockLocation/StockLocation";

import STATUSDELIVERY from "./page/Commission/StatusDelivery";
import LISTSTATUSDELIVERY from "./page/Commission/ListStatusDelivery";
import INVOICE from "./page/Invoice/Invoice";
import INVOICECREATE from "./page/Invoice/InvoiceCreate";
import COMMISSIONTYPE from "./page/CommissionType/CommissionType";

// REPORT
import REPORT from "./page/Report/Report";
import REPORTINVENTORY from "./page/Report/ReportInventory";
import REPORTPRODUCTSANDSERVICES from "./page/Report/ReportProductsAndServices";
import REPORTSALESCUSTOMERS from "./page/Report/ReportSalesCustomers";
import REPORTSALESPRODUCTS from "./page/Report/ReportSalesProducts";
import REPORTTRANSFER from "./page/Report/ReportTransfer";
import REPOREXPENSES from "./page/Report/ReportExpenses";
import REPORTREFUND from "./page/Report/ReportRefund";
import REPORTRECIEPT from "./page/Report/ReportReciept";

import WAREHOUSE from "./page/Warehouse/Warehouse";
import BANK from "./page/Bank/Bank";

//หน้าบ้าน
import MAINSCREEN from "./page/MainSale/Mainscreen";
import TRANSATIONSCREEN from "./page/MainSale/Transaction";
import ACCRUEDSCREEN from "./page/MainSale/Accrued";
import LISTACCRUEDSCREEN from "./page/MainSale/ListAccrued";

//Phase2
// ค่าใช้จ่าย
import EXPENSES from "./page/Phase2/Expenses/Expenses";
import EXPENSESCREATE from "./page/Phase2/Expenses/ExpensesCreate";
import EXPENSESTUPDATE from "./page/Phase2/Expenses/ExpensesUpdate";
import EXPENSESTYPESCREEN from "./page/Phase2/Expenses/ExpensesType";

// คืนสินค้า
import REFUND from "./page/Phase2/Refund/Refund";
import REFUNDCREATE from "./page/Phase2/Refund/RefundCreate";
import REFUNDUPDATE from "./page/Phase2/Refund/RefundUpdate";
import REFUNDDETAILS from "./page/Phase2/Refund/RefundDetail";

// ประวัิตการตรวจสอบ
import LISTLOGAUDITSCREEN from "./page/Phase2/LogAudit/LogAudit";
import LOGAUDITSCREEN from "./page/Phase2/LogAudit/LogAuditdetail";

//เงินคืนสะสม
import REFUNDACCUMULATE from "./page/Phase2/RefundAccumulate/RefundAccumulate";

import GENERATEBARCODESCREEN from "./page/Phase2/GenerateBarcode/GenerateBarcode";

import CHECKSTOCKSCREEN from "./page/Phase2/CheckStock/CheckStock";
import STOCKCYCLESCREEN from "./page/Phase2/CheckStock/StockCycle";
import STOCKCYCLEDETAILSCREEN from "./page/Phase2/CheckStock/StockCycleDetail";
import REPORTSTOCKCYCLESCREEN from "./page/Phase2/CheckStock/ReportStockCycle";

const Routes = (props) => (
  <Router {...props}>
    <Route path="/" component={LOADING} />
    <Route path="/login" component={LOGIN} />
    <Route path="/dashboard" component={DASHBOARD} />
    <Route path="/user" component={USER} />
    <Route path="/role" component={ROLE} />
    <Route path="/role/create" component={ROLECREATE} />
    <Route path="/role/edit" component={ROLEUPDATE} />
    <Route path="/company" component={COMPANY} />
    <Route path="/member" component={MEMBER} />
    <Route path="/rate-member" component={RATEMEMBER} />
    <Route path="/sale" component={SALE} />
    <Route path="/sale/payment-receive" component={PAYMENTRECEIVE} />
    <Route path="/sale-member" component={SALEMEMBER} />
    <Route path="/product" component={PRODUCT} />
    <Route path="/updateprice" component={UPDATEPRICESCREEN} />
    <Route path="/category" component={PRODUCTCATEGORY} />
    <Route path="/product/create" component={PRODUCTCREATE} />
    <Route path="/product/edit" component={PRODUCTUPDATE} />
    <Route path="/product/import" component={PRODUCTIMPORT} />

    <Route path="/product/receipt" component={RECEIPT} />
    <Route path="/product/receipt/receipt-create" component={RECEIPTCREATE} />
    <Route path="/product/receipt/receipt-update" component={RECEIPTUPDATE} />
    <Route path="/product/receipt/receipt-update-costprice" component={RECEIPTUPDATECOSTPRICE} />

    <Route path="/stock-move" component={STOCKMOVE} />
    <Route path="/stock-move/create" component={STOCKMOVECREATE} />

    <Route path="/stock-adjustment" component={STOCKADJUSTMENT} />
    <Route path="/stock-adjustment/create" component={STOCKADJUSTMENTCREATE} />
    <Route path="/stock-adjustment/update" component={STOCKADJUSTMENTUPDATE} />
    <Route path="/stock-adjustment/examine" component={STOCKADJUSTMENTCHECKSTOCK} />
    <Route path="/stock-adjustment/detail" component={STOCKADJUSTMENTDETAIL} />

    <Route path="/stock-location" component={STOCKLOCATION} />

    <Route path="/invoice" component={INVOICE} />
    <Route path="/invoice/create" component={INVOICECREATE} />

    <Route path="/status-delivery/create" component={STATUSDELIVERY} />
    <Route path="/status-delivery" component={LISTSTATUSDELIVERY} />
    <Route path="/commission-type" component={COMMISSIONTYPE} />

    {/* report */}
    <Route path="/report" component={REPORT} />
    <Route path="/reportinventory" component={REPORTINVENTORY} />
    <Route path="/reportproductsandservices" component={REPORTPRODUCTSANDSERVICES} />
    <Route path="/reportsalescustomers" component={REPORTSALESCUSTOMERS} />
    <Route path="/reportsalesproducts" component={REPORTSALESPRODUCTS} />
    <Route path="/reporttransfer" component={REPORTTRANSFER} />
    <Route path="/reportexpenses" component={REPOREXPENSES} />
    <Route path="/reportrefund" component={REPORTREFUND} />
    <Route path="/reportreciept" component={REPORTRECIEPT} />

    <Route path="/warehouse" component={WAREHOUSE} />
    <Route path="/bank" component={BANK} />

    {/* main */}
    <Route path="/main" component={MAINSCREEN} />
    <Route path="/transaction" component={TRANSATIONSCREEN} />
    <Route path="/accrued" component={ACCRUEDSCREEN} />
    <Route path="/accrued/list-accrued" component={LISTACCRUEDSCREEN} />

    {/* Phase2 */}
    {/* ค่าใช้จ่าย */}
    <Route path="/expenses" component={EXPENSES} />
    <Route path="/expenses/create" component={EXPENSESCREATE} />
    <Route path="/expenses/type" component={EXPENSESTYPESCREEN} />
    <Route path="/expenses/update" component={EXPENSESTUPDATE} />

    {/* คืนสินค้า */}
    <Route path="/refund" component={REFUND} />
    <Route path="/refund/create" component={REFUNDCREATE} />
    <Route path="/refund/update" component={REFUNDUPDATE} />
    <Route path="/refund/details" component={REFUNDDETAILS} />

    {/* ประวัติการตรวจสอบ */}
    <Route path="/logaudit" component={LISTLOGAUDITSCREEN} />
    <Route path="/logaudit/detail" component={LOGAUDITSCREEN} />

    {/* เงินคืนสะสม */}
    <Route path="/refund-accumulate" component={REFUNDACCUMULATE} />

    <Route path="/generate-barcode" component={GENERATEBARCODESCREEN} />

    <Route path="/check-stock" component={CHECKSTOCKSCREEN} />
    <Route path="/stock-cycle" component={STOCKCYCLESCREEN} />
    <Route path="/stock-cycle-detail" component={STOCKCYCLEDETAILSCREEN} />
    <Route path="/report-stock-cycle" component={REPORTSTOCKCYCLESCREEN} />

    <Route path="/*" component={PAGENOTFOUND} />
  </Router>
);
export default Routes;
