import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { tokens, baht, number, GET } from "../../../components/CustomComponent.js";
import "../../../assets/css/bootstrap.css";
export default class RefundAccumulateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      index_onClick: "",
      id: "",

      search: "",
      search_detail: "",

      title_name: "",
      start_date: "",
      end_date: "",

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,
      details: null,
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/refund/point-member?data_search=" + this.state.search + "&start_date=" + this.state.start_date + "&end_date=" + this.state.end_date, null);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="เงินคืนสะสม" />
            <div style={{ height: 60 }}></div>
            <div className="row w-100 mt-3 mx-0">
              {/* รายการยอด */}
              <div className="w-100 col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 mb-4">
                <div className="w-100 mb-3">
                  <h3>
                    <b>รายการยอดเงินคืนสะสม</b>
                  </h3>
                  <label className="text-msg text-14">ทั้งหมด {this.state.data ? number(this.state.data.data.length) : "0"} รายการ</label>
                </div>
                <div className="w-100">
                  <div className="card">
                    <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                      {/* ค้นหา */}
                      <div className="col-6 col-sm-6 col-xl-6 d-flex mb-2">
                        <div className="w-100">
                          <input
                            className="form-control-search"
                            type="search"
                            placeholder="พิมพ์คำค้นหา"
                            value={this.state.search}
                            onChange={(e) => {
                              this.setState({ page: 1, search: e.target.value, loading: false });
                              if (e.target.value === "") {
                                this.setState({ search: "" });
                                setTimeout(() => {
                                  this.GetAll();
                                  this.setState({ loading: false });
                                }, 20);
                              } else {
                                setTimeout(() => {
                                  this.GetAll();
                                  this.setState({ loading: false });
                                }, 20);
                              }
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-12 d-flex">
                                                <div className="d-flex row mx-0 w-100">
                                                    <div className="d-flex row mx-0 align-items-center">
                                                        <input
                                                            type="date"
                                                            className="form-control-select wpx-150 mb-1 mt-1"
                                                            value={this.state.start_date}
                                                            placeholder="วันที่เริ่มต้น"
                                                            onChange={(e) => {
                                                                this.setState({ page: 1, start_date: e.target.value })

                                                                setTimeout(() => {
                                                                    this.GetAll()
                                                                }, 20)
                                                            }}
                                                        ></input>
                                                        <label className="mx-2 ">ถึง</label>
                                                        <input
                                                            type="date"
                                                            className="form-control-select wpx-150 mb-1 mt-1 "
                                                            value={this.state.end_date}
                                                            placeholder="วันที่สิ้นสุด"
                                                            onChange={(e) => {

                                                                const date_start_date = new Date(this.state.start_date).getTime();
                                                                const date_end_date = new Date(e.target.value).getTime();

                                                                if (date_start_date <= date_end_date) {
                                                                    this.setState({ page: 1, end_date: e.target.value })
                                                                    setTimeout(() => {
                                                                        this.GetAll()
                                                                    }, 20)
                                                                }
                                                            }}
                                                        ></input>


                                                        <button type="button" className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                                                            onClick={() => {

                                                                this.setState({
                                                                    page: 1,
                                                                    search: "",
                                                                    data: null,

                                                                    index_onClick: "",
                                                                    start_date: "",
                                                                    end_date: "",

                                                                    search_detail: "",

                                                                    title_name: "",
                                                                    details: null
                                                                })


                                                                setTimeout(() => {
                                                                    this.GetAll()
                                                                }, 20)
                                                            }}>
                                                            <span className="icon-brand text-18 ">{"\uf2f9"}
                                                            </span>  รีเซ็ท
                                                        </button>

                                                    </div>
                                                </div>
                                            </div> */}
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive rounded-16 height-vh overflow-auto">
                        <table className="table table-hover table-striped table-borderless">
                          <thead>
                            <tr className=" bg-white">
                              <th className="text-center">
                                <b>ลำดับ</b>
                              </th>
                              <th className="text-left" style={{ minWidth: 100 }}>
                                <b>ชื่อลูกค้า</b>
                              </th>
                              <th className="text-right" style={{ minWidth: 150 }}>
                                <b>ยอดเงินคืนสะสม</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                              <tr>
                                <td colSpan={4} className="text-center table-light height-vh">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}

                            {this.state.data &&
                              this.state.data.data.map((item, index) => (
                                <tr
                                  className="pointer"
                                  key={index}
                                  onClick={() => {
                                    this.setState({
                                      title_name: item.member_name,
                                      index_onClick: index,
                                      details: item.refund_list,
                                      search_detail: "",
                                    });
                                  }}
                                >
                                  <td className={index === this.state.index_onClick ? "text-center text-white bg-primary" : "text-center"}>{Number(index + 1)}</td>
                                  <td className={index === this.state.index_onClick ? "text-left text-white bg-primary" : "text-left"}>{item.member_name}</td>
                                  <td className={index === this.state.index_onClick ? "text-right text-white bg-primary" : "text-right"}>{baht(item.net_total)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* รายละเอียด */}
              <div className="w-100 col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 mb-4 ">
                <div className="w-100 mb-3">
                  <h3>
                    <b>เงินคืนสะสม : {this.state.title_name ? this.state.title_name : ""} </b>
                  </h3>
                  <label className="text-msg text-14">ทั้งหมด {this.state.details ? number(this.state.details.length) : "0"} รายการ</label>
                </div>
                <div className="w-100">
                  <div className="card">
                    <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                      {/* ค้นหา */}
                      <div className="col-5 col-sm-5q col-md-4 col-lg-6 col-xl-6 d-flex  ">
                        <input
                          className="form-control-search  mb-1 mt-1"
                          type="search"
                          placeholder="พิมพ์คำค้นหา"
                          value={this.state.search_detail}
                          onChange={(e) => {
                            this.setState({ search_detail: e.target.value, loading: false });
                          }}
                        />
                      </div>
                    </div>

                    {/* table detail */}
                    <div className="card-body p-0 ">
                      <div className="table-responsive rounded-16 height-vh overflow-auto">
                        <table className="table  table-hover table-striped table-borderless">
                          <thead>
                            <tr>
                              <th className="text-left" style={{ minWidth: 100 }}>
                                <b>เลขที่เอกสาร</b>
                              </th>
                              <th className="text-left" style={{ minWidth: 150 }}>
                                <b>เลขที่ invoice</b>
                              </th>
                              <th className="text-right" style={{ minWidth: 100 }}>
                                <b>ยอดเงินคืนสินค้า</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {((this.state.details && this.state.details.length === 0) || !this.state.details) && (
                              <tr>
                                <td colSpan={3} className="text-center table-light height-vh">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}

                            {this.state.details &&
                              this.state.details
                                .filter((data) => data.rc_number.includes(this.state.search_detail) || this.state.search_detail === "")
                                .map((item, index) => (
                                  <tr key={index} className="pointer">
                                    <td className="text-left">{item.rc_number}</td>
                                    <td className="text-left">{item.invoice_number}</td>
                                    <td className="text-right">{baht(item.net_total)}</td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
