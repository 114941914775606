/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { alert, alert_url, GET, POST, tokens } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";

const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 60,
    padding: 8,
    paddingBottom: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const UserOption = (props) => (
  <Option {...props}>
    <div className="w-100 d-flex justify-content-between align-content-center">
      <label className="w-70">
        {props.data.value.product_main_data.product_code}, {props.data.value.product_main_data.product_name}
      </label>
    </div>
    <div className="w-100 d-flex justify-content-end">
      <p className="text-12 text-login mr-2">ไม้ในคลัง {props.data.value.stock_qty}</p>
      <p className="text-12 text-login ">หลาในคลัง {props.data.value.yard_qty}</p>
    </div>
  </Option>
);

export default class StockMoveCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modal_add: false,
      modal_edit: false,

      date: ("00" + new Date().getDate()).slice(-2) + "/" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "/" + (new Date().getFullYear() + 543),

      bill_receipt_code: "SPM" + new Date().getTime(),

      data: null,
      warehouselist: null,
      product_list: null,
      product: null,

      search: "",
      stock_location_from_id: "",
      stock_location_to_id: "",

      qty_move: null,

      wood: 0,

      stock_qty: 0,
      yard_qty: 0,
      total_yard: 0,

      sum_total_yard: 0,

      index: 0,

      user_id: "",
      product_id: "",
      type_product_id: "",
      status_url: "",
      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",
      product_color: "",

      search_product: "",
    };
  }

  async componentDidMount() {
    await this.GetWareHouse();
  }
  GetWareHouse = async () => {
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);
    if (result && result.status) {
      this.setState({ warehouselist: result.data.data });
    }
    this.setState({ loading: false });
  };

  Get_Product_All = async (id) => {
    this.setState({ loading: true });

    let result = await GET(tokens, "v1/product-mgt/all/filter-search/page/0?data_search" + this.state.search + "&status=" + this.state.status_url + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + id + "&user_member_id=" + this.state.user_id + "&product_id=" + this.state.product_id + "&sort_name=" + this.state.sort_name + "&sort_yard=" + this.state.sort_yard + "&sort_color=" + this.state.sort_color + "&product_design=" + this.state.design_value.replaceAll("#", "%23") + "&color=" + this.state.product_color, null);

    if (result && result.status) {
      this.setState({ product_list: result.data });
    }
    this.setState({ loading: false });
  };

  Add_Product = () => {
    let data = { ...this.state.product.value };
    if (this.state.data) {
      let d = this.state.data.filter((e) => e.all_stock_id === this.state.product.value.all_stock_id);

      if (d.length !== 0) {
        let array = this.state.data;
        array.find((e) => {
          if (e.stock_main_id === this.state.product.value.stock_main_id) {
            if (e.qty + 1 <= e.stock_qty) {
              e.qty += 1;
            }
          }
        });
        this.setState({ data: array });
      } else {
        let array = this.state.data;
        array.push(data);
        this.setState({ data: array });
      }
    } else {
      this.setState({ data: [data] });
    }

    this.setState({ modal_add: false });
  };

  AddStock = async () => {
    this.setState({ loading: true });
    let data = this.state.data;
    let array_data = [];

    let date = ("00" + new Date().getDate()).slice(-2) + "/" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "/" + new Date().getFullYear();

    data.forEach((e) => {
      let t = {
        all_stock_id: e.all_stock_id,
        qty: e.total_wood,
        yard_qty: parseFloat(e.total_yard),
      };
      array_data.push(t);
    });

    let body = {
      warehouse_from_id: this.state.stock_location_from_id,
      warehouse_to_id: this.state.stock_location_to_id,
      document_code: this.state.bill_receipt_code,
      date_stock_move: date,
      all_stock_list: array_data,
    };

    let result = await POST(tokens, "v1/stock-mgt/move/create", body);
    if (result && result.status) {
      alert_url("success", "ย้ายสต๊อกสินค้าเรียบร้อยแล้ว", "สต๊อกสินค้า ถูกย้ายในในระบบแล้ว", " /stock-move");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
    }
    this.setState({ loading: false });
  };

  MoveWood() {
    let array_wood = [];
    for (let i = 0; i < Number(this.state.wood); i++) {
      array_wood.push(0);
    }
    this.setState({ qty_move: array_wood, sum_total_yard: 0 });
  }

  Calculate_Yard() {
    let total_yard = 0;
    for (let sum of this.state.qty_move) {
      if (sum === 0) {
        alert("warning", "แจ้งเตือน", "จำนวนหลาต้องไม่เท่ากับ 0 หรือค่าว่าง");
        return;
      }
      total_yard += Number(sum);
    }
    if (!this.state.wood) {
      alert("warning", "แจ้งเตือน", "กรุณากรอกจำนวนไม้");
      return;
    } else if (Number(this.state.wood) === Number(this.state.stock_qty) && total_yard !== Number(this.state.yard_qty)) {
      alert("warning", "แจ้งเตือน", "กรุณากรอกจำนวนหลาให้ครบถ้วน");
      return;
    } else if (Number(this.state.stock_qty) - Number(this.state.wood) > Number(this.state.yard_qty) - total_yard) {
      alert("warning", "แจ้งเตือน", "จำนวนหลามีน้อยกว่าจำนวนไม้ที่เหลืออยู่ กรุณากรอกจำนวนหลาใหม่อีกครั้ง");
      return;
    }
    let array_data = this.state.data;
    array_data[this.state.index].total_wood = Number(this.state.wood);
    array_data[this.state.index].array_yard = this.state.qty_move;
    array_data[this.state.index].total_yard = Number(total_yard);

    setTimeout(() => {
      this.setState({ data: array_data, sum_total_yard: total_yard, modal_edit: false });
    }, 10);
  }

  ClearForm() {
    this.setState({
      loading: false,
      product: null,

      product_list: null,

      search: "",
      stock_location_from_id: "",
      stock_location_to_id: "",

      user_id: "",
      product_id: "",
      type_product_id: "",
      status_url: "",
      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",
    });
  }

  ClearDataProduct() {
    this.setState({
      product: null,
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="รายการย้ายสต๊อก" page3="ย้ายสต๊อก" />
            <div style={{ height: 60 }}></div>
            <div className="mx-5 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3">
                <h3>
                  <b>ย้ายสต๊อก</b>
                </h3>
              </div>

              {/* title */}
              <div className="w-100 m-3">
                <div className="card p-3">
                  <div className="d-flex row mx-0 align-items-center justify-content-between">
                    <div className="col-12 col-xl-6 d-flex align-items-end mb-3">
                      <div className="w-100 mr-2">
                        <label className="w-100">เลขที่เอกสาร:</label>
                        <input type="text" className="form-control w-100 border-0 pointer" value={this.state.bill_receipt_code} readOnly></input>
                      </div>
                      <div className="w-100 ml-2">
                        <label className="w-100">วันที่:</label>
                        <input type="text" className="form-control w-100 border-0 pointer" value={this.state.date} readOnly></input>
                      </div>
                      <div className="border-right d-none ml-4 d-xl-block" style={{ height: 50 }}></div>
                    </div>

                    <div className="col-12 col-xl-6 d-flex align-items-end mb-3">
                      <div className="w-100">
                        <label className="w-100">สถานที่:</label>
                        <select
                          className="form-control w-100 border-0 pointer"
                          onChange={(e) => {
                            this.setState({
                              stock_location_from_id: e.target.value,
                            });
                            this.Get_Product_All(e.target.value);
                          }}
                          value={this.state.stock_location_from_id}
                          disabled={this.state.data === null || this.state.data.length === 0 ? "" : "disabled"}
                        >
                          <option value="" selected disabled>
                            -- เลือก --
                          </option>
                          {this.state.warehouselist &&
                            this.state.warehouselist.map((item, index) => (
                              <option key={index} value={item.warehouse_id}>
                                {item.warehouse_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <label className="mx-3 wpx-50 text-center">ไปยัง</label>
                      <div className="w-100">
                        <label className="w-100">สถานที่:</label>
                        <select
                          className="form-control w-100 border-0 pointer"
                          onChange={(e) => {
                            this.setState({
                              stock_location_to_id: e.target.value,
                            });
                          }}
                          disabled={this.state.data === null || this.state.data.length === 0 ? "" : "disabled"}
                          value={this.state.stock_location_to_id}
                        >
                          <option value="" selected disabled>
                            -- เลือก --
                          </option>
                          {this.state.warehouselist &&
                            this.state.warehouselist.map((item, index) => (
                              <option key={index} value={item.warehouse_id}>
                                {item.warehouse_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* table */}
              <div className="w-100 m-3 pt-3">
                <div className="card" style={{ marginBottom: 32 }}>
                  <div className="card-header rounded-top">
                    <div className="w-100 d-flex justify-content-between align-items-center pt-2 rounded-top-16">
                      <h5>
                        <b>สินค้าที่ต้องการย้าย</b>
                      </h5>
                      <div>
                        <button
                          className="btn btn-outline-primary px-3 bg-white"
                          onClick={() => {
                            if (this.state.stock_location_from_id === "") {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกโกดัง");
                              return;
                            } else if (this.state.stock_location_to_id === "") {
                              alert("info", "แจ้งเตือน", "กรุณาเลือกสถานที่ปลายทาง");
                              return;
                            }
                            this.ClearDataProduct();

                            this.setState({
                              modal_add: true,
                            });
                          }}
                        >
                          + เพิ่มสินค้า
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive rounded-16" style={{ maxHeight: "45vh" }}>
                      <table className="table table-striped table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>รหัสสินค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 150 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            <th className="text-left">
                              <b>ประเภท</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 80 }}>
                              <b>สี</b>
                            </th>
                            <th className="text-left">
                              <b>ดีไซน์</b>
                            </th>
                            <th className="text-right">
                              <b>จำนวน(ไม้)</b>
                            </th>
                            <th className="text-right">
                              <b>จำนวน(หลา)</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 150 }}>
                              <b>นำออก</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.length === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={8} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                          {this.state.data &&
                            this.state.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className="text-left">{item.product_main_data.product_code}</td>
                                <td className="text-left">{item.product_main_data.product_name}</td>
                                <td>{item.product_main_data.relation_type_product}</td>
                                <td>{item.product_main_data.product_color}</td>
                                <td>{item.product_main_data.product_design}</td>
                                <td className="text-right"> {item.total_wood ? item.total_wood : null}</td>
                                <td className="text-right">{item.total_yard ? Number(item.total_yard).toFixed(2) : null}</td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-link text-secondary"
                                    onClick={() => {
                                      this.setState({
                                        modal_edit: true,
                                        stock_qty: item.stock_qty,
                                        yard_qty: item.yard_qty,
                                        wood: item.total_wood,
                                        index: index,
                                      });
                                      if (item.array_yard) {
                                        this.setState({
                                          qty_move: item.array_yard,
                                        });
                                      } else {
                                        setTimeout(() => {
                                          this.MoveWood();
                                        }, 10);
                                      }
                                    }}
                                  >
                                    <label
                                      className="icon bg-secondary-phone text-14 text-white pointer"
                                      style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: 12,
                                      }}
                                    >
                                      {"\uf040"}
                                    </label>
                                  </button>
                                  <button
                                    className="btn btn-link text-secondary"
                                    onClick={() => {
                                      let data = this.state.data;
                                      data.splice(index, 1);
                                      this.setState({ data: data });
                                    }}
                                  >
                                    <label
                                      className="icon bg-danger text-14 text-white pointer"
                                      style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: 12,
                                      }}
                                    >
                                      {"\uf00d"}
                                    </label>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="d-flex">
              <button
                className="btn btn-outline-primary mx-2 px-5"
                onClick={() => {
                  window.location.href = "/stock-move";
                }}
              >
                ยกเลิก
              </button>
              <button
                className="btn btn-primary mx-2 px-5"
                onClick={() => {
                  if (this.state.stock_location_from_id === "") {
                    alert("info", "แจ้งเตือน", "กรุณาเลือกสถานที่ต้นทาง");
                    return;
                  } else if (this.state.stock_location_to_id === "") {
                    alert("info", "แจ้งเตือน", "กรุณาเลือกสถานที่ปลายทาง");
                    return;
                  } else if (this.state.data === null) {
                    alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการย้าย");
                    return;
                  }

                  this.AddStock();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>

        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearDataProduct();
          }}
        >
          <Modal.Body>
            <div className="d-flex w-100 justify-content-between align-items-center mb-3">
              <h3>
                <b className=" text-18">ค้นหาสินค้า</b>
              </h3>
              <button
                className="btn btn-link text-secondary"
                onClick={() => {
                  this.setState({ modal_add: false });
                  this.ClearDataProduct();
                }}
              >
                <label
                  className="icon bg-gray text-14 text-white"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                >
                  {"\uf00d"}
                </label>
              </button>
            </div>
            <hr />
            {this.state.product && (
              <div className="card rounded-8 btn-select-member p-3 mb-3">
                <div className="d-flex justify-content-between">
                  <h5 className="text-title">ชื่อสินค้า: {this.state.product.value.product_main_data.product_name}</h5>
                </div>
                <label className="text-detail-name text-16 ">รหัสสินค้า: {this.state.product.value.product_main_data.product_code}</label>
              </div>
            )}
            <div className="w-100 d-flex mb-3">
              <div className="w-100">
                <Select
                  components={{ Option: UserOption }}
                  styles={customStyles}
                  options={
                    this.state.product_list &&
                    this.state.product_list.data &&
                    this.state.product_list.data.data_returns.map((item, index) => ({
                      value: item,
                      label: item.product_main_data.product_code + "," + item.product_main_data.product_name,
                    }))
                  }
                  placeholder="พิมพ์ชื่อสินค้า"
                  onChange={(e) => {
                    this.setState({
                      product: e,
                    });
                  }}
                  value={this.state.product}
                  onInputChange={(e) => {
                    this.setState({ search_product: e });
                    setTimeout(() => {
                      if (this.state.search_product.includes("PD-") && !this.state.search_product.includes(".") && this.state.search_product.length >= 12) {
                        this.setState({ search_product: this.state.search_product.split("/")[0] });
                      } else if (this.state.search_product.includes("PD-") && this.state.search_product.includes(".") && this.state.search_product.length >= 14) {
                        this.setState({ search_product: this.state.search_product.split("/")[0] });
                      }
                    }, 10);
                  }}
                  inputValue={this.state.search_product}
                  // onKeyDown={(e) => {
                  //     if (e.target.value.includes("PD-") && e.target.value.length === 12) {
                  //         e.target.value = (e.target.value).split("/")[0];
                  //     }
                  //     e.focus
                  // }}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearDataProduct();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.product === null) {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการย้าย");
                      return;
                    }

                    this.Add_Product();
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Edit modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false });
          }}
        >
          <Modal.Body>
            <div className="d-flex w-100 justify-content-between align-items-center mb-3">
              <h3>
                <b className=" text-18">สินค้าที่ต้องการย้าย</b>
              </h3>
              <button
                className="btn btn-link text-secondary"
                onClick={() => {
                  this.setState({ modal_edit: false });
                }}
              >
                <label
                  className="icon bg-gray text-14 text-white"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                >
                  {"\uf00d"}
                </label>
              </button>
            </div>
            <hr />

            <div className="card bg-primary px-3 pb-1 pt-1 mb-3 text-white">
              <div className="d-flex justify-content-between">
                <label>
                  จำนวน(ไม้) ในสต๊อก: <b className=" text-dark">{this.state.stock_qty}</b>
                </label>
                <label>
                  จำนวน(หลา) ในสต๊อก: <b className=" text-dark">{this.state.yard_qty}</b>
                </label>
              </div>
            </div>

            {/* จำนวนไม้ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>จำนวนไม้</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={14}
                  className="form-control bg-secondary-light border-0 text-right"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    if (/^\d*?\d{0}$/.test(e.target.value) && Number(e.target.value) >= 0 && e.target.value <= Number(this.state.stock_qty) && Number(e.target.value) < 999999999) {
                      this.setState({
                        wood: e.target.value,
                      });

                      setTimeout(() => {
                        this.MoveWood();
                      }, 10);
                    } else if (Number(this.state.stock_qty) === 0) {
                      alert("warning", "แจ้งเตือน", "จำนวนสินค้าไม่เพียงพอ");
                      this.setState({
                        wood: Number(this.state.stock_qty),
                      });
                      return;
                    } else {
                      this.setState({
                        wood: Number(this.state.stock_qty),
                      });

                      setTimeout(() => {
                        this.MoveWood();
                      }, 10);
                    }
                  }}
                  value={this.state.wood}
                />
              </div>
            </div>

            <div className="card bg-secondary-light px-3 pb-1 pt-1 mb-3">
              <div className="d-flex justify-content-between">
                <label>ไม้</label>
                <label>หลา</label>
              </div>
            </div>
            <div className="overflow-auto " style={{ maxHeight: "50vh" }}>
              {this.state.qty_move &&
                this.state.qty_move.map((item, index) => (
                  <div className="w-100 d-flex mb-3 mt-3  px-3" key={index}>
                    <div className="wpx-120">
                      <label>ไม้ {index + 1}</label>
                    </div>
                    <div className="w-100">
                      <input
                        maxLength={14}
                        className="form-control bg-secondary-light border-0 text-right"
                        type="text"
                        placeholder="กรอกข้อมูล"
                        onChange={(e) => {
                          if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                            let total_yard = 0;
                            let data_qty = this.state.qty_move;
                            data_qty[index] = e.target.value;
                            this.setState({ qty_move: data_qty });

                            for (let sum of this.state.qty_move) {
                              total_yard += Number(sum);
                            }

                            setTimeout(() => {
                              this.setState({ sum_total_yard: total_yard });
                            }, 10);
                          }
                        }}
                        id={"txt" + index}
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            if (this.state.qty_move.length > index + 1) {
                              document.getElementById(`txt` + (index + 1)).focus();
                            }
                          }
                        }}
                        value={item}
                      />
                    </div>
                  </div>
                ))}
            </div>

            <div className="w-100 d-flex mb-3 justify-content-between  ">
              <div>
                <label>
                  <b>รวม</b>
                </label>
              </div>
              <div>
                <b>{Number(this.state.sum_total_yard).toFixed(2)}</b>
              </div>
            </div>

            <div className="w-100 d-flex mb-3 justify-content-between  ">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.wood < 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาจำนวนไม้ที่ต้องการย้าย");
                      return;
                    } else if (Number(this.state.sum_total_yard) > Number(this.state.yard_qty)) {
                      alert("warning", "แจ้งเตือน", "จำนวนหลาไม่เพียงพอสำหรับการย้าย");
                      return;
                    }

                    setTimeout(() => {
                      this.Calculate_Yard();
                    }, 10);
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
