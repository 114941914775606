import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { GET, tokens, baht, total, alert, report_reciept, format_date } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class ReportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,
      data: null,
      search: "",
      modal_add: false,

      type: "Cash",

      start_date: "",
      end_date: "",
      user_type: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).user_type : "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/report/goods-recepit", null);

    if (result && result.status) {
      this.setState({
        data: result.data.data.map((e) => {
          return {
            ...e,
            yard_total: total(e.goods_receipt_list, "yard_qty"),
            wood_total: total(e.goods_receipt_list, "qty"),
            total_price_by_item: total(e.goods_receipt_list, "total_price_by_item"),
          };
        }),
      });
    }
    this.setState({ loading: false });
  };
  ClearForm() {
    this.setState({
      page: 1,
      type: "Cash",
      start_date: "",
      end_date: "",
      data: null,
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="สรุปรายงาน" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายงานรับเข้า</b>
              </h3>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    if (this.state.data.length === 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกข้อมูลที่ต้องการออกรายงาน");
                      return;
                    }
                    report_reciept(this.state.data.filter((e) => this.state.search === "" || JSON.stringify(e).includes(this.state.search)).filter((item) => (this.state.start_date !== "" && this.state.end_date !== "" ? new Date(this.state.start_date).getTime() <= new Date(item.receive_date).getTime() && new Date(item.receive_date).getTime() <= new Date(this.state.end_date).getTime() : true)));
                  }}
                >
                  <span className="icon-brand text-18 ">{"\uf6dd"}</span> ออกรายงาน
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-xl-12 d-flex">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex mx-0 align-items-center">
                        <input
                          maxLength={30}
                          className="form-control-search mr-2"
                          type="search"
                          placeholder="พิมพ์คำค้นหา"
                          value={this.state.search}
                          onChange={(e) => {
                            this.setState({ page: 1, search: e.target.value, loading: false });
                          }}
                        />
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({ page: 1, start_date: e.target.value });
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                            }
                          }}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-primary ml-2 mb-1 "
                          onClick={() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }}
                        >
                          {" "}
                          ค้นหา
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.setState({
                              page: 1,
                              search: "",
                              start_date: "",
                              end_date: "",
                              loading: false,
                            });
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="table-responsive rounded-16">
                    <table className="table table-borderless table-sm">
                      {this.state.data &&
                        this.state.data
                          .filter((e) => this.state.search === "" || JSON.stringify(e).includes(this.state.search))
                          .filter((item) => (this.state.start_date !== "" && this.state.end_date !== "" ? new Date(this.state.start_date).getTime() <= new Date(item.receive_date).getTime() && new Date(item.receive_date).getTime() <= new Date(this.state.end_date).getTime() : true))

                          .map((item, index) => (
                            <tbody>
                              <tr key={index} className="bg-white">
                                <td className="text-left pt-4" colSpan={this.state.user_type === "owner" ? 2 : 1}>
                                  <b>วันที่รับ :</b> {format_date(item.receive_date)}
                                </td>
                                <td className="text-left pt-4">
                                  <b>รหัสใบรับสินค้า :</b> {item.bill_receipt_code}
                                </td>
                                <td className="text-left pt-4">
                                  <b>ผู้จำหน่าย :</b> {item.user_approve}
                                </td>
                                <td className="text-left pt-4">
                                  <b>เอกสารอ้างอิง :</b> {item.ref_receipt_code}
                                </td>
                                <td className="text-left pt-4">
                                  <b>โกดัง :</b> {item.goods_receipt_list[0].warehouse}
                                </td>
                              </tr>
                              <tr className="bg-primary text-white">
                                <td className="text-center">ลำดับ</td>
                                <td className="text-center">รหัสสินค้า</td>
                                <td className="text-center">ชื่อสินค้า</td>
                                <td className="text-center">จำนวนหลา</td>
                                <td className="text-center">ไม้</td>
                                {this.state.user_type === "owner" ? <td></td> : null}
                                {this.state.user_type === "owner" ? <td className="text-center">ราคาที่รับมา</td> : null}
                                {this.state.user_type === "owner" ? <td className="text-center">ยอดรวมสุทธิ</td> : null}
                              </tr>
                              {item.goods_receipt_list.map((ex, ex_index) => (
                                <tr className="bg-secondary-light border-bottom">
                                  <td className="text-center">{ex_index + 1}</td>
                                  <td className="text-left">{ex.product_code}</td>
                                  <td className="text-left">{ex.product_name}</td>
                                  <td className="text-center">{ex.yard_qty}</td>
                                  <td className="text-center">{ex.qty}</td>
                                  {this.state.user_type === "owner" ? <td></td> : null}
                                  {this.state.user_type === "owner" ? <td className="text-center">{baht(ex.price_by_item)}</td> : null}
                                  {this.state.user_type === "owner" ? <td className="text-center">{baht(ex.total_price_by_item)}</td> : null}
                                </tr>
                              ))}
                              <tr>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center">
                                  <b>รวมจำนวนหลา : </b>
                                  {item.yard_total}
                                </td>
                                <td className="text-center">
                                  <b>รวมจำนวนไม้ : </b>
                                  {item.wood_total}
                                </td>
                                {this.state.user_type === "owner" ? <td className="text-center"></td> : null}
                                {this.state.user_type === "owner" ? <td className="text-center"></td> : null}
                                {this.state.user_type === "owner" ? (
                                  <td className="text-center">
                                    <b>ราคารวมสุทธิ : </b>
                                    {baht(item.total_price_by_item)}
                                  </td>
                                ) : null}
                              </tr>
                            </tbody>
                          ))}
                      {this.state.user_type === "owner" ? (
                        <tfoot>
                          <tr>
                            <td className="text-center">
                              <h4>
                                <b>รวมทั้งสิ้น</b>
                              </h4>
                            </td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center">
                              <h4>
                                <b>
                                  {this.state.data
                                    ? baht(
                                        total(
                                          this.state.data.filter((e) => this.state.search === "" || JSON.stringify(e).includes(this.state.search)).filter((item) => (this.state.start_date !== "" && this.state.end_date !== "" ? new Date(this.state.start_date).getTime() <= new Date(item.receive_date).getTime() && new Date(item.receive_date).getTime() <= new Date(this.state.end_date).getTime() : true)),

                                          "total_price_by_item"
                                        )
                                      )
                                    : "0.00"}
                                </b>
                              </h4>
                            </td>
                          </tr>
                        </tfoot>
                      ) : null}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
