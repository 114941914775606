/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { alert, alert_url, GET, POST, tokens, baht } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";

const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 70,
    padding: 8,
    paddingBottom: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const UserOption = (props) => (
  <Option {...props}>
    <div className="w-100 d-flex justify-content-between align-content-center">
      <label className="w-70">
        {props.data.value.product_main_data.product_code}, {props.data.value.product_main_data.product_name}
      </label>
    </div>
    <div className="w-100 d-flex justify-content-end">
      <p className="text-12 text-login mr-2">ไม้ในคลัง {props.data.value.stock_qty}</p>
      <p className="text-12 text-login ">หลาในคลัง {props.data.value.yard_qty}</p>
    </div>
  </Option>
);

export default class StockAdjustmentCreateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_add: false,

      data: null,
      index: 0,

      document_code: "SPM" + new Date().getTime(),

      date: ("00" + new Date().getDate()).slice(-2) + "/" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "/" + (new Date().getFullYear() + 543),

      profile: JSON.parse(localStorage.getItem("profile")),

      warehouselist: null,
      product_list: null,
      warehouse_id: "",
      product: null,

      remark_title: "",

      qty: 0,
      yard: 0,

      user_id: "",
      product_id: "",
      type_product_id: "",
      search: "",
      status_url: "",

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",
      product_color: "",
    };
  }
  async componentDidMount() {
    await this.GetWareHouse();
  }
  GetWareHouse = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);
    if (result && result.status) {
      this.setState({ warehouselist: result.data.data });
    }
    this.setState({ loading: false });
  };
  Get_Product_All = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/filter-search/page/0?data_search" + this.state.search + "&status=" + this.state.status_url + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + id + "&user_member_id=" + this.state.user_id + "&product_id=" + this.state.product_id + "&sort_name=" + this.state.sort_name + "&sort_yard=" + this.state.sort_yard + "&sort_color=" + this.state.sort_color + "&product_design=" + this.state.design_value.replaceAll("#", "%23") + "&color=" + this.state.product_color, null);
    if (result && result.status) {
      this.setState({ product_list: result.data });
    }
    this.setState({ loading: false });
  };
  Add_Product = () => {
    let data = { ...this.state.product, qty: "", total_qty: 0, yard: "", total_yard_qty: 0 };
    if (this.state.data) {
      let d = this.state.data.filter((e) => e.all_stock_id === this.state.product.all_stock_id);
      if (d.length !== 0) {
        alert("warning", "แจ้งเตือน", "มีสินค้าชนิดนี้อยู่ในรายการ");
        return;
      } else {
        let array = this.state.data;
        array.push(data);
        this.setState({ data: array });
      }
    } else {
      this.setState({ data: [data] });
    }
    this.setState({ modal_add: false });
  };

  AddStockAdjustment = async () => {
    this.setState({ loading: true });
    let data = this.state.data;
    let array_data = [];

    data.forEach((e) => {
      let t = {
        all_stock_id: e.all_stock_id,
        // จำนวนไม้ที่นับจริงที่ต้องนำไปตัดออก (เลขติดลบ)
        qty: e.total_qty.toString(),
        yard_qty: e.total_yard_qty.toString(),

        // จำนวนไม้และหลา
        total_qty: e.qty.toString(),
        total_yard_qty: e.yard.toString(),
      };
      array_data.push(t);
    });

    let body = {
      document_code: this.state.document_code,
      remark: this.state.remark_title ? this.state.remark_title : "",
      warehouse_id: this.state.warehouse_id,
      all_stock_list: array_data,
    };

    let result = await POST(tokens, "v1/stock-mgt/adjust/create", body);

    if (result && result.status) {
      alert_url("success", "ปรับสต๊อกสินค้าเรียบร้อยแล้ว", "สต๊อกสินค้า ถูกปรับในในระบบแล้ว", " /stock-adjustment");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
    }
    this.setState({ loading: false });
  };

  ClearDataProduct() {
    this.setState({
      product: null,
    });
  }

  ClearForm() {
    this.setState({
      loading: false,
      remark_title: "",

      user_id: "",
      product_id: "",
      type_product_id: "",
      search: "",
      status_url: "",

      index: 0,

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="รายการปรับสต๊อก" page3="ปรับสต๊อก" />
            <div style={{ height: 60 }}></div>
            <div className="mx-4 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3">
                <h3>
                  <b>ปรับสต๊อก</b>
                </h3>
              </div>

              {/* title */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card p-3">
                  <div className="d-flex row align-items-center ">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4  d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">ผู้ปรับสต๊อก:</label>
                        <input type="text" className="form-control bg-secondary-light border-0 " value={this.state.profile && this.state.profile.full_name ? this.state.profile.full_name : ""} readOnly></input>
                      </div>
                    </div>
                    {/* <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 d-flex mb-3 ">
                                            <div className="w-100 ">
                                                <label className="w-100">เลขที่เอกสาร:</label>
                                                <input
                                                    type="text"
                                                    className="form-control w-100 border-0"
                                                    value={this.state.document_code}
                                                    readOnly
                                                ></input>
                                            </div>
                                        </div> */}
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 d-flex mb-3">
                      <div className="w-100">
                        <label className="w-100">วันที่:</label>
                        <input type="text" className="form-control w-100 border-0" value={this.state.date} readOnly></input>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">สถานที่:</label>
                        <select
                          className="form-control w-100 border-0"
                          onChange={(e) => {
                            this.setState({
                              warehouse_id: e.target.value,
                            });
                            this.Get_Product_All(e.target.value);
                          }}
                          value={this.state.warehouse_id}
                          disabled={this.state.data === null || this.state.data.length === 0 ? "" : "disabled"}
                        >
                          <option value="" selected disabled>
                            -- เลือก --
                          </option>
                          {this.state.warehouselist &&
                            this.state.warehouselist.map((item, index) => (
                              <option key={index} value={item.warehouse_id}>
                                {item.warehouse_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex row align-items-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">หมายเหตุการปรับ:</label>
                        <input
                          type="text"
                          maxLength={50}
                          className="form-control w-100 border-0"
                          value={this.state.remark_title}
                          placeholder="อธิบายพอสังเขป"
                          onChange={(e) => {
                            this.setState({ remark_title: e.target.value });
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* table */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card" style={{ marginBottom: 32 }}>
                  <div className="card-header rounded-top-16">
                    <div className="w-100 d-flex justify-content-between align-items-center pt-2">
                      <h5>
                        <b>สินค้าที่ต้องการปรับ</b>
                      </h5>
                      <div>
                        <button
                          className="btn btn-outline-primary px-3 bg-white"
                          onClick={() => {
                            if (this.state.warehouse_id === "") {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกโกดัง");
                              return;
                            }
                            this.ClearDataProduct();

                            this.setState({
                              modal_add: true,
                            });
                          }}
                        >
                          + เพิ่มสินค้า
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body ">
                    <div className="table-responsive rounded-16 tableFixHead">
                      <table className="table table-striped table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 90 }}>
                              <b>รหัสสินค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>จำนวนในคลัง(ไม้)</b>
                              </th>
                            )}
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนนับจริง(ไม้)</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>ปรับจำนวน(ไม้)</b>
                              </th>
                            )}
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>จำนวนในคลัง(หลา)</b>
                              </th>
                            )}
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนนับจริง(หลา)</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>ปรับจำนวน(หลา)</b>
                              </th>
                            )}
                            <th className="text-center">
                              <b>นำออก</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.length === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={9} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className="text-left">{item.product_main_data.product_code}</td>
                                <td className="text-left">{item.product_main_data.product_name}</td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{item.stock_qty}</td>}
                                <td className="text-center">
                                  <input
                                    type="text"
                                    className="border wpx-100 hpx-38 form-control bg-white text-right mx-auto"
                                    maxLength={14}
                                    onChange={(e) => {
                                      if (/^\d*?\d{0}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                        let data = this.state.data;
                                        data[index].qty = e.target.value;
                                        data[index].total_qty = data[index].qty ? Number(data[index].qty) - Number(data[index].stock_qty) : data[index].stock_qty;

                                        data[index].yard = item.yard;
                                        data[index].total_yard_qty = data[index].yard ? Number(data[index].yard) - Number(data[index].yard_qty) : data[index].yard_qty.toFixed(2);
                                        this.setState({ data: data });
                                      }
                                    }}
                                    value={item.qty}
                                  />
                                </td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") ? item.total_qty : item.total_qty.toString().includes("-") ? item.total_qty.toString().slice(1) : item.total_qty}</td>}
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{baht(item.yard_qty)}</td>}

                                <td className="text-center">
                                  <input
                                    type="text"
                                    className="border wpx-100 hpx-38 form-control bg-white text-right  mx-auto"
                                    maxLength={14}
                                    onChange={(e) => {
                                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                        let data = this.state.data;
                                        data[index].qty = item.qty;
                                        data[index].total_qty = data[index].qty ? Number(data[index].qty) - Number(data[index].stock_qty) : data[index].stock_qty;

                                        data[index].yard = e.target.value;
                                        data[index].total_yard_qty = data[index].yard ? Number(data[index].yard) - Number(data[index].yard_qty) : data[index].yard_qty.toFixed(2);
                                        this.setState({ data: data });
                                      }
                                    }}
                                    value={item.yard}
                                  />
                                </td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system" ? Number(item.total_yard_qty).toFixed(2) : item.total_yard_qty.toString().includes("-") ? Number(item.total_yard_qty).toFixed(2).toString().slice(1) : Number(item.total_yard_qty).toFixed(2)}</td>}
                                <td className="text-center">
                                  <button
                                    className="btn btn-link text-secondary"
                                    onClick={() => {
                                      let data = this.state.data;
                                      data.splice(index, 1);
                                      this.setState({ data: data });
                                    }}
                                  >
                                    <label
                                      className="icon bg-danger text-12 text-white pointer"
                                      style={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: 12,
                                      }}
                                    >
                                      {"\uf00d"}
                                    </label>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="d-flex">
              <div className="mr-1 w-50">
                <button
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/stock-adjustment";
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="ml-1 w-50">
                <button
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {
                    if (this.state.warehouse_id === "") {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกโกดัง");
                      return;
                    } else if (this.state.data === null) {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการปรับ");
                      return;
                    } else if (this.state.data.length === 0) {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการปรับ");
                      return;
                    }

                    let data = this.state.data;
                    let count = data.filter((item) => item.qty === "" && item.yard === "");
                    if (count.length !== 0) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                    } else {
                      this.AddStockAdjustment();
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearDataProduct();
          }}
        >
          <Modal.Body>
            <div className="d-flex w-100 justify-content-between align-items-center mb-3">
              <h3>
                <b className=" text-18">ค้นหาสินค้า</b>
              </h3>
              <button
                className="btn btn-link text-secondary"
                onClick={() => {
                  this.setState({ modal_add: false });
                  this.ClearDataProduct();
                }}
              >
                <label
                  className="icon bg-gray text-14 text-white"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                >
                  {"\uf00d"}
                </label>
              </button>
            </div>
            <hr />
            {this.state.product && (
              <div className="card rounded-8 btn-select-member p-3 mb-3">
                <div className="d-flex justify-content-between">
                  <h5 className="text-title">ชื่อสินค้า: {this.state.product.product_main_data.product_name}</h5>
                </div>
                <label className="text-detail-name text-16 ">รหัสสินค้า: {this.state.product.product_main_data.product_code}</label>
              </div>
            )}
            <div className="w-100 d-flex mb-3">
              <div className="w-100">
                <Select
                  components={{ Option: UserOption }}
                  styles={customStyles}
                  options={
                    this.state.product_list &&
                    this.state.product_list.data &&
                    this.state.product_list.data.data_returns.map((item, index) => ({
                      value: item,
                      label: item.product_main_data.product_name,
                    }))
                  }
                  placeholder="พิมพ์ชื่อสินค้า"
                  onChange={(e) => {
                    this.setState({
                      product: e.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearDataProduct();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.product === null) {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการปรับ");
                      return;
                    }

                    this.Add_Product();
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
