import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { GET, POST, tokens, role, format_date, ModalDetailsMoveStock, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class StockMoveScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_delete: false,
      modal_detail: false,

      data: null,
      data_detail: "",

      search: "",
    };
  }
  async componentDidMount() {
    this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
    };
    let result = await POST(tokens, "v1/stock-mgt/move/filter-search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/stock-mgt/move/detail/" + id, null);
    if (result && result.status) {
      this.setState({ data_detail: result.data.data });

      setTimeout(() => {
        this.setState({
          modal_detail: true,
        });
      }, 10);

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  ClearForm() {
    this.setState({
      page: 1,
      loading: false,
      data: [],
      search: "",
      modal_delete: false,
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายการย้ายสต๊อก" />
            <div style={{ height: 60 }}></div>

            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายการย้ายสต๊อก</b>
              </h3>
              {role && role.move_stock[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/stock-move/create";
                    }}
                  >
                    {" "}
                    ย้ายสต๊อกสินค้า{" "}
                  </button>
                </div>
              )}
            </div>

            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex justify-content-between">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 130 }}>
                            <b>เลขที่เอกสาร</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>จากสถานที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>ไปยังสถานที่</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>รายละเอียด</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data && this.state.data.data.length === 0 && (
                          <tr>
                            <td colSpan={5} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{format_date(item.datetime_create)}</td>
                              <td className="text-left">{item.document_code}</td>
                              <td className="text-left">{item.warehouse_from_name}</td>
                              <td className="text-left">{item.warehouse_to_name}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    let id = item.stock_move_id;
                                    this.Get_by_id(id);
                                  }}
                                >
                                  <label className="icon mr-2">{"\uf570"}</label>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* modal ลบรายการย้ายสต๊อก */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ลบรายการย้ายสต๊อก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ใจว่าต้องการลบรายการย้ายสต๊อกนี้หรือไม่</div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียด modal */}
        <ModalDetailsMoveStock
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />
      </div>
    );
  }
}
