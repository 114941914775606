import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { alert_url, GET, PUT, tokens } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";

export default class CreateRoleUserScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      role_id: "",
      role_name: "",
      role_description: "",
      role_detail: global.role,
    };
  }
  componentDidMount() {
    if (this.props.location.query.id) {
      this.GetById(this.props.location.query.id);
    }
  }
  GetById = async (id) => {
    this.setState({ loading: true });

    let result = await GET(tokens, "v1/user-admin/role/detail/" + id, null);
    if (result && result.status) {
      let role = this.state.role_detail;
      for (let item of result.data.data.role_detail) {
        let index = role.findIndex((e) => e.menu_name === item.menu_name);

        if (index !== -1) {
          role[index].permission = {
            view: item.permission.view === "true" ? true : false,
            create: item.permission.create === "true" ? true : false,
            update: item.permission.update === "true" ? true : false,
            delete: item.permission.delete === "true" ? true : false,
            download: item.permission.download === "true" ? true : false,
            upload: item.permission.upload === "true" ? true : false,
          };
        }
      }
      this.setState({
        role_id: result.data.data.role_id,
        role_name: result.data.data.role_name,
        role_description: result.data.data.role_description,
        role_detail: role,
      });
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let data = {
      role_id: this.state.role_id,
      role_name: this.state.role_name,
      role_description: this.state.role_description,
      role_detail: this.state.role_detail
        .filter((e) => !e.title)
        .map((item) => {
          return {
            menu_name: item.menu_name,
            permission: {
              view: item.permission.view ? "true" : "false",
              create: item.permission.create ? "true" : "false",
              update: item.permission.update ? "true" : "false",
              delete: item.permission.delete ? "true" : "false",
              download: item.permission.download ? "true" : "false",
              upload: item.permission.upload ? "true" : "false",
            },
          };
        }),
    };
    let result = await PUT(tokens, "v1/user-admin/role/update", data);
    if (result && result.status) {
      alert_url("success", "แก้ไขบทบาทเรียบร้อยแล้ว", "บทบาท ' " + this.state.role_name + " ' แก้ไขแล้ว", "/role");
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  clear_form() {
    this.setState({
      loading: false,
      role_name: "",
      role_description: "",
      permission: global.role_detail,
    });
  }
  select_role = (type, index) => {
    let role = this.state.role_detail;
    if (type === "all") {
      role[index].permission["view"] = !role[index].permission["view"];
      role[index].permission["create"] = !role[index].permission["create"];
      role[index].permission["update"] = !role[index].permission["update"];
      role[index].permission["delete"] = !role[index].permission["delete"];
      role[index].permission["download"] = !role[index].permission["download"];
      role[index].permission["upload"] = !role[index].permission["upload"];
    } else {
      role[index].permission[type] = !role[index].permission[type];
    }
    this.setState({ role_detail: role });
  };
  select_all_role = (type, checked) => {
    let role = this.state.role_detail;
    if (type === "all") {
      for (let r of role) {
        if (r.permission) {
          r.permission["view"] = checked;
          r.permission["create"] = checked;
          r.permission["update"] = checked;
          r.permission["delete"] = checked;
          r.permission["download"] = checked;
          r.permission["upload"] = checked;
        }
      }
    } else {
      for (let r of role) {
        if (r.permission) {
          r.permission[type] = checked;
        }
      }
    }
    this.setState({ role_detail: role });
  };
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="บทบาทผู้ใช้" page3="แก้ไขบทบาทผู้ใช้" />
            <div className="row" style={{ marginTop: 60 }}>
              <div className="col-12 col-md-12 col-xl-10 p-3 mx-auto">
                {/* หัวข้อ */}
                <div className="d-flex row mx-0 align-items-center justify-content-between test">
                  <h4 className="mb-3">
                    <b>แก้ไขบทบาทผู้ใช้งาน</b>
                  </h4>
                  <button
                    className="btn btn-outline-primary px-4 mx-1 mb-3"
                    onClick={() => {
                      window.location.href = "/role";
                    }}
                  >
                    <label className="icon mr-2"> {"\uf060"} </label>
                    ย้อนกลับ
                  </button>
                </div>
                {/* กรอกข้อมูลด้านบน */}
                <div className="w-100 p-3">
                  <div className="card rounded border-0">
                    <div className="card-body">
                      {/* ชื่อบทบาท */}
                      <div className="d-flex row mx-0 align-items-center mb-3">
                        <div className="wpx-120">
                          <label>ชื่อบทบาท:</label>
                        </div>
                        <div className="col-12 col-md-8 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="กรอกข้อมูล"
                            onChange={(e) => {
                              this.setState({ role_name: e.target.value });
                            }}
                            value={this.state.role_name}
                          ></input>
                        </div>
                      </div>
                      {/* รายละเอียด */}
                      <div className="d-flex row mx-0 align-items-center">
                        <div className="wpx-120">
                          <label>รายละเอียด:</label>
                        </div>
                        <div className="col-12 col-md-8 col-lg-6 col-xl-6">
                          <textarea
                            className="form-control"
                            placeholder="กรอกข้อมูล"
                            onChange={(e) => {
                              this.setState({ role_description: e.target.value });
                            }}
                            value={this.state.role_description}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ตาราง */}
                <div className="w-100 p-3" style={{ marginBottom: 100 }}>
                  <div className="card rounded border-0">
                    <div className="card-bodyp-0 overflow-hidden">
                      <div className="table-responsive" style={{ maxHeight: 500 }}>
                        <table className="table table-borderless tableFixHead-primary">
                          <thead>
                            <tr className="bg-primary text-white">
                              <th className="text-left">เมนูจัดการ</th>
                              <th className="text-center wpx-70">
                                <label>ทั้งหมด</label>
                                <br />
                                <input
                                  className="hpx-20"
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.select_all_role("all", e.target.checked);
                                  }}
                                  checked={this.state.role_detail.filter((e) => e.permission && (e.permission["view"] === false || e.permission["create"] === false || e.permission["update"] === false || e.permission["delete"] === false || e.permission["download"] === false || e.permission["upload"] === false)).length === 0}
                                />
                              </th>
                              <th className="text-center wpx-70">
                                <label>ดู</label>
                                <br />
                                <input
                                  className="hpx-20"
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.select_all_role("view", e.target.checked);
                                  }}
                                  checked={this.state.role_detail.filter((e) => e.permission && e.permission["view"] === false).length === 0}
                                />
                              </th>
                              <th className="text-center wpx-70">
                                <label>เพิ่ม</label>
                                <br />
                                <input
                                  className="hpx-20"
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.select_all_role("create", e.target.checked);
                                  }}
                                  checked={this.state.role_detail.filter((e) => e.permission && e.permission["create"] === false).length === 0}
                                />
                              </th>
                              <th className="text-center wpx-70">
                                <label>แก้ไข</label>
                                <br />
                                <input
                                  className="hpx-20"
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.select_all_role("update", e.target.checked);
                                  }}
                                  checked={this.state.role_detail.filter((e) => e.permission && e.permission["update"] === false).length === 0}
                                />
                              </th>
                              <th className="text-center wpx-70">
                                <label>ลบ</label>
                                <br />
                                <input
                                  className="hpx-20"
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.select_all_role("delete", e.target.checked);
                                  }}
                                  checked={this.state.role_detail.filter((e) => e.permission && e.permission["delete"] === false).length === 0}
                                />
                              </th>
                              <th className="text-center wpx-70">
                                <label>ดาวน์โหลด</label>
                                <br />
                                <input
                                  className="hpx-20"
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.select_all_role("download", e.target.checked);
                                  }}
                                  checked={this.state.role_detail.filter((e) => e.permission && e.permission["download"] === false).length === 0}
                                />
                              </th>
                              <th className="text-center wpx-70">
                                <label>อัพโหลด</label>
                                <br />
                                <input
                                  className="hpx-20"
                                  type="checkbox"
                                  onChange={(e) => {
                                    this.select_all_role("upload", e.target.checked);
                                  }}
                                  checked={this.state.role_detail.filter((e) => e.permission && e.permission["upload"] === false).length === 0}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.role_detail.map((item, index) => (
                              <tr className={item.title ? "bg-contain" : ""}>
                                <td>{item.name_th}</td>
                                <td className="text-center">
                                  {!item.title && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.select_role("all", index);
                                      }}
                                      checked={item.permission["view"] && item.permission["create"] && item.permission["update"] && item.permission["delete"] && item.permission["download"] && item.permission["upload"]}
                                    />
                                  )}
                                </td>
                                <td className="text-center">
                                  {!item.title && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.select_role("view", index);
                                      }}
                                      checked={item.permission["view"]}
                                    />
                                  )}{" "}
                                </td>
                                <td className="text-center">
                                  {!item.title && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.select_role("create", index);
                                      }}
                                      checked={item.permission["create"]}
                                    />
                                  )}
                                </td>
                                <td className="text-center">
                                  {!item.title && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.select_role("update", index);
                                      }}
                                      checked={item.permission["update"]}
                                    />
                                  )}{" "}
                                </td>
                                <td className="text-center">
                                  {!item.title && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.select_role("delete", index);
                                      }}
                                      checked={item.permission["delete"]}
                                    />
                                  )}{" "}
                                </td>
                                <td className="text-center">
                                  {!item.title && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.select_role("download", index);
                                      }}
                                      checked={item.permission["download"]}
                                    />
                                  )}{" "}
                                </td>
                                <td className="text-center">
                                  {!item.title && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.select_role("upload", index);
                                      }}
                                      checked={item.permission["upload"]}
                                    />
                                  )}{" "}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ปุ่มด้านล่าง */}
            <div className="bodyw-100 px-3 pt-3 bg-white position-fixed" style={{ bottom: 0 }}>
              <div className="row mx-0">
                <div className="ml-auto col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-2 mb-3">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      window.location.href = "/role";
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-2 mb-3">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      this.Update();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
