import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { alert, POST, tokens, GET, success, alert_url, POST_CACHE, total, baht } from "../../../components/CustomComponent.js";
import Swal from "sweetalert2";
import Select from "react-select";
import "../../../assets/css/bootstrap.css";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: (base, state) => ({
    ...base,
    background: state.isDisabled ? "#ffffff" : "#ffffff",
    borderColor: state.isFocused ? "#fffffff" : "#ffffff",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#ffffff" : "#ffffff",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
export default class LogAuditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,

      data: null,
      data_type: [],
      data_return: null,
      data_input: [],
      data_shoot: [],
      data_room: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      data_pile: [],
      room_number: "A",
      pile: "1 - Front",
      barcode: "",

      history: null,
      type: null,
    };
  }
  async componentDidMount() {
    let data_pile = [];
    for (let i = 1; i <= 100; i++) {
      data_pile.push(i.toString() + " - Front");
      data_pile.push(i.toString() + " - Side");
    }
    this.setState({ data_pile: data_pile });
    await this.GetProductAll();
    await this.GetTypeAll();
    if (localStorage.getItem("check_stock_room_number")) {
      this.setState({ room_number: localStorage.getItem("check_stock_room_number") });
    } else {
      localStorage.setItem("check_stock_room_number", "A");
    }
    if (localStorage.getItem("check_stock_pile")) {
      this.setState({ pile: localStorage.getItem("check_stock_pile") });
    } else {
      localStorage.setItem("check_stock_pile", "1 - Front");
    }
    if (localStorage.getItem("check_stock_type")) {
      this.setState({ type: this.state.data_type.filter((item) => item.value === localStorage.getItem("check_stock_type"))[0] });
    }
    if (localStorage.getItem("check_stock_data")) {
      this.setState({ data_input: JSON.parse(localStorage.getItem("check_stock_data")) });
    }
    if (localStorage.getItem("check_shoot_data")) {
      this.setState({ data_shoot: JSON.parse(localStorage.getItem("check_shoot_data")) });
    }
  }

  GetProductAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/for-check-stock", null);
    if (result && result.status) {
      this.setState({
        data: result.data.data,
        loading: false,
      });
    }
    this.setState({ loading: false });
  };
  GetTypeAll = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", body);
    if (result && result.status) {
      this.setState({
        data_type: result.data.data.map((item) => {
          return { value: item.type_product_id, label: item.type_product_name };
        }),
      });
    }
  };
  Create = async () => {
    this.setState({ loading: true });

    let body = {
      room_number: this.state.room_number,
      pile: this.state.pile,
      checker_name: JSON.parse(localStorage.getItem("profile")).full_name,
      product_list: this.state.data_input,
      shoot_list: this.state.data_shoot,
    };
    let result = await POST_CACHE(tokens, "v1/check-stock/create", body);
    if (result && result.status) {
      this.setState({ loading: false, modal_add: false });
      localStorage.removeItem("check_stock_room_number");
      localStorage.removeItem("check_stock_pile");
      localStorage.removeItem("check_stock_type");
      localStorage.removeItem("check_stock_data");
      localStorage.removeItem("check_shoot_data");
      alert_url("success", "เช็คสต็อกเรียบร้อยแล้ว", "เช็คสต็อกเรียบร้อยแล้ว", "/check-stock");
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      data_shoot: [],
      data_input: [],

      room_number: "A",
      pile: "1 - Front",
      barcode: "",

      history: null,
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="เช็คสต็อก" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>เช็คสต็อก</b>
                {this.state.history && (
                  <button
                    type="button"
                    className="btn btn-danger mx-3 icon px-3"
                    onClick={() => {
                      Swal.fire({
                        icon: "warning",
                        title: "ยืนยันการย้อนการทำงาน",
                        text: "ยืนยันการย้อนการทำงานหรือไม่",
                        showCancelButton: true,
                        confirmButtonText: "ตกลง",
                        cancelButtonText: "ยกเลิก",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          let data_input = this.state.data_input;
                          let index = data_input.findIndex((di) => di.product_code === this.state.history.product_code);

                          data_input[index].yard_qty -= this.state.history.yard_qty;
                          data_input[index].wood_qty -= 1;
                          data_input[index].yard_detail.splice(data_input[index].yard_detail.length - 1, 1);

                          let data_shoot = this.state.data_shoot;
                          data_shoot.splice(data_shoot.length - 1, 1);

                          this.setState({
                            data_input: data_input,
                            data_shoot: data_shoot,
                            history: null,
                          });
                        }
                      });
                    }}
                  >
                    {"\uf1da"}
                  </button>
                )}
              </h3>
              <button
                type="button"
                className="btn btn-primary px-3 ml-1"
                onClick={() => {
                  window.location.href = "/stock-cycle-detail";
                }}
              >
                ประวัติการเช็คสต็อก
              </button>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <label className="mพ-2 ">ห้อง : </label>
                      <select
                        className="form-control-select"
                        onChange={(e) => {
                          this.setState({ room_number: e.target.value });
                          localStorage.setItem("check_stock_room_number", e.target.value);
                        }}
                        value={this.state.room_number}
                        // disabled={this.state.data_input.length !== 0}
                      >
                        {this.state.data_room.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <label className="mพ-2 ">กอง : </label>
                      <select
                        className="form-control-select"
                        onChange={(e) => {
                          this.setState({ pile: e.target.value });

                          localStorage.setItem("check_stock_pile", e.target.value);
                        }}
                        value={this.state.pile}
                        // disabled={this.state.data_input.length !== 0}
                      >
                        {this.state.data_pile.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <label className="mพ-2 ">ประเภทผ้า : </label>
                      <Select
                        options={this.state.data_type ? this.state.data_type : []}
                        placeholder="เลือกประเภท"
                        onChange={(e) => {
                          this.setState({ type: e });
                          localStorage.setItem("check_stock_type", e.value);
                        }}
                        value={this.state.type}
                        // isDisabled={this.state.data_input.length !== 0}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex mb-2">
                    <div className="w-100">
                      <label className="mพ-2 ">บาร์โค้ด : </label>
                      <input
                        type="text"
                        className="form-control border-0 bg-white"
                        onChange={(e) => {
                          this.setState({ barcode: e.target.value });
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            let yard_qty = 0;
                            let text = this.state.barcode;
                            if (text.includes("PD-") && ((!text.includes(".") && text.length >= 12)||(text.includes(".") && text.length >= 14))) {
                              if (!Number(text.split("/")[1])) {
                                alert("warning", "แจ้งเตือน", "บาร์โค้ดไม่ถูกต้อง");
                                this.setState({ barcode: "" });
                                return;
                              } else {
                                yard_qty = Number(baht(text.split("/")[1]).replaceAll(",", ""));
                                yard_qty = yard_qty < 0 ? yard_qty * -1 : yard_qty
                              }
                              if (yard_qty === 0) {
                                alert("warning", "แจ้งเตือน", "จำนวนหลาต้องไม่เป็น 0");
                                this.setState({ barcode: "" });
                                return;
                              }
                              text = text.split("/")[0];
                              let id = this.state.data.findIndex((item) => item.product_code === text);
                              if (id === -1 || this.state.data[id].type_product_id !== this.state.type.value) {
                                alert("warning", "แจ้งเตือน", "ประเภทผ้าไม่ถูกต้อง");
                                this.setState({ barcode: "" });
                                return;
                              }
                              if (id !== -1) {
                                let data_input = this.state.data_input;
                                if (data_input.findIndex((di) => di.product_code === text) === -1) {
                                  data_input.push({
                                    type_product_id: this.state.data[id].type_product_id,
                                    type_product_name: this.state.data[id].type_product_name,
                                    product_name: this.state.data[id].product_name,
                                    product_code: this.state.data[id].product_code,
                                    product_detail: this.state.data[id].product_detail,
                                    product_color: this.state.data[id].product_color,
                                    product_design: this.state.data[id].product_design,
                                    yard_qty: yard_qty,
                                    wood_qty: 1,
                                    yard_detail: [yard_qty],
                                  });
                                } else {
                                  data_input[data_input.findIndex((di) => di.product_code === text)].yard_qty += yard_qty;
                                  data_input[data_input.findIndex((di) => di.product_code === text)].wood_qty += 1;
                                  data_input[data_input.findIndex((di) => di.product_code === text)].yard_detail.push(yard_qty);
                                }
                                let data_shoot = this.state.data_shoot;
                                data_shoot.push({
                                  product_name: this.state.data[id].product_name,
                                  product_code: this.state.data[id].product_code,
                                  product_color: this.state.data[id].product_color,
                                  type_product_id: this.state.data[id].type_product_id,
                                  type_product_name: this.state.data[id].type_product_name,
                                  yard_qty: yard_qty,
                                });
                                this.setState({
                                  data_input: data_input,
                                  data_shoot: data_shoot,
                                  barcode: "",
                                  history: {
                                    product_code: this.state.data[id].product_code,
                                    product_name: this.state.data[id].product_name,
                                    yard_qty: yard_qty,
                                    wood_qty: 1,
                                  },
                                });
                                localStorage.setItem("check_stock_data", JSON.stringify(data_input));
                                localStorage.setItem("check_shoot_data", JSON.stringify(data_shoot));
                              }
                            }
                          }
                        }}
                        value={this.state.barcode}
                        disabled={!this.state.type}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body p-0 ">
                  {this.state.history ? (
                    <div className="p-2 d-flex align-items-center">
                      รายการล่าสุด :
                      <div className="d-flex bg-success-light rounded p-2 text-success ml-2">
                        {this.state.history.product_code} {this.state.history.product_name} จำนวน {this.state.history.yard_qty} หลา
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-hover">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>รหัสสินค้า</b>
                          </th>
                          <th className="text-left">
                            <b>ชื่อสินค้า</b>
                          </th>
                          <th className="text-center">
                            <b>จำนวนหลา</b>
                            <span className="px-2 bg-primary rounded-16 text-white ml-2">{baht(total(this.state.data_input, "yard_qty"))}</span>
                          </th>
                          <th className="text-center">
                            <b>จำนวนไม้</b>
                            <span className="px-2 bg-primary rounded-16 text-white ml-2">{total(this.state.data_input, "wood_qty")}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data_input && this.state.data_input.length === 0) || !this.state.data_input) && (
                          <tr>
                            <td colSpan={4} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data_input &&
                          this.state.data_input.map((item, index) => (
                            <tr key={index}>
                              <td className="text-left">{item.product_code}</td>
                              <td className="text-left">{item.product_name}</td>
                              <td className="text-center">{baht(item.yard_qty)}</td>
                              <td className="text-center">{item.wood_qty}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="w-100 mt-3 d-flex justify-content-between">
                <div></div>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary px-5 mr-1"
                    onClick={() => {
                      Swal.fire({
                        icon: "warning",
                        title: "ยืนยันการล้างข้อมูล",
                        text: "ยืนยันการล้างข้อมูลหรือไม่",
                        showCancelButton: true,
                        confirmButtonText: "ตกลง",
                        cancelButtonText: "ยกเลิก",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          localStorage.removeItem("check_stock_room_number");
                          localStorage.removeItem("check_stock_pile");
                          localStorage.removeItem("check_stock_type");
                          localStorage.removeItem("check_stock_data");
                          localStorage.removeItem("check_shoot_data");
                          success();
                          this.ClearForm();
                        }
                      });
                    }}
                  >
                    ล้างข้อมูล
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary px-5 ml-1"
                    onClick={() => {
                      this.Create();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
