import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { alert, alert_url, POST, tokens, baht, format_date_sale, role, date_invoice_s, number, total } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class InvoiceCreateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      check_all: false,

      bill_code: "DOC" + new Date().getTime(),
      modal_credit: false,
      due_date: 0,
      credit: 0,
      set_credit: "",

      //member
      data: null,
      search_member: "",
      member_type: "",

      //detail
      details: null,
      data_search: "",
      sort: "",
      count: 0,

      user_id: this.props.location.query.id ? this.props.location.query.id : "",
      user_search: "",
      user_status: 0,

      title_name: "",
      now_date: ("00" + new Date().getDate()).slice(-2) + " " + ("00" + (new Date().getMonth() + 1)).slice(-2) + " " + (new Date().getFullYear() + 543).toString().slice(-2) + "," + new Date().toTimeString(),
      index_onClick: "",
    };
  }
  async componentDidMount() {
    await this.GetMember();
    if (this.props.location.query.id) {
      await this.GetById();
      // eslint-disable-next-line array-callback-return
      this.state.data.data.filter((item, i) => {
        if (item.user_id === this.props.location.query.id) {
          this.setState({ title_name: item.full_name, index_onClick: i });
        }
      });
    }
  }
  GetMember = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search_member,
      sort: this.state.sort,
    };

    let result = await POST(tokens, "v1/all-sale/customer_invoice/search", body);

    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  GetById = async () => {
    this.setState({ loading: true, page: 1 });
    let body = {
      user_member_id: this.state.user_id,
      data_search: this.state.user_search,
    };

    let result = await POST(tokens, "v1/all-sale/customer/invoice/page/0", body);

    if (result && result.status) {
      this.setState({ details: result.data });
    }
    this.setState({ loading: false });
  };

  AddInvoice = async () => {
    this.setState({ loading: true });
    let due_date = "";
    let details = this.state.details;

    if (this.state.due_date === 0 || this.state.due_date === 0) {
      let set_credit = new Date();
      set_credit.setDate(set_credit.getDate()).toString();
      let set = set_credit.toISOString();
      due_date = Number(set.split("T")[0].split("-")[0]) + "-" + set.split("T")[0].split("-")[1] + "-" + set.split("T")[0].split("-")[2];
    } else {
      due_date = Number(this.state.set_credit.split("T")[0].split("-")[0]) + "-" + this.state.set_credit.split("T")[0].split("-")[1] + "-" + this.state.set_credit.split("T")[0].split("-")[2];
    }
    let body = {
      user_member_id: this.state.user_id,
      due_date: due_date,
      order_main_list: details.data.data_list
        .filter((e) => e.checked)
        .map((e) => {
          return e.order_main_id;
        }),
      bill_code: this.state.bill_code,
    };
    let result = await POST(tokens, "v1/all-sale/invoice/create", body);

    if (result && result.status) {
      alert_url("success", "สำเร็จ", "สร้างใบแจ้งหนี้เรียบร้อยแล้ว", "/invoice");
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({
      page: pageNumber,
      search: "",
      search_detail: "",
    });

    setTimeout(() => {
      this.GetById();
    }, 10);
  }

  ClearFormDueDate() {
    this.setState({
      due_date: 0,
      credit: 0,
      set_credit: "",
      index_onClick: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="ใบแจ้งหนี้" page3="ใบแจ้งหนี้แยกลูกค้า" />
            <div style={{ height: 60 }}></div>
            <div className="row w-100 mt-3 mx-0">
              {/* ใบแจ้งหนี้แยกลูกค้า */}
              <div className="w-100 col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 mb-4">
                <div className="w-100 mb-3">
                  <h3>
                    <b>ใบแจ้งหนี้แยกลูกค้า</b>
                  </h3>
                  <label className="text-msg text-14">ทั้งหมด {this.state.data && number(this.state.data.data.length)} รายการ</label>
                </div>
                <div className="w-100">
                  <div className="card">
                    <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                      <div className="col-6 col-sm-7 col-xl-7 d-flex mb-2">
                        <div className="w-100">
                          <input
                            className="form-control-search"
                            type="search"
                            placeholder="พิมพ์คำค้นหา"
                            maxLength={30}
                            value={this.state.search_member}
                            onChange={(e) => {
                              this.setState({ page: 1, search_member: e.target.value, loading: false });
                              if (e.target.value === "") {
                                this.setState({
                                  user_id: "",
                                  search_member: "",
                                  details: null,
                                  index_onClick: "",
                                  title_name: "",
                                });
                                setTimeout(() => {
                                  this.GetMember();
                                  this.setState({ loading: false });
                                }, 20);
                              } else {
                                setTimeout(() => {
                                  this.GetMember();
                                  this.setState({ loading: false });
                                }, 20);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6 col-sm-5 col-xl-5 d-flex">
                        <div className="w-100">
                          <select
                            className="form-control-select pointer"
                            onChange={(e) => {
                              this.setState({ sort: e.target.value, page: 1 });
                              setTimeout(() => {
                                this.GetMember();
                              }, 20);
                            }}
                            value={this.state.sort}
                          >
                            <option value="" selected>
                              จัดเรียง
                            </option>
                            <option value="desc">มากไปน้อย</option>
                            <option value="asc">น้อยไปมาก</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive rounded-16 height-vh overflow-auto">
                        <table className="table table-hover table-striped table-borderless">
                          <thead>
                            <tr className=" bg-white">
                              <th className="text-left">
                                <b>สมาชิก/บริษัท</b>
                              </th>
                              <th className="text-right ">
                                <b>ยอดค้างชำระ</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                              <tr>
                                <td colSpan={2} className="text-center table-light height-vh">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}
                            {this.state.data &&
                              this.state.data.data.map((item, index) => (
                                <tr
                                  key={index}
                                  className="pointer"
                                  onClick={() => {
                                    this.setState({
                                      details: null,
                                      user_id: item.user_id,
                                      search: "",
                                      search_detail: "",
                                      title_name: item.full_name,
                                      index_onClick: index,
                                      page: 1,
                                      check_all: false,
                                    });

                                    setTimeout(() => {
                                      this.GetById();
                                    }, 10);
                                  }}
                                >
                                  <td className={index === this.state.index_onClick ? "text-left text-white bg-primary" : "text-left"}>{item.full_name}</td>
                                  <td className={index === this.state.index_onClick ? "text-right text-white bg-primary" : "text-right"}>{baht(item.total_balance)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* เรทสมาชิกตามรายการสินค้า */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 mb-4 w-100">
                <div className="w-100 d-flex justify-content-between">
                  <div className="d-flex flex-column w-100 mx-0">
                    <h3>
                      <b>{this.state.title_name ? "สมาชิก : " + this.state.title_name : "สมาชิก :"}</b>
                    </h3>
                    {this.state.details && this.state.details.data.phone && (
                      <div className="mb-3">
                        <small className="bg-secondary-phone text-dark p-1 px-3 rounded-16">
                          <span className="icon-brand text-14 mr-2">{"\uf095"}</span>
                          {this.state.details.data.phone}
                        </small>
                      </div>
                    )}
                    <label className="text-msg mb-3 text-14">ทั้งหมด {this.state.details ? number(this.state.details.data.data_list.length) : "0"} รายการ</label>
                  </div>
                </div>
                {/* table detail */}
                <div className="card">
                  <div className="card-header row d-flex mx-0 justify-content-around align-items-center bg-secondary rounded-top-16">
                    <label>วันที่รับ: {date_invoice_s(this.state.now_date)}</label>
                    <div className="d-none d-xl-block" style={{ height: 40, width: 1, backgroundColor: "#c9c9c9" }}></div>
                    <div className="col-12 d-lg-none">
                      <hr />
                    </div>
                    <label>เลขที่เอกสาร: {this.state.bill_code}</label>
                    <div className="d-none d-xl-block" style={{ height: 40, width: 1, backgroundColor: "#c9c9c9" }}></div>
                    <div className="col-12  d-lg-none">
                      <hr />
                    </div>
                    <label className="mb-0">
                      จำนวนวันชำระ: {this.state.due_date ? this.state.due_date : 0} วัน
                      <span
                        className="pointer text-primary ml-1"
                        onClick={() => {
                          if (this.state.user_id === "") {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกสมาชิก");
                            return;
                          }
                          this.setState({ modal_credit: true });
                        }}
                      >
                        <label className="icon">{"\uf303"}</label>เปลี่ยน
                      </span>
                    </label>
                  </div>

                  <div className="card-header row d-flex mx-0 px-2">
                    <div className="w-100">
                      <input
                        className="form-control-search wpx-200"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        maxLength={30}
                        value={this.state.user_search}
                        onChange={(e) => {
                          this.setState({ page: 1, user_search: e.target.value, loading: false });
                          if (e.target.value === "" && this.state.details !== null) {
                            this.setState({ user_search: "" });
                            setTimeout(() => {
                              this.GetById();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetById();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />

                      <button
                        type="button"
                        className="btn btn-outline-primary bg-white mx-2 "
                        onClick={() => {
                          if (this.state.details !== null) {
                            this.setState({
                              check_all: false,
                              user_search: "",
                              count: 0,
                              page: 1,
                            });

                            setTimeout(() => {
                              this.GetById();
                            }, 10);
                          }
                        }}
                      >
                        <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive rounded-16 height-vh overflow-auto">
                      <table className="table table-hover table-striped table-borderless">
                        <thead>
                          <tr className=" bg-white">
                            {role && role.invoice[1] === 1 && (
                              <th className="text-center">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    if (this.state.details !== null) {
                                      let data = this.state.details;
                                      let count = 0;
                                      for (let d of data.data.data_list) {
                                        count += 1;
                                        d.checked = e.target.checked;
                                      }
                                      this.setState({
                                        details: data,
                                        check_all: e.target.checked,
                                        count: count,
                                      });
                                    }
                                  }}
                                  checked={this.state.check_all}
                                ></input>
                              </th>
                            )}
                            <th className="text-center">
                              <b>No.</b>
                            </th>
                            <th className="text-left">
                              <b>เลขที่เอกสาร</b>
                            </th>
                            <th className="text-left">
                              <b>วันที่เปิด Invoice</b>
                            </th>
                            <th className="text-right">
                              <b>ยอดค้างชำระ</b>
                            </th>
                            <th className="text-right">
                              <b>ยอดรวมทั้งสิ้น</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.details && this.state.details.data.data_list.length === 0) || !this.state.details) && (
                            <tr>
                              <td colSpan={6} className="text-center table-light height-vh">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                          {this.state.details &&
                            this.state.details.data.data_list.map((item, index) => (
                              <tr key={index} className="pointer">
                                {role && role.invoice[1] === 1 && (
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        let data = this.state.details;
                                        data.data.data_list[index].checked = e.target.checked;
                                        this.setState({ details: data });
                                      }}
                                      checked={item.checked}
                                    ></input>
                                  </td>
                                )}
                                <td className="text-center">{Number(index + 1)}</td>
                                <td className="text-left">{item.order_main_number}</td>
                                <td className="text-left">{format_date_sale(item.datetime_create)}</td>
                                <td className="text-right">{baht(item.total_balance)}</td>
                                <td className="text-right">{baht(item.total_price)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* pagination */}
                {/* {this.state.details && this.state.details.data.length !== 0 && (
                                    <div className="row w-100 justify-content-between mx-0  my-4">
                                        <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                                            <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                                                <label> แสดง {number(this.state.details.end_index)} รายการจาก{" "} {number(this.state.details.count)} รายการ </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                                            <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                                                <button className="btn-circle-white mx-1 pointer "
                                                    onClick={() => {

                                                        this.HandlePageChange(1)
                                                    }}>
                                                    <label className="icon pointer">{"\uf100"}</label>
                                                </button>
                                                <Pagination
                                                    activePage={this.state.page}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.details.count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.HandlePageChange.bind(this)}
                                                    prevPageText={<label className="icon pointer">{"\uf104"}</label>}
                                                    nextPageText={<label className="icon pointer">{"\uf105"}</label>}
                                                    hideFirstLastPages={true} />
                                                <button className="btn-circle-white mx-1 pointer"
                                                    onClick={() => { this.HandlePageChange(this.state.details.page_number) }}>
                                                    <label className="icon pointer">{"\uf101"}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
              </div>
            </div>
          </div>
        </div>

        {role && role.invoice[1] === 1 && (
          <div className="position-fixed bottom-0 bg-white w-100 p-3" style={{ bottom: 0, right: 0 }}>
            <div className="d-flex w-100 justify-content-end align-items-center">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <p className="mb-0 text-14 wpx-200 text-left">รวมรายการทั้งหมด {this.state.details ? this.state.details.count : 0} รายการ</p>
                  <p className="mb-0 text-14 wpx-220 text-left">
                    รวมยอดค้างชำระ{" "}
                    <b>
                      {this.state.details &&
                        this.state.details.data &&
                        baht(
                          total(
                            this.state.details.data.data_list.filter((e) => e.checked === true),
                            "total_balance"
                          )
                        )}
                    </b>{" "}
                    รายการ
                  </p>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <p className="mb-0 text-14 wpx-200 text-left">เลือก {this.state.details && this.state.details.data && number(this.state.details.data.data_list.filter((e) => e.checked === true).length)} รายการ</p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="mr-1 w-50">
                  <button
                    className="btn btn-outline-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/invoice";
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="ml-1 w-50">
                  <button
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      if (this.state.details !== null) {
                        let details = this.state.details;
                        let data = details.data.data_list
                          .filter((e) => e.checked)
                          .map((e) => {
                            return e.order_main_id;
                          });

                        if (data.length === 0) {
                          alert("info", "แจ้งเตือน", "กรุณาเลือกรายการ");
                          return;
                        }

                        this.AddInvoice();
                      } else {
                        alert("info", "แจ้งเตือน", "ไม่มีรายการที่เลือก");
                      }
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* เซตเครดิตวัน */}
        <Modal
          show={this.state.modal_credit}
          size="m"
          onHide={() => {
            this.setState({ modal_credit: false });
            this.ClearFormDueDate();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">จำนวนวันชำระ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>วัน:</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={10}
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      let credit = "";
                      credit = e.target.value.replace(/\D/g, "");
                      let set_credit = new Date();
                      set_credit.setDate(set_credit.getDate() + Number(credit)).toString();

                      this.setState({
                        credit: Number(e.target.value),
                        set_credit: set_credit.toISOString(),
                      });
                    }
                  }}
                  value={this.state.credit}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.btnsubmit.click();
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_credit: false });
                    this.ClearFormDueDate();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  ref={(node) => (this.btnsubmit = node)}
                  type="submit"
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ due_date: this.state.credit, modal_credit: false });
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
