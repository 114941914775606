import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { GET, POST, tokens, status_invoice, type_member, role, baht, total, ModalInvoice, ModalInvoiceHistory, report_4, format_date_sale, format_duedate_sale, alert, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

export default class InvoiceScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.location.query.page ? Number(this.props.location.query.page) : 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      modal_history: false,

      search: "",
      status_order_main: 0,
      document_type: 0,
      start_date: "",
      end_date: "",
      status_bill: 0,

      status_invoice: "",

      //details
      modal_detail: false,
      data_detail: "",

      data: null,

      invoice_id: "",
      detail: null,
      history: null,

      sort: "",
      sort_date: "",
      profile: JSON.parse(localStorage.getItem("profile")),
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
      status_order_main: this.state.status_order_main ? Number(this.state.status_order_main) : "",
      document_type: this.state.document_type ? Number(this.state.document_type) : "",
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      sort: this.state.sort,
      status_bill: Number(this.state.document_type),
      sort_date: this.state.sort_date,
    };
    let result = await POST(tokens, "v1/all-sale/invoice/search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  GetById = async (id, text) => {
    this.setState({ loading: true, page: 1 });
    let body = {
      invoice_id: id,
      data_search: "",
      start_date: "",
      history: 1,
    };
    let result = await POST(tokens, "v1/all-sale/invoice/detail", body);
    if (result && result.status) {
      this.setState({ data_detail: result.data.data });
      if (text !== "พิมพ์ใบแจ้งหนี้") {
        setTimeout(() => {
          this.setState({ modal_detail: true });
        }, 10);
      } else {
        report_4(this.state.data_detail);
      }
    }
    this.setState({ loading: false });
  };

  GetHistoryById = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/all-sale/invoice/history/" + id, null);

    if (result && result.status) {
      this.setState({ history: result.data.data });
      setTimeout(() => {
        this.setState({ modal_history: true });
      }, 10);
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,
      search: "",

      status_invoice: "",
      sort: "",
      sort_date: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="ใบแจ้งหนี้" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>ใบแจ้งหนี้</b>
              </h3>
              {role && role.invoice[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/invoice/create";
                    }}
                  >
                    {" "}
                    สร้างใบแจ้งหนี้{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-sm-2 col-md-4 col-lg-4 col-xl-2 d-flex">
                    <input
                      maxLength={30}
                      className="form-control-search mb-1 mt-1"
                      type="search"
                      placeholder="พิมพ์คำค้นหา"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ page: 1, search: e.target.value, loading: false });
                        if (e.target.value === "") {
                          this.setState({ search: "" });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        } else {
                          this.setState({ loading: false });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({ page: 1, start_date: e.target.value });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();
                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1 pointer"
                          onClick={() => {
                            this.setState({
                              search: "",
                              status_order_main: 0,
                              document_type: 0,
                              start_date: "",
                              end_date: "",
                              status_invoice: "",
                              page: 1,
                              sort: "",
                              sort_date: "",
                            });

                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-6 col-xl-4 d-flex justify-content-xl-end">
                    <select
                      className="form-control-select wpx-180  ml-1 mb-1 mt-1 pointer"
                      onChange={(e) => {
                        this.setState({ document_type: Number(e.target.value) });
                        setTimeout(() => {
                          this.GetAll();
                        }, 10);
                      }}
                      value={this.state.document_type}
                    >
                      <option value="0" selected>
                        แสดงทุกสถานะ
                      </option>
                      <option value="1">ยังไม่ได้ชำระ</option>
                      <option value="2">ชำระเงินแล้ว</option>
                      <option value="3">เกินกำหนด</option>
                    </select>
                  </div>
                </div>
                <div className="card-body p-0 px-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-sm table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 134 }}>
                            <b>เลขที่ใบแจ้งหนี้</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20 pointer">
                                  <span className="icon-brand text-10 pointer">{"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort: "desc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort === "desc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort === "asc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>มากไปน้อย</span>
                              </MenuItem>
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort: "asc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort === "asc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort === "desc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>น้อยไปมาก</span>
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-left" style={{ minWidth: 145 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>ชื่อลูกค้า</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 120 }}>
                            <b>ประเภทลูกค้า</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 132 }}>
                            <b>วันครบกำหนด</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20 pointer">
                                  <span className="icon-brand text-10 pointer">{"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_date: "desc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort_date === "desc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort_date === "asc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>วันที่ล่าสุด</span>
                              </MenuItem>

                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_date: "asc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort_date === "asc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort_date === "desc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>วันที่เก่าสุด</span>
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-right">
                            <b>ยอดรวม</b>
                          </th>
                          <th className="text-right ">
                            <b>ชำระแล้ว</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 90 }}>
                            <b>ยอดค้างชำระ</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 150 }}>
                            <b>สถานะ</b>
                          </th>
                          {role && role.invoice[2] === 1 && (
                            <th className="text-center">
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={10} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.invoice_number}</td>
                              <td className="text-left">{format_date_sale(item.datetime_create)}</td>
                              <td className="text-left">{item.cutomer_name}</td>
                              <td className="text-center">{type_member(item.cutomer_type)}</td>
                              <td className="text-center">{format_duedate_sale(item.due_date)}</td>
                              <td className="text-right">{baht(item.total_price)}</td>
                              <td className="text-right">{baht(item.total_pay)}</td>
                              <td className="text-right">{baht(item.total_balance)}</td>
                              <td className="text-center">{status_invoice(item.status_bill)}</td>
                              <td className="text-center">
                                <Menu
                                  menuButton={
                                    <button className="btn btn-link text-dark">
                                      <h1 className="icon h2">{"\uf142"}</h1>
                                    </button>
                                  }
                                  transition
                                >
                                  <MenuItem
                                    onClick={async () => {
                                      this.GetById(item.invoice_id, "พิมพ์ใบแจ้งหนี้");
                                    }}
                                  >
                                    <span className="icon h2 mr-2">{"\uf02f"} </span>
                                    พิมพ์ใบแจ้งหนี้
                                  </MenuItem>
                                  {role && role.payment_receive[0] === 1 && (
                                    <>
                                      {item.status_bill !== 2 && (
                                        <>
                                          {role && role.payment_receive[0] === 1 && (
                                            <MenuItem
                                              onClick={async () => {
                                                window.location.href = "/sale/payment-receive?id=" + item.invoice_number + "&page=" + this.state.page + "&page_name=/invoice&customer_name=" + item.cutomer_name + "&customer_id=" + item.cutomer_id;
                                              }}
                                            >
                                              <span className="icon h2 mr-1">{"\uf0d6"} </span>
                                              ระบบรับชำระเงิน
                                            </MenuItem>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  {role && role.invoice[0] === 1 && (
                                    <MenuItem
                                      onClick={async () => {
                                        this.GetById(item.invoice_id, "");
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf15c"}</span>
                                      รายละเอียด
                                    </MenuItem>
                                  )}

                                  {role && role.invoice[0] === 1 && (
                                    <MenuItem
                                      onClick={async () => {
                                        this.GetHistoryById(item.invoice_id);
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf1da"}</span>
                                      ประวัติการชำระเงิน
                                    </MenuItem>
                                  )}
                                </Menu>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {this.state.profile.user_type === "owner" && (
                        <tfoot>
                          <td className="text-left">
                            <b>ยอดรวม</b>
                          </td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-right">
                            <b>฿{this.state.data && this.state.data.data && baht(total(this.state.data.data, "total_balance"))}</b>
                          </td>
                          <td className="text-right">
                            <b>฿{this.state.data && this.state.data.data && baht(total(this.state.data.data, "total_pay"))}</b>
                          </td>
                          <td className="text-right">
                            <b>฿{this.state.data && this.state.data.data && baht(total(this.state.data.data, "total_price"))}</b>
                          </td>
                          <td className="text-right"></td>
                          <td className="text-right"></td>
                        </tfoot>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* รายละเอียด modal */}
        <ModalInvoice
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />

        {/* Modal ประวัติการชำระเงิน */}
        <ModalInvoiceHistory
          history={this.state.history}
          show={this.state.modal_history}
          onHide={() => {
            this.setState({ modal_history: false });
          }}
        />
      </div>
    );
  }
}
