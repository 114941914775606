import React, { Component } from "react";
import { alert, POST, tokens } from "../components/CustomComponent.js";
import "../assets/css/bootstrap.css";

export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      access_by: "pos_system",
      loading: false,
      modal_alert: false,

      passwordType: "password",
    };
  }
  componentDidMount() {
    let permission_page = localStorage.getItem("permission_page");
    if (tokens && permission_page) {
      window.location.href = permission_page;
    }
  }

  Login = async () => {
    this.setState({ loading: true });
    let data = {
      email: this.state.email.trim(),
      password: this.state.password.trim(),
      remember: true,
      access_by: this.state.access_by,
    };
    let result = await POST("", "v1/login", data);
    if (result && result.status) {
      let profile = {
        email: result.data.detail.email,
        full_name: result.data.detail.full_name,
        user_code: result.data.detail.user_code,
        user_type: result.data.detail.user_type,
      };
      let role = result.data.detail.role_data.role_detail;
      let role_array = {};
      let permission_page = "";
      for (let r of role) {
        let data = [0, 0, 0, 0, 0, 0];
        data[0] = r.permission.view === "true" ? 1 : 0; //view
        data[1] = r.permission.create === "true" ? 1 : 0; //create
        data[2] = r.permission.update === "true" ? 1 : 0; //update
        data[3] = r.permission.delete === "true" ? 1 : 0; //delete
        data[4] = r.permission.download === "true" ? 1 : 0; //download
        data[5] = r.permission.upload === "true" ? 1 : 0; //upload
        role_array[r.menu_name] = data;
        if (permission_page === "" && r.permission.view === "true") {
          switch (r.menu_name) {
            case "all_sale":
              //7 การขายทั้งหมด
              permission_page = "/sale";
              break;
            case "dashboard":
              //1 แดชบอร์ด
              permission_page = "/dashboard";
              break;
            case "user_admin":
              //2 รายชื่อผู้ใช้ทั้งหมด
              permission_page = "/user";
              break;
            case "role":
              //3 บทบาทผู้ใช้
              permission_page = "/role";
              break;
            case "company_supplier":
              //4 รายชื่อบริษัทจำหน่าย
              permission_page = "/company";
              break;
            case "user_member":
              //5 รายชื่อสมาชิกทั้งหมด
              permission_page = "/member";
              break;
            case "user_member_rate":
              //6 เรทสมาชิก
              permission_page = "/rate-member";
              break;

            case "all_sale_member":
              //8 ข้อมูลตามสมาชิก
              permission_page = "/sale-member";
              break;
            case "invoice":
              //9 ใบแจ้งหนี้
              permission_page = "/invoice";
              break;
            case "payment_receive":
              //10 ระบบรับชำระเงิน
              permission_page = "/sale/payment-receive";
              break;
            case "all_product":
              //11 สินค้าทั้งหมด
              permission_page = "/product";
              break;
            case "update_price":
              //12 ปรับราคาตามประเภทผ้า
              permission_page = "/updateprice";
              break;
            case "goods_receipt":
              //13 ใบรับสินค้า
              permission_page = "/product/receipt";
              break;
            case "type_product":
              //14 ประเภทผ้า
              permission_page = "/category";
              break;
            case "move_stock":
              //15 ย้ายสต๊อก
              permission_page = "/stock-move";
              break;
            case "adjust_stock":
              //16 ปรับสต๊อก
              permission_page = "/stock-adjustment";
              break;
            case "transaction_stock":
              //17 เส้นทางสต๊อก
              permission_page = "/stock-location";
              break;
            case "all_stock":
              //18 สต๊อกสินค้า
              permission_page = "/stock";
              break;
            case "status_delivery":
              //19 ค่าคอมมิสชัน
              permission_page = "/status-delivery";
              break;
            case "type_commission":
              //20 ประเภทคอมมิสชัน
              permission_page = "/commission-type";
              break;
            case "report_summary":
              //21 รายงาน
              permission_page = "/report";
              break;
            case "report_sales_customers":
              //22 รายงานสรุปยอดขายจากลูกค้ารายบุคคล
              permission_page = "/reportsalescustomers";
              break;
            case "report_sales_products":
              //23 รายงานสรุปยอดขายสินค้าและบริการทั้งหมด
              permission_page = "/reportsalesproducts";
              break;
            case "report_inventory":
              //24 รายงานสรุปมูลค่าสินค้าคงคลัง
              permission_page = "/reportinventory";
              break;
            case "report_products_and_services":
              //25 รายงานสรุปยอดขายสินค้าและบริการ
              permission_page = "/reportproductsandservices";
              break;
            case "report_transfer":
              //26 รายการโอน
              permission_page = "/reporttransfer";
              break;
            case "warehouse":
              //27 โกดังสินค้า
              permission_page = "/warehouse";
              break;
            case "bank_account":
              //28 บัญชีธนาคาร
              permission_page = "/bank";
              break;
            case "expenses":
              //29 ค่าใช้จ่าย
              permission_page = "/expenses";
              break;
            case "type_expenses":
              //30 ประเภทค่าใช้จ่าย
              permission_page = "/expenses/type";
              break;
            case "refund":
              //31 คืนสินค้า
              permission_page = "/refund";
              break;
            case "logaudit":
              //32 ประวิตการตรวจสอบ
              permission_page = "/logaudit";
              break;
            case "refund_accumulate":
              //33 เงินคืนสะสม
              permission_page = "/refund_accumulate";
              break;
            case "generate_barcode":
              //34 สร้างบาร์โค้ด
              permission_page = "/generate-barcode";
              break;
            case "check_stock":
              //35 เช็คสต๊อก
              permission_page = "/check-stock";
              break;
            case "pos_system":
              //34 การขาย
              permission_page = "/main";
              break;
            default:
              permission_page = "/login";
              break;
          }
        }
      }

      localStorage.setItem("token_AHJ_test", "token " + result.data.token);
      localStorage.setItem("profile", JSON.stringify(profile));
      localStorage.setItem("role", JSON.stringify(role_array));
      localStorage.setItem("status_bill", "ขายสินค้า");
      localStorage.setItem("user_code", "");
      setTimeout(async () => {
        if (this.state.access_by === "back_office") {
          window.location.href = permission_page;
          localStorage.setItem("permission_page", permission_page);
          return;
        } else {
          window.location.href = "/main";
          localStorage.setItem("permission_page", "/main");
          return;
        }
      }, 50);
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="full center bg-contain background-img">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="col-10 col-sm-8 col-md-6 col-lg-6 col-xl-4 mx-auto">
          <div className="row w-100 justify-content-center mb-4">
            <h1 className=" text-primary">
              <b>AIMHENGJAN</b>
            </h1>
          </div>

          <div className="w-100 d-flex">
            <button
              className={this.state.access_by === "pos_system" ? "btn btn-white w-50 hpx-90 rounded-16 text-login text-20" : "btn bg-cash-1 w-50 text-detail-login hpx-90 rounded-16 text-18"}
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => {
                if (window.getSelection().rangeCount >= 1) {
                  window.getSelection().getRangeAt(0);
                }
                this.setState({ access_by: "pos_system" });
              }}
            >
              <p>ระบบ POS</p>
            </button>
            <button
              className={this.state.access_by === "back_office" ? "btn btn-white w-50 hpx-90 rounded-16 text-login text-20" : "btn bg-cash-1 w-50 text-detail-login hpx-90 rounded-16 text-18"}
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => {
                if (window.getSelection().rangeCount >= 1) {
                  window.getSelection().getRangeAt(0);
                }
                this.setState({ access_by: "back_office" });
              }}
            >
              <p>ระบบ ADMIN</p>
            </button>
          </div>

          <div className="card rounded-16" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: -30 }}>
            <div className="col-12 p-4 ">
              <div className="row w-100 justify-content-center mb-4 ">
                <h3 className="mb-2">
                  <b>ลงชื่อเข้าใช้</b>
                </h3>
              </div>

              <div>
                <input
                  autoFocus
                  className="form-control mb-4"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="ผู้ใช้งาน"
                  maxLength={30}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value.trimStart(),
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      if (this.state.password) {
                        this.btn_login.focus();
                      } else {
                        this.input_password.focus();
                      }
                    }
                  }}
                ></input>

                <div className="input-group">
                  <input
                    ref={(node) => (this.input_password = node)}
                    className="form-control mb-4"
                    type={this.state.passwordType}
                    id="password"
                    name="password"
                    placeholder="รหัสผ่าน"
                    maxLength={30}
                    onChange={(e) => {
                      this.setState({
                        password: e.target.value.trimStart(),
                      });
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        if (this.state.email.trim() === "") {
                          alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                          return;
                        } else if (this.state.password.trim() === "") {
                          alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน");
                          return;
                        }
                        this.Login();
                      }
                    }}
                  ></input>
                  <div className="input-group-btn ml-1">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        let passwordType = this.state.passwordType;
                        this.setState({ passwordType: passwordType === "password" ? "text" : "password" });
                      }}
                    >
                      {this.state.passwordType === "password" ? <span className="icon-brand text-14 text-white">{"\uf070"}</span> : <span className="icon-brand text-14 text-white">{"\uf06e"}</span>}
                    </button>
                  </div>
                </div>
              </div>

              <button
                ref={(node) => (this.btn_login = node)}
                type="button"
                className="btn btn-primary w-100"
                onClick={() => {
                  if (this.state.email.trim() === "") {
                    alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                    return;
                  } else if (this.state.password.trim() === "") {
                    alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน");
                    return;
                  }
                  this.Login();
                }}
              >
                {" "}
                เข้าสู่ระบบ{" "}
              </button>

              <div className="w-100 text-center mt-3">
                <label className="text-secondary">{global.version}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
