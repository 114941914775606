import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { tokens, GET, format_date, baht, status_refund, status_sale_transaction } from "../../../components/CustomComponent.js";
import Select, { components } from "react-select";
import "../../../assets/css/bootstrap.css";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class RefundDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      return_warehouse: false,
      not_return_warehouse: false,

      select_all: false,
      selected: false,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      date_time: "",
      bill_code: "PC" + new Date().getTime(),

      data: null,

      data_member: [],

      data_member_id: this.props.location.query.member_id ? { label: this.props.location.query.member_name, value: this.props.location.query.member_id } : null,

      invoice_number_id: null,
      invoice_number: [],

      search: "",
      decision_remark: "",
      status_return_store: null,

      alert: 0,

      status_refund: null,
    };
  }
  async componentDidMount() {
    await this.GetAll_Member();
    await this.Get_by_id();
  }
  Get_by_id = async () => {
    let result = await GET(tokens, "v1/refund/rc-number-detail?rc_number=" + this.props.location.query.id, null);
    if (result && result.status) {
      let data_by_id = result.data;
      this.setState({
        data: data_by_id.data,
        date_time: data_by_id.data.pick_up_datetime,
        bill_code: this.props.location.query.id,
        decision_remark: data_by_id.data.remark,
        return_warehouse: data_by_id.data.status_return_store === 1 ? true : false,
        not_return_warehouse: data_by_id.data.status_return_store === 2 ? true : false,
        status_refund: data_by_id.data.status_refund,
      });
    }
    this.setState({ loading: false });
  };
  GetAll_Member = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/refund/member-list", null);

    if (result && result.status) {
      this.setState({ data_member: result.data.data });
    }
    this.setState({ loading: false });
  };

  ClearForm() {
    this.setState({
      page: 1,

      select_all: false,
      selected: false,
      return_warehouse: false,
      not_return_warehouse: false,

      data_member: [],
      data_member_id: null,

      invoice_number_id: null,
      invoice_number: [],

      id: "",
      decision_remark: "",
      alert: 0,
    });
  }

  total_all = (type) => {
    let total_yard_qty = 0;
    let price_by_item = 0;
    let total_price = 0;
    let data = this.state.data;
    if (data) {
      for (let item of data.refund_list) {
        let order =
          item.order_list &&
          item.order_list
            .filter((data) => data.product_name.includes(this.state.search) || this.state.search === "")
            .filter((item) => {
              return item.product_code !== "PD-0000";
            });

        for (let order_list of order) {
          total_yard_qty += Number(order_list.yard_qty);
          price_by_item += Number(order_list.wood_qty);
          total_price += Number(order_list.yard_qty * order_list.price_by_item);
        }
      }
    }

    if (type === "total_yard_qty") {
      return baht(total_yard_qty);
    } else if (type === "price_by_item") {
      return baht(price_by_item);
    } else if (type === "total_price") {
      return baht(total_price);
    }
  };
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="คืนสินค้า" page3="เพิ่มรายการคืนสินค้า" />
            <div style={{ height: 60 }}></div>
            <div className="mx-4 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between ">
                <h3>
                  <b>คืนสินค้า</b>
                </h3>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/refund";
                    }}
                  >
                    {" "}
                    <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ{" "}
                  </button>
                </div>
              </div>

              {/* date */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card-header row d-flex mx-0 justify-content-center align-items-center bg-secondary rounded-top-16">
                  <div className="d-flex row align-items-center justify-content-center">
                    <div className="mx-5 ">
                      <label>วันที่: {this.state.date_time ? format_date(this.state.date_time) : ""}</label>
                    </div>
                    <div className="d-none d-xl-block" style={{ height: 40, width: 1, backgroundColor: "#c9c9c9" }}></div>
                    <div className="col-12 d-lg-none">
                      <hr />
                    </div>
                    <div className="mx-5">
                      <label>เลขที่เอกสาร: {this.state.bill_code}</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* title */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card p-3">
                  <div className="d-flex row align-items-center justify-content-between">
                    <div className="col-6 col-lg-6 col-xl-6">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">ค้นหาชื่อลูกค้า:</label>
                          <Select
                            ref={(click) => (this.Input_member = click)}
                            className="px-0 mb-3"
                            // options={
                            //     this.state.data_member &&
                            //     this.state.data_member.map((item) => ({
                            //         value: item.user_id,
                            //         label: item.full_name,
                            //     }))
                            // }
                            styles={customStylesTypeOption}
                            components={{ Option: TypeOption }}
                            placeholder="ชื่อลูกค้า"
                            isSearchable={false}
                            isClearable={false}
                            defaultValue={this.state.data_member_id}
                            disabled={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">ค้นหาเลขที่เอกสาร:</label>
                          <Select ref={(click) => (this.Input_number = click)} className="px-0 mb-3" options={this.state.invoice_number} styles={customStylesTypeOption} components={{ Option: TypeOption }} placeholder="ค้นหาเลขที่เอกสาร" disabled={false} isSearchable={false} isClearable={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 pt-3">
                <div className="card">
                  <div className="card-header rounded-top-16">
                    <div className="w-100 d-flex justify-content-between align-items-center pt-2">
                      <h5>
                        <b>รายการคืนสินค้า</b>
                      </h5>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive rounded-16">
                      <table className="table table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 80, maxHeight: 80 }}>
                              <b>เลขที่เอกสาร</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>จำนวนหลาที่คืน</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>จำนวนไม้ที่คืน</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>รวม</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>ราคาต่อหน่วย</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>ยอดรวมสุทธิ</b>
                            </th>
                          </tr>
                        </thead>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tbody>
                            <tr>
                              <td colSpan={7} className="text-center table-light ">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          </tbody>
                        )}

                        {this.state.data &&
                          this.state.data.refund_list.map((item, index) => (
                            <>
                              <tbody>
                                <tr key={index} className="pointer">
                                  <td className={"text-center bg-light-gray pointer"} style={{ minWidth: 100, maxHeight: 100 }}>
                                    {item.invoice_number}
                                  </td>
                                  <td className={"text-left bg-light-gray"} style={{ minWidth: 200 }}>
                                    {status_sale_transaction(item.status_bill)}
                                  </td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                </tr>
                                {item.order_list &&
                                  item.order_list.map((order_list, order_list_index) => (
                                    <tr key={order_list_index} className="pointer">
                                      <td className={"text-center"}></td>
                                      <td className="text-left">{order_list.product_name}</td>
                                      <td className="text-center">{order_list.yard_detail.toString().replaceAll(",", ", ")}</td>
                                      <td className="text-center">{order_list.wood_qty}</td>
                                      <td className="text-center">{baht(order_list.yard_qty)}</td>
                                      <td className="text-right">{baht(order_list.price_by_item)}</td>
                                      <td className="text-right">{baht(order_list.yard_qty * order_list.price_by_item)}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bodyw-100 position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex">
              <h6>สรุปรายการคืน :</h6>
              <h6 className="ml-3">{this.total_all("total_yard_qty")} หลา</h6>
              <h6 className="ml-3">{this.total_all("price_by_item")} ไม้</h6>
              <h6 className="ml-3">{this.total_all("total_price")} บาท</h6>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex w-100 justify-content-end align-items-center">
                <div className="d-flex justify-content-between">
                  <div className="d-flex w-100 justify-content-end">
                    {this.state.return_warehouse === true && (
                      <>
                        <input
                          disabled={true}
                          type="checkbox"
                          onChange={(e) => {
                            this.setState({ return_warehouse: !this.state.return_warehouse, not_return_warehouse: false });
                          }}
                          checked={this.state.return_warehouse}
                        />
                        <label className="text-dark mx-2 ">คืนเข้าโกดัง</label>
                      </>
                    )}

                    {this.state.not_return_warehouse === true && (
                      <>
                        <input
                          disabled={true}
                          type="checkbox"
                          onChange={(e) => {
                            this.setState({ not_return_warehouse: !this.state.not_return_warehouse, return_warehouse: false });
                          }}
                          checked={this.state.not_return_warehouse}
                        />
                        <label className="text-dark ml-2">ไม่คืนเข้าโกดัง</label>
                      </>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between mx-2 text-16">
                  <label className="text-dark mx-2  wpx-60">หมายเหตุ: </label>
                  <div className="d-flex w-100 justify-content-end">
                    <label>{this.state.decision_remark ? this.state.decision_remark : "-"}</label>
                  </div>
                </div>
                <div className="d-flex justify-content-between mx-2 text-16">
                  <label className="text-dark mx-2  wpx-60">การอนุมัติ: </label>
                  <div className="d-flex w-100 justify-content-end">
                    <label>
                      <b>{status_refund(this.state.status_refund)}</b>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
