import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { alert, POST, tokens, date_invoice_s, total, baht, GET } from "../../../components/CustomComponent.js";
import WARNING from "../../../assets/images/warning.png";
import Select, { components } from "react-select";
import "../../../assets/css/bootstrap.css";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: "#E1E1E1",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class ExpensesCreatesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      date_time_receipt: ("00" + new Date().getDate()).slice(-2) + " " + ("00" + (new Date().getMonth() + 1)).slice(-2) + " " + (new Date().getFullYear() + 543).toString().slice(-2) + "," + new Date().toTimeString(),
      bill_code: "EX" + new Date().getTime(),

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_ref: false,

      ref_code: "",
      ref_codes: "",

      data: [],

      start_date: "",
      end_date: "",
      id: "",
      search: "",

      expense_type: "",

      detail: null,
      expense_category: {
        label: "",
        value: "",
      },

      unit: "",
      price_by_unit: "",
      total_price: "",

      alert: 0,
    };
    this.inputRef = React.createRef();
  }
  async componentDidMount() {
    await this.Expense_Type();
  }

  Expense_Type = async () => {
    let result = await GET(tokens, "v1/expense-mgt/expense_category/filter-search/page/0?data_search=" + this.state.search, null);
    if (result && result.status) {
      this.setState({ expense_type: result.data });
    }
  };

  AddExpenses = async () => {
    this.setState({ loading: true });
    let data = this.state.data;
    let expense_list = [];
    let date_time_receipt = new Date().getFullYear() + "-" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("00" + new Date().getDate()).slice(-2);
    data.forEach((e) => {
      let d = {
        detail: e.detail,
        expense_category_id: e.expense_category_id.value,
        price_by_unit: Number(e.price_by_unit),
        total_price: Number(e.total_price),
        unit: Number(e.unit),
      };
      expense_list.push(d);
    });

    let body = {
      date_time_receipt: date_time_receipt,
      ref_receipt_code: this.state.ref_code,
      total: total(this.state.data, "total_price"),
      expense_list: expense_list,
    };

    let result = await POST(tokens, "v1/expense-mgt/expense/create", body);
    if (result && result.status) {
      alert("success", "บันทึกสำเร็จ", "บันทึกข้อมูลเรียบร้อยเเล้ว");

      setTimeout(() => {
        window.location.href = "/expenses";
      }, 1000);
    }
    this.setState({ loading: false });
  };
  Calculate() {
    let data = this.state.data;
    for (let i = 0; i < data.length; i++) {
      data[i].total_price = Number(data[i].unit) * Number(data[i].price_by_unit);
    }
    this.setState({ data: data });
  }

  ClearForm() {
    this.setState({
      page: 1,

      unit: "",
      price_by_unit: "",
      total_price: "",

      modal_ref: false,

      alert: 0,
    });
  }

  ClearForm_Add() {
    this.inputRef.clearValue();
    this.setState({
      detail: "",
      expense_category_id: "",
      unit: "",
      price_by_unit: "",
      total_price: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="จัดการค่าใช้จ่าย" page3="บันทึกค่าใช้จ่าย" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>เพิ่มค่าใช้จ่าย</b>
              </h3>
            </div>

            {/* table */}
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 justify-content-around align-items-center bg-secondary rounded-top-16">
                  <label>วันที่: {date_invoice_s(this.state.date_time_receipt)}</label>
                  <div className="d-none d-xl-block" style={{ height: 40, width: 1, backgroundColor: "#c9c9c9" }}></div>
                  <div className="col-12 d-lg-none">
                    <hr />
                  </div>
                  <label>เลขที่เอกสาร: {this.state.bill_code}</label>
                  <div className="d-none d-xl-block" style={{ height: 40, width: 1, backgroundColor: "#c9c9c9" }}></div>
                  <div className="col-12  d-lg-none">
                    <hr />
                  </div>
                  <label className="mb-0">
                    เอกสารอ้างอิง: {this.state.ref_code ? this.state.ref_code : ""}
                    <span
                      className="pointer text-primary ml-1"
                      onClick={() => {
                        this.setState({ modal_ref: true });
                      }}
                    >
                      <label className="icon">{"\uf303"}</label>เปลี่ยน
                    </span>
                  </label>
                </div>
                <div className="card-body p-0 ">
                  <div className="table-responsive rounded-16 tableFixHead" style={{ paddingBottom: 500 }}>
                    <table className="table table-borderless table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <b>ลำดับ</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 200 }}>
                            <b>รายละเอียด</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>หมวดหมู่</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>จำนวน/หน่วย</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>ราคา/หน่วย</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 150 }}>
                            <b>ยอดรวมสุทธิ</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 90 }}>
                            <b>นำออก</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={7} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data &&
                          this.state.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-center">{Number(index + 1)}</td>
                              <td className="text-left">
                                <input
                                  maxLength={200}
                                  type="text"
                                  placeholder="กรอกข้อมูล"
                                  className="form-control text-left "
                                  onChange={(e) => {
                                    let data = this.state.data;
                                    data[index].detail = e.target.value;
                                    this.setState({ data: data });
                                  }}
                                  value={item.detail}
                                />
                              </td>
                              <td className="text-left">
                                <Select
                                  options={
                                    this.state.expense_type &&
                                    this.state.expense_type.data.map((item) => ({
                                      value: item.expense_category_id,
                                      label: item.expense_category_name,
                                    }))
                                  }
                                  components={{ Option: TypeOption }}
                                  styles={customStylesTypeOption}
                                  placeholder="ค้นหาชื่อหมวดหมู่"
                                  isClearable
                                  defaultValue={item.expense_category_id}
                                  onChange={(e) => {
                                    if (e === null) {
                                      let data = this.state.data;
                                      data[index].expense_category_id = e;
                                      this.setState({ data: data, page: 1 });

                                      setTimeout(() => {
                                        this.Expense_Type();
                                      }, 20);
                                    } else {
                                      let data = this.state.data;
                                      data[index].expense_category_id = e;
                                      this.setState({ data: data, page: 1 });

                                      setTimeout(() => {
                                        this.Expense_Type();
                                      }, 20);
                                    }
                                  }}
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  maxLength={14}
                                  type="text"
                                  placeholder="กรอกข้อมูล"
                                  className="form-control text-right"
                                  onChange={(e) => {
                                    if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                      let data = this.state.data;
                                      data[index].unit = e.target.value;
                                      this.setState({ data: data });

                                      setTimeout(() => {
                                        this.Calculate();
                                      }, 10);
                                    }
                                  }}
                                  value={item.unit}
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  maxLength={14}
                                  type="text"
                                  placeholder="กรอกข้อมูล"
                                  className="form-control text-right"
                                  onChange={(e) => {
                                    if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                      let data = this.state.data;
                                      data[index].price_by_unit = e.target.value;
                                      this.setState({ data: data });

                                      setTimeout(() => {
                                        this.Calculate();
                                      }, 10);
                                    }
                                  }}
                                  value={item.price_by_unit}
                                />
                              </td>
                              <td className="text-right">{baht(item.total_price)}</td>
                              <td className="text-left">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    let data = this.state.data;
                                    data.splice(index, 1);
                                    this.setState({ data: data });
                                  }}
                                >
                                  <label
                                    className="icon bg-danger text-14 text-white"
                                    style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: 12,
                                    }}
                                  >
                                    {"\uf00d"}
                                  </label>
                                </button>
                              </td>
                            </tr>
                          ))}
                        <tr>
                          <td className="text-center">{Number(this.state.data.length + 1)}</td>
                          <td className="text-left">
                            <input
                              id="detail"
                              maxLength={30}
                              type="text"
                              placeholder="กรอกข้อมูล"
                              className="form-control text-left"
                              onChange={(e) => {
                                this.setState({ detail: e.target.value });
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  this.inputRef.focus();
                                }
                              }}
                              value={this.state.detail}
                            />
                          </td>
                          <td className="text-left">
                            <Select
                              ref={(node) => (this.inputRef = node)}
                              options={
                                this.state.expense_type &&
                                this.state.expense_type.data.map((item) => ({
                                  value: item.expense_category_id,
                                  label: item.expense_category_name,
                                }))
                              }
                              components={{ Option: TypeOption }}
                              styles={customStylesTypeOption}
                              placeholder="ค้นหาชื่อหมวดหมู่"
                              isClearable
                              defaultValue={this.state.expense_category && this.state.expense_category.value}
                              onChange={(e) => {
                                if (e === null) {
                                  this.setState({ expense_category: e, page: 1 });
                                  setTimeout(() => {
                                    this.Expense_Type();
                                  }, 20);
                                } else {
                                  this.setState({ expense_category: e, page: 1 });
                                  setTimeout(() => {
                                    this.Expense_Type();
                                    document.getElementById("unit").focus();
                                  }, 20);
                                }
                              }}
                            />
                          </td>
                          <td className="text-right">
                            <input
                              id="unit"
                              maxLength={14}
                              type="text"
                              placeholder="กรอกข้อมูล"
                              className="form-control text-right"
                              onChange={(e) => {
                                if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                  this.setState({ unit: e.target.value });
                                }
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Enter" && this.state.unit !== "") {
                                  document.getElementById("price_by_unit").focus();
                                }
                              }}
                              value={this.state.unit}
                            />
                          </td>
                          <td className="text-right">
                            <input
                              id="price_by_unit"
                              maxLength={14}
                              type="text"
                              placeholder="กรอกข้อมูล"
                              className="form-control text-right"
                              onChange={(e) => {
                                if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                  this.setState({ price_by_unit: e.target.value });
                                }
                              }}
                              value={this.state.price_by_unit}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  if (this.state.detail && this.state.expense_category && this.state.unit && this.state.price_by_unit) {
                                    this.btn.click();
                                    document.getElementById("detail").focus();
                                  } else {
                                    alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                                  }
                                }
                              }}
                            />
                          </td>
                          <td className="text-right">{this.state.total_price}</td>
                          <td className="text-left">
                            <button
                              ref={(node) => (this.btn = node)}
                              type="submit"
                              className="btn btn-link text-secondary d-flex justify-content-center align-items-center"
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: 20,
                                backgroundColor: "#4E6FFB",
                              }}
                              onClick={() => {
                                if (this.state.detail && this.state.expense_category && this.state.unit && this.state.price_by_unit) {
                                  this.setState({
                                    data: [
                                      ...this.state.data,
                                      {
                                        detail: this.state.detail,
                                        expense_category_id: this.state.expense_category,
                                        unit: this.state.unit,
                                        price_by_unit: this.state.price_by_unit,
                                        total_price: Number(this.state.unit) * Number(this.state.price_by_unit),
                                      },
                                    ],
                                  });

                                  this.ClearForm_Add();
                                } else {
                                  alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                                }
                              }}
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <th className="text-right" colSpan={5}>
                          <b>รวม</b>
                        </th>
                        <th className="text-right">
                          <b>฿{this.state.data && baht(total(this.state.data, "total_price"))}</b>
                        </th>
                        <th className="text-left"></th>
                      </tfoot>
                    </table>

                    {this.state.alert === 1 && (
                      <div className="w-100 center">
                        <div className="card bg-yellow-warning mx-5 p-3 mb-3 wpx-300">
                          <div className=" row d-flex justify-content-center align-content-center p-2">
                            <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                            <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="mr-2">
              <button
                className="btn btn-outline-primary w-100 px-4"
                onClick={() => {
                  window.location.href = "/expenses";
                }}
              >
                ยกเลิก
              </button>
            </div>

            <div className="ml-2">
              <button
                className="btn btn-primary w-100 px-4"
                onClick={() => {
                  let data = this.state.data;

                  let count = data.filter((item) => item.detail === "" || item.expense_category_id === "" || item.expense_category_id === null || item.price_by_unit === "" || item.total_price === "" || item.unit === "");

                  if (count.length !== 0) {
                    alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                  } else {
                    if (this.state.alert === 0) {
                      this.setState({ alert: 1 });
                    } else {
                      this.AddExpenses();
                    }
                  }
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>

        {/* refcode modal */}
        <Modal
          show={this.state.modal_ref}
          onHide={() => {
            this.setState({ modal_ref: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขเอกสารอ้างอิง</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* เลขเอกสารอ้างอิง */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label className="mr-2">เลขเอกสารอ้างอิง</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={20}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ ref_codes: e.target.value });
                  }}
                  value={this.state.ref_codes}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_ref: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({
                      ref_code: this.state.ref_codes,
                      modal_ref: false,
                    });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
