/* eslint-disable jsx-a11y/anchor-is-valid */
import "./global.js";

import React, { Component } from "react";

import { alert, cookies, tokens, logout, role, GET } from "./CustomComponent.js";

import "../assets/css/bootstrap.css";

export default class NavbarmainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      full_name: "",
      user_code: localStorage.getItem("user_code"),
    };
  }
  componentDidMount() {
    let profile = JSON.parse(localStorage.getItem("profile"));
    if (!profile && role) {
      logout();
      return;
    } else if (!tokens) {
      localStorage.clear();

      cookies.remove("token_AHJ_test");
      cookies.remove("role");
      cookies.remove("profile");
      cookies.remove("permission_page");
      cookies.remove("status_bill");
      cookies.remove("bill");
      cookies.remove("user_code");

      window.location.href = "/";
    } else {
      this.setState({
        email: profile.email,
        full_name: profile.full_name,
      });

      if (localStorage.getItem("user_code") === "ME000000001" || localStorage.getItem("user_code") === undefined) {
        localStorage.setItem("status_bill", "ขายสินค้า");
      }
    }
    this.Log();
  }
  Log = async () => {
    await GET(tokens, "v1/report/store-log", null);
  };
  GetPageAdmin = async () => {
    let permission_page = "";

    if (role.all_sale[0] === 1) {
      permission_page = "/sale";
    } else if (role.dashboard[0] === 1) {
      permission_page = "/dashboard";
    } else if (role.user_admin[0] === 1) {
      permission_page = "/user";
    } else if (role.role[0] === 1) {
      permission_page = "/role";
    } else if (role.company_supplier[0] === 1) {
      permission_page = "/company";
    } else if (role.user_member[0] === 1) {
      permission_page = "/member";
    } else if (role.user_member_rate[0] === 1) {
      permission_page = "/rate-member";
    } else if (role.all_sale_member[0] === 1) {
      permission_page = "/sale-member";
    } else if (role.invoice[0] === 1) {
      permission_page = "/invoice";
    } else if (role.payment_receive[0] === 1) {
      permission_page = "/sale/payment-receive";
    } else if (role.all_product[0] === 1) {
      permission_page = "/product";
    } else if (role.update_price[0] === 1) {
      permission_page = "/update_price";
    } else if (role.goods_receipt[0] === 1) {
      permission_page = "/product/receipt";
    } else if (role.type_product[0] === 1) {
      permission_page = "/category";
    } else if (role.move_stock[0] === 1) {
      permission_page = "/stock-move";
    } else if (role.adjust_stock[0] === 1) {
      permission_page = "/stock-adjustment";
    } else if (role.transaction_stock[0] === 1) {
      permission_page = "/stock-location";
    } else if (role.all_stock[0] === 1) {
      permission_page = "/stock";
    } else if (role.status_delivery[0] === 1) {
      permission_page = "/status-delivery";
    } else if (role.type_commission[0] === 1) {
      permission_page = "/commission-type";
    } else if (role.report_summary[0] === 1) {
      permission_page = "/report";
    } else if (role.report_sales_customers[0] === 1) {
      permission_page = "/reportsalescustomers";
    } else if (role.report_sales_products[0] === 1) {
      permission_page = "/reportsalesproducts";
    } else if (role.report_inventory[0] === 1) {
      permission_page = "/reportinventory";
    } else if (role.report_products_and_services[0] === 1) {
      permission_page = "/reportproductsandservices";
    } else if (role.report_transfer[0] === 1) {
      permission_page = "/reporttransfer";
    } else if (role.warehouse[0] === 1) {
      permission_page = "/warehouse";
    } else if (role.bank_account[0] === 1) {
      permission_page = "/bank";
    } else if (role.expenses[0] === 1) {
      permission_page = "/expenses";
    } else if (role.type_expenses[0] === 1) {
      permission_page = "/type_expenses";
    } else if (role.refund[0] === 1) {
      permission_page = "/refund";
    } else if (role.logaudit[0] === 1) {
      permission_page = "/logaudit";
    } else if (role.refund_accumulate[0] === 1) {
      permission_page = "/refund_accumulate";
    } else if (role.generate_barcode[0] === 1) {
      permission_page = "/generate-barcode";
    } else if (role.check_stock[0] === 1) {
      permission_page = "/check-stock";
    }

    if (tokens) {
      window.location.href = permission_page;
      localStorage.setItem("permission_page", permission_page);
    }
  };

  render() {
    return (
      <div>
        <div className="navbar-component row mx-0 px-0 pb-0 position-fixed w-100">
          <div className="col-12 d-flex row justify-content-between align-items-center " style={{ backgroundColor: "#FFFFFF", paddingBottom: 5, paddingTop: 5 }}>
            <div className="align-items-center d-flex row text-14 mx-0">
              <h3 className="text-header ">
                <b>AIMHENGJAN</b>
              </h3>
              <div
                onClick={() => {
                  if (localStorage.getItem("user_code") === "ME000000001") {
                    alert("info", "แจ้งเตือน", "ลูกค้าทั่วไปไม่สามารถเข้าถึงโหมดใบเสนอราคาได้");
                    this.props.onChange("ขายสินค้า");
                    localStorage.setItem("status_bill", "ขายสินค้า");
                    return;
                  }
                  if (localStorage.getItem("user_code") === "" || localStorage.getItem("user_code") === undefined) {
                    alert("warning", "แจ้งเตือน", "กรุณาเลือกสมาชิก");
                    this.props.onChange("ขายสินค้า");
                    localStorage.setItem("status_bill", "ขายสินค้า");

                    return;
                  } else {
                    if (localStorage.getItem("status_bill") === "ขายสินค้า") {
                      localStorage.setItem("status_bill", "ใบเสนอราคา");
                      this.props.onChange("ใบเสนอราคา");
                    } else {
                      this.props.onChange("ขายสินค้า");
                      localStorage.setItem("status_bill", "ขายสินค้า");
                    }
                  }
                }}
              >
                <label className="ml-3 pointer">
                  {localStorage.getItem("status_bill") === "ใบเสนอราคา" ? "ใบเสนอราคา" : "ขายสินค้า"}

                  <span className="icon-brand text-primary text-14 ml-1 pointer">{"\uf079"}</span>
                </label>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mr-3">
              <div className="row align-items-center justify-content-center">
                <div className="row d-none d-lg-block mr-1">
                  {role && role.user_member[0] === 1 && (
                    <button
                      className="btn btn-outline-primary px-3 mx-3 pointer"
                      style={{ height: 28, borderRadius: 14 }}
                      onClick={() => {
                        localStorage.removeItem("POS");
                        localStorage.removeItem("bill");
                        localStorage.removeItem("user_code");

                        setTimeout(() => {
                          this.GetPageAdmin();
                        }, 20);
                      }}
                    >
                      <span className="icon-brand text-primary text-14 mr-1">{"\uf079"}</span>
                      สลับไปยัง Admin
                    </button>
                  )}
                </div>
                {/* <div className="border-right" style={{ height: 24 }}></div> */}
                <div className="rounded-circle bg-cash-1 img-thumbnail mx-2 center d-none d-sm-flex" style={{ height: 44, width: 44 }}>
                  <b className="text-header-logo text-18">{this.state.full_name.slice(0, 1)}</b>
                </div>
                <span className="align-items-center text-right d-none d-sm-flex mr-3">
                  <b className="my-0 text-right">{this.state.full_name}</b>
                </span>

                <div className="dropdowns">
                  <a href="#" className="dropbtns pointer">
                    <span className="icon-brand text-primary text-14">{"\uf078"}</span>
                  </a>
                  {/* <span className="dropbtns pointer">
                                        <span className="icon-brand text-primary text-14">
                                            {"\uf078"}
                                        </span>
                                    </span> */}
                  <div className="dropdowns-content">
                    {role && role.user_member[0] === 1 && (
                      <a
                        className="d-lg-none pointer"
                        onClick={() => {
                          localStorage.removeItem("POS");
                          localStorage.removeItem("bill");
                          localStorage.removeItem("user_code");

                          setTimeout(() => {
                            this.GetPageAdmin();
                          }, 20);
                        }}
                      >
                        <label className="icon">{"\uf079"} </label> สลับไปยัง Admin
                      </a>
                    )}

                    <a
                      onClick={async () => {
                        await logout();
                      }}
                      className="pointer"
                    >
                      <label className="icon">{"\uf30d"} </label> ออกจากระบบ
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
