/* eslint-disable react/no-direct-mutation-state */
import NavbarComponent from "../../components/Navbarmain";
import React, { Component } from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Select, { components } from "react-select";
import { Modal } from "react-bootstrap";
import { alert, alert_url, GET, POST, PUT, tokens, ModalDetails, status_pos_payment, date, date_overdue, ModalPrint, ModalInstallment, baht, total } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 75,
    padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};

const IconOption = (props) => (
  <Option {...props}>
    <div className="w-100 d-flex">
      {props.data.logo ? <img src={props.data.logo} style={{ width: 30, marginRight: 16, borderRadius: 20 }} alt={props.data.label} /> : <div className=" bg-gray hpx-30 wpx-30 rounded-circle" style={{ marginRight: 16 }}></div>}
      {"ชื่อธนาคาร: " + props.data.label}
    </div>
    <div className="w-100 d-flex">
      <div className="hpx-30 wpx-30"></div>
      <p className="text-16 text-login ml-3">{"เลขที่บัญชี: " + props.data.bank_number}</p>
    </div>
  </Option>
);

export default class ListAccruedScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_print: false,

      check_all: false,
      selected: false,

      check_date: new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":")),

      //payment
      modal_payment: false,
      calculate_type: "เงินสด",
      price: "00",
      payment_type: 1,
      bank_list: null,
      bank_name: "",
      installment: 0,
      installment_list: [],
      input: false,

      //details
      modal_detail: false,
      data_detail: "",

      data: null,

      search: "",

      type: Number(this.props.location.query.type),
      member_id: this.props.location.query.id,

      text_cancle: "",
      order_main_number: "",

      status_order_main: "",
      status_bill: "",

      total_all: 0,
      count: 0,
      total_payment: 0,

      modal_installment: false,
      installment_detail: "",

      payment_detail: {
        credit_card_type: "",
        bank_number: "",
        bank_name: "",
        card_number: "",
        slip_number: "",
        remark_payment_s2: "",
        remark_payment_s3: "",
        full_name_transfer: "",
        datetime_transfer: "",
        reference_number: "",
        remark_payment_s4: "",
        remark_payment_s5: "",
        total_pay: 0.0,
        installment_list: [
          {
            total_pay: 0.0,
            pay: 0.0,
          },
        ],
      },

      total_pay: 0.0,
      i_all_total_pay: 0.0,

      order_data: "",

      //เงินทอน
      change: 0.0,
      text_modal: "",

      message_detail: {
        title: "",
        status_order_main: 1,
        calculate_type: "",
        action: "",
        type: "",
        member_id: "",
      },
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetBank();
    await this.GetAll();
    this.setState({ loading: false });
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/pos-mgt/order/overdue/" + this.state.type + "/" + this.state.member_id, null);

    if (result && result.status) {
      let data = result.data;

      let total = 0;
      data.data = data.data.filter((item) => item.status_order_main === 1);
      if (this.state.search.trim() !== "") {
        data.data = data.data.filter((item) => item.order_main_number.includes(this.state.search));
      }

      for (let data of result.data.data) {
        if (this.state.type === 4) {
          data.must_pay = Number(Number(data.installment_list.installment_list.filter((item_in) => item_in.pay === 0)[0].total_pay).toFixed(2));
        }
        Number(Number(data.total_balance).toFixed(2));
        Number(Number(data.total_price_final).toFixed(2));

        if (data.status_order_main === 1) {
          total += data.total_balance;
        }
      }
      this.setState({ data: data, total_all: total });
    }
    this.setState({ loading: false });
  };
  Get_by_id = async (id, type) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + id, null);

    if (result && result.status) {
      let data = result.data.data;
      let total_yard = 0;
      let total_wood = 0;
      let installment_detail = "";
      for (let i = 0; i < data.order_list_data.length; i++) {
        let sum_yard = 0;
        for (let sum of data.order_list_data[i].detail_yard_list) {
          sum_yard += Number(sum);
        }
        data.order_list_data[i].yard_qty = Number(sum_yard);
        total_yard += data.order_list_data[i].yard_qty;
        total_wood += data.order_list_data[i].qty;

        if (type === "installment") {
          installment_detail = data.order_list_data[0].payment_detail_data[0].installment_list;
          break;
        }
      }
      data.total_yard = total_yard;
      data.total_wood = total_wood;

      this.setState({ data_detail: data, installment_detail: installment_detail });

      setTimeout(() => {
        let i_all_total_pay = 0;

        if (type === "installment") {
          for (let i = 0; i < this.state.installment_detail.length; i++) {
            i_all_total_pay += this.state.installment_detail[i].pay;
          }

          this.setState({
            i_all_total_pay: i_all_total_pay,
            modal_installment: true,
          });
        } else {
          this.setState({
            modal_detail: true,
          });
        }
      }, 10);
    }
    this.setState({ loading: false });
  };
  Payment = async () => {
    this.setState({ loading: true });
    let order = [];
    for (let data of this.state.data.data) {
      if (data.selected === true) {
        order.push(data.order_main_id);
      }
    }
    if (this.state.type === 4) {
      if (Number(this.state.price) === 0) {
        this.setState({ loading: false });
        alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
        return;
      }
      let installment = this.state.payment_detail.installment_list;
      for (let i = 0; i < installment.length; i++) {
        if (installment[i].pay === 0) {
          installment[i].pay = installment[i].total_pay;
          this.setState({
            price: installment[i].total_pay.toString(),
            total_pay: installment[i].total_pay,
          });
          break;
        }
      }
      let data = this.state.payment_detail;
      data.installment_list = installment;
      if (Number(this.state.price) === this.state.total_pay) {
        this.setState({ general: Number(this.state.price), text_modal: "ชำระเงินแล้ว ฿" });
      } else {
        alert("info", "แจ้งเตือน", "กรุณาชำระเงินเต็มจำนวน");
        this.setState({ price: this.state.total_pay.toString(), loading: false });
        return;
      }
    } else {
      if (this.state.calculate_type === "เงินโอน" || (this.state.calculate_type === "เงินสด" && this.state.status_order_main !== 4 && this.state.status_order_main !== 1)) {
        if (this.state.status_order_main === 5) {
          if (Number(this.state.price) < this.state.deposit) {
            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินมากกว่าค่ามัดจำ");
            this.setState({ price: this.state.deposit.toString(), loading: false });
            return;
          } else {
            let general = Number(this.state.price);
            this.setState({ general: general });
          }
        } else {
          if (Number(this.state.price) === 0) {
            this.setState({ loading: false });
            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");

            return;
          }
          if (Number(this.state.price) >= this.state.total_pay) {
            let general = Number(Number(this.state.price) - this.state.total_pay);
            this.setState({ general: general, text_modal: "เงินทอน ฿" });
          } else {
            this.setState({ general: Number(this.state.price), text_modal: "ชำระเงินแล้ว ฿" });
          }
        }
      }
    }

    let body = {
      member_id: this.state.member_id,
      payment_type_layer_1: this.state.payment_type,
      payment_detail: this.state.payment_detail,
      order_main_list: order,
    };

    let result = await PUT(tokens, "v1/pos-mgt/order/overdue/update", body);

    if (result && result.status) {
      switch (this.state.status_order_main) {
        case 1:
          this.setState({
            modal_print: true,
            message_detail: {
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              calculate_type: this.state.calculate_type,
              status_order_main: this.state.status_order_main,
              action: "paymentlist",
              type: this.state.type,
              member_id: this.state.member_id,
            },
          });
          this.setState({ loading: false, modal_payment: false });
          break;
        case 2:
          this.setState({
            modal_print: true,
            message_detail: {
              title: "ชำระเงิน",
              calculate_type: this.state.calculate_type,
              status_order_main: this.state.status_order_main,
              action: "paymentlist",
              type: this.state.type,
              member_id: this.state.member_id,
            },
          });
          this.setState({ loading: false, modal_payment: false });
          break;
        case 4:
          alert_url("success", "พักออเดอร์เรียบร้อยแล้ว", "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนู พักออเดอร์", "/main");
          this.setState({ loading: false, modal_pause_order: false });
          break;
        case 5:
          this.setState({
            modal_print: true,
            message_detail: {
              title: "ชำระค่ามัดจำ",
              calculate_type: this.state.calculate_type,
              status_order_main: this.state.status_order_main,
              action: "paymentlist",
              type: this.state.type,
              member_id: this.state.member_id,
            },
          });
          this.setState({ loading: false, modal_payment: false });
          break;
        default:
          this.setState({ loading: false });
          break;
      }

      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  Payment4 = async () => {
    this.setState({ loading: true });
    let data = this.state.data.data
      .filter((item) => item.selected)
      .map((item) => {
        return {
          order_main_id: item.order_main_id,
          total_pay: item.must_pay,
        };
      });
    let total_pay = Number(Number(total(data, "total_pay")).toFixed(2));

    if (this.state.type === 4) {
      if (Number(this.state.price) === 0) {
        this.setState({ loading: false });
        alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
        return;
      } else if (Number(this.state.price) === total_pay) {
        this.setState({ general: Number(this.state.price), text_modal: "ชำระเงินแล้ว ฿" });
      } else {
        alert("info", "แจ้งเตือน", "กรุณาชำระเงินเต็มจำนวน");
        this.setState({ price: total_pay.toString(), loading: false });
        return;
      }
    }

    let body = {
      member_id: this.state.member_id,
      payment_type_layer_1: this.state.payment_type,
      payment_detail: this.state.payment_detail,
      order_main_list: data.map((item) => {
        return {
          order_main_id: item.order_main_id,
          pay: item.total_pay,
        };
      }),
    };

    let result = await PUT(tokens, "v1/pos-mgt/order/overdue/payment_by_user", body);

    if (result && result.status) {
      switch (this.state.status_order_main) {
        case 1:
          this.setState({
            modal_print: true,
            message_detail: {
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              calculate_type: this.state.calculate_type,
              status_order_main: this.state.status_order_main,
              action: "paymentlist",
              type: this.state.type,
              member_id: this.state.member_id,
            },
          });
          this.setState({ loading: false, modal_payment: false });
          break;
        case 2:
          this.setState({
            modal_print: true,
            message_detail: {
              title: "ชำระเงิน",
              calculate_type: this.state.calculate_type,
              status_order_main: this.state.status_order_main,
              action: "paymentlist",
              type: this.state.type,
              member_id: this.state.member_id,
            },
          });
          this.setState({ loading: false, modal_payment: false });
          break;
        case 4:
          alert_url("success", "พักออเดอร์เรียบร้อยแล้ว", "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนู พักออเดอร์", "/main");
          this.setState({ loading: false, modal_pause_order: false });
          break;
        case 5:
          this.setState({
            modal_print: true,
            message_detail: {
              title: "ชำระค่ามัดจำ",
              calculate_type: this.state.calculate_type,
              status_order_main: this.state.status_order_main,
              action: "paymentlist",
              type: this.state.type,
              member_id: this.state.member_id,
            },
          });
          this.setState({ loading: false, modal_payment: false });
          break;
        default:
          this.setState({ loading: false });
          break;
      }

      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  GetBank = async () => {
    let body = {
      data_search: "",
    };

    let result = await POST(tokens, "v1/bank_account/search/page/0", body);

    if (result && result.status) {
      this.setState({ bank_list: result.data });
    }
  };
  edit_price = (number) => {
    if (Number(this.state.price) === 0 && !this.state.price.includes(".")) {
      this.setState({ price: number });
    } else {
      if (this.state.price.includes(".")) {
        if (this.state.price.split(".")[1].length < 2) {
          this.setState({ price: this.state.price + number });
        }
      } else {
        this.setState({ price: this.state.price + number });
      }
    }
  };
  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  Retry = async (id) => {
    window.location.href = "/main?retry_id=" + id;
  };
  ClearForm() {
    this.setState({
      page: 1,

      text_cancle: "",
      order_main_number: "",

      status_order_main: "",
      status_bill: "",

      total_payment: 0,
      count: 0,
      search: "",
      start_date: "",
      end_date: "",

      check_all: false,
      selected: false,
    });
  }
  payment_clear = () => {
    let data = this.state.payment_detail;
    data.credit_card_type = "";
    data.bank_number = "";
    data.bank_name = "";
    data.card_number = "";
    data.slip_number = "";
    data.remark_payment_s2 = "";
    data.remark_payment_s3 = "";
    data.full_name_transfer = "";
    data.datetime_transfer = "";
    data.reference_number = "";
    data.remark_payment_s4 = "";
    data.remark_payment_s5 = "";

    this.setState({
      payment_detail: data,
      input: false,
      installment: 0,
    });
  };
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}

        <div className="w-100" style={{ height: "100%", paddingBottom: "76px" }}>
          <NavbarComponent
            onChange={(e) => {
              this.setState({ status_bill: e });
            }}
          />
          <div style={{ height: 60 }}></div>

          <div className="mx-5 mt-3 mb-3 ">
            {/* header */}
            <div className="card w-100 p-4">
              <div className="mx-0 w-100 row justify-content-between">
                <h3>
                  <b>รายการค้างจ่าย</b>
                </h3>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 "
                    onClick={() => {
                      window.location.href = "/accrued";
                    }}
                  >
                    <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ
                  </button>
                </div>
              </div>
            </div>

            {/* table */}
            <div className="w-100 card mt-4 p-4">
              <div className="card rounded">
                <div className="row d-flex ">
                  <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex align-content-center mb-3">
                    <div className="w-100">
                      <div className="w-100 d-flex align-items-center">
                        <div className="border border-primary bg-white rounded w-100" style={{ padding: 6 }}>
                          <div className="d-flex w-100">
                            <div className="w-50">
                              <button
                                className={this.state.type === 2 ? "btn btn-primary w-100" : "btn btn-link w-100"}
                                onClick={() => {
                                  this.setState({ type: 2, page: 1 });

                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 10);
                                }}
                              >
                                เงินเชื่อ
                              </button>
                            </div>
                            <div className="w-50 pl-1">
                              <button
                                className={this.state.type === 4 ? "btn btn-primary w-100" : "btn btn-link w-100"}
                                onClick={() => {
                                  this.setState({ type: 4, page: 1 });

                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 10);
                                }}
                              >
                                แบ่งเป็นงวด
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 ml-auto mb-3">
                    <div className="row">
                      <div className="col-12 d-flex align-content-center mb-3 ">
                        <div className="w-100 mr-1"></div>

                        <input
                          maxLength={30}
                          className="form-control border-0 ml-1"
                          type="search"
                          placeholder="พิมพ์คำค้นหา"
                          value={this.state.search}
                          onChange={(e) => {
                            this.setState({ search: e.target.value });
                            if (e.target.value === "") {
                              this.setState({ page: 1, search: "" });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              this.setState({
                                page: 1,
                              });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body p-0 ">
                  <div className="table-responsive rounded-16">
                    {/* เงินเชื่อ */}
                    {this.state.type === 2 && (
                      <table className="table table-striped table-borderless table-sm table-hover">
                        <thead className="thead-dark-gray">
                          <tr className="">
                            <th className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  let data_array = this.state.data ? this.state.data : [];
                                  let count = 0;
                                  let total = 0;
                                  for (let data of data_array.data) {
                                    if (data.status_order_main === 1) {
                                      data.selected = e.target.checked;
                                      if (e.target.checked) {
                                        count += 1;
                                        total += data.total_balance;
                                      }
                                    }
                                  }
                                  this.setState({
                                    data: data_array,
                                    check_all: e.target.checked,
                                    count: count,
                                    total_payment: total,
                                  });
                                }}
                                checked={this.state.check_all}
                              />
                            </th>
                            <th className="text-center">เลขที่รายการ</th>
                            <th className="text-center">ชื่อลูกค้า</th>
                            <th className="text-center">วันที่</th>
                            <th className="text-center">กำหนดจ่าย</th>
                            <th className="text-center">ยอดค้างชำระ</th>
                            <th className="text-center">ยอดรวม</th>
                            <th className="text-center">สถานะ</th>
                            <th className="text-center">จัดการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data && this.state.data.data.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  {item.status_order_main === 1 && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        let data_array = this.state.data ? this.state.data : [];
                                        if (data_array.data[index].selected) {
                                          data_array.data[index].selected = !item.selected;
                                        } else {
                                          data_array.data[index].selected = true;
                                        }
                                        this.setState({ data: data_array });
                                        let selected = true;
                                        let count = 0;
                                        let total = 0;
                                        for (let data of this.state.data.data) {
                                          if (data.status_order_main === 1) {
                                            if (!data.selected) {
                                              selected = false;
                                            } else {
                                              count += 1;
                                              total += data.total_balance;
                                            }
                                          }
                                        }
                                        this.setState({
                                          check_all: selected,
                                          count: count,
                                          total_payment: total,
                                        });
                                      }}
                                      checked={item.selected}
                                    />
                                  )}
                                </td>
                                <td className="text-center">{item.order_main_number}</td>
                                <td className="text-center">{item.member_name}</td>
                                <td className="text-center">{date(item.datetime_create)}</td>
                                <td className="text-center">{date_overdue(item.payment_due_date, item.payment_countdown)}</td>
                                <td className="text-center">{baht(item.total_balance)}</td>
                                <td className="text-center">{baht(item.total_price_final)}</td>
                                <td className="text-center">{status_pos_payment(item.status_order_main)}</td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-dark">
                                        <h3 className="icon h2">{"\uf141"}</h3>
                                      </button>
                                    }
                                    transition
                                  >
                                    <MenuItem
                                      onClick={async () => {
                                        this.payment_clear();
                                        let data_array = this.state.data ? this.state.data : [];
                                        if (data_array.data[index].selected) {
                                          data_array.data[index].selected = !item.selected;
                                        } else {
                                          data_array.data[index].selected = true;
                                        }
                                        this.setState({ data: data_array });
                                        let selected = true;
                                        let count = 0;
                                        let total = 0;
                                        for (let data of this.state.data.data) {
                                          if (data.status_order_main === 1) {
                                            if (!data.selected) {
                                              selected = false;
                                            } else {
                                              count += 1;
                                              total += data.total_balance;
                                            }
                                          }
                                        }
                                        this.setState({
                                          check_all: selected,
                                          count: count,
                                          total_payment: total,
                                        });
                                      }}
                                    >
                                      <span className="icon mr-2 h2">{"\uf0d6"}</span>
                                      ชำระเงิน
                                    </MenuItem>

                                    {/* <MenuItem
                                                                        onClick={async () => {

                                                                        }}

                                                                    >
                                                                        <span className="icon mr-2 h2">{"\uf02f"}</span>
                                                                        ใบส่งของ/แจ้งหนี้
                                                                    </MenuItem> */}

                                    <MenuItem
                                      onClick={async () => {
                                        this.Get_by_id(item.order_main_id);
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf15c"}</span>
                                      รายละเอียด
                                    </MenuItem>
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                    {/* แบ่งเป็นงวด */}
                    {this.state.type === 4 && (
                      <table className="table table-striped table-borderless table-hover">
                        <thead className="thead-dark-gray">
                          <tr className="">
                            <th className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  let data_array = this.state.data ? this.state.data : [];
                                  let count = 0;
                                  let total = 0;
                                  for (let data of data_array.data) {
                                    if (data.status_order_main === 1) {
                                      data.selected = e.target.checked;
                                      if (e.target.checked) {
                                        count += 1;
                                        total += data.must_pay;
                                      }
                                    }
                                  }
                                  this.setState({
                                    data: data_array,
                                    check_all: e.target.checked,
                                    count: count,
                                    total_payment: Number(total).toFixed(2),
                                  });
                                }}
                                checked={this.state.check_all}
                              />
                            </th>
                            <th className="text-center">เลขที่รายการ</th>
                            <th className="text-center">ชื่อลูกค้า</th>
                            <th className="text-center">วันที่</th>
                            <th className="text-center">ยอดค้างชำระ</th>
                            <th className="text-center">ยอดรวม</th>
                            <th className="text-center">สถานะ</th>
                            <th className="text-center">จัดการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data && this.state.data.data.length === 0 && (
                            <tr>
                              <td colSpan={7} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  {item.status_order_main === 1 && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        let data_array = this.state.data ? this.state.data : [];
                                        if (data_array.data[index].selected) {
                                          data_array.data[index].selected = !item.selected;
                                        } else {
                                          data_array.data[index].selected = true;
                                        }
                                        this.setState({ data: data_array });
                                        let selected = true;
                                        let count = 0;
                                        let total = 0;
                                        for (let data of this.state.data.data) {
                                          if (data.status_order_main === 1) {
                                            if (!data.selected) {
                                              selected = false;
                                            } else {
                                              count += 1;
                                              total += data.must_pay;
                                            }
                                          }
                                        }
                                        this.setState({
                                          check_all: selected,
                                          count: count,
                                          total_payment: total,
                                        });
                                      }}
                                      checked={item.selected}
                                    />
                                  )}
                                </td>
                                <td className="text-center">{item.order_main_number}</td>
                                <td className="text-center">{item.member_name}</td>
                                <td className="text-center">{date(item.datetime_create)}</td>
                                <td className="text-center">{baht(item.total_balance)}</td>
                                <td className="text-center">{baht(item.total_price_final)}</td>
                                <td className="text-center">{status_pos_payment(item.status_order_main)}</td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-dark">
                                        <h3 className="icon h2">{"\uf141"}</h3>
                                      </button>
                                    }
                                    transition
                                  >
                                    <MenuItem
                                      onClick={async () => {
                                        let data_array = this.state.data ? this.state.data : [];
                                        if (data_array.data[index].selected) {
                                          data_array.data[index].selected = !item.selected;
                                        } else {
                                          data_array.data[index].selected = true;
                                        }
                                        this.setState({ data: data_array });
                                        let selected = true;
                                        let count = 0;
                                        let total = 0;
                                        for (let data of this.state.data.data) {
                                          if (data.status_order_main === 1) {
                                            if (!data.selected) {
                                              selected = false;
                                            } else {
                                              count += 1;
                                              total += data.must_pay;
                                            }
                                          }
                                        }
                                        this.setState({
                                          check_all: selected,
                                          count: count,
                                          total_payment: total,
                                        });
                                      }}
                                    >
                                      <span className="icon mr-2 h2">{"\uf0d6"}</span>
                                      ชำระเงิน
                                    </MenuItem>

                                    <MenuItem onClick={async () => {}}>
                                      <span className="icon mr-2 h2">{"\uf02f"}</span>
                                      ใบส่งของ/แจ้งหนี้
                                    </MenuItem>

                                    <MenuItem
                                      onClick={async () => {
                                        this.Get_by_id(item.order_main_id, "");
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf15c"}</span>
                                      รายละเอียด
                                    </MenuItem>

                                    <MenuItem
                                      onClick={async () => {
                                        this.Get_by_id(item.order_main_id, "installment");
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf1c0"}</span>
                                      รายการผ่อนชำระ
                                    </MenuItem>
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 pt-3 bg-white position-fixed bottom-0" style={{ bottom: 0, right: 0 }}>
          <div className="w-100 row d-flex justify-content-between align-items-center px-5">
            <div className="d-flex align-items-center mb-3">
              <label className="mx-2">รวมยอดค้างทั้งหมด:</label>
              <label className="text-primary">{baht(this.state.total_all)}</label>
            </div>
            <div className="d-flex align-items-center mb-3 ">
              <label className="mx-2">รวมที่เลือก {this.state.count} รายการ:</label>
              <h3 className="text-primary mx-2">
                <b>฿{baht(this.state.total_payment)}</b>
              </h3>
              <button
                type="button"
                className="btn btn-primary px-3"
                onClick={() => {
                  if (this.state.count === 0) {
                    alert("info", "แจ้งเตือน", "ไม่มีรายการที่เลือก");
                  } else {
                    this.payment_clear();

                    this.setState({
                      price: this.state.total_payment.toString(),
                      modal_payment: true,
                    });
                  }
                }}
              >
                ยืนยันการชำระ
              </button>
            </div>
          </div>
        </div>

        {/* ชำระเงิน modal */}
        <Modal
          size="m"
          show={this.state.modal_payment}
          onHide={() => {
            this.setState({ modal_payment: false });
            this.payment_clear();
          }}
        >
          <Modal.Body>
            <div className="center rounded btn-select-member hpx-54 mb-0">
              <label>
                <b>ยอดค้างชำระ: {baht(this.state.total_payment)} บาท</b>
              </label>
            </div>

            <div className="w-100 d-flex mt-3">
              {/* เงินสด status 1 */}
              <div className="w-50 px-1">
                <button
                  className={this.state.calculate_type === "เงินสด" ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative" : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"}
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินสด",
                      price: this.state.total_payment.toString(),
                      payment_type: 1,
                      status_order_main: 2,
                    });

                    this.payment_clear();
                  }}
                >
                  <label className={this.state.calculate_type === "เงินสด" ? "icon mb-0 pointer text-header" : "icon mb-0 pointer"}>{"\uf0d6"}</label>
                  <br />
                  <p className={this.state.calculate_type === "เงินสด" ? "text-header" : "text-detail-name"}>เงินสด</p>
                  {this.state.calculate_type === "เงินสด" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>

              {/* เงินโอน status 3 */}
              <div className="w-50 px-1">
                <button
                  className={this.state.calculate_type === "เงินโอน" ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative" : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"}
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินโอน",
                      price: this.state.total_payment.toString(),
                      payment_type: 3,
                      status_order_main: 2,
                    });

                    this.payment_clear();
                  }}
                >
                  <label className={this.state.calculate_type === "เงินโอน" ? "icon mb-0 pointer text-header" : "icon mb-0 pointer"}>{"\uf0ec"}</label>
                  <br />
                  <p className={this.state.calculate_type === "เงินโอน" ? "text-header" : "text-detail-name"}>เงินโอน</p>
                  {this.state.calculate_type === "เงินโอน" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>
            </div>

            {/* เงินสด */}
            {this.state.calculate_type === "เงินสด" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-between align-items-center py-4 ">
                  <button
                    className="btn btn-link text-secondary "
                    onClick={() => {
                      this.setState({
                        price: "00",
                      });
                    }}
                  >
                    <label
                      className="icon bg-dark text-14 text-white"
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                      }}
                    >
                      {"\uf00d"}
                    </label>
                  </button>
                  <h1>
                    <b>{this.state.price}</b>
                  </h1>
                </div>
                <div className="row mx-0 mb-3">
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("7");
                      }}
                    >
                      7
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("8");
                      }}
                    >
                      8
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("9");
                      }}
                    >
                      9
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-primary"
                      onClick={() => {
                        let price = this.state.price;
                        if (price.includes(".")) {
                          let number = Number(price.split(".")[0]);
                          number += 1000;
                          price = number + "." + price.split(".")[1];
                        } else {
                          let number = Number(price);
                          number += 1000;
                          price = number;
                        }
                        this.setState({ price: price.toString() });
                      }}
                    >
                      1,000
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("4");
                      }}
                    >
                      4
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("5");
                      }}
                    >
                      5
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("6");
                      }}
                    >
                      6
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-primary"
                      onClick={() => {
                        let price = this.state.price;
                        if (price.includes(".")) {
                          let number = Number(price.split(".")[0]);
                          number += 500;
                          price = number + "." + price.split(".")[1];
                        } else {
                          let number = Number(price);
                          number += 500;
                          price = number;
                        }
                        this.setState({ price: price.toString() });
                      }}
                    >
                      500
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("1");
                      }}
                    >
                      1
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("2");
                      }}
                    >
                      2
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("3");
                      }}
                    >
                      3
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-primary"
                      onClick={() => {
                        let price = this.state.price;
                        if (price.includes(".")) {
                          let number = Number(price.split(".")[0]);
                          number += 100;
                          price = number + "." + price.split(".")[1];
                        } else {
                          let number = Number(price);
                          number += 100;
                          price = number;
                        }
                        this.setState({ price: price.toString() });
                      }}
                    >
                      100
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        if (!this.state.price.includes(".")) {
                          if (Number(this.state.price) === 0) {
                            this.setState({ price: "0." });
                          } else {
                            this.setState({ price: this.state.price + "." });
                          }
                        }
                      }}
                    >
                      .
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        this.edit_price("0");
                      }}
                    >
                      0
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-secondary"
                      onClick={() => {
                        let price = this.state.price;
                        price = price.slice(0, -1);
                        if (price === "" || price === "0") {
                          price = "00";
                        }
                        this.setState({ price: price });
                      }}
                    >
                      <span className="icon-brand">{"\uf55a"}</span>
                    </button>
                  </div>
                  <div className="col-3 p-01">
                    <button
                      className="btn-cal-primary"
                      onClick={() => {
                        this.setState({
                          price: this.state.total_payment.toString(),
                        });
                      }}
                    >
                      เต็ม
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* เงินโอน */}
            {this.state.calculate_type === "เงินโอน" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-between align-items-center py-4">
                  <button
                    className="btn btn-link text-secondary "
                    onClick={() => {
                      this.setState({
                        price: "00",
                      });
                    }}
                  >
                    <label
                      className="icon bg-dark text-14 text-white"
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                      }}
                    >
                      {"\uf00d"}
                    </label>
                  </button>
                  <h1>
                    <b>{this.state.price.toString()}</b>
                  </h1>
                </div>
                <div className="w-100 text-center mb-b">
                  <label
                    className="text-primary pointer"
                    onClick={() => {
                      this.setState({ input: !this.state.input });
                    }}
                  >
                    กรอกตัวเลขด้วยตัวเอง
                    <span className="icon-brand ml-2">{this.state.input ? "\uf077" : "\uf078"}</span>
                  </label>
                </div>
                {this.state.input && (
                  <div className="w-100">
                    <div className="row mx-0 mb-3">
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("7");
                          }}
                        >
                          7
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("8");
                          }}
                        >
                          8
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("9");
                          }}
                        >
                          9
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-primary"
                          onClick={() => {
                            let price = this.state.price;
                            if (price.includes(".")) {
                              let number = Number(price.split(".")[0]);
                              number += 1000;
                              price = number + "." + price.split(".")[1];
                            } else {
                              let number = Number(price);
                              number += 1000;
                              price = number;
                            }
                            this.setState({ price: price.toString() });
                          }}
                        >
                          1,000
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("4");
                          }}
                        >
                          4
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("5");
                          }}
                        >
                          5
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("6");
                          }}
                        >
                          6
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-primary"
                          onClick={() => {
                            let price = this.state.price;
                            if (price.includes(".")) {
                              let number = Number(price.split(".")[0]);
                              number += 500;
                              price = number + "." + price.split(".")[1];
                            } else {
                              let number = Number(price);
                              number += 500;
                              price = number;
                            }
                            this.setState({ price: price.toString() });
                          }}
                        >
                          500
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("1");
                          }}
                        >
                          1
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("2");
                          }}
                        >
                          2
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("3");
                          }}
                        >
                          3
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-primary"
                          onClick={() => {
                            let price = this.state.price;
                            if (price.includes(".")) {
                              let number = Number(price.split(".")[0]);
                              number += 100;
                              price = number + "." + price.split(".")[1];
                            } else {
                              let number = Number(price);
                              number += 100;
                              price = number;
                            }
                            this.setState({ price: price.toString() });
                          }}
                        >
                          100
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            if (!this.state.price.includes(".")) {
                              if (Number(this.state.price) === 0) {
                                this.setState({ price: "0." });
                              } else {
                                this.setState({
                                  price: this.state.price + ".",
                                });
                              }
                            }
                          }}
                        >
                          .
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            this.edit_price("0");
                          }}
                        >
                          0
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-secondary"
                          onClick={() => {
                            let price = this.state.price;
                            price = price.slice(0, -1);
                            if (price === "" || price === "0") {
                              price = "00";
                            }
                            this.setState({ price: price });
                          }}
                        >
                          <span className="icon-brand">{"\uf55a"}</span>
                        </button>
                      </div>
                      <div className="col-3 p-01">
                        <button
                          className="btn-cal-primary"
                          onClick={() => {
                            this.setState({
                              price: this.state.total_price_final.toString(),
                            });
                          }}
                        >
                          เต็ม
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-100 ">
                  <Select
                    isSearchable={false}
                    className="px-0 mb-3 "
                    options={
                      this.state.bank_list &&
                      this.state.bank_list.data.map((item, index) => ({
                        value: item.bank_id,
                        label: item.bank_name,
                        logo: item.logo,
                        bank_number: item.bank_number,
                      }))
                    }
                    components={{ Option: IconOption }}
                    placeholder="เลือกธนาคาร"
                    styles={customStyles}
                    onChange={(e) => {
                      let data = this.state.payment_detail;
                      data.bank_name = e.label;
                      data.bank_number = e.bank_number;
                      this.setState({
                        payment_detail: data,
                      });
                    }}
                  />
                </div>

                <div className="row mt-3">
                  <div className="col-12 mb-3">
                    <input
                      maxLength={30}
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="ชื่อ-นามสกุลผู้โอน"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.full_name_transfer = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.full_name_transfer}
                    />
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-12 mb-3 pl-0 pr-1 ">
                    <input
                      className="form-control bg-secondary-light border-0"
                      type="datetime-local"
                      placeholder="วัน เดือน ปี"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.datetime_transfer = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      min={this.state.check_date}
                      value={this.state.payment_detail.datetime_transfer}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      maxLength={14}
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="หมายเลขอ้างอิง"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.reference_number = e.target.value.replace(/\D/g, "");
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.reference_number}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      maxLength={50}
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.remark_payment_s3 = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.remark_payment_s3}
                    />
                  </div>
                </div>
                <div className="w-100 my-1">
                  <label>คำแนะนำ: โปรดเก็บหลักฐานการชำระเงินทุกครั้ง หลังกรอกข้อมูลเสร็จสิ้นแล้ว</label>
                </div>
              </div>
            )}

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_payment: false });
                    this.payment_clear();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let payment_detail = this.state.payment_detail;

                    if (this.state.calculate_type === "เงินสด") {
                      payment_detail.total_pay = Number(this.state.price);
                      this.setState({ status_order_main: 2 });
                    } else if (this.state.calculate_type === "เงินโอน") {
                      if (payment_detail.bank_name === "") {
                        alert("info", "แจ้งเตือน", "กรุณาเลือกธนาคาร");
                        return;
                      } else if (payment_detail.full_name_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุลผู้โอน");
                        return;
                      } else if (payment_detail.datetime_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกวัน-เวลาที่โอน");
                        return;
                      }
                      payment_detail.total_pay = Number(this.state.price);
                      this.setState({ status_order_main: 2 });
                    }

                    this.setState({ payment_detail: payment_detail });

                    setTimeout(() => {
                      if (this.state.type === 4) {
                        this.Payment4();
                      } else {
                        this.Payment();
                      }
                    }, 10);
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียด modal */}
        <ModalDetails
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />
        {/* รายละเอียดการผ่อนชำระ */}
        <ModalInstallment
          i_all_total_pay={this.state.i_all_total_pay}
          value={this.state.data_detail}
          installment_detail={this.state.installment_detail}
          show={this.state.modal_installment}
          onHide={() => {
            this.setState({ modal_installment: false });
          }}
        />

        {/* ปริ้น modal */}
        <ModalPrint
          text_modal={this.state.text_modal}
          value={this.state.message_detail}
          general={this.state.general}
          show={this.state.modal_print}
          onHide={() => {
            this.setState({ modal_print: false });
            window.location.href = "/accrued";
          }}
        />
      </div>
    );
  }
}
