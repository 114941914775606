import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { POST, tokens, status_stock, format_date, alert, number } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Select, { components } from "react-select";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class StockLocationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_delete: false,

      data: null,

      search: "",
      start_date: "",
      end_date: "",
      stock_location_from_id: "",
      stock_tns_status: "",
      category_name: "",
      payment_type: "",

      product_type: "",
      type_product_id: "",
      warehouse: "",
      warehouse_id: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();

    await this.GetProductType();
    await this.GetWareHouse();
  }

  GetProductType = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", body);
    if (result && result.status) {
      this.setState({ product_type: result.data });
    }
  };
  GetWareHouse = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);
    if (result && result.status) {
      this.setState({ warehouse: result.data });
    }
  };

  GetAll = async () => {
    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }

    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      type_product_id: this.state.type_product_id,
      warehouse_id: this.state.warehouse_id,
    };
    let result = await POST(tokens, "v1/stock-mgt/transaction/filter-search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  Delete = async (id) => {
    this.setState({ loading: true });
    // let result = await DELETE(tokens, "api/age/delete/" + id, null);
    // if (result && result.status) {
    // this.ClearForm();
    // success();
    // this.GetAll();
    // }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      type_product_id: "",

      warehouse_id: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="เส้นทางสต๊อก" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>เส้นทางสต๊อก</b>
              </h3>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-sm-2 col-md-4 col-lg-4 col-xl-2 d-flex  ">
                    <input
                      maxLength={30}
                      className="form-control-search  mb-1 mt-1"
                      type="search"
                      placeholder="พิมพ์คำค้นหา"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ page: 1, search: e.target.value, loading: false });
                        if (e.target.value === "") {
                          this.setState({ search: "" });
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        } else {
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        }
                      }}
                    />
                  </div>

                  <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({ page: 1, start_date: e.target.value });

                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.Input.clearValue();
                            this.setState({
                              page: 1,
                              search: "",
                              data: null,
                              type_product_id: "",
                              warehouse_id: "",
                              start_date: "",
                              end_date: "",
                            });

                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4 col-lg-6 col-xl-4 d-flex">
                    <Select
                      ref={(click) => (this.Input = click)}
                      className="col-6 mr-1 mb-1 mt-1 px-0"
                      options={
                        this.state.product_type &&
                        this.state.product_type.data.map((item) => ({
                          value: item.type_product_id,
                          label: item.type_product_name,
                        }))
                      }
                      components={{ Option: TypeOption }}
                      styles={customStylesTypeOption}
                      placeholder="ค้นหาชื่อประเภท"
                      isClearable
                      defaultValue={this.state.type_product_id}
                      onChange={(e) => {
                        if (e === null) {
                          this.setState({ type_product_id: "", page: 1 });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        } else {
                          this.setState({ type_product_id: e.value, page: 1 });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }
                      }}
                    />

                    <select
                      className="form-control-select ml-1 mb-1 mt-1 pointer"
                      onChange={(e) => {
                        this.setState({ search: "", page: 1, warehouse_id: e.target.value });
                        setTimeout(() => {
                          this.GetAll();
                        }, 20);
                      }}
                      value={this.state.warehouse_id}
                    >
                      <option value="" selected>
                        แสดงทุกโกดัง
                      </option>
                      {this.state.warehouse &&
                        this.state.warehouse.data.map((item, index) => (
                          <option value={item.warehouse_id} key={index}>
                            {item.warehouse_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-hover">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>รหัสสินค้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>ชื่อสินค้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>ประเภทผ้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>จากสถานที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>ไปยังสถานที่</b>
                          </th>
                          <th className="text-center">
                            <b>จำนวน(ไม้)</b>
                          </th>
                          <th className="text-right">
                            <b>จำนวน(หลา)</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>สถานะ</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={10} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left ">{format_date(item.datetime_create)}</td>
                              <td className="text-left ">{item.stock_tns_detail.product_code}</td>
                              <td className="text-left">{item.stock_tns_detail.product_name}</td>
                              <td className="text-left">{item.stock_tns_detail.type_product}</td>
                              <td className="text-left">{item.stock_tns_detail.warehouse_from_name ? item.stock_tns_detail.warehouse_from_name : item.stock_tns_detail.warehouse_name}</td>
                              <td className="text-left">{item.stock_tns_detail.warehouse_to_name ? item.stock_tns_detail.warehouse_to_name : "-"}</td>
                              <td className="text-center">{number(item.qty)}</td>
                              <td className="text-right">{item.yard_qty.toFixed(2)}</td>
                              <td className="text-center">{status_stock(item.status)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0 my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
