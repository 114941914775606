import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { tokens, baht, total, alert, GET, format_date, report_refund } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class ReportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,
      data: null,
      search: "",
      modal_add: false,

      type: "Cash",

      start_date: "",
      end_date: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/refund/report/0", null);
    if (result && result.status) {
      this.setState({ data: result.data.data });
    }
    this.setState({ loading: false });
  };
  ClearForm() {
    this.setState({
      page: 1,
      type: "Cash",
      start_date: "",
      end_date: "",
      data: null,
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="สรุปรายงาน" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายงานคืนสินค้า</b>
              </h3>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    if (this.state.data.length === 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกข้อมูลที่ต้องการออกรายงาน");
                      return;
                    }
                    report_refund(this.state.data.filter((e) => this.state.search === "" || JSON.stringify(e).includes(this.state.search)).filter((item) => (this.state.start_date !== "" && this.state.end_date !== "" ? new Date(this.state.start_date).getTime() <= new Date(item.datetime_create).getTime() && new Date(item.datetime_create).getTime() <= new Date(this.state.end_date).getTime() : true)));
                  }}
                >
                  <span className="icon-brand text-18 ">{"\uf6dd"}</span> ออกรายงาน
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-xl-12 d-flex">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex mx-0 align-items-center">
                        <input
                          maxLength={30}
                          className="form-control-search mr-2"
                          type="search"
                          placeholder="พิมพ์คำค้นหา"
                          value={this.state.search}
                          onChange={(e) => {
                            this.setState({ page: 1, search: e.target.value, loading: false });
                          }}
                        />
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({ page: 1, start_date: e.target.value });
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                            }
                          }}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-primary ml-2 mb-1 "
                          onClick={() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }}
                        >
                          {" "}
                          ค้นหา
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.setState({
                              page: 1,
                              search: "",
                              start_date: "",
                              end_date: "",
                              loading: false,
                            });
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="table-responsive rounded-16">
                    <table className="table table-borderless table-sm">
                      {this.state.data &&
                        this.state.data
                          .filter((e) => this.state.search === "" || JSON.stringify(e).includes(this.state.search))
                          .filter((item) => (this.state.start_date !== "" && this.state.end_date !== "" ? new Date(this.state.start_date).getTime() <= new Date(item.datetime_create).getTime() && new Date(item.datetime_create).getTime() <= new Date(this.state.end_date).getTime() : true))

                          .map((item, index) => (
                            <tbody>
                              <tr key={index} className="bg-white">
                                <td className="text-left pt-4">
                                  <b>วันที่สร้างเอกสาร :</b> {format_date(item.datetime_create)}
                                </td>
                                <td className="text-left pt-4">
                                  <b>เลขที่เอกสาร :</b> {item.rc_number}
                                </td>
                                <td className="text-left pt-4">
                                  <b>ชื่อลูกค้า :</b> {item.member_name}
                                </td>
                                <td className="text-left pt-4">
                                  <b>สถานะ :</b> {item.status_return_store === 1 ? "คืนเข้าโกดัง" : item.status_return_store === 2 ? "ไม่คืนเข้าโกดัง" : ""}
                                </td>
                                <td className="text-left pt-4"></td>
                                <td className="text-left pt-4"></td>
                                <td></td>
                              </tr>
                              <tr className="bg-primary text-white">
                                <td className="text-center">เลขที่ใบขายสินค้า</td>
                                <td className="text-center">ชื่อสินค้า</td>
                                <td className="text-center">จำนวนหลาที่คืน</td>
                                <td className="text-center">จำนวนไม้ที่คืน</td>
                                <td className="text-center">รวมหลา</td>
                                <td className="text-center">ราคาต่อหน่วย</td>
                                <td className="text-center">ยอดรวมสุทธิ</td>
                              </tr>
                              {item.refund_main.map((ex, ex_index) => (
                                <>
                                  {" "}
                                  <tr className="bg-secondary-light border-bottom">
                                    <td className="text-center">{ex.invoice_number}</td>
                                    <td className="text-left"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center">{baht(ex.net_total)}</td>
                                  </tr>
                                  {ex.refund_list.map((refund, refund_index) => (
                                    <tr className="border-bottom">
                                      <td className="text-center"></td>
                                      <td className="text-left">{refund.product_name}</td>
                                      <td className="text-center">{refund.yard_qty}</td>
                                      <td className="text-center">{refund.wood_qty}</td>
                                      <td className="text-center">{refund.total_price}</td>
                                      <td className="text-center">{baht(refund.price_by_item)}</td>
                                      <td className="text-center">{baht(refund.price_by_item * refund.yard_qty)}</td>
                                    </tr>
                                  ))}
                                </>
                              ))}
                              <tr>
                                <td className="text-center">
                                  <b>หมายเหตุ : </b>
                                  {item.remark}
                                </td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center">
                                  <b>รวมจำนวนหลา : </b>
                                  {item.yard_total}
                                </td>
                                <td className="text-center">
                                  <b>รวมจำนวนไม้ : </b>
                                  {item.wood_total}
                                </td>
                                <td className="text-center"></td>
                                <td className="text-center">
                                  <b>ราคารวมสุทธิ : </b>
                                  {baht(item.net_total)}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      <tfoot>
                        <tr>
                          <td className="text-center">
                            <h4>
                              <b>รวมทั้งสิ้น</b>
                            </h4>
                          </td>
                          <td className="text-center"></td>
                          <td className="text-center"></td>
                          <td className="text-center"></td>
                          <td className="text-center"></td>
                          <td className="text-center"></td>
                          <td className="text-center">
                            <h4>
                              <b>
                                {this.state.data
                                  ? baht(
                                      total(
                                        this.state.data.filter((e) => this.state.search === "" || JSON.stringify(e).includes(this.state.search)).filter((item) => (this.state.start_date !== "" && this.state.end_date !== "" ? new Date(this.state.start_date).getTime() <= new Date(item.datetime_create).getTime() && new Date(item.datetime_create).getTime() <= new Date(this.state.end_date).getTime() : true)),

                                        "net_total"
                                      )
                                    )
                                  : "0.00"}
                              </b>
                            </h4>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
