/* eslint-disable use-isnan */
/* eslint-disable no-self-compare */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { alert, alert_url, GET, POST, POST_CACHE, tokens, baht } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
import WARNING from "../../assets/images/warning.png";
import { ExcelRenderer } from "react-excel-renderer";

export default class ProductImportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_add: false,
      modal_alert: false,

      page: 1,

      data: [],
      type_product: null,
      warehouse: null,

      search: "",

      file_name: "",
      user_id: "",
      product_id: "",
      status_url: "",
      type_product_id: "",
      warehouse_id: "",
      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",

      alert: 0,
      product_color: "",
    };
  }
  async componentDidMount() {
    // await this.GetAll()
    this.GetTypeProduct();
    this.GetWareHouse();
  }

  //ใช้เช็ค data  ที่มีอยู่ในระบบ
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/filter-search/page/0?data_search" + this.state.search + "&status=" + this.state.status_url + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + this.state.warehouse_id + "&user_member_id=" + this.state.user_id + "&product_id=" + this.state.product_id + "&sort_name=" + this.state.sort_name + "&sort_yard=" + this.state.sort_yard + "&sort_color=" + this.state.sort_color + "&product_design=" + this.state.design_value.replaceAll("#", "%23") + "&color=" + this.state.product_color, null);

    if (result && result.status) {
      // if (result.data.data.data_returns.length > 0) {
      //     window.location.href = "/product/create"
      this.setState({ loading: false });
      // }
    }
    this.setState({ loading: false });
  };
  GetTypeProduct = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", body);

    if (result && result.status) {
      this.setState({ type_product: result.data.data });
    }
    this.setState({ loading: false });
  };
  GetWareHouse = async () => {
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);

    if (result && result.status) {
      this.setState({ warehouse: result.data.data });
    }
    this.setState({ loading: false });
  };
  Upload_File = (e) => {
    try {
      let fileObj = e.target.files[0];
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          alert("warning", "เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง");
          return;
        } else {
          let data = resp.rows;
          let array_data = [];
          let index = 0;

          for (let i = 0; i < data.length; i++) {
            if (data[i].length > 0 && data[i] !== data[0]) {
              let product_list = {
                index: index,
                type_product_name: data[i][2],
                warehouse_name: data[i][11],
                product_name: data[i][0],
                safety_stock_noti: Number.isFinite(data[i][7]) ? Number(data[i][7]) : "",
                store_price: Number.isFinite(data[i][8]) ? Number(data[i][8]) : "",
                wholesale_price: Number.isFinite(data[i][9]) ? Number(data[i][9]) : "",
                cost_price: Number.isFinite(data[i][10]) ? Number(data[i][10]) : "",
                product_detail: data[i][1],
                product_color: data[i][3],
                product_design: data[i][4],
                qty: Number.isFinite(data[i][6]) ? Number(data[i][6].toFixed(0)) : "",
                yard_qty: Number.isFinite(data[i][5]) ? Number(data[i][5]) : "",
                check: true,
              };
              array_data.push(product_list);
              index += 1;
            }
          }
          this.setState({ data: array_data, file_name: fileObj.name });

          document.getElementById("file").value = "";
        }
      });
    } catch (error) {
      alert("warning", "เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง");
      document.getElementById("file").value = "";
    }
  };

  CheckAddProduct = async () => {
    let data = this.state.data;
    let array_type_product = this.state.type_product;
    let array_warehouse = this.state.warehouse;

    let type_array = array_type_product.map((item) => {
      return item.type_product_name;
    });
    let warehouse_array = array_warehouse.map((item) => {
      return item.warehouse_name;
    });

    let count = data.filter((item, index) => item.type_product_name === undefined || item.warehouse_name === undefined || item.product_name === undefined || item.safety_stock_noti === undefined || item.store_price === undefined || item.wholesale_price === undefined || item.cost_price === undefined || item.qty === undefined || item.yard_qty === undefined || item.cost_price === "" || item.wholesale_price === "" || item.store_price === "" || item.store_price === 0 || item.qty === "" || item.yard_qty === "" || item.safety_stock_noti === "" || !JSON.stringify(type_array).includes(item.type_product_name) || !JSON.stringify(warehouse_array).includes(item.warehouse_name));

    for (let p of count) {
      data[p.index].check = false;
    }

    this.setState({ data: data });

    if (count.length !== 0) {
      alert("warning", "ไม่สามารถอัปโหลดข้อมูลได้", "ข้อมูลบางรายการไม่ถูกต้อง\nกรุณาอัปโหลดไฟล์ใหม่อีกครั้ง");
    } else {
      this.AddProduct();
    }
  };

  AddProduct = async () => {
    this.setState({ loading: true });
    let data = this.state.data;
    let array = data.map((e) => {
      return {
        type_product_name: e.type_product_name ? e.type_product_name : "",
        product_name: e.product_name ? e.product_name : "",
        safety_stock_noti: Number(e.safety_stock_noti) ? Number(e.safety_stock_noti) : 0,
        store_price: parseFloat(e.store_price) ? parseFloat(e.store_price.toFixed(2)) : 0.0,
        wholesale_price: parseFloat(e.wholesale_price) ? parseFloat(e.wholesale_price.toFixed(2)) : 0.0,
        cost_price: parseFloat(e.cost_price) ? parseFloat(e.cost_price.toFixed(2)) : 0.0,
        product_detail: e.product_detail ? e.product_detail : "",
        product_color: e.product_color ? e.product_color.trim() : "",
        product_design: e.product_design ? e.product_design : "",
        warehouse_name: e.warehouse_name ? e.warehouse_name : "",
        qty: Number(e.qty) ? Number(e.qty) : 0,
        yard_qty: e.yard_qty ? Number(e.yard_qty.toFixed(2)) : 0.0,
      };
    });
    let body = {
      product_list: array,
    };

    let result = await POST_CACHE(tokens, "v1/product-mgt/all/create/import", body);

    if (result && result.status) {
      alert_url("success", "เพิ่มสินค้าเรียบร้อยแล้ว", "สินค้าถูกเพิ่มในระบบแล้ว", "/product");

      this.setState({ loading: false, modal_add: false });

      this.ClearForm();
    }
    this.setState({ loading: false });
  };

  ClearForm() {
    this.setState({
      data: [],
      file_name: "",
      user_id: "",
      product_id: "",
      status_url: "",
      type_product_id: "",
      warehouse_id: "",
      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="นำเข้าไฟล์สินค้า" />
            <div style={{ height: 60 }}></div>

            <div className="mx-5 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row  d-flex align-items-center justify-content-between px-3">
                <h3>
                  <b>เพิ่มสินค้าครั้งแรก</b>
                </h3>
              </div>

              {/* detail */}
              <div className="w-100 mt-3 text-center ">
                <div className="card rounded p-4">
                  <div className="border-secondary border rounded-16 p-4 text-line-dashed">
                    <span className="icon-brand text-52 text-primary ">{"\uf56f"}</span>
                    <p className="mb-3">Drag and Drop your files here.(.CSV)</p>
                    <small>หรือ</small>
                    <div className="w-100">
                      <div
                        className="btn btn-primary mt-2"
                        onClick={() => {
                          this.refs.fileUploader.click();
                        }}
                      >
                        <label className="mt-2 pointer">นำเข้าไฟล์</label>
                      </div>
                      <input
                        type="file"
                        id="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        ref="fileUploader"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          this.Upload_File(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row d-flex mx-1 justify-content-center mt-3">
                    {this.state.file_name && (
                      <div className="d-flex bg-secondary-light p-2 w-33 rounded-16 border mr-1 justify-content-between align-content-center" style={{ minWidth: 200 }}>
                        <div className="px-2" style={{ minWidth: 150 }}>
                          <span className="icon-brand text-18 text-primary ">{"\uf6dd"}</span> {this.state.file_name}
                        </div>
                        <div>
                          <label
                            className="icon bg-danger text-14 text-white ml-1"
                            style={{
                              width: 20,
                              height: 20,
                              borderRadius: 12,
                            }}
                            onClick={() => {
                              this.ClearForm();
                            }}
                          >
                            {"\uf00d"}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* table */}
              <div className="w-100 mt-3">
                <div className="card">
                  <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                    <div className="col-12 col-sm-6 col-xl-3 d-flex">
                      <div className="w-100">
                        <h5>
                          {" "}
                          <b>สินค้าที่ถูกนำเข้า</b>
                        </h5>
                        <label className="text-dark text-16">จำนวน {this.state.data.length} รายการ</label>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive rounded-16">
                      <table className="table table-striped table-borderless table-hover">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 120 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>รายละเอียด</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 80 }}>
                              <b>หมวดหมู่</b>
                            </th>
                            <th className="text-center">
                              <b>สี</b>
                            </th>
                            <th className="text-center">
                              <b>ดีไซน์</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>จำนวนหลา</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 80 }}>
                              <b>จำนวนไม้</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>แจ้งเตือนสินค้า</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ราคาหน้าร้าน</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ราคาขายส่ง</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ราคาต้นทุน</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 80 }}>
                              <b>โกดัง</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 80 }}>
                              <b>นำออก</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data && this.state.data.length === 0 && (
                            <tr>
                              <td colSpan={13} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_name}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_detail}</td>
                                <td className={item.check ? "text-center" : "text-center text-danger"}>{item.type_product_name}</td>
                                <td className={item.check ? "text-center" : "text-center text-danger"}>{item.product_color}</td>
                                <td className={item.check ? "text-center" : "text-center text-danger"}>{item.product_design}</td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>{baht(item.yard_qty)}</td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>{item.qty}</td>
                                <td className={item.check ? "text-center" : "text-center text-danger"}>{baht(item.safety_stock_noti)}</td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>{baht(item.store_price)}</td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>{baht(item.wholesale_price)}</td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>{baht(item.cost_price)}</td>
                                <td className={item.check ? "text-center" : "text-center text-danger"}>{item.warehouse_name}</td>
                                <td className={item.check ? "text-center" : "text-center text-danger"}>
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      let data = this.state.data;
                                      data.splice(index, 1);
                                      if (data.length === 0) {
                                        this.setState({ file_name: "" });
                                        document.getElementById("file").value = "";
                                      }
                                      this.setState({ data: data });
                                    }}
                                  >
                                    <label
                                      className="icon bg-danger text-14 text-white pointer"
                                      style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: 12,
                                      }}
                                    >
                                      {"\uf00d"}
                                    </label>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="position-fixed bottom-0 bg-white w-100 p-3" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="d-flex">
              <div className="mr-1 w-50">
                <button
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/product";
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="ml-1 w-50">
                <button
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {
                    if (this.state.data.length === 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาอัปโหลดไฟล์");
                      return;
                    }
                    if (this.state.alert === 0) {
                      this.setState({ alert: 1, modal_alert: true });
                    } else {
                      this.CheckAddProduct();
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ยืนยันการยกเลิกบิลสายส่ง */}
        <Modal
          show={this.state.modal_alert}
          size="m"
          onHide={() => {
            this.setState({ modal_alert: false });
          }}
        >
          <Modal.Body>
            <div className="card bg-yellow-warning w-100   ">
              <div className=" row d-flex justify-content-center align-content-center p-2">
                <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
