/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./global.js";
import { Link } from "react-router";
import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import { cookies, tokens, logout, role, GET } from "./CustomComponent.js";

import "../assets/css/bootstrap.css";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      full_name: "",
      sidebar: false,
    };
  }

  componentDidMount() {
    let profile = JSON.parse(localStorage.getItem("profile"));
    if (!profile) {
      logout();
      return;
    } else if (!tokens) {
      localStorage.clear();

      cookies.remove("token_AHJ_test");
      cookies.remove("role");
      cookies.remove("profile");
      cookies.remove("permission_page");
      cookies.remove("status_bill");
      cookies.remove("bill");
      cookies.remove("user_code");

      window.location.href = "/";
    } else {
      this.setState({
        email: profile.email,
        full_name: profile.full_name,
      });

      if (localStorage.getItem("user_code") === "ME000000001" || localStorage.getItem("user_code") === undefined) {
        localStorage.setItem("status_bill", "ขายสินค้า");
      }
    }
    this.Log();
  }
  Log = async () => {
    await GET(tokens, "v1/report/store-log", null);
  };

  render() {
    return (
      <div>
        <div className="navbar-component row mx-0 px-0 pb-0 position-fixed bodyw-100">
          <div className="col-12 d-flex row justify-content-between align-items-center " style={{ backgroundColor: "#FFFFFF", paddingBottom: 5, paddingTop: 5 }}>
            <div className="align-items-center justify-content-start d-flex row text-14 mx-0">
              {this.props.sidebar !== "false" && (
                <label
                  className="d-block d-lg-none d-xl-none mr-3 mt-2 pointer"
                  onClick={() => {
                    this.setState({ sidebar: !this.state.sidebar });
                  }}
                >
                  <span className="icon-brand text-dark">{"\uf0c9"}</span>
                </label>
              )}
              <h3 className="text-header d-lg-none d-xl-none">
                <b>AIMHENGJAN</b>
              </h3>
              <small className="text-secondary ml-3 d-lg-none d-xl-none"> {global.version}</small>

              <div className="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">
                <div className="border-right mx-3 d-lg-none d-xl-none" style={{ height: 24 }}></div>
                <div className="text-secondary">
                  <span>{this.props.page1}</span>
                </div>
                {this.props.page2 && <span className={this.props.page3 ? "text-secondary ml-2 mr-2" : "text-dark ml-2 mr-2"}> {"> "} </span>}
                <span className={this.props.page3 ? "text-secondary" : "text-title"}> {this.props.page2} </span>
                {this.props.page3 && <span className="text-dark ml-2 mr-2">{"> "}</span>}
                <span className="text-title">{this.props.page3}</span>
              </div>
            </div>

            <div className="row align-items-center justify-content-center mr-2">
              <div className="row align-items-center justify-content-center d-none d-sm-none d-md-none d-md-flex d-lg-flex d-xl-flex">
                <div className="border-right " style={{ height: 24 }}></div>
                <span className="d-flex align-items-center text-right">
                  <div className="ml-3 mr-3">
                    <b className="my-0 text-right">{this.state.full_name}</b>
                    <br />
                    <small className="text-right">{this.state.email}</small>
                  </div>
                </span>
                <div className="rounded-circle bg-cash-1 img-thumbnail  center" style={{ height: 44, width: 44 }}>
                  <b className="text-header-logo text-18">{this.state.full_name && this.state.full_name.slice(0, 1)}</b>
                </div>
              </div>

              <div className="dropdowns ml-4">
                <span className="dropbtns pointer">
                  <span className="icon-brand text-primary text-14">{"\uf078"}</span>
                </span>
                <div className="dropdowns-content">
                  <div className="row align-items-center justify-content-center mt-1 mb-1 d-md-none d-lg-none d-xl-none">
                    <div className="rounded-circle bg-cash-1 img-thumbnail center" style={{ height: 33, width: 33 }}>
                      <b className="text-header-logo text-16">{this.state.full_name && this.state.full_name.slice(0, 1)}</b>
                    </div>
                    <span className="d-flex align-items-center text-left">
                      <div className="ml-3 mr-3">
                        <b className="my-0 ">{this.state.full_name}</b>
                        <br />
                        <small>{this.state.email}</small>
                      </div>
                    </span>
                  </div>

                  {role && role.pos_system[0] === 1 && role && role.pos_system[1] === 1 && role && role.pos_system[2] === 1 && role && role.pos_system[3] === 1 && role && role.pos_system[4] === 1 && role && role.pos_system[5] === 1 && (
                    <a
                      onClick={() => {
                        localStorage.removeItem("POS");
                        localStorage.removeItem("bill");
                        localStorage.removeItem("user_code");
                        setTimeout(() => {
                          window.location.href = "/main";
                        }, 20);
                      }}
                      className="pointer"
                    >
                      <label className="icon">{"\uf079"}</label> สลับไปยัง POS
                    </a>
                  )}

                  <a
                    onClick={async () => {
                      await logout();
                    }}
                    className="pointer"
                  >
                    <label className="icon">{"\uf30d"}</label> ออกจากระบบ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProSidebar
          className="d-block d-lg-none d-xl-none full-screen"
          breakPoint="lg"
          toggled={this.state.sidebar}
          onFocus={() => {
            this.setState({ sidebar: this.state.sidebar });
          }}
          transitionduration={50}
        >
          <Menu iconShape="square" style={{ paddingBottom: 60, paddingTop: 62 }}>
            {/* แดชบอร์ด */}
            {role && role.dashboard && role.dashboard[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf643"}</label>} active={window.location.pathname === "/dashboard"}>
                <Link to="/dashboard" className="pro-button">
                  แดชบอร์ด
                </Link>
              </MenuItem>
            )}

            {/* ข้อมูลผู้ใช้ */}
            {role && (role.user_admin || role.role) && (role.user_admin[0] === 1 || role.role[0] === 1) && (
              <SubMenu icon={<label className="icon">{"\uf0c0"}</label>} title="ข้อมูลผู้ใช้" defaultOpen={window.location.pathname === "/user" || window.location.pathname === "/role" || window.location.pathname === "/role/create"}>
                {role && role.user_admin && role.user_admin[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/user"}>
                    <Link to="/user" className="pro-button">
                      รายชื่อผู้ใช้ทั้งหมด
                    </Link>
                  </MenuItem>
                )}
                {role && role.role && role.role[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/role" || window.location.pathname === "/role/create"}>
                    <Link to="/role" className="pro-button">
                      บทบาทผู้ใช้
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {/* รายชื่อบริษัทจำหน่าย */}
            {role && role.company_supplier && role.company_supplier[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf03a"}</label>} active={window.location.pathname === "/company"}>
                <Link to="/company" className="pro-button">
                  รายชื่อบริษัทจำหน่าย
                </Link>
              </MenuItem>
            )}

            {/* ข้อมูลสมาชิก */}
            {role && (role.user_member || role.user_member_rate) && (role.user_member[0] === 1 || role.user_member_rate[0] === 1) && (
              <SubMenu icon={<label className="icon">{"\uf0c0"}</label>} title="ข้อมูลสมาชิก" defaultOpen={window.location.pathname === "/member" || window.location.pathname === "/rate-member"}>
                {role && role.user_member && role.user_member[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/member"}>
                    <Link to="/member" className="pro-button">
                      รายชื่อสมาชิกทั้งหมด
                    </Link>
                  </MenuItem>
                )}
                {role && role.user_member_rate && role.user_member_rate[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/rate-member"}>
                    <Link to="/rate-member" className="pro-button">
                      เรทสมาชิก
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {/* การขายทั้งหมด */}
            {role && (role.all_sale || role.all_sale_member || role.invoice || role.payment_receive) && (role.all_sale[0] === 1 || role.all_sale_member[0] === 1 || role.invoice[0] === 1 || role.payment_receive[0] === 1) && (
              <SubMenu icon={<label className="icon">{"\uf53d"}</label>} title="รายการขาย" defaultOpen={window.location.pathname === "/sale" || window.location.pathname === "/sale-member" || window.location.pathname === "/invoice" || window.location.pathname === "/sale/payment-receive" || window.location.pathname === "/invoice/create"}>
                {role && role.all_sale && role.all_sale[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/sale"}>
                    <Link to="/sale" className="pro-button">
                      การขายทั้งหมด
                    </Link>
                  </MenuItem>
                )}
                {role && role.all_sale_member && role.all_sale_member[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/sale-member"}>
                    <Link to="/sale-member" className="pro-button">
                      ข้อมูลตามสมาชิก
                    </Link>
                  </MenuItem>
                )}
                {role && role.invoice && role.invoice[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/invoice" || window.location.pathname === "/invoice/create"}>
                    <Link to="/invoice" className="pro-button">
                      ใบแจ้งหนี้
                    </Link>
                  </MenuItem>
                )}
                {role && role.payment_receive && role.payment_receive[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/sale/payment-receive"}>
                    <Link to="/sale/payment-receive" className="pro-button">
                      ระบบรับชำระเงิน
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {/* จัดการสินค้า */}
            {role && (role.update_price || role.all_product || role.goods_receipt || role.type_product) && (role.update_price[0] === 1 || role.all_product[0] === 1 || role.goods_receipt[0] === 1 || role.type_product[0] === 1) && (
              <SubMenu icon={<label className="icon">{"\uf54f"}</label>} title="จัดการสินค้า" defaultOpen={window.location.pathname === "/product" || window.location.pathname === "/category" || window.location.pathname === "/updateprice" || window.location.pathname === "/product/receipt" || window.location.pathname === "/product/import" || window.location.pathname === "/product/create" || window.location.pathname === "/product/edit" || window.location.pathname === "/product/receipt/receipt-create"}>
                {role && role.all_product && role.all_product[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/product" || window.location.pathname === "/product/create" || window.location.pathname === "/product/edit" || window.location.pathname === "/product/import"}>
                    <Link to="/product" className="pro-button">
                      สินค้าทั้งหมด
                    </Link>
                  </MenuItem>
                )}
                {role && role.update_price && role.update_price[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/updateprice"}>
                    <Link to="/updateprice" className="pro-button">
                      ปรับราคาตามประเภทผ้า
                    </Link>
                  </MenuItem>
                )}
                {role && role.goods_receipt && role.goods_receipt[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/product/receipt" || window.location.pathname === "/product/receipt/receipt-create"}>
                    <Link to="/product/receipt" className="pro-button">
                      ใบรับสินค้า
                    </Link>
                  </MenuItem>
                )}
                {role && role.type_product && role.type_product[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/category"}>
                    <Link to="/category" className="pro-button">
                      จัดการประเภทผ้า
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {/* จัดการสต๊อก */}
            {role && (role.move_stock || role.adjust_stock || role.transaction_stock) && (role.move_stock[0] === 1 || role.adjust_stock[0] === 1 || role.transaction_stock[0] === 1) && (
              <SubMenu icon={<label className="icon">{"\uf468"}</label>} title="จัดการสต๊อก" defaultOpen={window.location.pathname === "/stock-move" || window.location.pathname === "/stock-adjustment" || window.location.pathname === "/stock-location" || window.location.pathname === "/stock-adjustment/create" || window.location.pathname === "/stock-move/create" || window.location.pathname === "/stock-adjustment/detail" || window.location.pathname === "/stock-adjustment/examine" || window.location.pathname === "/stock-adjustment/update"}>
                {role && role.move_stock && role.move_stock[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/stock-move" || window.location.pathname === "/stock-move/create"}>
                    <Link to="/stock-move" className="pro-button">
                      รายการย้ายสต๊อก
                    </Link>
                  </MenuItem>
                )}
                {role && role.adjust_stock && role.adjust_stock[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/stock-adjustment" || window.location.pathname === "/stock-adjustment/create" || window.location.pathname === "/stock-adjustment/detail" || window.location.pathname === "/stock-adjustment/examine" || window.location.pathname === "/stock-adjustment/update"}>
                    <Link to="/stock-adjustment" className="pro-button">
                      รายการปรับสต๊อก
                    </Link>
                  </MenuItem>
                )}
                {role && role.transaction_stock && role.transaction_stock[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/stock-location"}>
                    <Link to="/stock-location" className="pro-button">
                      เส้นทางสต๊อก
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {/* จัดการคอมมิสชัน */}
            {role && (role.status_delivery || role.type_commission) && (role.status_delivery[0] === 1 || role.type_commission[0] === 1) && (
              <SubMenu icon={<label className="icon">{"\uf468"}</label>} title="จัดการคอมมิสชัน" defaultOpen={window.location.pathname === "/status-delivery" || window.location.pathname === "/commission-type" || window.location.pathname === "/status-delivery/create"}>
                {role && role.status_delivery && role.status_delivery[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/status-delivery" || window.location.pathname === "/status-delivery/create"}>
                    <Link to="/status-delivery" className="pro-button">
                      สถานะส่ง
                    </Link>
                  </MenuItem>
                )}
                {role && role.type_commission && role.type_commission[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/commission-type"}>
                    <Link to="/commission-type" className="pro-button">
                      ประเภทค่าคอมมิชชัน
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {/* รายงาน */}
            {role && (role.report_summary || role.report_sales_customers || role.report_sales_products || role.report_inventory || role.report_products_and_services || role.report_transfer || role.report_expenses || role.report_refund || role.report_reciept) && ((role.report_summary && role.report_summary[0] === 1) || (role.report_sales_customers && role.report_sales_customers[0] === 1) || (role.report_sales_products && role.report_sales_products[0] === 1) || (role.report_inventory && role.report_inventory[0] === 1) || (role.report_products_and_services && role.report_products_and_services[0] === 1) || (role.report_transfer && role.report_transfer[0] === 1) || (role.report_expenses && role.report_expenses[0] === 1) || (role.report_refund && role.report_refund[0] === 1) || (role.report_reciept && role.report_reciept[0] === 1)) && (
              <SubMenu icon={<label className="icon">{"\uf570"}</label>} title="รายงาน" defaultOpen={window.location.pathname === "/report" || window.location.pathname === "/reportsalescustomers" || window.location.pathname === "/reportsalesproducts" || window.location.pathname === "/reportinventory" || window.location.pathname === "/reportproductsandservices" || window.location.pathname === "/reporttransfer" || window.location.pathname === "/reportexpenses" || window.location.pathname === "/reportrefund" || window.location.pathname === "/reportreciept"}>
                {role && role.report_summary && role.report_summary[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/report"}>
                    <Link to="/report" className="pro-button">
                      สรุปรายงาน
                    </Link>
                  </MenuItem>
                )}
                {role && role.report_sales_customers && role.report_sales_customers[0] === 1 && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 50, hide: 50 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <label>รายงานสรุปยอดขายจากลูกค้ารายบุคคล</label>
                      </Tooltip>
                    }
                  >
                    <MenuItem active={window.location.pathname === "/reportsalescustomers"}>
                      <Link to="/reportsalescustomers" className="pro-button">
                        รายงานสรุปยอดขายจากลูกค้ารายบุคคล
                      </Link>
                    </MenuItem>
                  </OverlayTrigger>
                )}
                {role && role.report_inventory && role.report_inventory[0] === 1 && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 50, hide: 50 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <label>รายงานสรุปมูลค่าสินค้าคงคลัง</label>
                      </Tooltip>
                    }
                  >
                    <MenuItem active={window.location.pathname === "/reportinventory"}>
                      <Link to="/reportinventory" className="pro-button">
                        รายงานสรุปมูลค่าสินค้าคงคลัง
                      </Link>
                    </MenuItem>
                  </OverlayTrigger>
                )}
                {role && role.report_sales_products && role.report_sales_products[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/reportsalesproducts"}>
                    <Link to="/reportsalesproducts" className="pro-button">
                      รายงานผลรวมยอดขาย
                    </Link>
                  </MenuItem>
                )}
                {role && role.report_products_and_services && role.report_products_and_services[0] === 1 && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 50, hide: 50 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <label>รายงานรายละเอียดยอดขาย</label>
                      </Tooltip>
                    }
                  >
                    <MenuItem active={window.location.pathname === "/reportproductsandservices"}>
                      <Link to="/reportproductsandservices" className="pro-button">
                        รายงานรายละเอียดยอดขาย
                      </Link>
                    </MenuItem>
                  </OverlayTrigger>
                )}
                {role && role.report_transfer && role.report_transfer[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/reporttransfer"}>
                    <Link to="/reporttransfer" className="pro-button">
                      รายการโอน
                    </Link>
                  </MenuItem>
                )}
                {role && role.report_expenses && role.report_expenses[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/reportexpenses"}>
                    <Link to="/reportexpenses" className="pro-button">
                      รายการค่าใช้จ่าย
                    </Link>
                  </MenuItem>
                )}
                {role && role.report_refund && role.report_refund[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/reportrefund"}>
                    <Link to="/reportrefund" className="pro-button">
                      รายการคืนสินค้า
                    </Link>
                  </MenuItem>
                )}
                {role && role.report_reciept && role.report_reciept[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/reportreciept"}>
                    <Link to="/reportreciept" className="pro-button">
                      รายการรับเข้า
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {/* โกดังสินค้า */}
            {role && role.warehouse && role.warehouse[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf276"}</label>} active={window.location.pathname === "/warehouse"}>
                <Link to="/warehouse" className="pro-button">
                  โกดังสินค้า
                </Link>
              </MenuItem>
            )}

            {/* บัญชีธนาคาร */}
            {role && role.bank_account && role.bank_account[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf19c"}</label>} active={window.location.pathname === "/bank"}>
                <Link to="/bank" className="pro-button">
                  บัญชีธนาคาร
                </Link>
              </MenuItem>
            )}

            {/* จัดการค่าใช้จ่าย */}
            {role && (role.expenses || role.type_expenses) && (role.expenses[0] === 1 || role.type_expenses[0] === 1) && (
              <SubMenu icon={<label className="icon">{"\uf571"}</label>} title="จัดการค่าใช้จ่าย" defaultOpen={window.location.pathname === "/expenses" || window.location.pathname === "/expenses/type" || window.location.pathname === "/expenses/create"}>
                {role && role.expenses && role.expenses[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/expenses"}>
                    <Link to="/expenses" className="pro-button">
                      บันทึกค่าใช้จ่าย
                    </Link>
                  </MenuItem>
                )}
                {role && role.type_expenses && role.type_expenses[0] === 1 && (
                  <MenuItem active={window.location.pathname === "/expenses/type"}>
                    <Link to="/expenses/type" className="pro-button">
                      จัดการหมวดหมู่ค่าใช้จ่าย
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {/* คืนสินค้า */}
            {role && role.refund && role.refund[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf47b"}</label>} active={window.location.pathname === "/refund" || window.location.pathname === "/refund/create"}>
                <Link to="/refund" className="pro-button">
                  คืนสินค้า
                </Link>
              </MenuItem>
            )}

            {/* ประวัติการตรวจสอบ */}
            {role && role.logaudit && role.logaudit[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf1da"}</label>} active={window.location.pathname === "/logaudit" || window.location.pathname === "/logaudit/detail"}>
                <Link to="/logaudit" className="pro-button">
                  ประวัติการตรวจสอบ
                </Link>
              </MenuItem>
            )}

            {/* เงินคืนสะสม */}
            {role && role.refund_accumulate && role.refund_accumulate[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf51e"}</label>} active={window.location.pathname === "/refund-accumulate"}>
                <Link to="/refund-accumulate" className="pro-button">
                  เงินคืนสะสม
                </Link>
              </MenuItem>
            )}

            {/* barcode */}
            {role && role.generate_barcode && role.generate_barcode[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf51e"}</label>} active={window.location.pathname === "/generate-barcode"}>
                <Link to="/generate-barcode" className="pro-button">
                  สร้างบาร์โค้ด
                </Link>
              </MenuItem>
            )}
            {/* barcode */}
            {role && role.check_stock && role.check_stock[0] === 1 && (
              <MenuItem icon={<label className="icon">{"\uf51e"}</label>} active={window.location.pathname === "/check-stock"}>
                <Link to="/check-stock" className="pro-button">
                  เช็คสต็อก
                </Link>
              </MenuItem>
            )}
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}
