import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { alert, GET, POST, PUT, tokens, status_delivery, role, ModalDetailStatusDelivery, report_5, format_date, GET_CACHE, status_sale_transaction2, ModalDetailStatusDeliveryHistory, format_date_notime, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import Select, { components } from "react-select";

const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 50,
    padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};

const UserOption = (props) => (
  <Option {...props}>
    <h5>{props.data.label}</h5>
  </Option>
);

export default class StatusDeliveryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      index_onClick: "",

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_remark: false,
      modal_add_invoice: false,

      data_list: null,
      details: null,

      datetime: new Date(),

      search: "",
      search_data: "",

      //details
      modal_detail: false,
      data_detail: "",

      delivery_number: "",
      no: "",
      update_status: "",
      payment_type: "",
      transfer_amount: 0,
      cash_amount: 0,
      credit_approver_by: "",
      received_by: "",

      status: "",
      status_delivery: "",
      delivery_id: "",

      //เลือกสายส่ง
      list_data_user: null,
      data_member: null,

      remark: "",
      remark_show: "",

      remark_status1: "",
      remark_status1_other: "",
      remark_status3: "",
      remark_status4: "",

      type: "status_delivery",
      history: null,

      //เลือกชื่อผู้ให้เครดิต
      list_data_admin: null,

      selected: false,
      select_all: false,
      search_invoice: "",

      sdl_id: "",

      //detailhistory
      data_detail_history: "",
      modal_detail_history: false,
    };
  }
  async componentDidMount() {
    await this.GetMember();
    await this.GetAll();
  }
  GetMember = async () => {
    let body = {
      data_search: "",
      user_type: "",
    };
    let result = await POST(tokens, "v1/user-admin/filter-search/page/0", body);
    if (result && result.status) {
      let emp_delivery = result.data.data.filter((e) => e.user_type === "emp_delivery");
      let cratedit = result.data.data.filter((e) => e.user_type === "owner" || e.user_type === "admin_system");
      this.setState({ list_data_user: emp_delivery, list_data_admin: cratedit });
    }
  };
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search_data,
      start_date: "",
      sort: "",
    };
    let result = await POST(tokens, "v1/delivery-mgt/search/page/0", body);

    if (result && result.status) {
      this.setState({ data_list: result.data });
    }
    this.setState({ loading: false });
  };

  Get_By_ID = async (text) => {
    this.setState({ loading: true });
    let body = {
      delivery_id: this.state.delivery_id,
      data_search: this.state.search,
      status_delivery: Number(this.state.status_delivery) === 0 ? "" : Number(this.state.status_delivery),
    };

    let result = "";
    if (text === "พิมพ์ใบส่งของ") {
      result = await POST(tokens, "v1/delivery-mgt/status_delivery/detail/page/0", body);
    } else {
      result = await POST(tokens, "v1/delivery-mgt/status_delivery/detail/page/" + this.state.page, body);
    }

    if (result && result.status) {
      if (text === "พิมพ์ใบส่งของ" && result.data.data.length === 0) {
        alert("warning", "แจ้งเตือน", "ไม่มีข้อมูล");
        this.setState({ loading: false });
        return;
      }

      this.setState({
        details: result.data,
        remark_show: result.data.relation_status_delivery.remark_delivery,
      });

      if (text === "พิมพ์ใบส่งของ") {
        report_5(this.state.details, "");
      }
    }
    this.setState({ loading: false });
  };

  GetHistory = async () => {
    this.setState({ loading: true });
    let body = {
      delivery_number: this.state.delivery_number,
    };
    let result = await POST(tokens, "v1/delivery-mgt/status_delivery_log/search/page/0", body);
    if (result && result.status) {
      if (result.data.data[0].detail_list.length !== 0) {
        this.setState({ history: result.data.data[0].detail_list });
        this.setState({ sdl_id: result.data.data[0].detail_list[0].sdl_id, loading: false });
      }
    }
    this.setState({ loading: false });
  };

  GetById_Detail = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/delivery-mgt/status_delivery/order/detail/" + id, null);
    if (result && result.status) {
      this.setState({ data_detail: result.data.data });

      setTimeout(() => {
        this.setState({ modal_detail: true });
      }, 10);
    }
    this.setState({ loading: false });
  };

  GetById_Detail_History = async (id) => {
    this.setState({ loading: true });
    let body = {
      order_main_number: id,
    };

    let result = await POST(tokens, "v1/delivery-mgt/status_delivery/log/order/detail/" + this.state.sdl_id, body);
    if (result && result.status) {
      this.setState({ data_detail_history: result.data.data });

      setTimeout(() => {
        this.setState({ modal_detail_history: true });
      }, 10);
    }
    this.setState({ loading: false });
  };

  UpdateStatus = async (id) => {
    this.setState({ loading: true });

    let remark_status1 = "";

    if (this.state.remark_status1 === "อื่นๆ") {
      remark_status1 = this.state.remark_status1_other;
    } else {
      remark_status1 = this.state.remark_status1;
    }

    let body = {
      status_delivery: Number(this.state.update_status),
      payment_type: Number(this.state.payment_type),
      transfer_amount: this.state.transfer_amount.toString(),
      cash_amount: this.state.cash_amount.toString(),
      credit_approver_by: this.state.credit_approver_by,
      received_by: this.state.received_by,
      status_delivery_remark_status1: remark_status1,
      status_delivery_remark_status3: this.state.remark_status3,
      status_delivery_remark_status4: this.state.remark_status4,
      delivery_number: this.state.delivery_number,
    };

    let result = await PUT(tokens, "v1/delivery-mgt/update/" + id, body);
    if (result && result.status) {
      alert("success", "สำเร็จ", "อัพเดทสถานะเรียบร้อยแล้ว");
      this.setState({ loading: false, modal_edit: false });

      this.ClearForm_Payment();

      this.Get_By_ID("");
    }
    this.setState({ loading: false });
  };

  UpdateRemark = async (id) => {
    this.setState({ loading: true });
    let body = {
      remark_delivery: this.state.remark_show,
    };
    let result = await PUT(tokens, "v1/delivery-mgt/update/remark/" + id, body);
    if (result && result.status) {
      this.setState({ loading: false, modal_remark: false });

      this.Get_By_ID("");
    }
    this.setState({ loading: false });
  };

  GetAll_IN = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      status_delivery: "",
      fillter_province: "",
      start_date: "",
      end_date: "",
    };
    let result = await GET_CACHE(tokens, "v1/delivery-mgt/status_delivery/order/page/0", body);
    if (result && result.status) {
      this.setState({ data_invoice: result.data, modal_add_invoice: true });
    }
    this.setState({ loading: false });
  };

  Update_Invoice = async (id) => {
    this.setState({ loading: true });

    let body = {
      order_main_list: id,
      status_delivery: 2,
      delivery_number: this.state.delivery_number,
    };

    let result = await POST(tokens, "v1/delivery-mgt/update-delivery", body);
    if (result && result.status) {
      alert("success", "สำเร็จ", "เพิ่มรายการเรียบร้อยแล้ว");
      this.setState({ loading: false, modal_add_invoice: false });

      this.Get_By_ID("");
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.Get_By_ID("");
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      remark_status1: "",
      details: null,
      //เลือกสายส่ง
      list_data_user: null,
      data_member: null,

      modal_remark: false,
      remark_status3: "",

      remark: "",

      status_delivery: "",
      delivery_id: "",

      update_status: "",

      payment_type: "",
      status: "",

      transfer_amount: "",
      cash_amount: "",
      credit_approver_by: "",
      received_by: "",

      selected: false,
      select_all: false,
      search_invoice: "",
    });
  }

  ClearForm_Payment() {
    this.setState({
      transfer_amount: 0,
      cash_amount: 0,
      credit_approver_by: "",
      received_by: "",
      remark_status1: "",
      remark_status1_other: "",
      remark_status3: "",
      remark_status4: "",
    });
  }

  ClearForm_Status() {
    this.setState({
      remark_status1: "",

      update_status: "",

      payment_type: "",
      status: "",

      transfer_amount: "",
      cash_amount: "",
      credit_approver_by: "",
      received_by: "",

      remark_status1_other: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="สถานะส่ง" page3="สถานะส่ง" />
            <div style={{ height: 60 }}></div>
            <div className="row w-100 mt-3 mx-0">
              {/* สถานะส่ง */}
              <div className="w-100 col-12 col-sm-12 col-md-5 col-lg-3 col-xl-3 mb-4">
                <div className="w-100 mb-3">
                  <h3>
                    <b>สถานะส่ง</b>
                  </h3>
                  <small className="text-msg text-14">ทั้งหมด {this.state.data_list && number(this.state.data_list.data.length)} รายการ</small>
                </div>
                <div className="w-100">
                  <div className="card">
                    <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                      <div className="col-6 col-sm-5 col-md-7 col-lg-8 col-xl-8  d-flex mb-2">
                        <div className="w-100">
                          <input
                            maxLength={30}
                            className="form-control-search"
                            type="search"
                            placeholder="พิมพ์คำค้นหา"
                            value={this.state.search_data}
                            onChange={(e) => {
                              this.setState({ page: 1, search_data: e.target.value, loading: false });
                              if (e.target.value === "") {
                                this.setState({ search_data: "", details: null, search: "", status_delivery: "", type: "status_delivery" });
                                setTimeout(() => {
                                  this.GetAll();
                                  this.setState({ loading: false });
                                }, 20);
                              } else {
                                setTimeout(() => {
                                  this.GetAll();
                                  this.setState({ loading: false });
                                }, 20);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive rounded-16 height-vh overflow-auto">
                        <table className="table table-hover table-striped table-borderless">
                          <thead>
                            <tr className=" bg-white">
                              <th className="text-left" style={{ minWidth: 100 }}>
                                <b>วันที่</b>
                              </th>
                              <th className="text-center" style={{ minWidth: 100 }}>
                                <b>เลขที่ใบส่งของสายส่ง</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data_list && this.state.data_list.data.length === 0 && (
                              <tr>
                                <td colSpan={2} className="text-center table-light height-vh">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}
                            {this.state.data_list &&
                              this.state.data_list.data.map((item, index) => (
                                <tr
                                  className="pointer"
                                  key={index}
                                  onClick={() => {
                                    this.setState({
                                      details: null,
                                      delivery_id: item.delivery_id,
                                      delivery_number: item.delivery_number,
                                      index_onClick: index,
                                      status_delivery: "",
                                      page: 1,
                                      search: "",
                                      remark: "",
                                      history: null,
                                      type: "status_delivery",
                                    });
                                    setTimeout(async () => {
                                      if (this.state.type === "status_delivery") {
                                        await this.Get_By_ID("");
                                      } else {
                                        await this.GetHistory();
                                      }
                                    }, 20);
                                  }}
                                >
                                  <td className={index === this.state.index_onClick ? "text-left text-white bg-primary" : "text-left"}>{format_date_notime(item.datetime_create)}</td>
                                  <td className={index === this.state.index_onClick ? "text-center text-white bg-primary" : "text-center"}>{item.delivery_number}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* เลขที่ใบส่งของ สายส่ง:  */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-9 col-xl-9 mb-4 w-100 ">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-xl-6">
                    <div className="d-flex flex-column w-100 mx-0">
                      <h3>
                        <b>เลขที่ใบส่งของ สายส่ง: {this.state.delivery_number}</b>
                      </h3>
                      <label className="text-msg mb-3 text-14">ทั้งหมด {this.state.details && number(this.state.details.data.length)} รายการ</label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12  col-xl-6 mb-3">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className={this.state.type === "status_delivery" ? "btn btn-outline-primary bg-white w-70 mr-2" : "btn btn-outline-secondary bg-white w-70 mr-2"}
                        disabled={this.state.type === "status_delivery" ? false : true}
                        onClick={() => {
                          if (this.state.type === "status_delivery") {
                            if (this.state.delivery_number === "") {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกเลขที่ใบส่งของที่ต้องการพิมพ์");
                              return;
                            }
                            setTimeout(async () => {
                              await this.Get_By_ID("พิมพ์ใบส่งของ");
                            }, 10);
                          }
                        }}
                      >
                        <span className="icon-brand text-18 ">{"\uf02f"}</span> พิมพ์ใบส่งของ
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary w-70 ml-2"
                        onClick={() => {
                          window.location.href = "/status-delivery";
                        }}
                      >
                        <span className="icon-brand text-18 ">{"\uf060"}</span> กลับไปที่หน้าหลัก
                      </button>
                    </div>
                  </div>
                </div>

                {/* table detail */}
                <div className="card">
                  <div className="w-100 d-flex">
                    <button
                      className={this.state.type === "status_delivery" ? "btn bg-secondary w-50 hpx-90 rounded-16 text-title text-16" : "btn w-50 hpx-90 rounded-16 text-msg text-16"}
                      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                      onClick={() => {
                        if (this.state.delivery_number === "") {
                          alert("warning", "แจ้งเตือน", "กรุณาเลือกเลขที่ใบส่งของ");
                          return;
                        }
                        this.setState({ type: "status_delivery", page: 1, search: "", remark: "" });

                        setTimeout(async () => {
                          await this.Get_By_ID("");
                        }, 20);
                      }}
                    >
                      <label className="icon pointer" style={{ marginBottom: 45 }}>
                        {"\uf733"}
                      </label>{" "}
                      ใบส่งของ
                    </button>
                    <button
                      className={this.state.type === "history" ? "btn bg-secondary w-50 hpx-90 rounded-16 text-title text-16" : "btn w-50 hpx-90 rounded-16 text-msg text-16"}
                      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                      onClick={() => {
                        if (this.state.delivery_number === "") {
                          alert("warning", "แจ้งเตือน", "กรุณาเลือกเลขที่ใบส่งของ");
                          return;
                        }
                        this.setState({ type: "history", page: 1, search: "", remark: "" });

                        setTimeout(() => {
                          this.GetHistory();
                        }, 20);
                      }}
                    >
                      <label className="icon pointer" style={{ marginBottom: 45 }}>
                        {"\uf1da"}
                      </label>{" "}
                      ประวัติ
                    </button>
                  </div>

                  {this.state.type === "status_delivery" ? (
                    <>
                      <div className="card-header row d-flex mx-0 justify-content-between align-items-left bg-secondary rounded-top-16" style={{ marginTop: -45 }}>
                        {role && role.status_delivery[2] === 1 && (
                          <div className="d-flex mb-3 align-content-center ">
                            <label className="mb-0 wpx-300">
                              หมายเหตุ: {this.state.remark_show}
                              <span
                                className="pointer text-primary ml-1"
                                onClick={() => {
                                  if (this.state.delivery_number === "") {
                                    alert("warning", "แจ้งเตือน", "กรุณาเลือกเลขที่ใบส่งของ");
                                    return;
                                  }
                                  this.setState({ modal_remark: true, remark: this.state.remark_show });
                                }}
                              >
                                <label className="icon pointer">{"\uf303"}</label>เปลี่ยน
                              </span>
                            </label>
                          </div>
                        )}

                        {role && role.status_delivery[2] === 1 && (
                          <div>
                            <button
                              disabled={this.state.details && this.state.details.status_edit === "off" ? true : false}
                              type="button"
                              className="btn btn-primary w-100 px-4"
                              onClick={() => {
                                if (this.state.details && this.state.details.status_edit !== "off") {
                                  this.setState({
                                    selected: false,
                                    select_all: false,
                                    search_invoice: "",
                                  });
                                  if (this.state.delivery_number === "") {
                                    alert("warning", "แจ้งเตือน", "กรุณาเลือกเลขที่ใบส่งของ");
                                    return;
                                  }

                                  this.GetAll_IN();
                                }
                              }}
                            >
                              เพิ่ม
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="card-header row d-flex mx-0 px-2 justify-content-between">
                        <div className="col-6 col-sm-5 col-md-5  col-lg-4  col-xl-4 d-flex mb-2">
                          <div className="w-100">
                            <input
                              className="form-control-search"
                              type="search"
                              placeholder="พิมพ์คำค้นหา"
                              value={this.state.search}
                              onChange={(e) => {
                                this.setState({ page: 1, search: e.target.value, loading: false });
                                if (e.target.value === "" && this.state.details !== null) {
                                  this.setState({ search: "" });
                                  setTimeout(() => {
                                    this.Get_By_ID("");
                                    this.setState({ loading: false });
                                  }, 20);
                                } else {
                                  setTimeout(() => {
                                    this.Get_By_ID("");
                                    this.setState({ loading: false });
                                  }, 20);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-6 col-sm-5 col-md-5 col-lg-4  col-xl-4 d-flex mb-2">
                          <div className="w-100">
                            <select
                              className="form-control-select"
                              onChange={(e) => {
                                if (this.state.delivery_number === "") {
                                  alert("warning", "แจ้งเตือน", "กรุณาเลือกเลขที่ใบส่งของ");
                                  return;
                                }
                                this.setState({ status_delivery: e.target.value, page: 1, remark: "" });
                                setTimeout(() => {
                                  this.Get_By_ID("");
                                }, 20);
                              }}
                              value={this.state.status_delivery}
                            >
                              <option value="" selected>
                                แสดงทุกสถานะ
                              </option>
                              <option value="1">ยังไม่ส่ง</option>
                              <option value="2">อยู่ระหว่างขนส่ง</option>
                              <option value="3">ส่งแล้ว(ยังไม่ได้รับเงิน)</option>
                              <option value="4">ส่งแล้ว</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive rounded-16 height-vh overflow-auto">
                          <table className="table table-hover table-striped table-borderless">
                            <thead>
                              <tr className=" bg-white">
                                <th className="text-center">
                                  <b>No.</b>
                                </th>
                                <th className="text-left" style={{ minWidth: 100 }}>
                                  <b>วันที่ Invoice</b>
                                </th>
                                <th className="text-left" style={{ minWidth: 100 }}>
                                  <b>เลขที่ Invoice</b>
                                </th>
                                <th className="text-left" style={{ minWidth: 100 }}>
                                  <b>ชื่อลูกค้า</b>
                                </th>
                                <th className="text-left" style={{ minWidth: 120 }}>
                                  <b>เบอร์โทรลูกค้า</b>
                                </th>
                                <th className="text-center" style={{ minWidth: 80 }}>
                                  <b>จำนวนไม้</b>
                                </th>
                                <th className="text-center" style={{ minWidth: 180 }}>
                                  <b>สถานะ</b>
                                </th>
                                {role && role.status_delivery[2] === 1 && (
                                  <th className="text-center">
                                    <b>จัดการ</b>
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {((this.state.details && this.state.details.data.length === 0) || !this.state.details) && (
                                <tr>
                                  <td colSpan={9} className="text-center table-light height-vh">
                                    -- ไม่มีข้อมูล --
                                  </td>
                                </tr>
                              )}

                              {this.state.details &&
                                this.state.details.data.map((item, index) => (
                                  <tr key={index} className="pointer">
                                    <td className="text-center">{Number(index + 1)}</td>
                                    <td className="text-left ">{format_date(item.datetime_create)}</td>
                                    <td className="text-left ">{item.ab_number}</td>
                                    <td className="text-left">{item.cutomer_name}</td>
                                    <td className="text-left">{item.cutomer_phone ? item.cutomer_phone : "-"}</td>
                                    <td className="text-center">{number(item.qty)}</td>
                                    <td className="text-center">{status_delivery(item.status_delivery)}</td>
                                    {/* <td className="text-left"></td> */}
                                    <td className="text-center">
                                      <Menu
                                        menuButton={
                                          <button className="btn btn-link text-dark">
                                            <h1 className="icon h2">{"\uf142"}</h1>
                                          </button>
                                        }
                                        transition
                                      >
                                        {role && role.status_delivery[2] === 1 && (
                                          <>
                                            {item.status_delivery !== 4 && (
                                              <MenuItem
                                                onClick={async () => {
                                                  this.ClearForm_Payment();
                                                  let status_delivery_remark_status1 = "";

                                                  if (item.status_delivery_remark_status1 === "" || item.status_delivery_remark_status1 !== "ส่งแล้วไม่รับของ" || item.status_delivery_remark_status1 !== "สินค้าไม่ตรงตามออเดอร์" || item.status_delivery_remark_status1 !== "ลูกค้าติดต่อไม่ได้" || item.status_delivery_remark_status1 !== "ส่งไม่ทัน") {
                                                    status_delivery_remark_status1 = "อื่นๆ";
                                                    this.setState({
                                                      remark_status1_other: item.status_delivery_remark_status1,
                                                    });
                                                  } else {
                                                    status_delivery_remark_status1 = item.status_delivery_remark_status1;
                                                  }

                                                  this.setState({
                                                    modal_edit: true,
                                                    status: item.status_delivery.toString(),

                                                    update_status: item.status_delivery.toString(),

                                                    id: item.order_main_id,

                                                    received_by: item.status_delivery_received_by,
                                                    credit_approver_by: item.credit_approver_by,
                                                    cash_amount: item.cash_amount,
                                                    transfer_amount: item.transfer_amount,
                                                    payment_type: item.status_delivery_payment_type.toString(),
                                                    remark_status1: status_delivery_remark_status1,
                                                    remark_status3: item.status_delivery_remark_status3,
                                                    remark_status4: item.status_delivery_remark_status4,
                                                  });
                                                }}
                                              >
                                                <span className="icon mr-2 h2 pointer">{"\uf040"}</span>
                                                อัพเดทสถานะ
                                              </MenuItem>
                                            )}
                                          </>
                                        )}
                                        {role && role.status_delivery[0] === 1 && (
                                          <MenuItem
                                            onClick={async () => {
                                              this.setState({ page: 1 });
                                              this.GetById_Detail(item.order_main_id);
                                            }}
                                          >
                                            <span className="icon mr-2 h2 pointer">{"\uf543"}</span>
                                            รายละเอียดสถานะส่งของ
                                          </MenuItem>
                                        )}
                                      </Menu>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="card-header row d-flex mx-0 justify-content-left align-items-left bg-secondary rounded-top-16 hpx-54" style={{ marginTop: -45 }}></div>
                      <div className="card-body p-0 min-vh-100">
                        <div className="table-responsive rounded-16 height-vh overflow-auto">
                          <table className="table table-striped table-borderless table-hover">
                            <thead>
                              <tr>
                                <th className="text-left" style={{ minWidth: 100 }}>
                                  <b>วันที่</b>
                                </th>
                                <th className="text-left" style={{ minWidth: 100 }}>
                                  <b>เลขที่ Invoice</b>
                                </th>
                                <th className="text-left" style={{ minWidth: 100 }}>
                                  <b>ชื่อลูกค้า</b>
                                </th>
                                <th className="text-left" style={{ minWidth: 120 }}>
                                  <b>เบอร์โทรลูกค้า</b>
                                </th>
                                <th className="text-center" style={{ minWidth: 80 }}>
                                  <b>จำนวนไม้</b>
                                </th>
                                <th className="text-center" style={{ minWidth: 180 }}>
                                  <b>สถานะ</b>
                                </th>
                                <th className="text-center" style={{ minWidth: 100 }}>
                                  <b>รายละเอียด</b>
                                </th>
                              </tr>
                            </thead>

                            {((this.state.history && this.state.history.length === 0) || !this.state.history) && (
                              <tbody>
                                <tr>
                                  <td colSpan={7} className="text-center table-light height-vh">
                                    -- ไม่มีข้อมูล --
                                  </td>
                                </tr>
                              </tbody>
                            )}

                            {this.state.history &&
                              this.state.history.map((item, index) => (
                                <tbody>
                                  <tr
                                    key={"dt" + index}
                                    className="table-light pointer"
                                    onClick={() => {
                                      let data = this.state.history;

                                      if (data[index].expand) {
                                        data[index].expand = !data[index].expand;
                                      } else {
                                        data[index].expand = true;
                                      }
                                      this.setState({ data: data });
                                    }}
                                  >
                                    <td className="text-left">
                                      {format_date(item.time)}
                                      <span className="icon ml-2 text-10">{item.expand ? "\uf077" : "\uf078"}</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  {item.expand &&
                                    item.detail.map((detail, detail_index) => (
                                      <tr key={"pd" + detail_index} className="pointer">
                                        <td className="text-left"></td>
                                        <td className="text-left ">{detail.ab_number}</td>
                                        <td className="text-left">{detail.cutomer_name}</td>
                                        <td className="text-left">{detail.cutomer_phone ? detail.cutomer_phone : "-"}</td>
                                        <td className="text-center">{number(detail.qty)}</td>
                                        <td className="text-center">{status_delivery(detail.status_delivery)}</td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-link text-secondary"
                                            onClick={() => {
                                              this.setState({ page: 1 });
                                              this.GetById_Detail_History(detail.order_main_number);
                                            }}
                                          >
                                            <label className="icon mr-2">{"\uf570"}</label>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              ))}
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* pagination */}
                {this.state.type === "status_delivery" && this.state.details && this.state.details.data.length !== 0 && (
                  <div className="row w-100 justify-content-between mx-0  my-4">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                      <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                        <label>
                          {" "}
                          แสดง {number(this.state.details.end_index)} รายการจาก {number(this.state.details.count)} รายการ{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                      <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                        <button
                          className="btn-circle-white mx-1 pointer"
                          onClick={() => {
                            this.HandlePageChange(1);
                          }}
                        >
                          {" "}
                          <label className="icon pointer">{"\uf100"}</label>{" "}
                        </button>
                        <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.details.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                        <button
                          className="btn-circle-white mx-1 pointer"
                          onClick={() => {
                            this.HandlePageChange(this.state.details.page_number);
                          }}
                        >
                          <label className="icon pointer">{"\uf101"}</label>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* อัพเดทสถานะ */}
        <Modal
          show={this.state.modal_edit}
          size="m"
          onHide={() => {
            this.setState({ modal_edit: false });
            this.ClearForm_Status();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">อัพเดทสถานะ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>สถานะ:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ update_status: e.target.value, payment_type: "" });
                  }}
                  value={this.state.update_status}
                >
                  <option value="" selected disabled>
                    -- สถานะ --
                  </option>
                  <option value="1" disabled={this.state.status === "1" ? true : false}>
                    ยังไม่ได้ส่ง
                  </option>
                  <option value="2" disabled={this.state.status === "2" ? true : false}>
                    อยู่ระหว่างการขนส่ง
                  </option>
                  <option value="3" disabled={this.state.status === "3" ? true : false}>
                    ส่งแล้ว (ยังไม่ได้รับเงินสด)
                  </option>
                  <option value="4" disabled={this.state.status === "4" ? true : false}>
                    ส่งแล้ว (ได้รับเงินสด)
                  </option>
                </select>
              </div>
            </div>

            {this.state.update_status === "1" ? (
              <>
                <div className="w-100 d-flex mb-3">
                  <div className="wpx-120">
                    <label>หมายเหตุ:</label>
                  </div>
                  <div className="w-100">
                    <select
                      className="form-control bg-secondary-light border-0 pointer"
                      onChange={(e) => {
                        this.setState({ remark_status1: e.target.value });
                      }}
                      value={this.state.remark_status1}
                    >
                      <option value="" selected disabled>
                        -- หมายเหตุ --
                      </option>
                      <option value="ส่งแล้วไม่รับของ">ส่งแล้วไม่รับของ</option>
                      <option value="สินค้าไม่ตรงตามออเดอร์">สินค้าไม่ตรงตามออเดอร์</option>
                      <option value="ลูกค้าติดต่อไม่ได้">ลูกค้าติดต่อไม่ได้</option>
                      <option value="ส่งไม่ทัน">ส่งไม่ทัน</option>
                      <option value="อื่นๆ">อื่นๆ</option>
                    </select>
                  </div>
                </div>

                {this.state.remark_status1 === "อื่นๆ" && (
                  <div className="w-100 d-flex mb-3">
                    <div className="wpx-120"></div>
                    <div className="w-100">
                      <textarea
                        maxLength={30}
                        type="text"
                        placeholder="จำเป็นต้องกรอก"
                        className="form-control bg-secondary-light border-0"
                        onChange={(e) => {
                          this.setState({ remark_status1_other: e.target.value });
                        }}
                        value={this.state.remark_status1_other}
                      ></textarea>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="w-100 d-flex mb-3">
                  <div className="wpx-120">
                    <label>วิธีรับเงิน:</label>
                  </div>
                  <div className="w-100">
                    <select
                      className="form-control bg-secondary-light border-0 pointer"
                      onChange={(e) => {
                        this.setState({ payment_type: e.target.value });

                        this.ClearForm_Payment();
                      }}
                      value={this.state.payment_type}
                    >
                      <option value="" selected>
                        -- วิธีรับเงิน --
                      </option>
                      <option value="1" disabled={this.state.update_status === "3" ? true : false}>
                        สด
                      </option>
                      <option value="2" disabled={this.state.update_status === "4" ? true : false}>
                        โอน
                      </option>
                      <option value="3" disabled={this.state.update_status === "3" ? true : false}>
                        โอน/สด
                      </option>
                      <option value="4" disabled={this.state.update_status === "4" ? true : false}>
                        เครดิต
                      </option>
                    </select>
                  </div>
                </div>

                {this.state.payment_type === "3" && (
                  <>
                    <div className="w-100 d-flex mb-3">
                      <div className="wpx-120">
                        <label>จำนวนเงินสด:</label>
                      </div>
                      <div className="w-100">
                        <input
                          maxLength={14}
                          type="text"
                          className="form-control bg-secondary-light border-0"
                          onChange={(e) => {
                            if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                              this.setState({ cash_amount: e.target.value });
                            }
                          }}
                          value={this.state.cash_amount}
                        ></input>
                      </div>
                    </div>
                    <div className="w-100 d-flex mb-3">
                      <div className="wpx-120">
                        <label>จำนวนเงินโอน:</label>
                      </div>
                      <div className="w-100">
                        <input
                          maxLength={14}
                          type="text"
                          className="form-control bg-secondary-light border-0"
                          onChange={(e) => {
                            if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                              this.setState({ transfer_amount: e.target.value });
                            }
                          }}
                          value={this.state.transfer_amount}
                        ></input>
                      </div>
                    </div>
                  </>
                )}

                {this.state.payment_type === "4" && (
                  <>
                    <div className="w-100 d-flex mb-3">
                      <div className="wpx-120">
                        <label>ผู้อนุมัติเครดิต:</label>
                      </div>
                      <div className="w-100">
                        <Select
                          className="px-0 mb-3 "
                          options={
                            this.state.list_data_admin &&
                            this.state.list_data_admin.map((item) => ({
                              value: item.user_id,
                              label: item.full_name,
                            }))
                          }
                          components={{ Option: UserOption }}
                          styles={customStyles}
                          placeholder="พิมพ์ชื่อ"
                          onChange={(e) => {
                            this.setState({ credit_approver_by: e.label });
                          }}
                          defaultInputValue={this.state.credit_approver_by}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="w-100 d-flex mb-3">
                  <div className="wpx-120">
                    <label>ผู้ส่งของ:</label>
                  </div>
                  <div className="w-100">
                    <Select
                      className="px-0 mb-3 "
                      options={
                        this.state.list_data_user &&
                        this.state.list_data_user.map((item) => ({
                          value: item.user_id,
                          label: item.full_name,
                        }))
                      }
                      components={{ Option: UserOption }}
                      styles={customStyles}
                      placeholder="พิมพ์ชื่อ"
                      onChange={(e) => {
                        this.setState({ received_by: e.label });
                      }}
                      defaultInputValue={this.state.received_by}
                    />
                  </div>
                </div>

                {(this.state.update_status === "3" || this.state.update_status === "4") && (
                  <div className="w-100 d-flex mb-3">
                    <div className="wpx-120">หมายเหตุ:</div>
                    <div className="w-100">
                      <select
                        className="form-control bg-secondary-light border-0"
                        onChange={(e) => {
                          this.setState({ remark_status3: e.target.value });
                        }}
                        value={this.state.remark_status3}
                      >
                        <option value="" disabled>
                          -- เลือก --
                        </option>
                        <option value="รอโอน">รอโอน</option>
                        <option value="โอนทันที">โอนทันที</option>
                      </select>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                    this.ClearForm_Status();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.update_status === "") {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานะ");
                      return;
                    } else if (this.state.update_status === "1" && this.state.remark_status1 === "อื่นๆ" && this.state.remark_status1_other === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกหมายเหตุอื่นๆ");
                      return;
                    } else if (this.state.update_status === "3" && this.state.remark_status3 === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกหมายเหตุ");
                      return;
                    }

                    this.UpdateStatus(this.state.id);
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียด modal */}
        <ModalDetailStatusDelivery
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />
        {/* รายละเอียดประวัติ */}
        <ModalDetailStatusDeliveryHistory
          value={this.state.data_detail_history}
          show={this.state.modal_detail_history}
          onHide={() => {
            this.setState({ modal_detail_history: false });
          }}
        />

        {/* หมายเหตุ */}
        <Modal
          show={this.state.modal_remark}
          size="m"
          onHide={() => {
            this.setState({ modal_remark: false, remark: "" });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>หมายเหตุ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ระบุหมายเหตุ:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="ไม่จำเป็นต้องกรอก"
                  onChange={(e) => {
                    this.setState({ remark: e.target.value });
                  }}
                  maxLength={30}
                  value={this.state.remark}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_remark: false, remark: "" });
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ remark_show: this.state.remark });

                    setTimeout(() => {
                      this.UpdateRemark(this.state.delivery_id);
                    }, 10);
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* เพิ่มเลขที่ใบส่งของ */}
        <Modal
          show={this.state.modal_add_invoice}
          size="lg"
          onHide={() => {
            this.setState({ modal_add_invoice: false, remark: "" });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เพิ่มเลขที่ใบส่งของ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mb-3 overflow-auto p-3" style={{ maxHeight: "50vh" }}>
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 justify-content-between">
                  <div className="col-6 col-sm-3 col-lg-3 col-xl-3 d-flex mb-2">
                    <input
                      maxLength={20}
                      className="form-control-search"
                      type="search"
                      placeholder="ค้นหาเลขinvoice"
                      value={this.state.search_invoice}
                      onChange={(e) => {
                        this.setState({ search_invoice: e.target.value });
                      }}
                    />
                  </div>

                  <div className="col-6 col-sm-3 col-lg-3 col-xl-3">
                    <button
                      type="button"
                      className="btn btn-outline-primary bg-white w-100"
                      onClick={() => {
                        let data = this.state.data_invoice;
                        for (let d of data.data) {
                          d.selected = false;
                        }

                        setTimeout(() => {
                          this.setState({
                            data_invoice: data,
                            selected: false,
                            select_all: false,
                            order_main_list: [],
                          });
                        }, 10);
                      }}
                    >
                      เคลียร์การเลือก
                    </button>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16 height-vh overflow-auto">
                    <table className="table table-hover table-striped table-borderless">
                      <thead>
                        <tr>
                          <th className="text-center" style={{ minWidth: 50 }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              id="selectAll"
                              onChange={() => {
                                let data = this.state.data_invoice;
                                for (let d of data.data) {
                                  d.selected = !this.state.select_all;
                                }
                                setTimeout(() => {
                                  this.setState({
                                    data: data,
                                    selected: !this.state.select_all,
                                    select_all: !this.state.select_all,
                                  });
                                }, 10);
                              }}
                              checked={this.state.select_all}
                              value={this.state.select_all}
                            ></input>
                          </th>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>เลขที่ใบแจ้งหนี้/invoice</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>ชื่อลูกค้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>จังหวัด</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>สถานะ</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data_invoice && this.state.data_invoice.data.length === 0 && (
                          <tr>
                            <td colSpan={6} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data_invoice &&
                          this.state.data_invoice.data
                            .filter((item) => item.ab_number.includes(this.state.search_invoice))
                            .map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className={item.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      let data = this.state.data_invoice;
                                      data.data[index].selected = !data.data[index].selected;
                                      if (!data.data[index].selected) {
                                        this.setState({ select_all: false });
                                      }
                                      setTimeout(() => {
                                        this.setState({ data: data });
                                      }, 10);
                                    }}
                                    checked={item.selected}
                                    value={item.selected}
                                  ></input>
                                </td>
                                <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{format_date(item.datetime_create)}</td>
                                <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{item.ab_number}</td>
                                <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{item.cutomer_name}</td>
                                <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{item.cutomer_province}</td>
                                <td className={item.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>{status_sale_transaction2(item.status_bill)}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-outline-primary bg-white w-25 mr-2"
                onClick={() => {
                  this.setState({ modal_add_invoice: false });
                }}
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-primary w-25 ml-2"
                onClick={() => {
                  let order = [];
                  for (let data of this.state.data_invoice.data) {
                    if (data.selected === true) {
                      order.push(data.order_main_id);
                    }
                  }
                  if (order.length === 0) {
                    alert("warning", "แจ้งเตือน", "กรุณาเลือกรายการ");
                    return;
                  }
                  this.Update_Invoice(order);
                }}
              >
                บันทึก
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
