import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, GET, POST, tokens, role, baht, DELETE_CACHE, number } from "../../components/CustomComponent.js";
import { Menu, MenuItem } from "@szhsin/react-menu";

import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Select, { components } from "react-select";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);
export default class ProductScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_delete: false,

      data: null,
      data_stock: null,

      search: "",
      status: "",
      product_type: "",
      type_product_id: "",
      warehouse: "",
      warehouse_id: "",
      product_id: "",
      design: "",
      design_value: "",

      product_name: "",
      id: "",
      user_id: "",

      sort_yard: "",
      sort_name: "",
      sort_color: "",

      color: "",
      product_color: "",
    };
  }
  async componentDidMount() {
    await this.GetWareHouse();
    await this.GetProductType();
    // await this.GetDesing()
    // await this.GetColor()
  }

  GetProductType = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", body);

    if (result && result.status) {
      this.setState({ product_type: result.data });
    }
  };
  GetWareHouse = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);

    if (result && result.status) {
      this.setState({ warehouse: result.data });
      let find = result.data.data.find((item) => item.is_active === true);
      setTimeout(async () => {
        if (find === undefined) {
          this.setState({ warehouse_id: "" });
          await this.GetAll();
        } else {
          this.setState({ warehouse_id: find.warehouse_id });
          await this.GetAll();
        }
      }, 10);
    }
    this.setState({ loading: false });
  };
  GetDesing = async () => {
    let result = await GET(tokens, "v1/product-mgt/all/design/0", null);
    if (result && result.status) {
      this.setState({ design: result.data });
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  GetColor = async () => {
    let result = await GET(tokens, "v1/product-mgt/all/color/0", null);
    if (result && result.status) {
      this.setState({ color: result.data });
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/filter-search/page/" + this.state.page + "?data_search=" + (this.state.search ? this.state.search.split("/")[0] : "") + "&status=" + this.state.status + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + this.state.warehouse_id + "&user_member_id=" + this.state.user_id + "&product_id=" + this.state.product_id + "&sort_name=" + this.state.sort_name + "&sort_yard=" + this.state.sort_yard + "&sort_color=" + this.state.sort_color + "&color=" + this.state.product_color + "&product_design=" + this.state.design_value.replaceAll("#", "%23"), null);
    if (result && result.status) {
      this.setState({
        data: result.data,
        color: result.data.data.color,
        design: result.data.data.design,
      });
    }
    this.setState({ loading: false });
  };

  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE_CACHE(tokens, "v1/product-mgt/all/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลสินค้าเรียบร้อยแล้ว", "สินค้า ' " + this.state.product_name + " ' ถูกลบข้อมูลในระบบแล้ว");

      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  GetStockAll = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/pos-mgt/order/warehouse-all/" + id, null);
    if (result && result.status) {
      this.setState({ data_stock: result.data });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      user_id: "",
      page: 1,

      product_name: "",
      id: "",
      warehouse_id: "",
      type_product_id: "",
      status: "",
      product_id: "",

      sort_yard: "",
      sort_name: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="สินค้าทั้งหมด" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <div className="row px-3">
                <div>
                  <h3>
                    <b>สินค้าทั้งหมด</b>
                  </h3>
                  <label className="text-msg text-14">ทั้งหมด {this.state.data ? number(this.state.data.count) : 0} รายการ</label>
                </div>
              </div>

              {role && role.all_product[1] === 1 && (
                <div className="d-flex">
                  {/* {this.state.data && this.state.data.data.length === 0 && ( */}
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 mx-2"
                    onClick={() => {
                      window.location.href = "/product/import";
                    }}
                  >
                    <span className="icon-brand text-18">{"\uf56f"}</span> นำเข้าไฟล์สินค้า
                  </button>
                  {/* )} */}
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/product/create";
                    }}
                  >
                    {" "}
                    เพิ่มสินค้า{" "}
                  </button>
                </div>
              )}
            </div>

            {/* count */}
            <div className="w-100 pt-3 d-flex justify-content-between">
              <div className="d-flex text-white w-100 px-3">
                <div className="card bg-cash-4 p-3 w-100">
                  <div className="d-flex justify-content-between w-100 text-dark">
                    <div>
                      <span className="icon-brand text-16 ">{"\uf49c"}</span> <b>LOW STOCK</b>
                    </div>
                    <b>{this.state.data ? number(this.state.data.low_stock) : 0}</b>
                  </div>
                </div>
              </div>
              <div className="d-flex text-white w-100 px-3">
                <div className="card  bg-total-2 w-100 p-3">
                  <div className="d-flex justify-content-between w-100">
                    <div>
                      <span className="icon-brand text-16 ">{"\uf5ec"}</span> <b>OUT OF STOCK</b>
                    </div>
                    <b>{this.state.data ? number(this.state.data.out_of_stock) : 0}</b>
                  </div>
                </div>
              </div>
            </div>

            {/* table detail */}
            <div className="w-100 px-3 pt-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  {/* ค้นหา */}
                  <div className="col-6 col-sm-6 col-md-3  col-lg-2  col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            if (this.state.search.includes("PD-") && !this.state.search.includes(".") && this.state.search.length >= 12) {
                              this.setState({ search: this.state.search.split("/")[0] });
                            } else if (this.state.search.includes("PD-") && this.state.search.includes(".") && this.state.search.length >= 14) {
                              this.setState({ search: this.state.search.split("/")[0] });
                            }
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 10);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* โกดัง  */}
                  <div className="col-6 col-sm-6 col-md-3  col-lg-2  col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <select
                        className="form-control-select pointer"
                        onChange={(e) => {
                          this.setState({ search: "", page: 1, warehouse_id: e.target.value });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                        value={this.state.warehouse_id}
                      >
                        <option value="" selected>
                          แสดงทุกโกดัง
                        </option>
                        {this.state.warehouse &&
                          this.state.warehouse.data.map((item, index) => (
                            <option value={item.warehouse_id} key={index}>
                              {item.warehouse_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {/* ประเภท */}
                  <div className="col-6 col-sm-6 col-md-3  col-lg-2  col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <Select
                        ref={(click) => (this.Input = click)}
                        className="px-0"
                        options={
                          this.state.product_type &&
                          this.state.product_type.data.map((item) => ({
                            value: item.type_product_id,
                            label: item.type_product_name,
                          }))
                        }
                        components={{ Option: TypeOption }}
                        styles={customStylesTypeOption}
                        placeholder="แสดงทุกประเภท"
                        isClearable
                        defaultValue={this.state.type_product_id}
                        onChange={(e) => {
                          this.Inputcolor.clearValue();
                          this.Inputdesign.clearValue();
                          if (e === null) {
                            this.setState({ type_product_id: "", page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          } else {
                            this.setState({ type_product_id: e.value, page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* สี */}
                  <div className="col-6 col-sm-6 col-md-3  col-lg-2  col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <Select
                        ref={(click) => (this.Inputcolor = click)}
                        className="px-0"
                        options={
                          this.state.color &&
                          this.state.color.map((item) => ({
                            value: item.product_color,
                            label: item.product_color,
                          }))
                        }
                        components={{ Option: TypeOption }}
                        styles={customStylesTypeOption}
                        placeholder="แสดงทุกสี"
                        isClearable
                        defaultValue={this.state.product_color}
                        onChange={(e) => {
                          this.Inputdesign.clearValue();
                          if (e === null) {
                            this.setState({ product_color: "", page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          } else {
                            this.setState({ product_color: e.value, page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* ดีไซน์ */}
                  <div className="col-6 col-sm-6 col-md-3  col-lg-2  col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <Select
                        ref={(click) => (this.Inputdesign = click)}
                        className="px-0"
                        options={
                          this.state.design &&
                          this.state.design.map((item) => ({
                            value: item.product_design,
                            label: item.product_design,
                          }))
                        }
                        components={{ Option: TypeOption }}
                        styles={customStylesTypeOption}
                        placeholder="แสดงทุกดีไซน์"
                        isClearable
                        defaultValue={this.state.design_value}
                        onChange={(e) => {
                          if (e === null) {
                            this.setState({ design_value: "", page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          } else {
                            this.setState({ design_value: e.value, page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* สถานะ */}
                  <div className="col-6 col-sm-6 col-md-3  col-lg-2  col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <select
                        className="form-control-select pointer"
                        onChange={(e) => {
                          this.setState({ search: "", page: 1, status: e.target.value });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                        value={this.state.status}
                      >
                        <option value="" selected>
                          แสดงทุกสถานะ
                        </option>
                        <option value="0">LOW STOCK</option>
                        <option value="1">OUT OF STOCK</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-6 col-sm-6 col-md-2  col-lg-2  col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <button
                        type="button"
                        className="btn btn-outline-primary bg-white "
                        onClick={() => {
                          this.Input.clearValue();
                          this.Inputcolor.clearValue();
                          this.Inputdesign.clearValue();
                          this.setState({
                            page: 1,
                            search: "",
                            data: null,
                            status: "",
                            type_product_id: "",
                            warehouse_id: "",
                            sort_yard: "",
                            sort_name: "",
                            sort_color: "",
                            design_value: "",
                            color: "",
                          });
                          setTimeout(() => {
                            this.GetWareHouse();
                          }, 20);
                        }}
                      >
                        <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16" style={{ height: 500 }}>
                    <table className="table  table-sm table-striped table-borderless table-hover table-sm tableFixHead">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 70 }}>
                            <b>รหัสสินค้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 130 }}>
                            <b>ชื่อสินค้า</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20 pointer">
                                  <span className="icon-brand text-10 pointer"> {"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_name: "asc", page: 1 });

                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                A-Z
                              </MenuItem>

                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_name: "desc", page: 1 });

                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                ก-ฮ
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-center" style={{ minWidth: 70 }}>
                            <b>ประเภทผ้า</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 70 }}>
                            <b>สี</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20 pointer">
                                  <span className="icon-brand text-10 pointer"> {"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_color: "asc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                A-Z
                              </MenuItem>

                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_color: "desc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                ก-ฮ
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-center">
                            <b>ดีไซน์</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>แจ้งเตือนสินค้า</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 100 }}>
                            <b>ราคาหน้าร้าน</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 80 }}>
                            <b>โกดัง</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 125 }}>
                            <b>จำนวนหลา</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20 pointer">
                                  <span className="icon-brand text-10 pointer"> {"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_yard: "desc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                มากไปน้อย
                              </MenuItem>

                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_yard: "asc", page: 1 });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                น้อยไปมาก
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-right" style={{ minWidth: 80 }}>
                            <b>จำนวนไม้</b>
                          </th>

                          {role && (role.all_product[2] === 1 || role.all_product[3] === 1) && (
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={11} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.data_list.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left ">{item.product_main_data.product_code}</td>
                              <td className="text-left ">{item.product_main_data.product_name}</td>
                              <td className="text-center">{item.product_main_data.relation_type_product}</td>
                              <td className="text-center">{item.product_main_data.product_color}</td>
                              <td className="text-center">{item.product_main_data.product_design}</td>
                              <td className="text-center ">{number(item.product_main_data.safety_stock_noti)}</td>
                              <td className="text-right ">฿{baht(item.product_main_data.store_price)}</td>
                              <td className="text-center ">{item.warehouse_name}</td>
                              <td className="text-right ">{baht(item.yard_qty)}</td>
                              <td className="text-right ">
                                <span className={item.stock_qty === 0 ? "bg-total-2 text-white text-14 px-3 pt-1 pb-1 rounded" : item.stock_qty <= item.product_main_data.safety_stock_noti ? "bg-cash-4 text-dark text-14 px-3 pt-1 pb-1 rounded" : null}>{number(item.stock_qty)}</span>
                              </td>

                              <td className="text-center ">
                                {role && role.all_product[2] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      window.location.href = "/product/edit?id=" + item.product_main_data.product_id;
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf040"}</label>
                                  </button>
                                )}

                                {role && role.all_product[3] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      // this.GetStockAll(item.product_main_data.product_id)

                                      this.setState({
                                        product_name: item.product_main_data.product_name,
                                        id: item.product_main_data.product_id,
                                        modal_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf2ed"}</label>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.data_list.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0 my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={100} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal ลบสินค้า */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการลบสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className=" mb-3">ท่านแน่ในว่าต้องการลบสินค้า “{this.state.product_name}” หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
              {/* <div className="px-4 w-100" >
                                {this.state.data_stock && this.state.data_stock.data.map((item, index) => (
                                    <div className="row text-left" key={index}>
                                        <p className="mr-2">ชื่อโกดัง: {item.warehouse_name} </p>
                                        <p >คงเหลือ: {item.stock_qty} ไม้ <label className="text-detail">|</label> คงเหลือ: {baht(item.yard_qty)} หลา</p>
                                    </div>
                                ))}
                            </div> */}
              <div className="text-danger mb-3">
                <b>หมายเหตุ:</b> หากมีสินค้าอยู่ในสต๊อกโกดังใดโกดังหนึ่งจะไม่สามารถสิบสินค้านี้ได้
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
