import NavbarComponent from "../../components/Navbarmain";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { Modal } from "react-bootstrap";
import { alert, GET, POST, PUT, tokens, status_payment, date, ModalDetails, report_3, report_6, report_2, baht, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

export default class TransactionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_detail: false,

      data: null,

      search: "",
      bank_name: "",
      bank_NO: "",
      branch: "",
      id: "",

      status_order_main: "",

      start_date: "",
      end_date: "",

      order_main_number: "",
      text_cancle: "",

      data_detail: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }

    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
      status_order_main: this.state.status_order_main,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    let result = await POST(tokens, "v1/pos-mgt/transaction/filter/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  Get_by_id = async (id, text) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + id, null);

    if (result && result.status) {
      let data = result.data.data;
      let total_yard = 0;
      let total_wood = 0;
      for (let i = 0; i < data.order_list_data.length; i++) {
        let sum_yard = 0;
        for (let sum of data.order_list_data[i].detail_yard_list) {
          sum_yard += Number(sum);
        }
        data.order_list_data[i].yard_qty = Number(sum_yard);
        total_yard += data.order_list_data[i].yard_qty;
        total_wood += data.order_list_data[i].qty;
        data.order_list_data[i].product_main = {};
        data.order_list_data[i].product_main.product_main_data = data.order_list_data[i].product_main_data;
      }
      data.total_yard = total_yard;
      data.total_wood = total_wood;

      this.setState({ data_detail: data });

      if (text === "ใบกำกับภาษี" || text === "ใบเสร็จ" || text === "ใบส่งสินค้าชั่วคราว") {
        let data_number = {
          tax_invoice_number: result.data.data.tax_invoice_number,
          input_vat_number: result.data.data.input_vat_number,
          ab_number: result.data.data.ab_number,
          est_number: result.data.data.est_number,
        };
        let data = {
          detail_print: result.data.data,
          order_list: result.data.data.order_list_data,
          member: result.data.data.member_data,
          machine_profile: result.data.data.admin_data.full_name,
          data_number: data_number,
        };

        if (data && text === "ใบกำกับภาษี") {
          report_3(data, "Transaction");
          this.setState({ loading: false });
        } else if (data && text === "ใบส่งสินค้าชั่วคราว") {
          report_2(data, "sale");
          this.setState({ loading: false });
        } else {
          report_6(data, "Transaction");
        }
      } else {
        setTimeout(() => {
          this.setState({
            modal_detail: true,
          });
        }, 10);
      }

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  Retry = async (id) => {
    window.location.href = "/main?retry_id=" + id;
  };

  Delete = async () => {
    this.setState({ loading: true });
    let body = {
      order_main_id: this.state.id,
      remark_cancel: this.state.text_cancle,
    };
    let result = await PUT(tokens, "v1/pos-mgt/order/cancel", body);
    if (result && result.status) {
      alert("success", "ยกเลิกรายการเรียบร้อยแล้ว", "ท่านยังสามารถสั่งรายการแบบเดิมได้โดยกดที่ปุ่มเมนู", "“สั่งอีกครั้ง”");

      this.setState({ loading: false, modal_delete: false });

      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      search: "",
      bank_name: "",
      bank_NO: "",
      branch: "",
      id: "",

      status_order_main: "",

      start_date: "",
      end_date: "",

      order_main_number: "",
      text_cancle: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="w-100 ">
          <NavbarComponent
            onChange={(e) => {
              this.setState({ status_bill: e });
            }}
          />
          <div style={{ height: 60 }}></div>

          <div className="mx-3 mt-3 mb-3">
            {/* header */}
            <div className="card w-100 p-4">
              <div className="mx-0 w-100 row justify-content-between">
                <h3>
                  <b>รายการธุรกรรม</b>
                </h3>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 "
                    onClick={() => {
                      window.location.href = "/main";
                    }}
                  >
                    <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ
                  </button>
                </div>
              </div>
            </div>

            {/* table */}
            <div className="w-100 card mt-3 p-3">
              <div className="card rounded">
                <div className="row d-flex ">
                  <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 d-flex justify-content-end">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control border-0 wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          onChange={(e) => {
                            this.setState({
                              start_date: e.target.value,
                              page: 1,
                            });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control border-0 wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1 pointer"
                          onClick={() => {
                            this.setState({
                              page: 1,
                              search: "",
                              data: null,
                              start_date: "",
                              end_date: "",
                            });

                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4 col-lg-4 col-xl-4 d-flex ">
                    <select
                      className="form-control  border-0 mr-1 mb-1 mt-1 pointer"
                      value={this.state.status_order_main}
                      onChange={(e) => {
                        this.setState({
                          status_order_main: Number(e.target.value),
                          page: 1,
                        });

                        setTimeout(() => {
                          this.GetAll();
                        }, 20);
                      }}
                    >
                      <option value="all" selected>
                        ทุกสถานะ
                      </option>
                      <option value="2">ชำระเงินแล้ว</option>
                      <option value="3">ยกเลิก (คืนสินค้า)</option>
                    </select>

                    <input
                      maxLength={30}
                      className="form-control border-0 ml-1 mb-1 mt-1"
                      type="search"
                      placeholder="พิมพ์คำค้นหา"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ page: 1, search: e.target.value, loading: false });
                        if (e.target.value === "") {
                          this.setState({ search: "" });
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        } else {
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="card-body p-0 mt-3">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead className="thead-dark-gray">
                        <tr>
                          <th className="text-left">
                            <b>เลขที่รายการ</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 170 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-center">
                            <b>รายการ</b>
                          </th>
                          <th className="text-left">
                            <b>รหัสลูกค้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>ชื่อลูกค้า</b>
                          </th>
                          <th className="text-left">
                            <b>เลขที่ POS</b>
                          </th>
                          <th className="text-right">
                            <b>ยอดรวม</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 150 }}>
                            <b>สถานะ</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>จัดการ</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={9} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td className="text-left">{item.ab_number}</td>
                              <td className="text-left">{date(item.datetime_create)}</td>
                              <td className="text-center">{item.count_order}</td>
                              <td className="text-left">{item.member_data.user_code}</td>
                              <td className="text-left">{item.member_data.full_name}</td>
                              <td className="text-left">{item.machine_number}</td>
                              <td className="text-right">{baht(item.total_price_final)}</td>
                              <td className="text-center">{status_payment(item.status_order_main)}</td>
                              <td className="text-center">
                                <Menu
                                  menuButton={
                                    <button className="btn btn-link text-dark">
                                      <h3 className="icon">{"\uf141"}</h3>
                                    </button>
                                  }
                                  transition
                                >
                                  {item.status_order_main !== 3 && (
                                    <>
                                      {item.vat === 0 ? (
                                        <MenuItem
                                          onClick={async () => {
                                            this.Get_by_id(item.order_main_id, "ใบส่งสินค้าชั่วคราว");
                                          }}
                                        >
                                          <span className="icon mr-2 h2">{"\uf02f"}</span>
                                          พิมพ์ใบส่งสินค้าชั่วคราว
                                        </MenuItem>
                                      ) : (
                                        <MenuItem
                                          onClick={async () => {
                                            this.Get_by_id(item.order_main_id, "ใบกำกับภาษี");
                                          }}
                                        >
                                          <span className="icon mr-2 h2">{"\uf02f"}</span>
                                          พิมพ์ใบกำกับภาษี
                                        </MenuItem>
                                      )}

                                      {/* <MenuItem
                                                                            onClick={async () => {
                                                                                this.setState({
                                                                                    modal_delete: true,
                                                                                    id: item.order_main_id,
                                                                                    order_main_number: item.order_main_number,
                                                                                    text_cancle: "",

                                                                                })
                                                                            }}

                                                                        >
                                                                            <span className="icon mr-2 h2">{"\uf057"}</span>
                                                                            ยกเลิกรายการ
                                                                        </MenuItem> */}

                                      <MenuItem
                                        onClick={async () => {
                                          this.Get_by_id(item.order_main_id, "ใบเสร็จ");
                                        }}
                                      >
                                        <span className="icon mr-2 h2">{"\uf02f"}</span>
                                        ใบเสร็จ
                                      </MenuItem>
                                    </>
                                  )}

                                  <MenuItem
                                    onClick={async () => {
                                      this.Get_by_id(item.order_main_id, "");
                                    }}
                                  >
                                    <span className="icon mr-2">{"\uf15c"}</span>
                                    รายละเอียด
                                  </MenuItem>
                                </Menu>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0 my-4">
                  <div className="col-12 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label className="pointer">
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal ยกเลิกรายการ */}
        <Modal
          Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยกเลิกรายการ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการยกเลิกรายการ “{this.state.order_main_number}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
              {/* การยกเลิก */}
              <div className="w-100 mb-3">
                <textarea
                  className="form-control border-0"
                  placeholder="สาเหตุในการยกเลิก"
                  value={this.state.text_cancle}
                  onChange={(e) => {
                    this.setState({ text_cancle: e.target.value });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn  btn-outline-primary  w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ย้อนกลับ{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger  w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ยืนยัน{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียด modal */}
        <ModalDetails
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />
      </div>
    );
  }
}
