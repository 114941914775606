import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, DELETE, tokens, role, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class RoleScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_delete: false,

      data: null,

      search: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/user-admin/role/filter-search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };
  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "/v1/user-admin/role/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลบทบาทผู้ใช้เรียบร้อยแล้ว", "บทบาทผู้ใช้ ' " + this.state.role_name + " ' ถูกลบข้อมูลในระบบแล้ว");
      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  ClearForm() {
    this.setState({
      loading: false,
      search: "",
      page: 1,
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="บทบาทผู้ใช้" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>บทบาทผู้ใช้</b>
              </h3>
              {role && role.role[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/role/create";
                    }}
                  >
                    {" "}
                    เพิ่มบทบาท{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        maxLength={30}
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>บทบาท</b>
                          </th>
                          <th className="text-left">
                            <b>รายละเอียด</b>
                          </th>
                          {((role && role.role[2] === 1) || (role && role.role[3] === 1)) && (
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={3} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.role_name}</td>
                              <td className="text-left">{item.role_description}</td>
                              <td className="text-center">
                                {role && role.role[2] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      window.location.href = "/role/edit?id=" + item.role_id;
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf040"}</label>
                                  </button>
                                )}
                                {role && role.role[3] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        role_name: item.role_name,
                                        id: item.role_id,
                                        modal_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf2ed"}</label>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการลบบทบาท</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการลบบทบาท" {this.state.role_name} "</div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวาร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary  w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
