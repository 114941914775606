/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { GET, POST, tokens, status_adjustment, baht } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";

export default class StockAdjustmentDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_add: false,

      data: null,
      index: 0,

      document_code: "",

      date: "",
      profile: JSON.parse(localStorage.getItem("profile")),

      decision_status: null,

      warehouselist: null,
      product_list: null,
      warehouse_id: "",
      product: null,

      qty: 0,
      yard: 0,

      full_name: "",
      remark_title: "",
      remark_status: "",
      status: "0",

      id: this.props.location.query.id,

      action_by: "",
      user_approve: "",
    };
  }
  async componentDidMount() {
    await this.GetWareHouse();
    await this.Get_by_id(this.props.location.query.id);
  }

  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/stock-mgt/adjust/detail/" + id, null);
    if (result && result.status) {
      let date = result.data.data.datetime_create;
      let warehouse_id = this.state.warehouselist.find((e) => e.warehouse_name === result.data.data.warehouse_name).warehouse_id;
      let datetimes = date.split("T")[0].split("-")[2] + "/" + ("0" + Number(date.split("-")[1].split("/")[0])).toString().slice(-2) + "/" + (Number(date.split("T")[0].split("-")[0]) + 543).toString().slice(2, 4);

      setTimeout(() => {
        this.setState({
          warehouse_id: warehouse_id,
          remark_title: result.data.data.remark,
          full_name: result.data.data.action_by,
          document_code: result.data.data.document_code,
          date: datetimes,
          data: result.data.data.data_list,
          decision_status: result.data.data.decision_status,
          decision_remark: result.data.data.decision_remark,
          action_by: result.data.data.action_by,
          user_approve: result.data.data.user_approve,
        });
      }, 10);
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  GetWareHouse = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);

    if (result && result.status) {
      this.setState({ warehouselist: result.data.data });
    }
    this.setState({ loading: false });
  };

  ClearDataProduct() {
    this.setState({
      product: null,
    });
  }

  ClearForm() {
    this.setState({
      loading: false,
      remark_title: "",
      warehouse_id: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="รายการปรับสต๊อก" page3="รายละเอียดการปรับสต๊อก" />
            <div style={{ height: 60 }}></div>
            <div className="mx-5 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between ">
                <h3>
                  <b>รายละเอียดการปรับสต๊อก</b>
                </h3>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 ml-2"
                    onClick={() => {
                      window.location.href = "/stock-adjustment";
                    }}
                  >
                    <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ
                  </button>
                </div>
              </div>

              {/* title */}
              <div className="w-100 mx-0 mt-3">
                <div className="card p-3">
                  <div className="d-flex row align-items-center ">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3  d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">ผู้ปรับสต๊อก:</label>
                        <input type="text" className="form-control border-0 pointer" value={this.state.full_name} readOnly></input>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-flex mb-3 ">
                      <div className="w-100 ">
                        <label className="w-100">เลขที่เอกสาร:</label>
                        <input type="text" className="form-control w-100 border-0 pointer" value={this.state.document_code} readOnly></input>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-flex mb-3">
                      <div className="w-100">
                        <label className="w-100">วันที่:</label>
                        <input type="text" className="form-control w-100 border-0 pointer" value={this.state.date} readOnly></input>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">สถานที่:</label>
                        <select
                          className="form-control w-100 border-0 pointer"
                          onChange={(e) => {
                            this.setState({
                              warehouse_id: e.target.value,
                            });
                          }}
                          value={this.state.warehouse_id}
                          disabled={this.state.data === null || this.state.data.length === 0 ? "" : "disabled"}
                        >
                          <option value="" selected disabled>
                            -- เลือก --
                          </option>
                          {this.state.warehouselist &&
                            this.state.warehouselist.map((item, index) => (
                              <option key={index} value={item.warehouse_id}>
                                {item.warehouse_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex row align-items-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">หมายเหตุการปรับ:</label>
                        <input
                          type="text"
                          className="form-control w-100 border-0"
                          value={this.state.remark_title}
                          placeholder="อธิบายพอสังเขป"
                          onChange={(e) => {
                            this.setState({ remark_title: e.target.value });
                          }}
                          readOnly
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* table */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card" style={{ marginBottom: 32 }}>
                  <div className="card-header rounded-top-16">
                    <div className="w-100 d-flex justify-content-between align-items-center pt-2">
                      <h5>
                        <b>สินค้าที่ต้องการปรับ</b>
                      </h5>
                    </div>
                  </div>
                  <div className="card-body ">
                    <div className="table-responsive rounded-16 tableFixHead ">
                      <table className="table table-striped table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 90 }}>
                              <b>รหัสสินค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>จำนวนในคลัง(ไม้)</b>
                              </th>
                            )}
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนนับจริง(ไม้)</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <>
                                <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                  <b>ปรับจำนวน(ไม้)</b>
                                </th>
                                <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                  <b>จำนวนในคลัง(หลา)</b>
                                </th>
                              </>
                            )}
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนนับจริง(หลา)</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>ปรับจำนวน(หลา)</b>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.length === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={9} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className="text-left">{item.product_code}</td>
                                <td className="text-left">{item.product_name}</td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{item.real_stock_qty}</td>}
                                <td className="text-center">{item.total_qty}</td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                                  <>
                                    <td className="text-center">{this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system" ? item.qty : (this.state.profile.user_type !== "owner" || this.state.profile.user_type !== "admin_system") && item.qty.toString().includes("-") ? item.qty.toString().slice(1) : item.qty}</td>
                                  </>
                                )}
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{baht(item.real_stock_yard_qty)}</td>}
                                <td className="text-center">{baht(item.total_yard_qty)}</td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system" ? baht(item.yard_qty) : (this.state.profile.user_type !== "owner" || this.state.profile.user_type !== "admin_system") && item.yard_qty.toString().includes("-") ? item.yard_qty.toString().slice(1) : item.yard_qty}</td>}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="d-flex  mr-4 ">
              <label>หมายเหตุ: {this.state.decision_remark ? this.state.decision_remark : "-"}</label>
            </div>
            <div className="d-flex  mr-4 ">
              <label>ผู้อนุมัติ: {this.state.user_approve ? this.state.user_approve : "-"}</label>
            </div>
            <div className="d-flex  ">
              <label>
                <b>{status_adjustment(this.state.decision_status)}</b>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
