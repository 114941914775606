import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { POST, tokens, baht, total, report_products, alert } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class ReportSalesProductScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,

      data: [],
      data_year: [],

      year: "",
      start_date: "",
      end_date: "",
    };
  }
  async componentDidMount() {
    let year = [];
    for (let i = 0; i < 10; i++) {
      year.push(new Date().getFullYear() - i);
    }
    this.setState({ data_year: year });
  }
  GetAll = async () => {
    this.setState({ loading: true });
    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }

    let body = {
      year: this.state.year ? Number(this.state.year) : 0,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };

    let result = await POST(tokens, "v1/report/sale-product-service/summary/page/0", body);
    if (result && result.status) {
      let data = result.data.data;
      for (let i = 0; i < data.length; i++) {
        data[i].wood_qty = total(data[i].product_list, "wood_qty");
        data[i].yard_qty = total(data[i].product_list, "yard_qty");
        data[i].amount = total(data[i].product_list, "amount");
        data[i].sale_percent = total(data[i].product_list, "sale_percent");
        data[i].cos = total(data[i].product_list, "cost");
      }

      this.setState({ data: data });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      year: "",
      start_date: "",
      end_date: "",
      data: [],
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายงานผลรวมยอดขาย" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายงานผลรวมยอดขาย</b>
              </h3>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    if (this.state.data.length === 0) {
                      alert("warning", "แจ้งเตือน", "ไม่มีข้อมูลรายงาน");
                      return;
                    }
                    report_products(this.state.data, this.state.start_date, this.state.end_date, this.state.year);
                  }}
                >
                  <span className="icon-brand text-18 ">{"\uf6dd"}</span> ออกรายงาน
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="d-flex row mx-0 justify-content-between w-100">
                    <div className="d-flex row mx-0 align-items-center">
                      <select
                        className="form-control-select wpx-180 mx-2 mb-2 pointer"
                        onChange={(e) => {
                          this.setState({
                            year: e.target.value,
                          });
                        }}
                        value={this.state.year}
                      >
                        <option value="0">เลือกปี</option>
                        {this.state.data_year.map((item, index) => (
                          <option value={item}>ปี {item + 543}</option>
                        ))}
                      </select>
                      <input
                        type="date"
                        className="form-control-select wpx-180 mx-2 mb-2 pointer"
                        placeholder="วันที่เริ่มต้น"
                        onChange={(e) => {
                          this.setState({
                            start_date: e.target.value,
                          });
                        }}
                        value={this.state.start_date}
                      ></input>
                      <label className="mx-2 mb-2">ถึง</label>
                      <input
                        type="date"
                        className="form-control-select wpx-180 mx-2 mb-2 pointer"
                        placeholder="วันที่สิ้นสุด"
                        onChange={(e) => {
                          const date_start_date = new Date(this.state.start_date).getTime();
                          const date_end_date = new Date(e.target.value).getTime();

                          if (date_start_date <= date_end_date) {
                            this.setState({ end_date: e.target.value });
                          }
                        }}
                        value={this.state.end_date}
                      ></input>
                      <button
                        type="button"
                        className="btn btn-primary ml-2 mb-1"
                        onClick={() => {
                          this.GetAll();
                        }}
                      >
                        {" "}
                        ค้นหา
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary bg-white mx-2 mb-2 pointer"
                        onClick={() => {
                          this.ClearForm();
                        }}
                      >
                        <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>Product</b>
                          </th>
                          <th className="text-right">
                            <b>ไม้</b>
                          </th>
                          <th className="text-right">
                            <b>หลา</b>
                          </th>
                          <th className="text-right">
                            <b>Amount</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>% of Sales</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>AVG Price</b>
                          </th>
                          <th className="text-right">
                            <b>Cos</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>Gross Margin</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>Gross Margin %</b>
                          </th>
                        </tr>
                      </thead>

                      {((this.state.data && this.state.data.length === 0) || !this.state.data) && (
                        <tbody>
                          <tr>
                            <td colSpan={9} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {this.state.data &&
                        this.state.data.map((item, index) => (
                          <tbody>
                            <tr
                              key={"dt" + index}
                              className="table-light pointer"
                              onClick={() => {
                                let data = this.state.data;
                                if (data[index].expand) {
                                  data[index].expand = !data[index].expand;
                                } else {
                                  data[index].expand = true;
                                }
                                this.setState({ data: data });
                              }}
                            >
                              <td className="text-left">
                                {item.type_product_name}
                                <span className="icon ml-2">{item.expand ? "\uf077" : "\uf078"}</span>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {item.expand &&
                              item.product_list.map((product, product_index) => (
                                <tr key={"pd" + product_index}>
                                  <td className="text-left">{product.product_name}</td>
                                  <td className="text-right">{product.wood_qty}</td>
                                  <td className="text-right">{baht(product.yard_qty)}</td>
                                  <td className="text-right">{baht(product.amount)}</td>
                                  <td className="text-right">{product.sale_percent} %</td>
                                  <td className="text-right">{baht(product.avg_price)}</td>
                                  <td className="text-right">{baht(product.cost)}</td>
                                  <td className="text-right">{baht(product.gross_margin)}</td>
                                  <td className="text-right">{product.gross_margin_percent}</td>
                                </tr>
                              ))}
                            {item.expand && (
                              <tr key={index} className=" table-light  pointer">
                                <td className="text-left">
                                  <b>ยอดรวม</b>
                                </td>
                                <td className="text-right">
                                  <b>{item.wood_qty}</b>
                                </td>
                                <td className="text-right">
                                  <b>{baht(item.yard_qty)}</b>
                                </td>
                                <td className="text-right">
                                  <b>{baht(item.amount)}</b>
                                </td>
                                <td className="text-right">
                                  <b>{baht(item.sale_percent)} %</b>
                                </td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                              </tr>
                            )}
                          </tbody>
                        ))}
                      <tfoot>
                        <td className="text-left">
                          <b>ยอดรวมทั้งหมด</b>
                        </td>
                        <td className="text-right">
                          <b>{total(this.state.data, "wood_qty")}</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "yard_qty"))}</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "amount"))}</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "sale_percent"))} %</b>
                        </td>
                        <td className="text-right"></td>
                        <td className="text-right"></td>
                        <td className="text-right"></td>
                        <td className="text-right"></td>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
