import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { POST, tokens, baht, total, alert } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class ReportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,
      data: null,
      search: "",
      modal_add: false,

      type: "Cash",

      start_date: "",
      end_date: "",
    };
  }
  async componentDidMount() {
    // await this.GetAll()
  }

  GetAll = async () => {
    this.setState({ loading: true });

    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }

    let body = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    let result = await POST(tokens, "v1/report/summary/page/0", body);
    if (result && result.status) {
      let data = result.data;

      this.setState({ data: data });
    }
    this.setState({ loading: false });
  };
  ClearForm() {
    this.setState({
      page: 1,
      type: "Cash",
      start_date: "",
      end_date: "",
      data: null,
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="สรุปรายงาน" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายงาน</b>
              </h3>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-xl-12 d-flex">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({
                              start_date: e.target.value,
                            });
                          }}
                          value={this.state.start_date}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 pointer mb-1 mt-1"
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ end_date: e.target.value });
                            }
                          }}
                          value={this.state.end_date}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-primary ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.GetAll();
                          }}
                        >
                          {" "}
                          ค้นหา
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.ClearForm();
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" w-100 mt-3 card mx-0">
                <div className="row justify-content-between d-flex w-100 p-3 mx-0">
                  <p className="mb-0">
                    <b>Column</b>
                  </p>
                  <p className="mb-0">
                    <b>Total</b>
                  </p>
                </div>
              </div>

              <div className=" w-100 mt-3 card mx-0">
                {this.state.data &&
                  this.state.data.data.map((item, index) => (
                    <>
                      {item.topic_name.includes("Accounts Receivable (A/R)") && (
                        <>
                          <div className="row justify-content-between d-flex w-100 p-3 mx-0" key={index}>
                            <p className="mb-0">{item.topic_name}</p>
                            <p className="mb-0">฿{baht(item.total_amount)}</p>
                          </div>
                          <div className="row justify-content-between d-flex w-100 p-3 mx-0">
                            <p className="mb-0">
                              <b>Total Accounts receivable</b>
                            </p>
                            <p className="mb-0">
                              <b>฿{baht(item.total_amount)}</b>
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ))}

                {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                  <div className="row justify-content-between d-flex w-100 p-3 mx-0">
                    <p className="mb-0">
                      <b>Total Accounts receivable</b>
                    </p>
                    <p className="mb-0">
                      <b>0.00</b>
                    </p>
                  </div>
                )}
              </div>

              <div className="w-100 mt-3">
                <div className="card">
                  <div className={this.state.data && this.state.data.length === 0 ? "card-body p-0 height-vh" : "card-body p-0 "}>
                    <div className="table-responsive rounded-16">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="text-left">
                              <b>Column</b>
                            </th>
                            <th className="text-right">
                              <b>จำนวนครั้งที่โอน</b>
                            </th>
                            <th className="text-right">
                              <b>Total</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={3} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-left">{item.topic_name}</td>
                                <td className="text-right">{item.transfer_qty}</td>
                                <td className="text-right">฿{baht(item.total_amount)}</td>
                              </tr>
                            ))}
                        </tbody>

                        <tfoot>
                          <td className="text-left">
                            <b>Total Current Assets</b>
                          </td>
                          <td className="text-right">{this.state.data && this.state.data.data ? total(this.state.data.data, "transfer_qty") : "0"}</td>
                          <td className="text-right">
                            <b>฿{this.state.data && this.state.data.data ? baht(total(this.state.data.data, "total_amount")) : "0.00"}</b>
                          </td>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
