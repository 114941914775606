import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { GET, tokens, baht } from "../../components/CustomComponent.js";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import "../../assets/css/bootstrap.css";

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
};

export default class Dashboardscreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,
      data: null,
      search: "",
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      total_sale_by_month: "",
      invoices: "",

      data_year: [],

      start_date: "",
      end_date: "",

      year: "",
      year_bank: "",

      data_invoices: [],
      expenses_graph: [],

      total_expenses: "",

      fillter_sale: 1,
      fillter_invoice: 1,
      fillter_invoice_duedate: 1,
      fillter_bank_accounts: 1,
      fillter_expenses_filter: 1,
      profit_and_loss_filter: 1,
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    let year = [];
    for (let i = 0; i < 10; i++) {
      year.push(new Date().getFullYear() - i + 543);
    }
    this.setState({ data_year: year });

    await this.GetAll();
    this.setState({ loading: false });
  }
  GetAll = async () => {
    this.setState({ loading: true });

    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }

    let result = await GET(tokens, "v1/report/dashboard?sale_filter=" + this.state.fillter_sale + "&invoices_filter=1&expenses_filter=" + this.state.fillter_expenses_filter + "&bank_filter=" + this.state.year_bank + "&invoices_start_date=" + this.state.start_date + "&invoices_end_date=" + this.state.end_date + "&year_filter=" + this.state.year + "&profit_and_loss_filter=" + this.state.profit_and_loss_filter, null);

    if (result && result.status) {
      let data = result.data;
      let invoices = data.data.invoices;
      let labels = [];
      let datasets = [];
      let data_invoice = [];
      let color = ["#8044FF", "#E8B951", "#448FFF", "#0D9D35", "#BD583D", "#E98432", "#F60000"];
      let array_invoice = [
        {
          invoices: invoices.invoice_sb1,
          count: invoices.count_sb1,
        },
        {
          invoices: invoices.invoice_sb7,
          count: invoices.count_sb7,
        },
        {
          invoices: invoices.invoice_sb2,
          count: invoices.count_sb2,
        },
        {
          invoices: invoices.invoice_sb3,
          count: invoices.count_sb3,
        },
        {
          invoices: invoices.invoice_sb4,
          count: invoices.count_sb4,
        },
        {
          invoices: invoices.invoice_sb5,
          count: invoices.count_sb5,
        },
        {
          invoices: invoices.invoice_sb6,
          count: invoices.count_sb6,
        },
      ];
      let color_array = ["#213363", "#17594A", "#8EAC50", "#D3D04F", "#884A39", "#F4A548", "#F6D198", "#F35588", "#FFBBB4", "#5D414D", "#7E858B", "#ABD4C1", "#E5F6C6", "#F4806D", "#C02727", "#7C83FD", "#96BAFF", "#7DEDFF", "#8BCDCD", "#40513B"];
      //กราฟเส้นของsale
      for (let i = 0; i < data.data.total_sale_by_month.length; i++) {
        let l = data.data.total_sale_by_month[i].month;
        let d = data.data.total_sale_by_month[i].total_sales;
        labels.push(l);
        datasets.push(d);
      }
      let total_sale_by_month = {
        labels: labels,
        datasets: [
          {
            fill: false,
            lineTension: 0.5,
            borderColor: "rgba(0,0,0,1)",
            borderWidth: 2,
            data: datasets,
          },
        ],
      };
      //กราฟวงกลมของinvoice
      let invoices_graph = {
        labels: ["ใบเสนอราคา", "รอดำเนินการ", "เปิดบิล", "ปิดบิล", "เกินกำหนด", "เกินกำหนด/ใบแจ้งหนี้", "ยกเลิก"],
        datasets: [
          {
            backgroundColor: ["#8044FF", "#E8B951", "#448FFF", "#0D9D35", "#BD583D", "#E98432", "#F60000"],
            data: [invoices.invoice_sb1, invoices.invoice_sb7, invoices.invoice_sb2, invoices.invoice_sb3, invoices.invoice_sb4, invoices.invoice_sb5, invoices.invoice_sb6],
          },
        ],
      };
      let expenses_name = data.data.expenses;
      let expenses_array = [];
      let index = 0;
      let graph_labels = [];
      let graph_color = [];
      let graph_value = [];
      for (let i = 0; i < expenses_name.length; i++) {
        let data_expenses = {
          name_expenses: expenses_name[i].name_expenses,
          value_expenses: expenses_name[i].value_expenses,
          color: color_array[index],
        };

        expenses_array.push(data_expenses);
        graph_labels.push(expenses_name[i].name_expenses);
        graph_color.push(color_array[index]);
        graph_value.push(expenses_name[i].value_expenses);

        if (index < 20) {
          index += 1;
        } else {
          index = 0;
        }
      }

      //กราฟวงกลมของค่าใช้จ่าย
      let expenses_graph = {
        labels: graph_labels,
        datasets: [
          {
            backgroundColor: graph_color,
            data: graph_value,
          },
        ],
      };

      // รายละเอียดของกราฟวงกลมinvoice
      for (let i = 0; i < array_invoice.length; i++) {
        for (let a = 0; a < invoices_graph.labels.length; a++) {
          for (let y = 0; y < color.length; y++) {
            let set_graph_invoice = {
              invoices: array_invoice[i].invoices,
              count: array_invoice[i].count,
              color: color[i],
              text: invoices_graph.labels[i],
            };
            data_invoice.push(set_graph_invoice);
            break;
          }
          break;
        }
      }

      this.setState({
        data: data,
        total_expenses: data.total_expenses,
        total_sale_by_month: total_sale_by_month,
        invoices: invoices_graph,
        data_invoices: data_invoice,
        data_expenses: expenses_array,
        expenses_graph: expenses_graph,
      });
    }

    this.setState({ loading: false });
  };

  ClearForm() {
    this.setState({
      page: 1,
      loading: false,
      data: [],

      start_date: "",
      end_date: "",
      year: "",
      year_bank: "",

      modal_add: false,
      modal_edit: false,
      modal_delete: false,
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="แดชบอร์ด" />
            <div style={{ height: 60 }}></div>

            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>แดชบอร์ด</b>
              </h3>
              <div className="d-flex">
                <select
                  className="form-control-select wpx-180 mx-2 mb-2 border border-1 pointer"
                  onChange={(e) => {
                    this.setState({
                      year: e.target.value,
                    });
                    setTimeout(() => {
                      this.GetAll();
                    }, 10);
                  }}
                  value={this.state.year}
                >
                  <option value="">เลือกปี</option>
                  {this.state.data_year.map((item, index) => (
                    <option value={item}>ปี {item}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="row text-white">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-3">
                  <div className="card bg-cash-1 p-4 w-100 h-100">
                    <span className="icon-brand text-26 mb-2">{"\uf555"}</span>
                    <h5 className="mb-1">
                      <b>฿{this.state.data && this.state.data.data.income ? baht(this.state.data.data.income) : "0.00"}</b>
                    </h5>
                    <small className="text-14">รายได้</small>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-3">
                  <div className="card bg-cash-2 p-4 w-100 h-100">
                    <span className="icon-brand text-26 mb-2">{"\uf555"}</span>
                    <h5 className="mb-1">
                      <b>฿{this.state.data && this.state.data.data.total_expenses ? baht(this.state.data.data.total_expenses) : "0.00"}</b>
                    </h5>
                    <small className="text-14">ค่าใช้จ่าย</small>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-3">
                  <div className="card bg-cash-3 p-4 w-100 h-100">
                    <span className="icon-brand text-26 mb-2">{"\uf555"}</span>
                    <h5 className="mb-1">
                      <b>฿{this.state.data && this.state.data.data.profit ? baht(this.state.data.data.profit) : "0.00"}</b>
                    </h5>
                    <small className="text-14">กำไร</small>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-3">
                  <div className="card bg-cash-4 p-4 w-100 text-dark h-100">
                    <span className="icon-brand text-26 mb-2">{"\uf555"}</span>
                    <h5 className="mb-1">
                      <b>฿{this.state.data && this.state.data.data.total_sale ? baht(this.state.data.data.total_sale) : "0.00"}</b>
                    </h5>
                    <small className="text-14">ยอดขาย</small>
                  </div>
                </div>
              </div>

              <hr />

              {/* dashboard */}
              <div className="row mt-3">
                <div className="col-12 col-md-6  col-xl-7 mb-3">
                  <div className="border border-grey rounded-16 p-3 h-100">
                    <div className="row justify-content-between px-3">
                      <p className="text-16 mb-1">
                        <b>ยอดขาย</b>
                      </p>
                      <select
                        className="form-control-select border border-1 wpx-150 mb-1 pointer"
                        onChange={(e) => {
                          this.setState({
                            fillter_sale: Number(e.target.value),
                          });

                          setTimeout(() => {
                            this.GetAll();
                          }, 10);
                        }}
                        value={this.state.fillter_sale}
                      >
                        <option value="1" selected>
                          All
                        </option>
                        <option value="2">Last 30 days</option>
                        <option value="3">This month</option>
                        <option value="4">This quarter</option>
                        <option value="5">This year</option>
                        <option value="6">Last month</option>
                        <option value="7">Last quarter</option>
                        <option value="8">Last year</option>
                      </select>
                    </div>
                    <h5 className="mb-1 text-primary">
                      <b>฿{this.state.data && this.state.data.data.total_sale ? baht(this.state.data.data.total_sale) : "0.00"}</b>
                    </h5>
                    <p className="text-msg mb-1 mb-3">ยอดขายรวม</p>
                    <hr />
                    {this.state.total_sale_by_month ? <Line data={this.state.total_sale_by_month} options={options} /> : <div className="text-center text-msg text-14">-- กำลังโหลด --</div>}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-5 mb-3">
                  <div className="border border-grey rounded-16 p-3 h-100">
                    <div className="row justify-content-between px-3">
                      <p className="text-16 mb-1">
                        <b>รายการขาย</b>
                      </p>
                      <div className="d-flex ml-2">
                        <input
                          type="date"
                          className="form-control-select border border-1 mb-2 pointer"
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({
                              start_date: e.target.value,
                            });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                          value={this.state.start_date}
                        ></input>
                        <label className="mx-2 mb-2 mt-2">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select border border-1 mb-2 pointer"
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ end_date: e.target.value });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                          value={this.state.end_date}
                        ></input>
                      </div>
                    </div>
                    <h5 className="mb-1 text-primary">
                      <b>฿{this.state.data && this.state.data.data.invoices.sum_invoice ? baht(this.state.data.data.invoices.sum_invoice) : "0.00"}</b>
                    </h5>
                    <p className="text-msg mb-1 mb-3">ยอดรวมรายการขาย</p>
                    <hr />
                    {this.state.invoices ? (
                      <div className=" d-flex justify-content-between">
                        <div className=" w-100 mr-3 ">
                          {this.state.data_invoices &&
                            this.state.data_invoices.map((item, index) => (
                              <div className="mb-3" key={index}>
                                <div className="d-flex align-items-center">
                                  <div className="rounded-circle mr-2 wpx-16 hpx-16" style={{ backgroundColor: item.color }} />
                                  <p className="mb-0">
                                    <b>฿{item.invoices ? baht(item.invoices) : "0.00"}</b>
                                  </p>
                                </div>
                                <div className="row justify-content-between px-3">
                                  <small className="text-msg text-12 text-left">{item.text}</small>
                                  <small className="text-msg text-12">|</small>
                                  <small className="text-msg text-12 text-right">{item.count} รายการ</small>
                                </div>
                                <hr className="my-1" />
                              </div>
                            ))}
                        </div>
                        <div className="chart-container">
                          <Doughnut data={this.state.invoices} options={options} />
                        </div>
                      </div>
                    ) : (
                      <div className="text-center text-msg text-14">-- กำลังโหลด --</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 col-md-6  col-xl-7">
                  <div className="border border-grey rounded-16 p-3 h-100">
                    <div className="row justify-content-between px-3">
                      <p className="text-16 mb-1">
                        <b>ค่าใช้จ่าย</b>
                      </p>
                      <select
                        className="form-control-select border border-1 wpx-150 mb-1 pointer"
                        onChange={(e) => {
                          this.setState({
                            fillter_expenses_filter: Number(e.target.value),
                          });

                          setTimeout(() => {
                            this.GetAll();
                          }, 10);
                        }}
                        value={this.state.fillter_expenses_filter}
                      >
                        <option value="1" selected>
                          All
                        </option>
                        <option value="2">Last 30 days</option>
                        <option value="3">This month</option>

                        <option value="9">quater one</option>
                        <option value="10">quater two</option>
                        <option value="11">quater three</option>
                        <option value="12">quater four</option>

                        <option value="5">This year</option>
                        <option value="6">Last month</option>
                        <option value="7">Last quarter</option>
                        <option value="8">Last year</option>
                      </select>
                    </div>
                    <h5 className="mb-1 text-primary">
                      <b>฿{this.state.data && this.state.data.data.sum_expenses ? baht(this.state.data.data.sum_expenses) : "0.00"}</b>
                    </h5>
                    <p className="text-msg mb-1 mb-3">ยอดรวมค่าใช้จ่าย</p>
                    <hr />

                    {this.state.data_expenses ? (
                      <div className=" d-flex justify-content-between ">
                        <div className="center mr-3 w-50">
                          <div className="chart-EXPENSES">
                            <Doughnut data={this.state.expenses_graph} options={options} />
                          </div>
                        </div>
                        <div className="overflow-auto w-60" style={{ maxHeight: "60vh", overflowX: "hidden" }}>
                          {this.state.data_expenses &&
                            this.state.data_expenses.map((item, index) => (
                              <div className="mb-3" key={index}>
                                <div className="d-flex align-items-center">
                                  <div className="rounded-circle mr-2 wpx-16 hpx-16" style={{ backgroundColor: item.color }} />
                                  <p className="mb-0">
                                    <b>฿{item.value_expenses ? baht(item.value_expenses) : "0.00"}</b>
                                  </p>
                                </div>
                                <div className="row justify-content-between px-3">
                                  <small className="text-msg text-12 text-left">{item.name_expenses}</small>
                                  <small className="text-msg text-12"></small>
                                  <small className="text-msg text-12 text-right"></small>
                                </div>
                                <hr className="my-1" />
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <div className="text-center text-msg text-14">-- กำลังโหลด --</div>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-5">
                  <div className="border border-grey rounded-16 p-3  mb-3">
                    <div className="row justify-content-between px-3  mb-3">
                      <p className="text-16 mb-1">
                        <b>กำไร ขาดทุน</b>
                      </p>
                      <select
                        className="form-control-select border border-1 wpx-150 pointer"
                        onChange={(e) => {
                          this.setState({
                            profit_and_loss_filter: Number(e.target.value),
                          });

                          setTimeout(() => {
                            this.GetAll();
                          }, 10);
                        }}
                        value={this.state.profit_and_loss_filter}
                      >
                        <option value="1" selected>
                          All
                        </option>
                        <option value="2">Last 30 days</option>
                        <option value="3">This month</option>

                        <option value="9">quater one</option>
                        <option value="10">quater two</option>
                        <option value="11">quater three</option>
                        <option value="12">quater four</option>

                        {/* <option value="4">This quarter</option> */}
                        <option value="5">This year</option>
                        <option value="6">Last month</option>
                        <option value="7">Last quarter</option>
                        <option value="8">Last year</option>
                      </select>
                    </div>

                    <div className="card bg-blue-dark p-2 w-100 align-items-center text-white mb-3">
                      <p>กำไร ขาดทุน</p>
                      <h5>
                        <b>฿{this.state.data && this.state.data.data.profit_and_loss ? baht(this.state.data.data.profit_and_loss.profit_and_loss) : "0.00"}</b>
                      </h5>
                      <small>รายได้สุทธิสำหรับปีนี้</small>
                    </div>
                    <div className="row text-white">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <div className="card bg-content-2 p-2 w-50 align-items-center w-100">
                          <span className="icon-brand text-20">{"\uf555"}</span>
                          <h6>
                            <b>฿{this.state.data && this.state.data.data.profit_and_loss ? baht(this.state.data.data.profit_and_loss.income) : "0.00"}</b>
                          </h6>
                          <small>รายได้</small>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="card bg-blue-dark p-2 w-50 align-items-center w-100">
                          <span className="icon-brand text-20">{"\uf555"}</span>
                          <h6>
                            <b>฿{this.state.data && this.state.data.data.profit_and_loss ? baht(this.state.data.data.profit_and_loss.expenses) : "0.00"}</b>
                          </h6>
                          <small>ค่าใช้จ่าย</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border border-grey rounded-16 p-3">
                    <div className="row  justify-content-between px-3">
                      <p className="text-16 mb-1">
                        <b>บัญชีธนาคาร</b>
                      </p>
                      <select
                        className="form-control-select wpx-180 mx-2 mb-2 border border-1 pointer"
                        onChange={(e) => {
                          this.setState({
                            year_bank: e.target.value,
                          });
                          setTimeout(() => {
                            this.GetAll();
                          }, 10);
                        }}
                        value={this.state.year_bank}
                      >
                        <option value="">เลือกปี</option>
                        {this.state.data_year.map((item, index) => (
                          <option value={item}>ปี {item}</option>
                        ))}
                      </select>
                    </div>
                    <div className="overflow-auto w-100" style={{ maxHeight: "25vh" }}>
                      {((this.state.data && this.state.data.data.bank_summery.length === 0) || !this.state.data) && <div className="text-center text-msg text-14">-- ไม่มีข้อมูล --</div>}
                      {this.state.data &&
                        this.state.data.data.bank_summery.map((item, index) => (
                          <div key={index} style={{ width: "95%" }}>
                            <small className="text-14">
                              {item.bank_name} {item.bank_number}
                            </small>
                            <div className="row justify-content-between px-3 align-items-center ">
                              <small className="text-msg text-12">จำนวน</small>
                              <small>
                                <b>{item.count} รายการ</b>
                              </small>
                            </div>
                            <div className="row justify-content-between px-3 align-items-center ">
                              <small className="text-msg text-12">รายได้สุทธิสำหรับปีนี้</small>
                              <small>
                                <b>฿{baht(item.total_sale)}</b>
                              </small>
                            </div>
                            <hr className="mt-1 mb-1" />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
