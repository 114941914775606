import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, DELETE, tokens, status_adjustment, role, format_date, number } from "../../components/CustomComponent.js";
import { Menu, MenuItem } from "@szhsin/react-menu";

import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

export default class StockAdjustmentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_delete: false,

      data: null,

      search: "",
      start_date: "",
      end_date: "",
      status: "",

      document_code: "",

      profile: JSON.parse(localStorage.getItem("profile")),
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
      start_date: "",
      end_date: "",
      decision_status: this.state.status ? Number(this.state.status) : "",
    };

    let result = await POST(tokens, "v1/stock-mgt/adjust/filter-search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "/v1/stock-mgt/adjust/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลการปรับสต๊อกสินค้าเรียบร้อยแล้ว", "การปรับสต๊อกสินค้า ' " + this.state.stock_code + " ' ถูกลบข้อมูลในระบบแล้ว");

      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();

      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      document_code: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายการปรับสต๊อก" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายการปรับสต๊อก</b>
              </h3>
              {role && role.adjust_stock[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/stock-adjustment/create";
                    }}
                  >
                    {" "}
                    ปรับสต๊อกสินค้า{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                  <div className="col-5 col-sm-4 col-xl-3 d-flex justify-content-between">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-5 col-sm-4 col-xl-3 d-flex justify-content-between">
                    <div className="w-100">
                      <select
                        className="form-control-select pointer"
                        onChange={(e) => {
                          this.setState({ status: e.target.value });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                        value={this.state.status}
                      >
                        <option value="" selected>
                          แสดงทุกสถานะ
                        </option>
                        <option value="1">Approved</option>
                        <option value="2">Reject</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table  table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>เลขที่เอกสาร</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>จากสถานที่</b>
                          </th>
                          <th className="text-center">
                            <b>หมายเหตุ</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 150 }}>
                            <b>วันที่แก้ไข</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>สถานะ</b>
                          </th>
                          {role && (role.adjust_stock[0] === 1 || role.adjust_stock[2] === 1 || role.adjust_stock[3] === 1) && (
                            <th className="text-center" style={{ minWidth: 100 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={8} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{format_date(item.datetime_create)}</td>
                              <td className="text-left">{item.document_code}</td>
                              <td className="text-left">{item.relation_warehouse}</td>
                              <td className={item.remark ? "text-left" : "text-center"}>{item.remark ? item.remark : "-"}</td>
                              <td className="text-left">{item.datetime_update ? format_date(item.datetime_update) : "-"}</td>
                              <td className="text-center">
                                <b>{status_adjustment(item.decision_status)}</b>
                              </td>
                              {role && (role.adjust_stock[0] === 1 || role.adjust_stock[2] === 1 || role.adjust_stock[3] === 1) && (
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-dark">
                                        <h3 className="icon">{"\uf141"}</h3>
                                      </button>
                                    }
                                    transition
                                  >
                                    {this.state.profile && this.state.profile.user_type === "owner" && (
                                      <>
                                        {role && (role.adjust_stock[0] === 1 || role.adjust_stock[2] === 1) && (
                                          <MenuItem
                                            onClick={() => {
                                              if ((role && role.adjust_stock[0] === 1 && role.adjust_stock[2] === 0) || item.decision_status === 1) {
                                                window.location.href = "/stock-adjustment/detail?id=" + item.stock_adjust_main_id;
                                              } else {
                                                window.location.href = "/stock-adjustment/examine?id=" + item.stock_adjust_main_id;
                                              }
                                            }}
                                          >
                                            <span className="icon mr-2 h2">{(role && role.adjust_stock[0] === 1 && role.adjust_stock[2] === 0) || item.decision_status === 1 ? "\uf570" : "\uf040"}</span>
                                            {(role && role.adjust_stock[0] === 1 && role.adjust_stock[2] === 0) || item.decision_status === 1 ? "ดูรายละเอียด" : "ตรวจสอบการปรับสต๊อก"}
                                          </MenuItem>
                                        )}
                                      </>
                                    )}

                                    {this.state.profile && this.state.profile.user_type !== "owner" && (
                                      <>
                                        {role && (role.adjust_stock[0] === 1 || role.adjust_stock[2] === 1) && (
                                          <MenuItem
                                            onClick={() => {
                                              if ((role && role.adjust_stock[0] === 1 && role.adjust_stock[2] === 0) || item.decision_status === 1) {
                                                window.location.href = "/stock-adjustment/detail?id=" + item.stock_adjust_main_id;
                                              } else {
                                                window.location.href = "/stock-adjustment/update?id=" + item.stock_adjust_main_id;
                                              }
                                            }}
                                          >
                                            <span className="icon mr-2 h2">{(role && role.adjust_stock[0] === 1 && role.adjust_stock[2] === 0) || item.decision_status === 1 ? "\uf570" : "\uf040"}</span>
                                            {(role && role.adjust_stock[0] === 1 && role.adjust_stock[2] === 0) || item.decision_status === 1 ? "ดูรายละเอียด" : "จัดการปรับสต๊อก"}
                                          </MenuItem>
                                        )}
                                      </>
                                    )}

                                    {role && role.adjust_stock[3] === 1 && (
                                      <MenuItem
                                        onClick={() => {
                                          this.setState({
                                            id: item.stock_adjust_main_id,
                                            stock_code: item.document_code,
                                            modal_delete: true,
                                          });
                                        }}
                                      >
                                        <span className="icon mr-2 h2">{"\uf2ed"}</span>
                                        ยกเลิก
                                      </MenuItem>
                                    )}
                                  </Menu>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100  justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal ลบรายการปรับสต๊อก */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ลบรายการปรับสต๊อก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ใจว่าต้องการลบรายการปรับสต๊อก “{this.state.stock_code}” นี้หรือไม่ </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
