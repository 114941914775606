/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable array-callback-return */
import NavbarComponent from "../../components/Navbarmain";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { alert, alert_url, GET, POST, PUT, DELETE, tokens, ModalPrint, role, format_date_main, total, baht, GET_CACHE, number } from "../../components/CustomComponent.js";
import Select, { components } from "react-select";
import NONE from "../../assets/images/none.png";
import CLOCK from "../../assets/images/clock.png";
import WARNING from "../../assets/images/warning.png";
import moment from "moment-timezone";
import "../../assets/css/bootstrap.css";
const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 75,
    padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const UserOption = (props) => (
  <Option {...props}>
    <h5>{props.data.full_name}</h5>
    <div className="w-100 d-flex justify-content-between">
      <label className="w-50">เบอร์โทร : {props.data.phone}</label>
      <label className="w-50">เลขบัตร : {props.data.id}</label>
    </div>
  </Option>
);
const IconOption = (props) => (
  <Option {...props}>
    <div className="w-100 d-flex">
      {props.data.logo ? <img src={props.data.logo} style={{ width: 30, marginRight: 16, borderRadius: 20 }} alt={props.data.label} /> : <div className=" bg-gray hpx-30 wpx-30 rounded-circle" style={{ marginRight: 16 }}></div>}
      {"ชื่อธนาคาร: " + props.data.label}
    </div>
    <div className="w-100 d-flex">
      <div className="hpx-30 wpx-30"></div>
      <p className="text-16 text-login ml-3">{"เลขที่บัญชี: " + props.data.bank_number}</p>
    </div>
  </Option>
);
const customStylesTypeOption = {
  option: (provided, state) => ({ ...provided, height: 34 }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    {" "}
    <label>{props.data.label}</label>{" "}
  </Option>
);
export default class MainScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      new_OM: false,

      date: new Date(),
      check_date: new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":")),
      order_main_number: "",

      profile: JSON.parse(localStorage.getItem("profile")),
      machine_number: JSON.parse(localStorage.getItem("profile")) ? JSON.parse(localStorage.getItem("profile")).user_code : "",

      loading: false,

      modal_credit: false,
      credit: 7,
      credit_type: "7 วัน (Default)",

      index: 0,
      i_yard: 0,

      status_delivery: "",
      status_delivery_no: false,
      status_delivery_yes: false,

      check_tax: false,
      select_point: false,

      //member
      modal_new_member: false,
      modal_add_member: false,
      list_member: null,
      member_type: "name",
      member: null,
      user: null,
      member_select: "",

      full_name: "",
      tax_invoice: "",
      phone: "",
      user_type: "",
      address: "",
      province: "",
      district: "",
      sub_district: "",
      post_code: "",
      credit_day: 0,
      credit_money: 0,

      set_credit: "",

      province_list: null,
      districts_list: [],
      subdistricts_list: [],
      zipcode_list: [],
      SUB_DISTRICT_ID: "",
      DISTRICT_ID: "",
      PROVINCE_ID: "",
      ZIPCODE_ID: "",

      //datamember_header
      header_name: "",
      header_code: "",
      header_credit_day: 0,

      //product
      // data: localStorage.getItem("product") ? JSON.parse(localStorage.getItem("product")) : null,

      data: null,
      product: "",
      product_name: "",
      product_id: "",
      product_list: null,

      //stock
      modal_stock: false,
      data_stock: null,
      warehouse_id: "",

      //pauseorder
      modal_pause_order: false,
      modal_pause_data: false,
      pause_status: false,

      //cancel_order
      modal_cancel: false,
      data_hole: null,
      order_main_id: "",

      //payment
      modal_payment: false,
      calculate_type: "เงินสด",
      price: "00",
      payment_type: 1,
      status_order_main: 0,
      bank_list: null,
      bank_name: "",
      installment: 0,
      installment_list: [],
      input: false,

      //alert
      message_detail: { title: "", status_order_main: 1, calculate_type: "", action: "", detail_print: "", member: "", data_number: "", order_list: [], machine_profile: "", datetime_create: "" },
      modal_print: false,

      //editprice
      modal_edit_price: false,
      input_edit_price: "",

      //modal_text_input
      modal_text_input: false,
      title_text_input: "",

      search_product: "",
      type_product_id: "",
      product_type: "",

      order_list: [],
      count_order: 0,
      total_price: 0.0,
      delivery_price: 0.0,
      delivery_address: "",
      vat: 0.0,
      discount: 0.0,
      deposit: 0.0,
      total_price_final: 0.0,

      total_yard: 0,
      total_wood: 0,

      text_input: "",
      remark: "",

      payment_detail: {
        credit_card_type: "",
        bank_number: "",
        bank_name: "",
        card_number: "",
        slip_number: "",
        remark_payment_s2: "",
        remark_payment_s3: "",
        full_name_transfer: "",
        datetime_transfer: "",
        reference_number: "",
        remark_payment_s4: "",
        remark_payment_s5: "",
        total_pay: 0.0,
        installment_list: [
          {
            total_pay: 0.0,
            pay: 0.0,
          },
        ],
      },

      //เงินทอน
      change: 0.0,
      text_modal: "",

      // status_bill
      status_bill: "",

      height: window.innerHeight,

      alert: 0,
      modal_remove_pause: false,

      page_name: "",
      edit_status: false,
      timeZone: moment.tz.guess(),

      qty_shoot: 0,
      datetime_create: "",
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });

    // พักออร์เดอร์
    let data_hole = await localStorage.getItem("POS");

    if (data_hole) {
      this.setState({ pause_status: true });
    }

    localStorage.setItem("status_bill", "ขายสินค้า");
    localStorage.removeItem("user_code");

    this.GetProductAll();
    await this.GetOMMumber();
    await this.GetMember();
    await this.GetBank();
    await this.GetProductType();
    if (this.props.location.query.retry_id) {
      this.Get_Retry_Detail(this.props.location.query.retry_id);
    }
    this.setState({ status_bill: "ขายสินค้า" });

    this.setState({ loading: false });
  }
  GetOMMumber = async () => {
    let body = {
      data_search: "",
      user_type: "",
    };
    let result = await POST(tokens, "v1/pos-mgt/order/generate-om-number", body);
    if (result && result.status) {
      this.setState({ order_main_number: result.data.data });
      if (this.state.new_OM === true) {
        this.CreateOrder();
      }
    }
  };
  GetMember = async () => {
    let body = {
      data_search: "",
      user_type: "",
      fillter_province: "",
    };
    let result = await POST(tokens, "v1/user-member/search/page/0", body);

    if (result && result.status) {
      this.setState({ list_member: result.data, province_list: result.data.province_list });
    }
  };
  GetBank = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/bank_account/search/page/0", body);
    if (result && result.status) {
      this.setState({ bank_list: result.data });
    }
  };
  GetProductType = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", body);
    if (result && result.status) {
      this.setState({ product_type: result.data });
    }
  };
  GetProductAll = async () => {
    let result = await GET(tokens, "v1/pos-mgt/order/filter?data_search=" + this.state.search_product + "&type_product_id=" + this.state.type_product_id, null);
    // .filter(e => e.stock_qty !== 0 && e.yard_qty !== 0)
    if (result && result.status) {
      let data = result.data.data;
      this.setState({ data: data });
    }
  };
  Districts = async () => {
    let body = { PROVINCE_ID: this.state.province.value };
    let result = await POST(tokens, "v1/user-member/districts", body);
    if (result && result.status) {
      this.setState({ districts_list: result.data.data_list_districts });
    }
  };
  SubDistricts = async () => {
    let body = { DISTRICT_ID: this.state.district.value };
    let result = await POST(tokens, "v1/user-member/sub_districts", body);
    if (result && result.status) {
      this.setState({ subdistricts_list: result.data.data_list_sub_districts });
    }
  };
  ZipCode = async () => {
    let body = { SUB_DISTRICT_CODE: this.state.sub_district.item.SUB_DISTRICT_CODE };
    let result = await POST(tokens, "v1/user-member/post_code", body);
    if (result && result.status) {
      this.setState({ zipcode_list: result.data.data_list_post_code });
    }
  };
  ClearFormInPutMember() {
    this.setState({
      full_name: "",
      tax_invoice: "",
      phone: "",
      user_type: "",
      address: "",
      province: "",
      district: "",
      sub_district: "",
      post_code: "",
      credit_day: 0,
      credit_money: 0,
      PROVINCE_ID: "",
      districts_list: [],
      DISTRICT_ID: "",
      subdistricts_list: [],
      SUB_DISTRICT_ID: "",
      zipcode_list: [],
      ZIPCODE_ID: "",
    });
  }
  AddMember = async () => {
    this.setState({ loading: true });
    let body = {
      full_name: this.state.full_name,
      tax_invoice: this.state.tax_invoice,
      phone: this.state.phone,
      user_type: this.state.user_type,
      address: this.state.address,
      province: this.state.province.item.PROVINCE_NAME,
      province_id: this.state.province.item.PROVINCE_ID,
      district: this.state.district.item.DISTRICT_NAME,
      district_id: this.state.district.item.DISTRICT_ID,
      sub_district: this.state.sub_district && this.state.sub_district.item.SUB_DISTRICT_NAME ? this.state.sub_district.item.SUB_DISTRICT_NAME : "",
      sub_district_id: this.state.sub_district && this.state.sub_district.item.SUB_DISTRICT_ID ? this.state.sub_district.item.SUB_DISTRICT_ID : "",
      post_code: this.state.post_code && this.state.post_code.item.ZIPCODE ? this.state.post_code.item.ZIPCODE : "",
      post_code_id: this.state.post_code && this.state.post_code.item.SUB_DISTRICT_CODE ? this.state.post_code.item.SUB_DISTRICT_CODE : "",
      credit_day: this.state.credit_day ? Number(this.state.credit_day) : 0,
      credit_money: this.state.credit_money ? Number(this.state.credit_money) : 0,
    };
    let result = await POST(tokens, "v1/user-member/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.full_name + " ' ถูกเพิ่มในระบบแล้ว");
      this.setState({ loading: false, modal_new_member: false });
      this.ClearFormInPutMember();
      let bodys = {
        data_search: "",
        user_type: "",
        fillter_province: "",
      };
      let result_member = await POST(tokens, "v1/user-member/search/page/0", bodys);
      if (result_member && result_member.status) {
        let credit_day = new Date();
        let member = result_member.data.data[0];
        credit_day.setDate(credit_day.getDate() + member.credit_day).toString();
        this.setState({
          set_credit: credit_day.toISOString(),
          header_name: member.full_name,
          header_code: member.user_code,
          header_credit_day: member.credit_day,
          member: member,
          user: member,
        });
        localStorage.setItem("user_code", member.user_code);
      }
    }
    this.setState({ loading: false });
  };
  ClearForm() {
    this.setState({
      alert: 0,
      page: 1,
      loading: false,
      search: "",
      search_product: "",
      new_OM: false,
    });
  }
  payment_clear = () => {
    let data = this.state.payment_detail;
    data.credit_card_type = "";
    data.bank_number = "";
    data.bank_name = "";
    data.card_number = "";
    data.slip_number = "";
    data.remark_payment_s2 = "";
    data.remark_payment_s3 = "";
    data.full_name_transfer = "";
    data.datetime_transfer = "";
    data.reference_number = "";
    data.remark_payment_s4 = "";
    data.remark_payment_s5 = "";
    data.installment_list = [{ total_pay: 0.0, pay: 0.0 }];

    this.setState({
      payment_detail: data,
      input: false,
      new_OM: false,
      installment_list: [],
      installment: 0,
      calculate_type: "เงินสด",
      payment_type: 1,
    });
  };
  Get_Retry_Detail = async (id) => {
    this.setState({ loading: true });
    let result = await GET_CACHE(tokens, "v1/pos-mgt/order/detail/" + id, null);
    if (result && result.status) {
      let set_credit = new Date();
      let data = result.data.data;
      let order_list_data = data.order_list_data;
      let array_order_list = [];
      for (let i = 0; i < order_list_data.length; i++) {
        let product = {
          product_main_data: {
            product_id: order_list_data[i].product_id,
            product_code: order_list_data[i].product_code,
            product_name: order_list_data[i].product_name,
            wholesale_price: order_list_data[i].wholesale_price,
            cost_price: order_list_data[i].cost_price,
          },
          warehouse_name: order_list_data[i].warehouse_name,
          stock_qty: order_list_data[i].stock_qty,
          yard_qty: order_list_data[i].stock_yard_qty,
        };
        let d = {
          product_id: order_list_data[i].product_id,
          product_main: product,
          warehouse_id: order_list_data[i].warehouse_id,
          price_by_item: order_list_data[i].price_by_item,

          qty: order_list_data[i].qty,
          yard_qty: order_list_data[i].yard_qty,
          detail_yard_list: order_list_data[i].detail_yard_list,

          total_price: order_list_data[i].total_price,
          total_price_cost: order_list_data[i].cost_price,
        };
        array_order_list.push(d);
      }
      switch (data.payment_type_layer_1) {
        case 0:
          this.setState({ calculate_type: "เงินสด", payment_type: 0 });
          break;
        case 1:
          this.setState({ calculate_type: "เงินสด", payment_type: 1 });
          break;
        case 2:
          this.setState({ calculate_type: "เงินเชื่อ", payment_type: 2 });
          break;
        case 3:
          this.setState({ calculate_type: "เงินโอน", payment_type: 3 });
          break;
        case 4:
          this.setState({ calculate_type: "เป็นงวด", payment_type: 4 });
          break;
        default:
          this.setState({ calculate_type: "เงินสด", payment_type: 1 });
          break;
      }
      let bill = localStorage.getItem("bill");
      if (bill === "รีบิลเป็นการขาย" || bill === "บันทึกรอชำระ") {
        localStorage.setItem("status_bill", "ขายสินค้า");
        this.setState({ status_bill: localStorage.getItem("status_bill") });
      }
      let payment = this.state.payment_detail;
      payment.full_name_transfer = data.member_data.full_name;
      this.setState({
        payment_detail: payment,
        pause_status: false,
        order_main_id: data.order_main_id,
        order_main_number: this.state.order_main_number,
        count_order: data.count_order,
        total_price: data.total_price,
        delivery_price: data.delivery_price,
        delivery_address: data.delivery_address,
        vat: data.vat,
        discount: data.discount,
        deposit: bill === "รีบิลเป็นการขาย" || bill === "บันทึกรอชำระ" ? data.deposit : 0.0,
        total_price_final: Number(data.total_price_final - data.deposit),
        remark: data.remark,

        check_tax: Number(data.vat) === 0 ? false : true,

        status_order_main: data.status_order_main,

        header_name: data.member_data.full_name,
        header_code: data.member_data.user_code,
        header_credit_day: data.member_data.credit_day,

        member: data.member_data,
        order_list: array_order_list ? array_order_list : [],

        check_bill: data.status_bill,
        edit_status: true,
        datetime_create: data.datetime_create,
      });

      if (data.status_delivery === 1) {
        this.setState({ status_delivery_yes: true, status_delivery_no: false, status_delivery: true });
      } else if (data.status_delivery === 6) {
        this.setState({ status_delivery: "", status_delivery_yes: false, status_delivery_no: false });
      } else {
        if (data.transmission_type === "ส่ง") {
          this.setState({ status_delivery: data.status_delivery, status_delivery_yes: true, status_delivery_no: false });
        } else if (data.transmission_type === "ไม่ส่ง") {
          this.setState({ status_delivery: data.status_delivery, status_delivery_yes: false, status_delivery_no: true });
        }
      }

      if (this.state.member) {
        if (this.state.member.user_code !== "ME000000001") {
          set_credit.setDate(set_credit.getDate() + this.state.member.credit_day).toString();
          this.setState({ set_credit: set_credit.toISOString() });
        }
      }

      let m = {
        user_id: this.state.member.user_id,
        user_code: this.state.member.user_code,
        user_type: this.state.member.user_type,
        full_name: this.state.member.full_name,
        credit_day: this.state.member.credit_day,
        tax_invoice: this.state.member.tax_invoice,
        address: this.state.member.address,
        phone: this.state.member.phone,
        province: this.state.member.province,
        province_id: this.state.member.province_id,
        district: this.state.member.district,
        district_id: this.state.member.district_id,
        sub_district: this.state.member.sub_district,
        sub_district_id: this.state.member.sub_district_id,
        post_code: this.state.member.post_code,
        post_code_id: this.state.member.post_code_id,
        credit_money: this.state.member.credit_money,
        credit_money_balance: this.state.member.credit_money_balance,
        refund_point: this.state.member.refund_point,
      };

      localStorage.setItem("user_code", this.state.member.user_code);

      this.setState({ member: m });
      setTimeout(() => {
        this.Calculate();
      }, 10);

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  Calculate() {
    let bill = localStorage.getItem("bill");
    let total_price = 0;
    let data = this.state.order_list;
    let discount = Number(this.state.discount);
    let deposit = Number(this.state.deposit);
    let delivery_price = Number(this.state.delivery_price);

    for (let i = 0; i < data.length; i++) {
      let sum_yard = total(data[i].detail_yard_list);
      data[i].yard_qty = sum_yard;
      data[i].total_price = data[i].yard_qty * data[i].price_by_item;
      total_price += data[i].total_price;

      if (bill === "รีบิลเป็นการขาย" || this.props.location.query.retry_id) {
        if (total(data[i].detail_yard_list) > Number(data[i].product_main.yard_qty) || data[i].qty > Number(data[i].product_main.stock_qty)) {
          alert("warning", "แจ้งเตือน", "จำนวนคงเหลือในสต๊อกไม่เพียงพอ\nกรุณาตรวจสอบสินค้าก่อนทำรายการ");
        }
      }
    }
    let summary = Number(total_price) - Number(discount) + Number(delivery_price);
    let vat = this.state.check_tax ? (summary * 7) / 107 : 0.0;
    if (bill === "รีบิลเป็นการขาย" || this.props.location.query.retry_id || (this.state.status_bill === "ขายสินค้า" && this.state.deposit !== 0) || this.state.edit_status) {
      summary = summary - deposit;
    }

    this.setState({
      order_list: data,
      total_price: Number(total_price).toFixed(2),
      vat: Number(vat).toFixed(2),
      total_price_final: Number(summary).toFixed(2),
    });
  }
  calculate = (number) => {
    const input = document.getElementById("price");
    input.focus();
    if (number === "delete") {
      const currentPosition = input.selectionStart;
      const textBeforeCursor = input.value.slice(0, currentPosition);
      const textAfterCursor = input.value.slice(currentPosition);
      input.value = textBeforeCursor.slice(0, -1) + textAfterCursor;
      input.selectionStart = currentPosition - 1;
      input.selectionEnd = currentPosition - 1;
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    } else if (number === 100) {
      if (Number(this.state.price) + 100 < 999999999) {
        this.setState({ price: Number(this.state.price) + 100 });
      }
    } else if (number === 500) {
      if (Number(this.state.price) + 500 < 999999999) {
        this.setState({ price: Number(this.state.price) + 500 });
      }
    } else if (number === 1000) {
      if (Number(this.state.price) + 1000 < 999999999) {
        this.setState({ price: Number(this.state.price) + 1000 });
      }
    } else if (number === "full") {
      let price = this.state.status_bill === "ใบเสนอราคา" ? this.state.deposit : this.state.total_price_final;
      if (this.state.select_point) {
        price = Number(this.state.total_price_final - this.state.member.refund_point);
      }

      this.setState({ price: price });
    } else if (number === "wholesaleprice") {
      this.setState({
        price: this.state.product.product_main_data.wholesale_price,
      });
    } else {
      input.setRangeText(number, input.selectionStart, input.selectionEnd, "end");
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    }
  };
  GetProduct = async (id) => {
    let result = await GET(tokens, "/v1/product-mgt/all/filter-search-pos/page/0?data_search" + this.state.data_search + "&status=" + this.state.status + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + id + "&user_member_id=" + this.state.member.user_id + "&product_id=" + this.state.product_id, null);

    if (result && result.status) {
      this.setState({ product: result.data.data });
    }
  };
  GetStockAll = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/pos-mgt/order/warehouse-all/" + id, null);
    if (result && result.status) {
      this.setState({ data_stock: result.data });
      this.setState({ loading: false });
      this.setState({ warehouse_id: "", product_id: id });
    }
    this.setState({ loading: false });
  };
  CreateOrder = async () => {
    let due_date = "";
    let status_bill = 0;
    let order_list = [];
    if (this.state.status_bill === "ใบเสนอราคา") {
      status_bill = 1;
    } else {
      let status_order_main = this.state.status_order_main;
      let payment_type = this.state.payment_type;
      if (status_order_main === 1 && (payment_type === 2 || payment_type === 4 || payment_type === 1 || payment_type === 3)) {
        //กรณีเงินเชื่อและเป็นงวดและจ่ายเงินไม่ครบ
        status_bill = 2;
      } else if (status_order_main === 2 && (payment_type === 1 || payment_type === 3)) {
        //กรณีเงินโอนและเงินสด
        status_bill = 3;
      } else if (status_order_main === 1 && payment_type === 0) {
        //กรณีบันทึกรอชำระ
        status_bill = 7;
      }
    }
    if (this.state.member.user_code === "ME000000001" && this.state.credit_day === 0) {
      let set_credit = new Date();
      set_credit.setDate(set_credit.getDate()).toString();
      let set = set_credit.toISOString();
      due_date = set.split("T")[0].split("-")[2] + "/" + set.split("T")[0].split("-")[1] + "/" + Number(set.split("T")[0].split("-")[0]);
    } else {
      due_date = this.state.set_credit.split("T")[0].split("-")[2] + "/" + this.state.set_credit.split("T")[0].split("-")[1] + "/" + Number(this.state.set_credit.split("T")[0].split("-")[0]);
    }

    for (let i = 0; i < this.state.order_list.length; i++) {
      let d = {
        product_id: this.state.order_list[i].product_id,
        warehouse_id: this.state.order_list[i].warehouse_id,
        price_by_item: Number(Number(this.state.order_list[i].price_by_item).toFixed(2)),
        qty: Number(this.state.order_list[i].qty),
        yard_qty: Number(Number(this.state.order_list[i].yard_qty).toFixed(2)),
        total_price: Number(Number(this.state.order_list[i].total_price).toFixed(2)),
        detail_yard_list: this.state.order_list[i].detail_yard_list,
        total_price_cost: Number(Number(this.state.order_list[i].total_price_cost * this.state.order_list[i].yard_qty).toFixed(2)),
      };
      order_list.push(d);
    }
    if (this.state.calculate_type === "เงินโอน" || (this.state.calculate_type === "เงินสด" && this.state.status_order_main !== 4 && this.state.status_order_main !== 1)) {
      if (this.state.status_order_main === 5 && !this.state.select_point && this.state.total_price_final > this.state.member.refund_point) {
        if (Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) < this.state.deposit) {
          alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินมากกว่าค่ามัดจำ");
          this.setState({ price: this.state.deposit, loading: false });
          return;
        } else if (Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) > this.state.deposit) {
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) - Number(this.state.deposit);
          let payment_detail = this.state.payment_detail;
          payment_detail.total_pay = this.state.deposit;
          this.setState({ payment_detail: payment_detail, text_modal: "เงินทอน ฿", general: general.toFixed(2) });
        } else {
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0);
          this.setState({ general: general.toFixed(2), text_modal: "ชำระเงินแล้ว ฿" });
        }
      } else {
        if (Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) === 0 && !this.state.select_point && this.state.total_price_final > this.state.member.refund_point) {
          this.setState({ loading: false });
          alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
          return;
        }

        if (Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) >= this.state.total_price_final) {
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) - Number(this.state.total_price_final);
          this.setState({ general: general.toFixed(2), text_modal: "เงินทอน ฿" });
        } else {
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0);
          this.setState({ general: general.toFixed(2), text_modal: "ชำระเงินแล้ว ฿" });
        }
      }
    }
    let refund_point = 0;
    if (this.state.select_point) {
      if (this.state.total_price_final > this.state.member.refund_point) {
        refund_point = Number(this.state.member.refund_point);
      } else {
        refund_point = Number(this.state.total_price_final);
        status_bill = 3;
      }
    }
    let body = {
      user_member_id: this.state.member.user_id,
      order_main_number: this.state.order_main_number,
      machine_number: this.state.machine_number,
      count_order: this.state.count_order,
      total_price: Number(Number(this.state.total_price).toFixed(2)),
      delivery_price: Number(this.state.delivery_price),
      delivery_address: this.state.delivery_address,
      vat: Number(this.state.vat),
      discount: Number(this.state.discount),
      deposit: Number(this.state.deposit),
      total_price_final: Number(this.state.total_price_final),
      status_order_main: this.state.status_order_main,
      payment_type_layer_1: this.state.payment_type,
      remark: this.state.remark,
      due_date: due_date,
      credit_day: this.state.member.credit_day,
      status_delivery: this.state.status_delivery === "" ? 6 : this.state.status_delivery ? 1 : 0,
      status_bill: status_bill,
      payment_detail: this.state.payment_detail,
      order_list: order_list,
      time_zone: this.state.timeZone,
      refund_point: refund_point,
      transmission_type: this.state.status_delivery_yes ? "ส่ง" : this.state.status_delivery_no ? "ไม่ส่ง" : "",
    };

    setTimeout(async () => {
      this.setState({ loading: true });
      let result = await POST(tokens, "v1/pos-mgt/order/create", body);

      if (result && result.status) {
        switch (this.state.status_order_main) {
          case 1:
            this.setState({
              modal_print: true,
              message_detail: {
                title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "main",
                detail_print: body,
                member: this.state.member,
                data_number: result.data.data,
                order_list: this.state.order_list,
                machine_profile: this.state.profile.full_name,
                datetime_create: this.state.datetime_create,
              },
            });
            this.setState({ loading: false, modal_payment: false, general: 0.0 });
            break;
          case 2:
            this.setState({
              modal_print: true,
              message_detail: {
                title: "ชำระเงิน",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "main",
                detail_print: body,
                member: this.state.member,
                data_number: result.data.data,
                order_list: this.state.order_list,
                machine_profile: this.state.profile.full_name,
                datetime_create: this.state.datetime_create,
              },
            });
            this.setState({ loading: false, modal_payment: false });
            break;
          case 4:
            alert_url("success", "พักออเดอร์เรียบร้อยแล้ว", "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนู พักออเดอร์", "/main");
            this.setState({ loading: false, modal_pause_order: false });
            break;
          case 5:
            this.setState({
              modal_print: true,
              message_detail: {
                title: "ชำระค่ามัดจำ",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "main",
                detail_print: body,
                member: this.state.member,
                data_number: result.data.data,
                order_list: this.state.order_list,
                machine_profile: this.state.profile.full_name,
                datetime_create: this.state.datetime_create,
              },
            });
            this.setState({ loading: false, modal_payment: false });
            break;
          default:
            this.setState({ loading: false });
            break;
        }
      } else if (result === "getOM") {
        this.setState({ order_main_number: "", new_OM: true });
        this.GetOMMumber();
      }
      this.setState({ loading: false });
    }, 10);
  };
  EditOrder = async () => {
    let due_date = "";
    let status_bill = this.state.check_bill;
    let order_list = [];
    if (this.state.status_bill === "ใบเสนอราคา") {
      status_bill = 1;
    } else {
      let status_order_main = this.state.status_order_main;
      let payment_type = this.state.payment_type;
      if (status_order_main === 1 && (payment_type === 2 || payment_type === 4 || payment_type === 1 || payment_type === 3)) {
        //กรณีเงินเชื่อและเป็นงวดและจ่ายเงินไม่ครบ
        status_bill = 2;
      } else if (status_order_main === 2 && (payment_type === 1 || payment_type === 3)) {
        //กรณีเงินโอนและเงินสด
        status_bill = 3;
      } else if (status_order_main === 1 && payment_type === 0) {
        //กรณีบันทึกรอชำระ
        status_bill = 7;
      }
    }
    if (this.state.member.user_code === "ME000000001" && this.state.credit_day === 0) {
      let set_credit = new Date();
      set_credit.setDate(set_credit.getDate()).toString();
      let set = set_credit.toISOString();
      due_date = set.split("T")[0].split("-")[2] + "/" + set.split("T")[0].split("-")[1] + "/" + Number(set.split("T")[0].split("-")[0]);
    } else {
      due_date = this.state.set_credit.split("T")[0].split("-")[2] + "/" + this.state.set_credit.split("T")[0].split("-")[1] + "/" + Number(this.state.set_credit.split("T")[0].split("-")[0]);
    }
    for (let i = 0; i < this.state.order_list.length; i++) {
      let d = {
        product_id: this.state.order_list[i].product_id,
        warehouse_id: this.state.order_list[i].warehouse_id,
        price_by_item: Number(Number(this.state.order_list[i].price_by_item).toFixed(2)),
        qty: Number(this.state.order_list[i].qty),
        yard_qty: Number(Number(this.state.order_list[i].yard_qty).toFixed(2)),
        total_price: Number(Number(this.state.order_list[i].total_price).toFixed(2)),
        detail_yard_list: this.state.order_list[i].detail_yard_list,
        total_price_cost: Number(Number(this.state.order_list[i].total_price_cost * this.state.order_list[i].yard_qty).toFixed(2)),
      };
      order_list.push(d);
    }

    if (this.state.calculate_type === "เงินโอน" || (this.state.calculate_type === "เงินสด" && this.state.status_order_main !== 4 && this.state.status_order_main !== 1)) {
      if (this.state.status_order_main === 5 && !this.state.select_point && this.state.total_price_final > this.state.member.refund_point) {
        //ใบเสนอราคา
        if (Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) < this.state.deposit) {
          //จ่ายเงินน้อยกว่าค่ามัดจำ
          alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินมากกว่าค่ามัดจำ");
          this.setState({ price: this.state.deposit, loading: false });
          return;
        } else if (Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) > this.state.deposit) {
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) - Number(this.state.deposit);
          let payment_detail = this.state.payment_detail;
          payment_detail.total_pay = this.state.deposit;
          this.setState({ payment_detail: payment_detail, text_modal: "เงินทอน ฿", general: general.toFixed(2) });
        } else {
          //จ่ายเงินมากกว่าใบเสนอราคา
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0);

          this.setState({ general: general.toFixed(2), text_modal: "ชำระเงินแล้ว ฿" });
        }
      } else {
        //ไม่ใช่ใบเสนอราคา
        // if (status_bill === 7 && Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) === 0 && !this.state.select_point && this.state.total_price_final > this.state.member.refund_point) {
        //     //ห้ามจ่ายเงินเป็น0
        //     this.setState({ loading: false })
        //     alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน")
        //     return
        // }

        if (Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) >= this.state.total_price_final) {
          //จ่ายเงินมากกว่าราคา  เป็นเคส เงินทอน
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0) - Number(this.state.total_price_final);
          this.setState({ general: general.toFixed(2), text_modal: "เงินทอน ฿" });
        } else {
          //จ่ายเงินไม่ครบยอด เป็นเคสบันทึกรอชำระ
          let general = Number(this.state.price) + (this.state.select_point ? Number(this.state.member.refund_point) : 0);
          this.setState({ general: general.toFixed(2), text_modal: "ชำระเงินแล้ว ฿" });
        }
      }
    }

    let total = 0;

    if (localStorage.getItem("bill") === "รีบิลเป็นการขาย" || this.props.location.query.retry_id || (this.state.status_bill === "ขายสินค้า" && this.state.deposit !== 0) || this.state.edit_status) {
      total = Number(this.state.total_price_final) + Number(this.state.deposit);
    } else {
      total = Number(this.state.total_price_final);
    }

    let refund_point = 0;
    if (this.state.select_point) {
      if (total > this.state.member.refund_point) {
        refund_point = Number(this.state.member.refund_point);
      } else {
        refund_point = Number(this.state.total_price_final);
        status_bill = 3;
      }

      let general = Number(refund_point);
      this.setState({ general: general.toFixed(2), text_modal: "จำนวน ฿" });
    }

    setTimeout(async () => {
      this.setState({ loading: true });
      let body = {
        order_main_id: this.state.order_main_id,
        user_member_id: this.state.member.user_id,
        order_main_number: this.state.order_main_number,
        machine_number: this.state.machine_number,
        count_order: this.state.count_order,
        total_price: Number(this.state.total_price),
        delivery_price: Number(this.state.delivery_price),
        delivery_address: this.state.delivery_address,
        vat: Number(this.state.vat),
        discount: Number(this.state.discount),
        deposit: Number(this.state.deposit),
        total_price_final: total,
        status_order_main: this.state.status_order_main,
        payment_type_layer_1: this.state.payment_type,
        remark: this.state.remark,
        due_date: due_date,
        credit_day: this.state.member.credit_day,
        status_delivery: this.state.status_delivery === "" ? 6 : this.state.status_delivery === 2 ? 2 : this.state.status_delivery ? 1 : 0,
        status_bill: status_bill,
        payment_detail: this.state.payment_detail,
        order_list: order_list,
        time_zone: this.state.timeZone,
        refund_point: refund_point,

        transmission_type: this.state.status_delivery_yes ? "ส่ง" : this.state.status_delivery_no ? "ไม่ส่ง" : "",
      };

      let result = await PUT(tokens, "v1/pos-mgt/order/update", body);

      if (result && result.status) {
        switch (this.state.status_order_main) {
          case 1:
            this.setState({
              modal_print: true,
              message_detail: {
                title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "main",
                detail_print: body,
                member: this.state.member,
                data_number: result.data.data,
                order_list: this.state.order_list,
                machine_profile: this.state.profile.full_name,
                datetime_create: this.state.datetime_create,
              },
            });
            this.setState({ loading: false, modal_payment: false, general: 0.0 });
            break;
          case 2:
            this.setState({
              modal_print: true,
              message_detail: {
                title: this.state.select_point ? "ชำระด้วยเงินสะสม" : "ชำระเงิน",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "main",
                detail_print: body,
                member: this.state.member,
                data_number: result.data.data,
                order_list: this.state.order_list,
                machine_profile: this.state.profile.full_name,
                datetime_create: this.state.datetime_create,
              },
            });
            this.setState({ loading: false, modal_payment: false });
            break;
          case 4:
            alert_url("success", "พักออเดอร์เรียบร้อยแล้ว", "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนู พักออเดอร์", "/main");
            this.setState({ loading: false, modal_pause_order: false });
            break;
          case 5:
            this.setState({
              modal_print: true,
              message_detail: {
                title: this.state.select_point ? "ชำระด้วยเงินสะสม" : "ชำระเงิน",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "main",
                detail_print: body,
                member: this.state.member,
                data_number: result.data.data,
                order_list: this.state.order_list,
                machine_profile: this.state.profile.full_name,
                datetime_create: this.state.datetime_create,
              },
            });
            this.setState({ loading: false, modal_payment: false });
            break;
          default:
            this.setState({ loading: false });
            break;
        }
      } else if (result === "getOM") {
        this.setState({ order_main_number: "", new_OM: true });
        this.GetOMMumber();
      }
      this.setState({ loading: false });
    }, 10);
  };
  CheckStock = async () => {
    let order_list = [];
    for (let i = 0; i < this.state.order_list.length; i++) {
      let d = {
        product_id: this.state.order_list[i].product_id,
        warehouse_id: this.state.order_list[i].warehouse_id,
        price_by_item: Number(Number(this.state.order_list[i].price_by_item).toFixed(2)),
        qty: Number(this.state.order_list[i].qty),
        yard_qty: Number(Number(this.state.order_list[i].yard_qty).toFixed(2)),
        total_price: Number(Number(this.state.order_list[i].total_price).toFixed(2)),
        detail_yard_list: this.state.order_list[i].detail_yard_list,
        total_price_cost: Number(Number(this.state.order_list[i].total_price_cost * this.state.order_list[i].yard_qty).toFixed(2)),
      };
      order_list.push(d);
    }
    let body = {
      order_list: order_list,
    };
    let result = await POST(tokens, "v1/pos-mgt/order/check-stock-only", body);
    if (result && result.status) {
      let price = this.state.status_bill === "ใบเสนอราคา" ? this.state.deposit : this.state.total_price_final;
      if (this.state.select_point) {
        price = Number(this.state.total_price_final - this.state.member.refund_point);
      }
      this.setState({
        modal_payment: true,
        status_order_main: this.state.status_bill === "ใบเสนอราคา" ? 5 : 2,
        price: Number(price),
        payment_type: 1,
      });
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    localStorage.removeItem("user_code");
    localStorage.removeItem("bill");
    let result = await DELETE(tokens, "v1/pos-mgt/order/delete/" + this.state.order_main_id, null);
    if (result && result.status) {
      alert_url("success", "ยกเลิกเรียบร้อยแล้ว", "ยกเลิกรายการสินค้าทั้งหมดแล้ว", "/main");
      this.setState({ loading: false, modal_cancel: false });
    }
    this.setState({ loading: false });
  };
  select_member() {
    let set_credit = new Date();
    if (this.state.user === null || this.state.user === undefined) {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกสมาชิก");
      return;
    }
    if (this.state.user) {
      if (this.state.user.user_code !== "ME000000001") {
        set_credit.setDate(set_credit.getDate() + this.state.user.credit_day).toString();
      }
    }
    let m = {
      user_id: this.state.user.user_id,
      user_code: this.state.user.user_code,
      user_type: this.state.user.user_type,
      full_name: this.state.user.full_name,
      credit_day: this.state.user.credit_day,
      credit_money: this.state.user.credit_money,
      credit_money_balance: this.state.user.credit_money_balance,
      tax_invoice: this.state.user.tax_invoice,
      address: this.state.user.address,
      phone: this.state.user.phone,
      province: this.state.user.province,
      province_id: this.state.user.province_id,
      district: this.state.user.district,
      district_id: this.state.user.district_id,
      sub_district: this.state.user.sub_district,
      sub_district_id: this.state.user.sub_district_id,
      post_code: this.state.user.post_code,
      post_code_id: this.state.user.post_code_id,
      refund_point: this.state.user.refund_point,
    };
    this.setState({ member: m });
    setTimeout(() => {
      this.setState({
        set_credit: set_credit.toISOString(),
        header_name: this.state.user.full_name,
        header_code: this.state.user.user_code,
        header_credit_day: this.state.user.credit_day,
        modal_add_member: false,
      });
    }, 10);
    localStorage.setItem("user_code", this.state.user.user_code);
    if (localStorage.getItem("user_code") === "ME000000001" && localStorage.getItem("status_bill") === "ใบเสนอราคา") {
      alert("info", "แจ้งเตือน", "ลูกค้าทั่วไปไม่สามารถเข้าถึงโหมดใบเสนอราคาได้");
      this.setState({ status_bill: "ขายสินค้า" });
      localStorage.setItem("status_bill", "ขายสินค้า");
      return;
    }
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="w-100">
          <NavbarComponent
            onChange={(e) => {
              this.setState({ select_point: false });
              if (this.props.location.query.retry_id || this.state.edit_status) {
                alert("info", "แจ้งเตือน", "ไม่สามารถสลับไปยังใบเสนอราคาได้");
                this.setState({ status_bill: "ขายสินค้า" });
                localStorage.setItem("status_bill", "ขายสินค้า");
                return;
              } else if (this.state.check_bill === 7) {
                alert("info", "แจ้งเตือน", "ไม่สามารถสลับไปยังใบเสนอราคาได้");
                localStorage.setItem("status_bill", "ขายสินค้า");
                this.setState({ status_bill: "ขายสินค้า" });
                return;
              } else if (this.state.check_bill === 1 && this.state.payment_type !== 0) {
                alert("info", "แจ้งเตือน", "ไม่สามารถสลับไปยังใบเสนอราคาได้");
                localStorage.setItem("status_bill", "ขายสินค้า");
                this.setState({ status_bill: "ขายสินค้า" });
                return;
              } else {
                this.setState({ status_bill: e, deposit: 0.0 });
                setTimeout(() => {
                  this.Calculate();
                }, 10);
              }
            }}
          />
          <div style={{ height: 60 }}></div>
          <div className="row mx-0">
            {/* ---------------------------------------------- ซ้าย ---------------------------------------------- */}
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
              {/* header */}
              <div className="card p-3 mb-3 mt-3">
                <div className="row">
                  {/* เลขที่ลูกค้า */}
                  <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 px-0 text-center" style={{ minWidth: 100 }}>
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-login">เลขที่ลูกค้า</label>
                        <br />
                        <div
                          onClick={() => {
                            if (this.state.order_list.length !== 0) {
                              alert("warning", "แจ้งเตือน", "ไม่สามารถเปลี่ยนลูกค้าได้ เคลียร์รายการสินค้าทั้งหมดก่อนทำรายการ");
                              return;
                            }
                            if (this.state.member === null) {
                              this.setState({ modal_add_member: true, user: null });
                            } else {
                              let set_credit = new Date();
                              if (this.state.member === null || this.state.member === undefined) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกสมาชิก");
                                return;
                              } else if (this.state.member && this.state.member.user_code !== "ME000000001") {
                                set_credit.setDate(set_credit.getDate() + this.state.member.credit_day).toString();
                              }
                              let u = {
                                set_credit: set_credit.toISOString(),
                                user_id: this.state.member.user_id,
                                user_code: this.state.member.user_code,
                                user_type: this.state.member.user_type,
                                full_name: this.state.member.full_name,
                                credit_day: this.state.member.credit_day,
                                credit_money: this.state.member.credit_money,
                                credit_money_balance: this.state.member.credit_money_balance,
                                tax_invoice: this.state.member.tax_invoice,
                                address: this.state.member.address,
                                phone: this.state.member.phone,
                                province: this.state.member.province,
                                province_id: this.state.member.province_id,
                                district: this.state.member.district,
                                district_id: this.state.member.district_id,
                                sub_district: this.state.member.sub_district,
                                sub_district_id: this.state.member.sub_district_id,
                                post_code: this.state.member.post_code,
                                post_code_id: this.state.member.post_code_id,
                                refund_point: this.state.member.refund_point,
                              };
                              this.setState({
                                modal_add_member: true,
                                user: u,
                              });
                            }
                          }}
                          className="text-primary"
                        >
                          <label className="pointer">{this.state.header_name ? this.state.header_code.slice(-5) + "," + this.state.header_name : "+ เพิ่มลูกค้าใหม่"}</label>
                          <label className="icon ml-1 pointer">{"\uf303"}</label>
                        </div>
                      </div>
                      <div className="hpx-32 border-right d-none d-sm-block d-lg-block my-auto"></div>
                    </div>
                  </div>
                  {/* เลขที่รายการ */}
                  <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 px-0 text-center">
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-login">เลขที่รายการ</label>
                        <br />
                        <label>{this.state.order_main_number}</label>
                      </div>
                      <div className="hpx-32 border-right d-none d-sm-block d-lg-block my-auto"></div>
                    </div>
                  </div>
                  {/* เลขที่เครื่อง POS */}
                  <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 px-0 text-center">
                    <div className="w-100 d-flex align-items-cennter">
                      {this.state.header_name ? (
                        <div className="w-100">
                          <label className="text-login">เครดิต</label>
                          <br />
                          <div>
                            <label className="pointer">{this.state.header_name ? this.state.header_credit_day : 0}</label>
                          </div>
                        </div>
                      ) : (
                        <div className="w-100">
                          <label className="text-login">เลขที่เครื่อง POS</label>
                          <br />
                          <label>{this.state.profile && this.state.profile.user_code ? this.state.profile.user_code : ""}</label>
                        </div>
                      )}
                      <div className="hpx-32 border-right d-none d-sm-block d-lg-block  my-auto"></div>
                    </div>
                  </div>
                  {/* วันเวลาทำรายการ */}
                  <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 px-0 text-center">
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-login"> วันเวลาทำรายการ </label>
                        <br />
                        <label>{format_date_main(this.state.date)}</label>
                      </div>
                      <div className="hpx-32 border-right d-none d-sm-block d-lg-block my-auto"></div>
                    </div>
                  </div>
                  {/* พนักงาน */}
                  <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 px-0 text-center">
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-login">พนักงาน</label>
                        <br />
                        <label>{this.state.profile && this.state.profile.full_name ? this.state.profile.full_name : ""}</label>
                      </div>
                      <div className="hpx-32 border-right d-none d-sm-block d-lg-block  my-auto"></div>
                    </div>
                  </div>
                  {/* พัก */}
                  <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 px-0 text-center d-flex center">
                    <button
                      className={this.state.pause_status ? "btn btn-primary" : "btn btn-outline-primary"}
                      onClick={() => {
                        if (this.state.pause_status) {
                          this.setState({ modal_pause_data: true });
                        } else {
                          if (this.state.order_list.length === 0) {
                            alert("info", "แจ้งเตือน", "ไม่มีออเดอร์");
                          } else {
                            this.setState({ modal_pause_order: true });
                          }
                        }
                      }}
                      style={{ position: "relative" }}
                    >
                      <label className="icon mr-2">{"\uf017"}</label>พัก
                      {this.state.pause_status && (
                        <div className="run-number bg-danger" style={{ top: -10, right: -10, position: "absolute" }}>
                          <label className="icon">{"\uf0f3"}</label>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {/* ตารางออร์เดอร์ */}
              <div className="card p-3 mb-3 vh-100">
                {this.state.order_list && this.state.order_list.length > 0 ? (
                  <div className="table-responsive rounded-16" style={{ maxHeight: 600 }}>
                    <table className="table design-table-sell tableFixHead">
                      <thead className="thead-dark-gray">
                        <tr>
                          <th className="text-center">
                            <b>No.</b>
                          </th>
                          <th className="text-left">
                            <b>สินค้า</b>
                          </th>

                          <th className="text-center">
                            <b>รายละเอียด</b>
                          </th>

                          <th className="text-center " style={{ minWidth: 100 }}>
                            <b>ราคา/หน่วย</b>
                          </th>
                          <th className="text-center " style={{ minWidth: 100 }}>
                            <b>จำนวนหลา</b>
                            <br />
                            <b>({baht(total(this.state.order_list, "yard_qty"))} หลา)</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>จำนวนไม้</b>
                            <br />
                            <b>({total(this.state.order_list, "qty")} ไม้)</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 120 }}>
                            <b>ราคารวมสุทธิ</b>
                          </th>
                          <th className="text-center">
                            <b>ลบ</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.order_list.map((item, index) => (
                          <tr>
                            <td className="text-center">{Number(index + 1)}</td>
                            <td className="text-left" style={{ minWidth: 130 }}>
                              {item.product_main.product_main_data.product_name}
                            </td>
                            <td>
                              <div className="row w-100 d-flex justify-content-center mx-0" style={{ minWidth: 430, maxWidth: 50 }}>
                                {item.detail_yard_list &&
                                  item.detail_yard_list.map((yard, i) => (
                                    <>
                                      <input
                                        className="border wpx-40 hpx-40 form-control bg-secondary-light mx-1 mb-1 px-1 text-center"
                                        type="text"
                                        onChange={(e) => {
                                          if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                                            let data = this.state.order_list;
                                            const old = data[index].detail_yard_list[i];
                                            data[index].detail_yard_list[i] = e.target.value;
                                            if (this.state.status_bill !== "ใบเสนอราคา" && total(data[index].detail_yard_list) > Number(data[index].product_main.yard_qty)) {
                                              alert("warning", "แจ้งเตือน", "จำนวนคงเหลือในสต๊อกไม่เพียงพอ \n สินค้าคงเหลือ " + data[index].product_main.yard_qty.toFixed(2) + " หลา");
                                              data[index].detail_yard_list[i] = old;
                                              this.setState({ order_list: data, index: index, select_point: false });
                                              return;
                                            }
                                            this.setState({ order_list: data, index: index, select_point: false });
                                            setTimeout(() => {
                                              this.Calculate();
                                            }, 10);
                                          }
                                        }}
                                        onBlur={(e) => {
                                          if (e.target.value === "" || e.target.value === "0") {
                                            let data = this.state.order_list;
                                            const old = data[index].detail_yard_list[i];
                                            data[index].detail_yard_list[i] = e.target.value;
                                            if (this.state.status_bill !== "ใบเสนอราคา" && total(data[index].detail_yard_list) > Number(data[index].product_main.yard_qty)) {
                                              alert("warning", "แจ้งเตือน", "จำนวนคงเหลือในสต๊อกไม่เพียงพอ \n สินค้าคงเหลือ " + data[index].product_main.yard_qty.toFixed(2) + " หลา");
                                              data[index].detail_yard_list[i] = old;
                                              this.setState({ order_list: data, index: index, select_point: false });
                                              return;
                                            }
                                            this.setState({ order_list: data, index: index, select_point: false });
                                            setTimeout(() => {
                                              this.Calculate();
                                            }, 10);
                                          }
                                        }}
                                        onKeyUp={(e) => {
                                          if (e.key === "Enter") {
                                            let data = this.state.order_list;
                                            data[index].qty += 1;
                                            if (this.state.status_bill !== "ใบเสนอราคา" && Number(data[index].qty) > Number(item.product_main.stock_qty)) {
                                              data[index].qty = Number(item.product_main.stock_qty);
                                            } else {
                                              data[index].detail_yard_list = [...data[index].detail_yard_list, ""];
                                            }
                                            this.setState({ order_list: data });
                                            setTimeout(() => {
                                              this.Calculate();
                                            }, 10);
                                          }
                                        }}
                                        value={yard}
                                        placeholder="ระบุ"
                                      />
                                      <div style={{ marginTop: -10, marginLeft: -14 }}>
                                        <label
                                          onClick={() => {
                                            let data = this.state.order_list;
                                            if (data[index].qty > 1) {
                                              data[index].qty -= 1;
                                              data[index].detail_yard_list.splice(i, 1);
                                              this.setState({ order_list: data, select_point: false });
                                              setTimeout(() => {
                                                this.Calculate();
                                              }, 10);
                                            }
                                          }}
                                          className="icon bg-danger text-12 text-white text-center pb-0"
                                          style={{ width: 16, height: 16, borderRadius: 16 }}
                                        >
                                          {"\uf00d"}
                                        </label>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </td>
                            <td className="text-center">
                              <label
                                className="icon mr-2 text-secondary pointer mb-0"
                                onClick={() => {
                                  this.setState({
                                    modal_edit_price: true,
                                    index: index,
                                    price: item.price_by_item,
                                    input_edit_price: "แก้ไขราคา",
                                  });
                                }}
                              >
                                {"\uf303"}
                              </label>
                              {baht(Number(item.price_by_item))}
                            </td>
                            <td className="text-center">{baht(Number(item.yard_qty).toFixed(2))}</td>
                            <td className="text-center">{item.qty}</td>
                            <td className="text-right">{baht(Number(item.total_price))}</td>
                            <td className="text-center">
                              <button
                                className="btn btn-link text-secondary "
                                onClick={() => {
                                  let data = this.state.order_list;
                                  data.splice(index, 1);
                                  this.setState({
                                    order_list: data,
                                    count_order: data.length,
                                    select_point: false,
                                  });
                                  setTimeout(() => {
                                    this.Calculate();
                                  }, 10);
                                }}
                              >
                                <label
                                  className="icon bg-danger text-14 text-white"
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 12,
                                  }}
                                >
                                  {"\uf00d"}
                                </label>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* <tfoot style={{ backgroundColor: "#CDCDCD" }}>
                                                <td className="text-left"></td>
                                                <td className="text-left">รวมทั้งหมด</td>
                                                <td className="text-left"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center wpx-150"><b>{baht(total(this.state.order_list, "yard_qty"))} หลา</b></td>
                                                <td className="text-center wpx-100"><b>{total(this.state.order_list, "qty")} ไม้</b></td>
                                                <td className="text-rigth"></td>
                                                <td className="text-center"></td>
                                            </tfoot> */}
                    </table>
                  </div>
                ) : (
                  <div className={window.innerHeight < 941 ? "w-100 center my-3" : "my-5 p-5 w-100 center"}>
                    <div>
                      <img className={window.innerHeight < 941 ? "none-product my-3 mx-3" : "none-product mt-5 mx-5 mb-2"} alt="logo" src={NONE}></img>
                      <div className="w-100">
                        <label className="w-100 text-center text-18">{this.state.status_bill}</label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* รายละเอียดการสั่งซื้อ */}
              <div className="card p-3 mb-3">
                <div className="row justify-content-between">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 border-right-md text-15">
                    <div className="d-flex justify-content-between mt-1">
                      <label className="text-detail">รายการ</label>
                      <label className="text-dark">{this.state.count_order}</label>
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                      <label className="text-detail" style={{ minWidth: 68 }}>
                        ที่อยู่จัดส่ง
                      </label>
                      {this.state.delivery_address ? (
                        <label className="text-dark text-right pointer">
                          <label
                            className="icon mr-2 text-primary pointer"
                            onClick={() => {
                              this.setState({
                                title_text_input: "ที่อยู่จัดส่ง",
                                address: this.state.delivery_address,
                                modal_text_input: true,
                                text_input: this.state.delivery_address,
                              });
                            }}
                          >
                            {"\uf303"}
                          </label>
                          <label
                            className="icon mr-2 text-danger pointer"
                            onClick={() => {
                              this.setState({
                                address: "",
                                delivery_address: "",
                                text_input: "",
                              });
                            }}
                          >
                            {"\uf00d"}
                          </label>
                          {this.state.delivery_address}
                        </label>
                      ) : (
                        <button
                          className="btn btn-outline-primary wpx-100"
                          style={{ height: 24, borderRadius: 14, paddingBottom: 25 }}
                          onClick={() => {
                            this.setState({
                              title_text_input: "ที่อยู่จัดส่ง",
                              modal_text_input: true,
                              address: this.state.delivery_address,
                              text_input: "",
                            });
                          }}
                        >
                          <small>
                            <label className="icon pointer">{"\uf067"}</label> เพิ่มที่อยู่
                          </small>
                        </button>
                      )}
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                      <label className="text-detail mr-2 ">สายส่ง</label>
                      <div className="d-flex w-100 justify-content-end mt-1">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            this.setState({ status_delivery_yes: true, status_delivery_no: false, status_delivery: true });
                          }}
                          checked={this.state.status_delivery_yes}
                          disabled={this.state.status_delivery >= 2}
                        />
                        <label className="text-detail ml-2 mr-2 ">ส่ง</label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            this.setState({ status_delivery_no: true, status_delivery_yes: false, status_delivery: false });
                          }}
                          checked={this.state.status_delivery_no}
                          disabled={this.state.status_delivery >= 2}
                        />
                        <label className="text-detail ml-2">ไม่ส่ง</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 border-right-md text-15">
                    <div className="d-flex justify-content-between mt-1">
                      <label className="text-detail">ราคารวม</label>
                      <label className="text-dark">฿{baht(Number(this.state.total_price))}</label>
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                      <label className="text-detail">ค่าบริการจัดส่ง</label>
                      <label className="text-dark">
                        <label
                          className="icon ml-2 text-primary pointer mr-2"
                          onClick={() => {
                            let data = this.state.order_list;
                            if (data.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์");
                              return;
                            }
                            this.setState({
                              modal_edit_price: true,
                              price: this.state.delivery_price,
                              input_edit_price: "ค่าบริการจัดส่ง",
                            });
                          }}
                        >
                          {"\uf303"}
                        </label>
                        ฿{baht(Number(this.state.delivery_price))}
                      </label>
                    </div>

                    <div className="d-flex justify-content-between mt-1">
                      <label className={localStorage.getItem("status_bill") === "ขายสินค้า" ? "text-disable " : "text-detail"}>ค่ามัดจำ</label>
                      <label className={localStorage.getItem("status_bill") === "ขายสินค้า" ? "text-disable " : "text-dark"}>
                        <label
                          className={localStorage.getItem("status_bill") === "ขายสินค้า" ? "icon ml-2 text-disable pointer mr-2" : "icon ml-2 text-primary pointer mr-2"}
                          onClick={() => {
                            let data = this.state.order_list;
                            if (data.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์");
                              return;
                            }
                            if (localStorage.getItem("status_bill") !== "ขายสินค้า") {
                              this.setState({
                                modal_edit_price: true,
                                price: this.state.deposit,
                                input_edit_price: "ค่ามัดจำ",
                              });
                            }
                          }}
                        >
                          {"\uf303"}
                        </label>
                        ฿{baht(Number(this.state.deposit))}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-15">
                    <div className="d-flex justify-content-between mt-1">
                      <div className="d-flex ">
                        <label className="text-detail mr-2">ภาษีมูลค่าเพิ่ม</label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            this.setState({ check_tax: !this.state.check_tax });
                            setTimeout(() => {
                              this.Calculate();
                            }, 10);
                          }}
                          checked={this.state.check_tax}
                        />
                      </div>
                      <label className="text-dark ml-1">฿{baht(Number(this.state.vat))}</label>
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                      <label className="text-detail">ส่วนลด</label>
                      <label className="text-danger">
                        <label
                          className="icon ml-2 text-primary pointer mr-2"
                          onClick={() => {
                            let data = this.state.order_list;
                            if (data.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์");
                              return;
                            }
                            this.setState({
                              modal_edit_price: true,
                              price: this.state.discount,
                              input_edit_price: "ส่วนลด",
                            });
                          }}
                        >
                          {"\uf303"}
                        </label>
                        ฿ {baht(Number(this.state.discount))}
                      </label>
                    </div>

                    <div className="d-flex justify-content-between mt-1">
                      <div className="d-flex">
                        <label className={localStorage.getItem("status_bill") === "ใบเสนอราคา" || (this.state.member && Number(this.state.member.refund_point) === 0) || (this.state.member && this.state.member.user_code === "ME000000001") ? "text-disable mr-2" : "text-detail mr-2"}>เงินสะสม</label>
                        <input
                          disabled={localStorage.getItem("status_bill") === "ใบเสนอราคา" || (this.state.member && Number(this.state.member.refund_point) === 0) || (this.state.member && this.state.member.user_code === "ME000000001") ? true : false}
                          type="checkbox"
                          onChange={(e) => {
                            let data = this.state.order_list;
                            if (data.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์");
                              return;
                            }
                            if (Number(this.state.total_price) === 0) {
                              alert("info", "แจ้งเตือน", "ข้อมูลไม่ถูกต้อง");
                              return;
                            }

                            if (localStorage.getItem("status_bill") !== "ใบเสนอราคา") {
                              this.setState({ select_point: !this.state.select_point });

                              setTimeout(() => {
                                this.Calculate();
                              }, 10);
                            }
                          }}
                          checked={this.state.select_point}
                        />
                      </div>
                      <label className={(this.state.member && Number(this.state.member.refund_point) === 0) || (this.state.member && this.state.member.user_code === "ME000000001") ? "text-disable ml-1" : "text-dark ml-1"}>฿ {(this.state.member && Number(this.state.member.refund_point) === 0) || this.state.member === null || this.state.member === undefined || (this.state.member && this.state.member.user_code === "ME000000001") ? "0.00" : this.state.member && baht(Number(this.state.member.refund_point))}</label>
                    </div>

                    <div className="d-flex justify-content-between mt-1">
                      <label className="text-detail">ยอดเงินรวมทั้งหมด</label>
                      <label className="text-dark">
                        <b>฿{baht(Number(this.state.total_price_final))}</b>
                      </label>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className={"d-flex  text-15"}>
                  <label className="text-detail">*หมายเหตุ:</label>
                  <label className="text-dark">
                    <label
                      className="icon ml-2 text-primary pointer"
                      onClick={() => {
                        this.setState({
                          modal_text_input: true,
                          title_text_input: "หมายเหตุ",
                          text_input: this.state.remark,
                        });
                      }}
                    >
                      {"\uf303"}
                    </label>{" "}
                    {this.state.remark}
                  </label>
                </div>
              </div>
              {/* บุ่มด้านล่าง */}
              <div className="card px-3 pt-3">
                <div className="w-100 row mx-0  ">
                  {this.state.profile && (this.state.profile.user_type === "admin_system" || this.state.profile.user_type === "manager" || this.state.profile.user_type === "owner") && (
                    <>
                      {/* รายการธุรกรรม */}
                      <div className="col px-1 mb-3 text-primary ">
                        <button
                          className="btn btn-outline-primary w-100"
                          onClick={() => {
                            if (this.state.pause_status || this.state.data_hole) {
                              this.setState({ page_name: "/transaction", modal_remove_pause: true });
                            } else {
                              localStorage.removeItem("bill");
                              localStorage.removeItem("user_code");
                              window.location.href = "/transaction";
                            }
                          }}
                        >
                          <label className="icon mr-2 ">{"\uf0c9"}</label>
                          รายการธุรกรรม
                        </button>
                      </div>
                      {/* ค้างจ่าย */}
                      <div className="col px-1 mb-3 text-primary ">
                        <button
                          className="btn btn-outline-primary w-100 "
                          onClick={async () => {
                            if (this.state.pause_status || this.state.data_hole) {
                              this.setState({ page_name: "/accrued", modal_remove_pause: true });
                            } else {
                              localStorage.removeItem("bill");
                              localStorage.removeItem("user_code");
                              window.location.href = "/accrued";
                            }
                          }}
                        >
                          <label className="icon mr-2">{"\uf0d6"}</label>ค้างจ่าย
                        </button>
                      </div>
                    </>
                  )}
                  {/* บันทึก/รอชำระ */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn btn-button-green w-100 text-white"
                      disabled={localStorage.getItem("status_bill") !== "ขายสินค้า" || this.state.select_point ? true : false}
                      onClick={() => {
                        if (this.state.order_list.length === 0) {
                          alert("info", "แจ้งเตือน", "ไม่มีออเดอร์");
                        } else {
                          if (this.state.status_bill !== "ใบเสนอราคา" && Number(this.state.total_price_final) === 0) {
                            alert("warning", "ไม่สามารถชำระเงินได้", "ยอดเงินรวมไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง");
                            return;
                          }
                          if (this.state.status_bill !== "ใบเสนอราคา" && this.state.total_price_final.toString().includes("-")) {
                            alert("warning", "แจ้งเตือน", "ไม่สามารถทำรายการได้ เนื่องจากมีค่ามัดจำมากกว่ายอดเงินรวมของสินค้า");
                            return;
                          }
                          if (this.state.order_list.filter((e) => e.detail_yard_list.indexOf("") !== -1 || e.detail_yard_list.indexOf("0") !== -1).length !== 0) {
                            alert("warning", "พบข้อมูลบางรายการไม่ถูกต้อง", "กรุณาตรวจสอบรายละเอียดของสินค้าใหม่อีกครั้ง");
                            return;
                          }

                          if (this.state.status_delivery === "") {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกสายส่ง");
                            return;
                          }

                          this.setState({ status_order_main: 1, payment_type: 0 });

                          setTimeout(() => {
                            if (this.state.data_hole) {
                              localStorage.removeItem("POS");
                            }
                            let bill = localStorage.getItem("bill");
                            if (this.props.location.query.retry_id || this.state.edit_status) {
                              if (bill === undefined) {
                                this.setState({ status_order_main: 1, payment_type: 0, calculate_type: "เงินสด" });
                                this.CreateOrder();
                              } else {
                                this.EditOrder();
                              }
                            } else {
                              this.CreateOrder();
                            }
                          }, 20);
                        }
                      }}
                    >
                      บันทึก/รอชำระ
                    </button>
                  </div>
                  {/* เคลียร์หน้าจอ */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn btn-button-danger w-100 text-white"
                      onClick={() => {
                        localStorage.removeItem("bill");
                        localStorage.removeItem("user_code");

                        if (this.state.order_list.length !== 0) {
                          this.setState({ modal_cancel: true });
                        } else {
                          alert("info", "แจ้งเตือน", "ไม่มีออร์เดอร์");
                        }
                      }}
                    >
                      {this.state.pause_status === true && ((this.state.check_bill === 1 && this.state.payment_type === 0) || (this.state.check_bill === 0 && this.state.payment_type === 0)) ? "ยกเลิก" : "เคลียร์หน้าจอ"}
                    </button>
                  </div>
                  {/* ชำระเงิน */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn bg-cash-1 w-100 text-white "
                      onClick={() => {
                        if (this.state.order_list.length === 0) {
                          alert("info", "แจ้งเตือน", "ไม่มีออเดอร์");
                        } else {
                          if (this.state.status_bill !== "ใบเสนอราคา" && Number(this.state.total_price_final) === 0) {
                            alert("info", "แจ้งเตือน", "ยอดเงินรวมไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง");
                            return;
                          }
                          if (this.state.status_bill !== "ใบเสนอราคา" && this.state.total_price_final.toString().includes("-")) {
                            alert("warning", "ไม่สามารถทำรายการได้ ", "เนื่องจากมีค่ามัดจำมากกว่ายอดเงินรวมของสินค้า");
                            return;
                          }
                          if (this.state.order_list.filter((e) => e.detail_yard_list.indexOf("") !== -1 || e.detail_yard_list.indexOf("0") !== -1).length !== 0) {
                            alert("warning", "พบข้อมูลบางรายการไม่ถูกต้อง", "กรุณาตรวจสอบรายละเอียดของสินค้าใหม่อีกครั้ง");
                            return;
                          }
                          if (this.state.status_delivery === "") {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกสายส่ง");
                            return;
                          }

                          if (this.state.status_bill !== "ใบเสนอราคา") {
                            if ((this.state.member.credit_money !== 0 && this.state.member.credit_money_balance === 0) || (this.state.member.credit_money !== 0 && this.state.member.credit_money_balance > 0 && Number(this.state.total_price_final) > Number(this.state.member.credit_money_balance))) {
                              alert("warning", "ไม่สามารถทำรายการได้", "เนื่องจากยอดซื้อเกินจำนวนเครดิตที่ใช้ได้");
                              return;
                            }
                          }

                          setTimeout(() => {
                            if (this.state.status_bill === "ใบเสนอราคา") {
                              this.setState({
                                modal_payment: true,
                                status_order_main: this.state.status_bill === "ใบเสนอราคา" ? 5 : 2,
                                price: this.state.status_bill === "ใบเสนอราคา" ? this.state.deposit : this.state.total_price_final,
                                payment_type: 1,
                              });
                            } else {
                              // if (this.state.select_point) {
                              //     if (Number(this.state.total_price_final) <= Number(this.state.member.refund_point)) {
                              // alert("warning", "ไม่สามารถทำรายการได้", "เนื่องจากยอดซื้อน้อยกว่ายอดเงินสะสม")
                              // return
                              //     }

                              //     this.setState({
                              //         status_order_main: 2,
                              //         payment_type: 1,
                              //     })

                              //     if (this.state.data_hole) {
                              //         localStorage.removeItem("POS");
                              //     }
                              //     if (this.props.location.query.retry_id || this.state.edit_status || localStorage.getItem("bill") === undefined) {

                              //         this.EditOrder()
                              //     } else {

                              //         this.CreateOrder()
                              //     }
                              // } else {

                              if (this.state.select_point && Number(this.state.total_price_final) < Number(this.state.member.refund_point)) {
                                alert("warning", "ไม่สามารถทำรายการได้", "เนื่องจากยอดซื้อน้อยกว่ายอดเงินสะสม");
                                return;
                              }
                              if (this.state.select_point && Number(this.state.total_price_final) === Number(this.state.member.refund_point)) {
                                let payment_detail = this.state.payment_detail;
                                payment_detail.total_pay = 0;
                                this.setState({
                                  payment_detail: payment_detail,
                                  status_order_main: this.state.status_bill === "ใบเสนอราคา" ? 5 : 2,
                                  price: 0,
                                  payment_type: 1,
                                });
                                if (this.state.data_hole) {
                                  localStorage.removeItem("POS");
                                }
                                if (this.props.location.query.retry_id || this.state.edit_status) {
                                  let bill = localStorage.getItem("bill");
                                  if (bill === undefined) {
                                    this.CreateOrder();
                                  } else {
                                    this.EditOrder();
                                  }
                                } else {
                                  this.CreateOrder();
                                }
                                return;
                              }

                              this.CheckStock();
                            }
                          }, 20);
                        }
                      }}
                    >
                      ชำระเงิน
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------------------------------------------- ขวา ---------------------------------------------- */}
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-3">
              <div className="card p-3 h-100">
                <div className="row mx-0">
                  <div className="col-6 col-xl-6 mb-3 px-1">
                    <Select
                      className="px-0"
                      options={
                        this.state.product_type &&
                        this.state.product_type.data.map((item) => ({
                          value: item.type_product_id,
                          label: item.type_product_name,
                        }))
                      }
                      components={{ Option: TypeOption }}
                      styles={customStylesTypeOption}
                      placeholder="ค้นหาชื่อประเภท"
                      defaultValue={this.state.type_product_id}
                      isClearable
                      onChange={(e) => {
                        if (e === null) {
                          this.setState({ type_product_id: "" });
                        } else {
                          this.setState({ type_product_id: e.value });
                        }
                      }}
                    />
                  </div>
                  <div className="col-6 col-xl-6 mb-3 px-1">
                    <input
                      className="form-control border-0"
                      type="search"
                      placeholder="ค้นหาชื่อสินค้า"
                      value={this.state.search_product}
                      autoFocus
                      onChange={(e) => {
                        if (e.target.value.includes("PD-") && !e.target.value.includes(".") && e.target.value.length >= 12) {
                          let qty_shoot = Number(e.target.value.split("/")[1]);
                          this.setState({ search_product: e.target.value.split("/")[0] });
                          setTimeout(async () => {
                            let product = this.state.data.filter((item) => (item.product_code + ":" + item.product_name).includes(this.state.search_product) || this.state.search_product === "").filter((item) => item.type_product_id === this.state.type_product_id || this.state.type_product_id === "");
                            if (this.state.member === null || this.state.member === undefined) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกสมาชิก");
                              return;
                            }
                            if (product.length !== 0) {
                              await this.GetStockAll(product[0].product_id);
                              this.setState({
                                product_name: product[0].product_code + " " + product[0].product_name,
                              });
                              if (this.state.data_stock && this.state.data_stock.data) {
                                // .filter(e => e.yard_qty > 0 && e.stock_qty > 0)
                                let stock = this.state.data_stock.data;
                                if (stock.length !== 0) {
                                  this.setState({
                                    warehouse_id: stock[0].warehouse_id,
                                  });
                                  await this.GetProduct(stock[0].warehouse_id);
                                  let order_list = this.state.order_list;
                                  let check = order_list.find((e) => {
                                    if (e.warehouse_id === this.state.warehouse_id && e.product_main.product_main_data.product_id === this.state.product.product_main_data.product_id) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  });

                                  if (!check) {
                                    order_list.push({
                                      product_id: this.state.product.product_main_data.product_id,
                                      product_main: this.state.product,
                                      warehouse_id: this.state.warehouse_id,
                                      price_by_item: this.state.product.special_price === 0 ? this.state.product.product_main_data.store_price : this.state.product.special_price,
                                      //ไม้
                                      qty: 1,
                                      //หลา
                                      yard_qty: qty_shoot,
                                      detail_yard_list: [qty_shoot.toString()],

                                      total_price: this.state.product.special_price === 0 ? this.state.product.product_main_data.store_price * qty_shoot : this.state.product.special_price * qty_shoot,
                                      total_price_cost: this.state.product.product_main_data.cost_price,
                                      warehouse_name: this.state.product.warehouse_name,
                                    });
                                  } else {
                                    let index_of = order_list.findIndex((e) => e.product_id === this.state.product.product_main_data.product_id);
                                    if (index_of !== -1) {
                                      order_list[index_of].qty += 1;
                                      order_list[index_of].yard_qty += qty_shoot;
                                      order_list[index_of].detail_yard_list = [...order_list[index_of].detail_yard_list, qty_shoot.toString()];
                                      order_list[index_of].total_price += this.state.product.special_price === 0 ? this.state.product.product_main_data.store_price * qty_shoot : this.state.product.special_price * qty_shoot;
                                    }
                                    // alert("info", "แจ้งเตือน", "มีสินค้าชนิดนี้ในรายการเเล้ว")
                                    // return
                                  }
                                  this.setState({
                                    order_list: order_list,
                                    modal_stock: false,
                                    count_order: order_list.length,
                                    warehouse_id: "",
                                    search_product: "",
                                  });
                                  setTimeout(() => {
                                    this.Calculate();
                                  }, 10);
                                }
                              }
                            }
                          }, 10);
                        } else if (e.target.value.includes("PD-") && e.target.value.includes(".") && e.target.value.length >= 14) {
                          let qty_shoot = Number(e.target.value.split("/")[1]);
                          this.setState({ search_product: e.target.value.split("/")[0] });
                          setTimeout(async () => {
                            let product = this.state.data.filter((item) => (item.product_code + ":" + item.product_name).includes(this.state.search_product) || this.state.search_product === "").filter((item) => item.type_product_id === this.state.type_product_id || this.state.type_product_id === "");
                            if (this.state.member === null || this.state.member === undefined) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกสมาชิก");
                              return;
                            }
                            if (product.length !== 0) {
                              await this.GetStockAll(product[0].product_id);
                              this.setState({
                                product_name: product[0].product_code + " " + product[0].product_name,
                              });
                              if (this.state.data_stock && this.state.data_stock.data) {
                                let stock = this.state.data_stock.data.filter((e) => e.yard_qty > 0 && e.stock_qty > 0);
                                if (stock.length !== 0) {
                                  this.setState({
                                    warehouse_id: stock[0].warehouse_id,
                                  });
                                  await this.GetProduct(stock[0].warehouse_id);
                                  let order_list = this.state.order_list;
                                  let check = order_list.find((e) => {
                                    if (e.warehouse_id === this.state.warehouse_id && e.product_main.product_main_data.product_id === this.state.product.product_main_data.product_id) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  });

                                  if (!check) {
                                    order_list.push({
                                      product_id: this.state.product.product_main_data.product_id,
                                      product_main: this.state.product,
                                      warehouse_id: this.state.warehouse_id,
                                      price_by_item: this.state.product.special_price === 0 ? this.state.product.product_main_data.store_price : this.state.product.special_price,
                                      //ไม้
                                      qty: 1,
                                      //หลา
                                      yard_qty: qty_shoot,
                                      detail_yard_list: [qty_shoot.toString()],

                                      total_price: this.state.product.special_price === 0 ? this.state.product.product_main_data.store_price * qty_shoot : this.state.product.special_price * qty_shoot,
                                      total_price_cost: this.state.product.product_main_data.cost_price,
                                      warehouse_name: this.state.product.warehouse_name,
                                    });
                                  } else {
                                    let index_of = order_list.findIndex((e) => e.product_id === this.state.product.product_main_data.product_id);
                                    if (index_of !== -1) {
                                      order_list[index_of].qty += 1;
                                      order_list[index_of].yard_qty += qty_shoot;
                                      order_list[index_of].detail_yard_list = [...order_list[index_of].detail_yard_list, qty_shoot.toString()];
                                      order_list[index_of].total_price += this.state.product.special_price === 0 ? this.state.product.product_main_data.store_price * qty_shoot : this.state.product.special_price * qty_shoot;
                                    }
                                    // alert("info", "แจ้งเตือน", "มีสินค้าชนิดนี้ในรายการเเล้ว")
                                    // return
                                  }
                                  this.setState({
                                    order_list: order_list,
                                    modal_stock: false,
                                    count_order: order_list.length,
                                    warehouse_id: "",
                                    search_product: "",
                                  });
                                  setTimeout(() => {
                                    this.Calculate();
                                  }, 10);
                                }
                              }
                            }
                          }, 10);
                        } else {
                          this.setState({ search_product: e.target.value, qty_shoot: 0 });
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="row p-3 h-100">
                  <div className="table-responsive rounded-16 " style={{ maxHeight: this.state.height.length >= 941 ? null : this.state.height - 220 }}>
                    <table className="table table-striped table-sm table-hover">
                      <thead className="thead-dark-gray">
                        <tr>
                          <th scope="col" className="text-left" style={{ minWidth: 125 }}>
                            ชื่อสินค้า
                          </th>
                          <th scope="col" className="text-center" style={{ minWidth: 100 }}>
                            คงเหลือ(ไม้)
                          </th>
                          <th scope="col " className="text-center" style={{ minWidth: 85 }}>
                            คงเหลือ(หลา)
                          </th>
                          <th scope="col" className="text-center" style={{ minWidth: 90 }}>
                            ราคา
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data && this.state.data.length === 0 && (
                          <tr>
                            <td colSpan={4} className="w-100 h-100 text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data &&
                          this.state.data
                            .filter((item) => (item.product_code + ":" + item.product_name).includes(this.state.search_product) || this.state.search_product === "")
                            .filter((item) => item.type_product_id === this.state.type_product_id || this.state.type_product_id === "")
                            .map((item, index) => (
                              <tr
                                key={index}
                                className="pointer"
                                onClick={async () => {
                                  if (this.state.member === null || this.state.member === undefined) {
                                    alert("warning", "แจ้งเตือน", "กรุณาเลือกสมาชิก");
                                    return;
                                  }
                                  await this.GetStockAll(item.product_id);
                                  this.setState({
                                    product_name: item.product_code + " " + item.product_name,
                                    modal_stock: true,
                                  });
                                }}
                              >
                                <th scope="row" className="text-left">
                                  {item.product_name}
                                </th>
                                <th scope="row" className="text-center">
                                  {number(item.stock_qty)}
                                </th>
                                <th scope="row" className="text-center">
                                  {baht(Number(item.yard_qty).toFixed(2))}
                                </th>
                                <th scope="row" className="text-center">
                                  {baht(item.store_price)}
                                </th>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal add member */}
        <Modal
          show={this.state.modal_add_member}
          onHide={() => {
            this.setState({ modal_add_member: false, user: null });
          }}
        >
          <Modal.Header>
            <Modal.Title className="w-100 d-flex justify-content-between">
              <b className="text-18">ค้นหาสมาชิก</b>

              {role && role.user_member[1] ? (
                <button
                  className={"btn btn-outline-primary"}
                  onClick={() => {
                    this.setState({ modal_add_member: false, modal_new_member: true, alert: 0 });
                  }}
                >
                  + เพิ่มสมาชิก
                </button>
              ) : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mb-3">
              <div className="border border-primary bg-white rounded hpx-48 mb-3">
                <div className="d-flex pt-1 pb-1 w-100">
                  <button
                    type="button"
                    className={this.state.member_type === "name" ? "btn bg-button-light w-50 hpx-38 mx-1 text-primary" : "btn btn-white w-50 hpx-32  mx-1 text-login"}
                    onClick={() => {
                      this.setState({ member_type: "name", member_select: "" });
                    }}
                  >
                    {" "}
                    <b>ชื่อ</b>{" "}
                  </button>
                  <button
                    type="button"
                    className={this.state.member_type === "phone" ? "btn bg-button-light w-50 hpx-38 mx-1 text-primary" : "btn btn-white w-50 hpx-32 mx-1 text-login"}
                    onClick={() => {
                      this.setState({ member_type: "phone", member_select: "" });
                    }}
                  >
                    {" "}
                    <b>เบอร์โทรศัพท์</b>
                  </button>
                </div>
              </div>
              {this.state.user && (
                <div className="card rounded-8 btn-select-member p-3">
                  <div className="d-flex  align-items-center">
                    <h5 className="text-title">{this.state.user && this.state.user.full_name ? this.state.user.full_name : "-"}</h5>
                  </div>
                  <label className="text-detail-name text-16 ">
                    เบอร์โทร: {this.state.user && this.state.user.phone ? this.state.user.phone : " - "} | รหัสสมาชิก: {this.state.user && this.state.user.user_code}
                  </label>
                  <div
                    className="run-number bg-danger text-14 pointer"
                    style={{ top: 10, right: 10, position: "absolute" }}
                    onClick={() => {
                      this.setState({ user: null, member_select: "" });
                    }}
                  >
                    <label className="icon mt-2 pointer">{"\uf00d"}</label>
                  </div>
                </div>
              )}
              {/* ค้นหา */}
              <div className="w-100 mt-3">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.list_member &&
                    this.state.list_member.data.map((item) => ({
                      value: item.user_id,
                      label: this.state.member_type === "name" ? item.full_name : item.phone,
                      full_name: item.full_name,
                      phone: item.phone ? item.phone : "-",
                      id: item.user_code,
                      item: item,
                    }))
                  }
                  components={{ Option: UserOption }}
                  styles={customStyles}
                  placeholder={this.state.member_type === "name" ? "ค้นหาด้วยชื่อ" : "ค้นหาด้วยเบอร์โทร"}
                  defaultValue={this.state.member_select}
                  isClearable
                  onChange={(e) => {
                    if (e) {
                      e.item.refund_point = e.item.refund_point < 0 ? 0 : e.item.refund_point;
                      if (this.state.list_member && this.state.list_member.data && e === null) {
                        this.setState({ member_select: "" });
                      } else {
                        this.setState({ member_select: e.label, user: e.item });
                        this.btn_select_member.focus();
                      }
                    }
                  }}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add_member: false, user: null });
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  ref={(node) => (this.btn_select_member = node)}
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.select_member();
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* เพิ่มสมาชิกใหม่ */}
        <Modal
          show={this.state.modal_new_member}
          size="m"
          onHide={() => {
            this.setState({ modal_new_member: false });
            this.ClearFormInPutMember();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เพิ่มสมาชิกใหม่</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value });
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* เลขที่กำกับภาษี: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขที่กำกับภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={13}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice;
                    tax_invoice = e.target.value.replace(/\D/g, "");
                    this.setState({
                      tax_invoice: tax_invoice,
                    });
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone;
                    phone = e.target.value.replace(/\D/g, "");
                    this.setState({
                      phone: phone,
                    });
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100 ">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value });
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected>
                    -- เลือก --
                  </option>
                  <option value="store_normol">ลูกค้าทั่วไป</option>
                  <option value="store_technician">ร้านค้าช่างเย็บ</option>
                  <option value="store_wholesale">ร้านค้าช่วง</option>
                  <option value="store_school">ลูกค้าโรงเรียน</option>
                </select>
              </div>
            </div>
            <hr />
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* จังหวัด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>จังหวัด:</label>
              </div>
              <div className="w-100 ">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.province_list &&
                    this.state.province_list.map((item) => ({
                      value: item.PROVINCE_ID,
                      label: item.PROVINCE_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกจังหวัด --"
                  value={this.state.province}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({
                        province: "",
                        district: "",
                        post_code: "",
                        sub_district: "",
                        districts_list: [],
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                    } else if (this.state.province.value !== e.value) {
                      this.setState({
                        province: e,
                        district: "",
                        post_code: "",
                        sub_district: "",
                        districts_list: [],
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.Districts();
                      }, 10);
                    } else {
                      this.setState({ province: e });
                      setTimeout(() => {
                        this.Districts();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>
            {/* อำเภอ: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อำเภอ:</label>
              </div>
              <div className="w-100 ">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.districts_list &&
                    this.state.districts_list.map((item) => ({
                      value: item.DISTRICT_ID,
                      label: item.DISTRICT_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกอำเภอ --"
                  value={this.state.district}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ district: "", sub_district: "", post_code: "", subdistricts_list: [], zipcode_list: [] });
                    } else if (this.state.district.value !== e.value) {
                      this.setState({
                        district: e,
                        post_code: "",
                        sub_district: "",
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.SubDistricts();
                      }, 10);
                    } else {
                      this.setState({ district: e });
                      setTimeout(() => {
                        this.SubDistricts();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>
            {/* ตำบล: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ตำบล:</label>
              </div>
              <div className="w-100 ">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.subdistricts_list &&
                    this.state.subdistricts_list.map((item) => ({
                      value: item.SUB_DISTRICT_ID,
                      label: item.SUB_DISTRICT_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกตำบล --"
                  value={this.state.sub_district}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ sub_district: "", post_code: "", zipcode_list: [] });
                    } else if (this.state.sub_district.value !== e.value) {
                      this.setState({
                        sub_district: e,
                        post_code: "",
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    } else {
                      this.setState({ sub_district: e });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>
            {/* รหัสไปรษณีย์ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสไปรษณีย์:</label>
              </div>
              <div className="w-100 ">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.zipcode_list &&
                    this.state.zipcode_list.map((item) => ({
                      value: item.ZIPCODE_ID,
                      label: item.ZIPCODE,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกรหัสไปรษณีย์ --"
                  value={this.state.post_code}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ post_code: "" });
                    } else if (this.state.post_code.value !== e.label) {
                      this.setState({
                        post_code: e,
                      });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    } else {
                      this.setState({ post_code: e });
                    }
                  }}
                />
              </div>
            </div>
            <hr />
            {/* เครดิต(วัน) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(วัน):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  maxLength={9}
                  onChange={(e) => {
                    let credit_day = this.state.credit_day;
                    credit_day = e.target.value.replace(/\D/g, "");
                    this.setState({
                      credit_day: credit_day,
                    });
                  }}
                  value={this.state.credit_day}
                />
              </div>
            </div>
            {/* เครดิต(ยอดซื้อ) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(ยอดซื้อ):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={9}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let credit_money = this.state.credit_money;
                    credit_money = e.target.value.replace(/\D/g, "");
                    this.setState({
                      credit_money: credit_money,
                    });
                  }}
                  value={this.state.credit_money}
                />
              </div>
            </div>
            {this.state.alert === 1 && (
              <div>
                <div className="card bg-yellow-warning w-100 p-3 mb-3">
                  <div className=" row d-flex justify-content-center align-content-center p-2">
                    <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                    <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                  </div>
                </div>
              </div>
            )}
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.ClearFormInPutMember();
                    this.setState({ modal_new_member: false, modal_add_member: true });
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.full_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                      return;
                    } else if (this.state.phone.length === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.phone.length !== 10) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรให้ครบ 10 หลัก");
                      return;
                    } else if (this.state.phone.trim() !== "" && !this.state.phone.match(/^^[0][0-9]*$/)) {
                      alert("warning", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง");
                      return;
                    } else if (this.state.user_type === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกประเภท");
                      return;
                    } else if (this.state.province.value === "" || this.state.province.value === undefined) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกจังหวัด");
                      return;
                    } else if (this.state.district.value === "" || this.state.district.value === undefined) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอำเภอ");
                      return;
                    }
                    if (this.state.alert === 0) {
                      this.setState({ alert: 1 });
                    } else {
                      this.AddMember();
                    }
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* modal stock */}
        <Modal
          show={this.state.modal_stock}
          size="m"
          onHide={() => {
            this.setState({ modal_stock: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เลือกสต๊อกสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mb-3">
              <label>โปรดเลือกสต๊อกสินค้าที่จัดเก็บ {this.state.product_name}</label>
            </div>
            <div className="w-100">
              <div className="row mx-0 overflow-scroll">
                {this.state.data_stock &&
                  this.state.data_stock.data.map((item, index) => (
                    <>
                      {/* {item.yard_qty > 0 && item.stock_qty > 0 && ( */}
                      <div className="col-6 px-1 mb-3">
                        <div
                          className={this.state.warehouse_id === item.warehouse_id ? "card bg-secondary-light p-2 text-center pointer border bd-primary" : "card bg-secondary-light p-2 text-center pointer "}
                          key={index}
                          onClick={() => {
                            // if (item.yard_qty !== 0 && item.stock_qty !== 0) {
                            this.setState({ warehouse_id: item.warehouse_id });
                            this.GetProduct(item.warehouse_id);
                            // }
                          }}
                        >
                          <div className="d-flex w-100 justify-content-end">{this.state.warehouse_id === item.warehouse_id ? <input type="checkbox" checked></input> : <input type="checkbox"></input>}</div>
                          <div className="d-flex w-100 justify-content-center mb-3">
                            <div className=" w-100">
                              <h5>
                                <b>{baht(Number(item.yard_qty).toFixed(2))}</b>
                              </h5>
                              <h5>
                                <b>หลา</b>
                              </h5>
                            </div>
                            <div className=" border-right mr-1 ml-1"></div>
                            <div className=" w-100">
                              <h5>
                                <b>{number(item.stock_qty)}</b>
                              </h5>
                              <h5>
                                <b>ไม้</b>
                              </h5>
                            </div>
                          </div>
                          <label>{item.warehouse_name}</label>
                        </div>
                      </div>
                      {/* )} */}
                    </>
                  ))}
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_stock: false });
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  disabled={this.state.product ? false : true}
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.warehouse_id === "") {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกสต๊อกสินค้า");
                      return;
                    } else {
                      let data = this.state.order_list;
                      let check = data.find((e) => {
                        if (e.warehouse_id === this.state.warehouse_id && e.product_main.product_main_data.product_id === this.state.product.product_main_data.product_id) {
                          return true;
                        } else {
                          return false;
                        }
                      });

                      if (!check) {
                        data.push({
                          product_id: this.state.product.product_main_data.product_id,
                          product_main: this.state.product,
                          warehouse_id: this.state.warehouse_id,
                          price_by_item: this.state.product.special_price === 0 ? this.state.product.product_main_data.store_price : this.state.product.special_price,
                          //ไม้
                          qty: 1,
                          //หลา
                          yard_qty: 0,
                          detail_yard_list: [""],

                          total_price: 0,
                          total_price_cost: this.state.product.product_main_data.cost_price,
                          warehouse_name: this.state.product.warehouse_name,
                        });
                      } else {
                        let index_of = data.findIndex((e) => e.product_id === this.state.product.product_main_data.product_id);
                        if (index_of !== -1) {
                          data[index_of].qty += 1;
                          data[index_of].detail_yard_list = [...data[index_of].detail_yard_list, ""];
                        }
                        // alert("info", "แจ้งเตือน", "มีสินค้าชนิดนี้ในรายการเเล้ว")
                        // return
                      }
                      this.setState({
                        order_list: data,
                        modal_stock: false,
                        count_order: data.length,
                        warehouse_id: "",
                      });
                      setTimeout(() => {
                        this.Calculate();
                      }, 10);
                    }
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ยืนยันการพักออเดอร์ */}
        <Modal
          show={this.state.modal_pause_order}
          size="m"
          onHide={() => {
            this.setState({ modal_pause_order: false });
          }}
        >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={CLOCK}></img>
              <h3>
                <b className="text-title">ยืนยันการพักออเดอร์</b>
              </h3>
              <div className="w-75 text-center mx-auto text-detail-name mb-2">คุณแน่ใจว่าต้องการพักออเดอร์สินค้า</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_pause_order: false });
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ modal_pause_order: false });
                    if (this.props.location.query.retry_id || this.state.pause_status === true || this.state.edit_status) {
                      this.setState({
                        status_order_main: 4,
                      });
                    } else {
                      this.setState({
                        status_order_main: 4,
                        payment_type: 0,
                      });
                    }
                    setTimeout(() => {
                      localStorage.removeItem("bill");
                      localStorage.removeItem("user_code");
                      localStorage.setItem("POS", JSON.stringify(this.state));
                      window.location.href = "/main";
                    }, 10);
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ดึงข้อมูลออเดอร์ที่พักไว้ */}
        <Modal
          show={this.state.modal_pause_data}
          onHide={() => {
            this.setState({ modal_pause_data: false });
          }}
        >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={CLOCK}></img>
              <h2>
                {" "}
                <b style={{ color: "#595959" }}>ดึงข้อมูลออเดอร์ที่พักไว้</b>{" "}
              </h2>
              <div className="w-75 text-center mx-auto text-secondary mb-2"> คุณแน่ใจว่าต้องการดึงข้อมูลออเดอร์ที่พักไว้ </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_pause_data: false });
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={async () => {
                    let data_hole = await localStorage.getItem("POS");
                    this.setState(JSON.parse(data_hole));
                    localStorage.setItem("status_bill", JSON.parse(data_hole).status_bill);
                    this.setState({ pause_status: true, data_hole: data_hole });
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ยืนยันเคลียร์หน้าจอ */}
        <Modal
          size="m"
          show={this.state.modal_cancel}
          onHide={() => {
            this.setState({ modal_cancel: false });
          }}
        >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={WARNING}></img>
              <h3>
                <b className="text-title">{this.state.pause_status === true && ((this.state.check_bill === 1 && this.state.payment_type === 0) || (this.state.check_bill === 0 && this.state.payment_type === 0)) ? "ยืนยันการยกเลิกออเดอร์ที่พักไว้" : "ยืนยันเคลียร์หน้าจอ"} </b>
              </h3>
              <div className="w-75 text-center mx-auto text-detail-name mb-2 text-14">{this.state.pause_status === true && ((this.state.check_bill === 1 && this.state.payment_type === 0) || (this.state.check_bill === 0 && this.state.payment_type === 0)) ? "คุณแน่ใจว่าต้องการยกเลิกออเดอร์ที่พักไว้หรือไม่ เมื่อยืนยันแล้วจะไม่สามารถย้อนคืนข้อมูลได้" : "คุณแน่ใจว่าต้องการเคลียร์รายการสินค้าทั้งหมด เมื่อยืนยันแล้วจะไม่สามารถย้อนคืนข้อมูลได้"}</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    if (this.state.pause_status === true) {
                      this.setState({ modal_cancel: false });
                    } else {
                      this.setState({ modal_cancel: false });
                    }
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    if (this.state.data_hole) {
                      localStorage.removeItem("POS");
                      this.setState({ modal_cancel: false });
                      alert_url("success", "ยกเลิกเรียบร้อยแล้ว", "ยกเลิกรายการสินค้าทั้งหมดแล้ว", "/main");
                    } else {
                      this.setState({ modal_cancel: false });
                      alert_url("success", "ยกเลิกเรียบร้อยแล้ว", "ยกเลิกรายการสินค้าทั้งหมดแล้ว", "/main");
                    }
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ยืนยันการลบ พักออร์เดอร์ */}
        <Modal
          size="m"
          show={this.state.modal_remove_pause}
          onHide={() => {
            this.setState({ modal_remove_pause: false });
          }}
        >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={WARNING}></img>
              <h3>
                <b className="text-title">แจ้งเตือน</b>
              </h3>
              <div className="w-75 text-center mx-auto text-detail-name mb-2 text-14">มีการพักออร์เดอร์ค้างอยู่ในระบบ ยืนยันที่จะล้างข้อมูลการพักออร์เดอร์แล้วออกจากหน้านี้หรือไม่</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_remove_pause: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    localStorage.removeItem("POS");
                    this.setState({ modal_remove_pause: false });
                    if (this.state.page_name === "/save") {
                      this.setState({ status_order_main: 1, payment_type: 0 });
                      setTimeout(() => {
                        let bill = localStorage.getItem("bill");
                        if (this.props.location.query.retry_id || this.state.edit_status) {
                          if (bill === undefined) {
                            this.setState({ status_order_main: 1, payment_type: 0, calculate_type: "เงินสด" });
                            this.CreateOrder();
                          } else {
                            this.EditOrder();
                          }
                        } else {
                          this.CreateOrder();
                        }
                      }, 20);
                    } else if (this.state.page_name === "/transaction") {
                      localStorage.removeItem("bill");
                      localStorage.removeItem("user_code");
                      window.location.href = "/transaction";
                    } else if (this.state.page_name === "/accrued") {
                      localStorage.removeItem("bill");
                      localStorage.removeItem("user_code");
                      window.location.href = "/accrued";
                    }
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ชำระเงิน modal */}
        <Modal
          size="m"
          show={this.state.modal_payment}
          onHide={() => {
            this.setState({ modal_payment: false });
            this.payment_clear();
          }}
        >
          <Modal.Body>
            <div className="center d-flex flex-column rounded btn-select-member hpx-62 mb-0">
              <b> เครดิตที่ใช้ได้ : {this.state.member && baht(this.state.member.credit_money_balance)} บาท </b>
              <b> เครดิตรวม : {this.state.member && baht(this.state.member.credit_money)} บาท </b>
            </div>
            <div className="w-100 d-flex mt-3">
              {/* เงินสด status 1 */}
              <div className="w-33 px-1">
                <button
                  className={this.state.calculate_type === "เงินสด" ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative pointer" : "btn btn-calculate bg-white w-100 text-center p-0 position-relative pointer"}
                  onClick={() => {
                    this.payment_clear();
                    if (this.state.status_bill === "ใบเสนอราคา") {
                      this.setState({
                        calculate_type: "เงินสด",
                        price: this.state.deposit,
                        payment_type: 1,
                        status_order_main: 5,
                      });
                    } else {
                      let price = this.state.total_price_final;
                      if (this.state.select_point) {
                        price = Number(this.state.total_price_final - this.state.member.refund_point);
                      }
                      this.setState({
                        calculate_type: "เงินสด",
                        price: price,
                        payment_type: 1,
                      });
                    }
                  }}
                >
                  <label className={this.state.calculate_type === "เงินสด" ? "icon mb-0 pointer text-header " : "icon mb-0 pointer"}>{"\uf0d6"}</label>
                  <br />
                  <p className={this.state.calculate_type === "เงินสด" ? "text-header" : "text-detail-name"}>เงินสด</p>
                  {this.state.calculate_type === "เงินสด" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>
              {/* เงินเชื่อ status 2 */}
              <div className="w-33 px-1">
                <button
                  className={this.state.calculate_type === "เงินเชื่อ" ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative pointer" : "btn btn-calculate bg-white w-100 text-center p-0 position-relative pointer"}
                  onClick={() => {
                    this.payment_clear();

                    let price = this.state.total_price_final;
                    if (this.state.select_point) {
                      price = Number(this.state.total_price_final - this.state.member.refund_point);
                    }
                    this.setState({
                      calculate_type: "เงินเชื่อ",
                      price: price,
                      payment_type: 2,
                      status_order_main: 1,
                    });
                    this.btn.focus();
                  }}
                  disabled={this.state.status_bill === "ใบเสนอราคา" || (this.state.member && this.state.member.user_code === "ME000000001") || (this.state.member && this.state.member.credit_money === 0) || this.state.credit_money_balance === 0 ? true : false}
                >
                  <label className={this.state.calculate_type === "เงินเชื่อ" ? "icon mb-0 pointer text-header" : "icon mb-0 pointer"}>{"\uf073"}</label>
                  <br />
                  <p className={this.state.calculate_type === "เงินเชื่อ" ? "text-header" : "text-detail-name"}>เงินเชื่อ</p>
                  {this.state.calculate_type === "เงินเชื่อ" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>
              {/* เงินโอน status 3 */}
              <div className="w-33 px-1">
                <button
                  // disabled={this.state.select_point && Number(this.state.total_price_final === 0) ? true : false}
                  className={this.state.calculate_type === "เงินโอน" ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative pointer" : "btn btn-calculate bg-white w-100 text-center p-0 position-relative pointer"}
                  onClick={() => {
                    this.payment_clear();
                    let data = this.state.payment_detail;
                    data.full_name_transfer = this.state.member.full_name;
                    this.setState({
                      payment_detail: data,
                    });
                    if (this.state.status_bill === "ใบเสนอราคา") {
                      this.setState({
                        calculate_type: "เงินโอน",
                        price: this.state.deposit,
                        payment_type: 3,
                        status_order_main: 5,
                      });
                    } else {
                      let price = this.state.total_price_final;
                      if (this.state.select_point) {
                        price = Number(this.state.total_price_final - this.state.member.refund_point);
                      }
                      this.setState({
                        calculate_type: "เงินโอน",
                        price: price,
                        payment_type: 3,
                      });
                    }
                  }}
                >
                  <label className={this.state.calculate_type === "เงินโอน" ? "icon mb-0 pointer text-header" : "icon mb-0 pointer"}>{"\uf0ec"}</label>
                  <br />
                  <p className={this.state.calculate_type === "เงินโอน" ? "text-header" : "text-detail-name"}>เงินโอน</p>
                  {this.state.calculate_type === "เงินโอน" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>
            </div>
            {/* เงินสด */}
            {this.state.calculate_type === "เงินสด" && (
              <div className="w-100">
                <div className="d-flex py-2 ">
                  {/* ปุ่มลบ */}
                  <div
                    className="btn btn-link text-secondary mt-3 pointer"
                    onClick={() => {
                      this.setState({ price: "" });
                    }}
                  >
                    <label className="icon bg-dark text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                      {"\uf00d"}
                    </label>
                  </div>
                  {/* ช่องกรอก */}
                  <input
                    id="price"
                    className="text-right w-100 border-0 text-32"
                    style={{ fontWeight: "bold" }}
                    type="text"
                    placeholder="0.00"
                    maxLength={14}
                    onChange={(e) => {
                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                        this.setState({ price: e.target.value });
                      }
                    }}
                    value={this.state.price}
                    autoFocus
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        this.btn.click();
                      }
                    }}
                  />
                </div>
                <div className="row mx-0">
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(7);
                    }}
                  >
                    <div>
                      <h5>7</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(8);
                    }}
                  >
                    <div>
                      <h5>8</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(9);
                    }}
                  >
                    <div>
                      <h5>9</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(1000);
                    }}
                  >
                    <div className="primary">
                      <h5>1,000</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(4);
                    }}
                  >
                    <div>
                      <h5>4</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(5);
                    }}
                  >
                    <div>
                      <h5>5</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(6);
                    }}
                  >
                    <div>
                      <h5>6</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(500);
                    }}
                  >
                    <div className="primary">
                      <h5>500</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(1);
                    }}
                  >
                    <div>
                      <h5>1</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(2);
                    }}
                  >
                    <div>
                      <h5>2</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(3);
                    }}
                  >
                    <div>
                      <h5>3</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(100);
                    }}
                  >
                    <div className="primary">
                      <h5>100</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(".");
                    }}
                  >
                    <div>
                      <h5>.</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate(0);
                    }}
                  >
                    <div>
                      <h5>0</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate("delete");
                    }}
                  >
                    <div>
                      <h5 className="icon">{"\uf55a"}</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal pointer"
                    onClick={() => {
                      this.calculate("full");
                    }}
                  >
                    <div className="primary">
                      <h5>เต็ม</h5>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* เงินเชื่อ */}
            {this.state.calculate_type === "เงินเชื่อ" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end align-items-center py-4">
                  <b className="text-32">{baht(this.state.price)}</b>
                </div>
                <div className="card bg-secondary-light p-3">
                  <div className="d-flex justify-content-between">
                    <label className="w-25">เลขสมาชิก: </label>
                    <label className="w-75 text-right">{this.state.member && this.state.member.user_code}</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="w-25">ชื่อ-นามสกุล: </label>
                    <label className="w-75 text-right">{this.state.member && this.state.member.full_name}</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <b className="w-25">เครดิต(วัน): </b>
                    <b className="w-75 text-right">{this.state.member && this.state.member.credit_day} วัน</b>
                  </div>
                  <div className="d-flex justify-content-between">
                    <b className="w-50">เครดิต(ยอดซื้อ): </b>
                    <b className="w-50 text-right">{this.state.member && baht(this.state.member.credit_money)} บาท</b>
                  </div>
                </div>
                <div className="w-100 mt-4 mb-3">
                  <input
                    className="form-control bg-secondary-light border-0"
                    type="text"
                    placeholder="ไม่จำเป็นต้องกรอก"
                    onChange={(e) => {
                      let data = this.state.payment_detail;
                      data.remark_payment_s2 = e.target.value;
                      this.setState({
                        payment_detail: data,
                      });
                    }}
                    value={this.state.payment_detail.remark_payment_s2}
                  />
                </div>
              </div>
            )}
            {/* เงินโอน */}
            {this.state.calculate_type === "เงินโอน" && (
              <div className="w-100">
                <div className="w-100">
                  <div className="d-flex py-2 ">
                    {/* ปุ่มลบ */}
                    <div
                      className="btn btn-link text-secondary mt-3 pointer"
                      onClick={() => {
                        this.setState({ price: "" });
                      }}
                    >
                      <label className="icon bg-dark text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                        {"\uf00d"}
                      </label>
                    </div>
                    {/* ช่องกรอก */}
                    <input
                      id="price"
                      className="text-right w-100 border-0 text-32"
                      style={{ fontWeight: "bold" }}
                      type="text"
                      placeholder="0.00"
                      maxLength={14}
                      onChange={(e) => {
                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                          this.setState({ price: e.target.value });
                        }
                      }}
                      value={this.state.price}
                      autoFocus
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                  <div className="w-100 text-center mb-3">
                    <label
                      className="text-primary pointer"
                      onClick={() => {
                        this.setState({ input: !this.state.input });
                      }}
                    >
                      กรอกตัวเลขด้วยตัวเอง
                      <span className="icon-brand ml-2">{this.state.input ? "\uf077" : "\uf078"}</span>
                    </label>
                  </div>
                  {this.state.input && (
                    <div className="row mx-0">
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(7);
                        }}
                      >
                        <div>
                          <h5>7</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(8);
                        }}
                      >
                        <div>
                          <h5>8</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(9);
                        }}
                      >
                        <div>
                          <h5>9</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(1000);
                        }}
                      >
                        <div className="primary">
                          <h5>1,000</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(4);
                        }}
                      >
                        <div>
                          <h5>4</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(5);
                        }}
                      >
                        <div>
                          <h5>5</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(6);
                        }}
                      >
                        <div>
                          <h5>6</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(500);
                        }}
                      >
                        <div className="primary">
                          <h5>500</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(1);
                        }}
                      >
                        <div>
                          <h5>1</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(2);
                        }}
                      >
                        <div>
                          <h5>2</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(3);
                        }}
                      >
                        <div>
                          <h5>3</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(100);
                        }}
                      >
                        <div className="primary">
                          <h5>100</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(".");
                        }}
                      >
                        <div>
                          <h5>.</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate(0);
                        }}
                      >
                        <div>
                          <h5>0</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate("delete");
                        }}
                      >
                        <div>
                          <h5 className="icon">{"\uf55a"}</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal pointer"
                        onClick={() => {
                          this.calculate("full");
                        }}
                      >
                        <div className="primary">
                          <h5>เต็ม</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-100 mt-3">
                  <Select
                    isSearchable={false}
                    className="px-0 mb-3 pointer"
                    options={
                      this.state.bank_list &&
                      this.state.bank_list.data.map((item, index) => ({
                        value: item.bank_id,
                        label: item.bank_name,
                        logo: item.logo,
                        bank_number: item.bank_number,
                      }))
                    }
                    components={{ Option: IconOption }}
                    placeholder="เลือกธนาคาร"
                    styles={customStyles}
                    onChange={(e) => {
                      let data = this.state.payment_detail;
                      data.bank_name = e.label;
                      data.bank_number = e.bank_number;
                      this.setState({
                        payment_detail: data,
                      });
                      this.textInput.focus();
                    }}
                  />
                </div>
                <div className="row mt-3">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="ชื่อ-นามสกุลผู้โอน"
                      ref={(click) => (this.textInput = click)}
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.full_name_transfer = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.full_name_transfer}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-12 mb-3 pl-0 pr-1 ">
                    <input
                      className="form-control bg-secondary-light border-0"
                      type="datetime-local"
                      placeholder="วัน เดือน ปี"
                      ref={(click) => (this.dateInput = click)}
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.datetime_transfer = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });

                        this.dateInput.focus();
                      }}
                      min={this.state.check_date}
                      value={this.state.payment_detail.datetime_transfer}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="หมายเลขอ้างอิง"
                      maxLength={16}
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.reference_number = e.target.value.replace(/\D/g, "");
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.reference_number}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.remark_payment_s3 = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.remark_payment_s3}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="w-100 my-1">
                  <label>คำแนะนำ: โปรดเก็บหลักฐานการชำระเงินทุกครั้ง หลังกรอกข้อมูลเสร็จสิ้นแล้ว</label>
                </div>
              </div>
            )}
            <div className="w-100 d-flex mt-3">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_payment: false });
                    this.payment_clear();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  ref={(node) => (this.btn = node)}
                  type="submit"
                  onClick={() => {
                    let payment_detail = this.state.payment_detail;
                    if (this.state.calculate_type === "เงินสด") {
                      if (Number(this.state.price) >= Number(this.state.total_price_final)) {
                        if (this.state.status_bill !== "ใบเสนอราคา") {
                          if (Number(this.state.price) === 0) {
                            this.setState({ loading: false });
                            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
                            return;
                          }
                        }
                        this.setState({ status_order_main: this.state.status_bill === "ใบเสนอราคา" ? 5 : 2 });
                        payment_detail.total_pay = Number(this.state.price);
                      } else {
                        if (this.state.status_bill !== "ใบเสนอราคา") {
                          if (Number(this.state.price) === 0 && this.state.select_point === false) {
                            this.setState({ loading: false });
                            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
                            return;
                          }
                        }
                        this.setState({ status_order_main: this.state.status_bill === "ใบเสนอราคา" ? 5 : 1 });
                        payment_detail.total_pay = Number(this.state.price);
                      }
                    } else if (this.state.calculate_type === "เงินเชื่อ") {
                      payment_detail.total_pay = Number(this.state.price);
                      this.setState({ status_order_main: 1 });
                    } else if (this.state.calculate_type === "เงินโอน") {
                      if (payment_detail.bank_name === "") {
                        alert("info", "แจ้งเตือน", "กรุณาเลือกธนาคาร");
                        return;
                      } else if (payment_detail.full_name_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุลผู้โอน");
                        return;
                      } else if (payment_detail.datetime_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกวัน-เวลาที่โอน");
                        return;
                      }
                      if (Number(this.state.price) >= Number(this.state.total_price_final)) {
                        if (this.state.status_bill !== "ใบเสนอราคา") {
                          if (Number(this.state.price) === 0) {
                            this.setState({ loading: false });
                            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
                            return;
                          }
                        }
                        this.setState({ status_order_main: this.state.status_bill === "ใบเสนอราคา" ? 5 : 2 });
                        payment_detail.total_pay = Number(this.state.price);
                      } else {
                        if (this.state.status_bill !== "ใบเสนอราคา") {
                          if (Number(this.state.price) === 0) {
                            this.setState({ loading: false });
                            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
                            return;
                          }
                        }
                        this.setState({ status_order_main: this.state.status_bill === "ใบเสนอราคา" ? 5 : 1 });
                        payment_detail.total_pay = Number(this.state.price);
                      }
                    }
                    this.setState({ payment_detail: payment_detail });
                    setTimeout(() => {
                      if (this.state.data_hole) {
                        localStorage.removeItem("POS");
                      }
                      if (this.props.location.query.retry_id || this.state.edit_status) {
                        let bill = localStorage.getItem("bill");
                        if (bill === undefined) {
                          this.CreateOrder();
                        } else {
                          this.EditOrder();
                        }
                      } else {
                        this.CreateOrder();
                      }
                    }, 20);
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* modal edit price */}
        <Modal
          size="m"
          show={this.state.modal_edit_price}
          onHide={() => {
            this.setState({ modal_edit_price: false });
          }}
        >
          <Modal.Body>
            <div className="w-100">
              <div className="d-flex py-2 ">
                {/* ปุ่มลบ */}
                <div
                  className="btn btn-link text-secondary mt-3 pointer"
                  onClick={() => {
                    this.setState({ price: "" });
                  }}
                >
                  <label className="icon bg-dark text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                    {"\uf00d"}
                  </label>
                </div>
                {/* ช่องกรอก */}
                <input
                  id="price"
                  className="text-right w-100 border-0 text-32"
                  style={{ fontWeight: "bold" }}
                  type="text"
                  placeholder="0.00"
                  maxLength={14}
                  onChange={(e) => {
                    if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                      this.setState({ price: e.target.value });
                    }
                  }}
                  autoFocus
                  value={this.state.price}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.btn_edit.click();
                    }
                  }}
                />
              </div>
              <div className="row mx-0">
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(7);
                  }}
                >
                  <div>
                    <h5>7</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(8);
                  }}
                >
                  <div>
                    <h5>8</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(9);
                  }}
                >
                  <div>
                    <h5>9</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(1000);
                  }}
                >
                  <div className="primary">
                    <h5>1,000</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(4);
                  }}
                >
                  <div>
                    <h5>4</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(5);
                  }}
                >
                  <div>
                    <h5>5</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(6);
                  }}
                >
                  <div>
                    <h5>6</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(500);
                  }}
                >
                  <div className="primary">
                    <h5>500</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(1);
                  }}
                >
                  <div>
                    <h5>1</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(2);
                  }}
                >
                  <div>
                    <h5>2</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(3);
                  }}
                >
                  <div>
                    <h5>3</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(100);
                  }}
                >
                  <div className="primary">
                    <h5>100</h5>
                  </div>
                </div>
                <div
                  className="col-3 cal pointer"
                  onClick={() => {
                    this.calculate(".");
                  }}
                >
                  <div>
                    <h5>.</h5>
                  </div>
                </div>
                {this.state.input_edit_price === "แก้ไขราคา" ? (
                  <>
                    <div
                      className="col-3 cal pointer"
                      onClick={() => {
                        this.calculate(0);
                      }}
                    >
                      <div>
                        <h5>0</h5>
                      </div>
                    </div>
                    <div
                      className="col-3 cal pointer"
                      onClick={() => {
                        this.calculate("delete");
                      }}
                    >
                      <div>
                        <h5 className="icon">{"\uf55a"}</h5>
                      </div>
                    </div>
                    <div
                      className="col-3 cal pointer"
                      onClick={() => {
                        this.calculate("wholesaleprice");
                      }}
                    >
                      <div className="primary">
                        <h5>ราคาส่ง</h5>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="col-6 cal pointer"
                      onClick={() => {
                        this.calculate(0);
                      }}
                    >
                      <div>
                        <h5>0</h5>
                      </div>
                    </div>
                    <div
                      className="col-3 cal pointer"
                      onClick={() => {
                        this.calculate("delete");
                      }}
                    >
                      <div>
                        <h5 className="icon">{"\uf55a"}</h5>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit_price: false, price: "" });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  ref={(node) => (this.btn_edit = node)}
                  type="submit"
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let data = this.state.order_list;
                    let summary = 0;
                    let total_price = 0;
                    let discount = Number(this.state.discount);
                    let delivery_price = Number(this.state.delivery_price);
                    for (let i = 0; i < data.length; i++) {
                      data[i].total_price = data[i].yard_qty * data[i].price_by_item;
                      total_price += data[i].total_price;
                    }
                    summary = Number(total_price) + Number(delivery_price);
                    if (this.state.input_edit_price === "ค่าบริการจัดส่ง") {
                      this.setState({ delivery_price: Number(this.state.price), discount: 0.0 });
                    } else if (this.state.input_edit_price === "ค่ามัดจำ") {
                      if (Number(this.state.price) > Number(summary) - Number(discount)) {
                        alert("info", "แจ้งเตือน", "ไม่สามารถกรอกค่ามัดจำมากกว่ายอดเงินรวมทั้งหมดได้");
                        this.setState({ price: Number(summary) - Number(discount), discount: 0.0 });
                        return;
                      } else if (Number(this.state.price) === Number(summary) - Number(discount)) {
                        alert("info", "แจ้งเตือน", "ไม่สามารถชำระค่ามัดจำทั้งหมดได้");
                        this.setState({ price: "0.00" });
                        return;
                      }
                      this.setState({ deposit: Number(this.state.price) });
                    } else if (this.state.input_edit_price === "ส่วนลด") {
                      if (Number(this.state.price) > Number(summary)) {
                        alert("info", "แจ้งเตือน", "ไม่สามารถกรอกส่วนลดมากกว่ายอดเงินรวมทั้งหมดได้");
                        this.setState({ price: summary });
                        return;
                      }
                      this.setState({ discount: Number(this.state.price) });
                    } else if (this.state.input_edit_price === "แก้ไขราคา") {
                      let data = this.state.order_list;
                      data[this.state.index].price_by_item = Number(this.state.price);
                      this.setState({ order_list: data, deposit: 0.0, discount: 0.0 });
                    }
                    setTimeout(() => {
                      this.Calculate();
                    }, 10);
                    this.setState({ modal_edit_price: false, select_point: false });
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* modal_text_input */}
        <Modal
          show={this.state.modal_text_input}
          onHide={() => {
            this.setState({ modal_text_input: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">{this.state.title_text_input}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mb-3">
              <textarea
                className="form-control border-0"
                placeholder="ไม่จำเป็นต้องกรอก"
                onChange={(e) => {
                  if (this.state.title_text_input === "ที่อยู่จัดส่ง") {
                    this.setState({ text_input: e.target.value });
                  } else {
                    this.setState({ text_input: e.target.value });
                  }
                }}
                value={this.state.text_input}
              ></textarea>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_text_input: false });
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.title_text_input === "ที่อยู่จัดส่ง") {
                      this.setState({ delivery_address: this.state.text_input });
                    } else {
                      this.setState({ remark: this.state.text_input });
                    }
                    this.setState({ modal_text_input: false });
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ปริ้น modal */}
        <ModalPrint
          text_modal={this.state.text_modal}
          value={this.state.message_detail}
          general={this.state.general}
          show={this.state.modal_print}
          vat={this.state.check_tax}
          Screen={"MainScreen"}
          onHide={() => {
            localStorage.removeItem("user_code");
            localStorage.removeItem("bill");
            this.setState({ modal_print: false });
            this.setState({ status_bill: "ขายสินค้า" });
            localStorage.setItem("status_bill", "ขายสินค้า");
            window.location.href = "/main";
          }}
        />
      </div>
    );
  }
}
