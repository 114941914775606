/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { alert, alert_url, GET, POST, tokens, format_date, baht, total, PUT } from "../../components/CustomComponent.js";
import { ExcelRenderer } from "react-excel-renderer";
import WARNING from "../../assets/images/warning.png";
import "../../assets/css/bootstrap.css";
import moment from "moment-timezone";
export default class ReceiptUpdateCostPriceScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      loading: false,
      modal_date: false,
      modal_supplier: false,
      modal_ref: false,
      modal_warehouse: false,

      date: "",

      data: [],
      warehouselist: [],
      companylist: [],
      productlist: [],

      company: "",
      company_supplier_id: "",
      ref_code: "",
      ref_codes: "",

      warehouse_id: "",

      warehouse: "",
      product_value: null,
      qty: "",
      yard_qty: "",
      price_by_item: "",
      total_price_by_item: "0.00",

      total_qty: 0,
      total_yard_qty: 0,
      total_final: 0,
      total_price_all: 0,

      search: "",

      user_id: "",
      product_id: "",
      type_product_id: "",
      status_url: "",
      file_name: "",

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",

      status: "0",
      bill_receipt_code: "",

      alert: 0,
      product_color: "",
      timeZone: moment.tz.guess(),
      user_type: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).user_type : "",
    };
  }
  async componentDidMount() {
    await this.GetWareHouse();
    await this.GetCompany();
    await this.Get_by_id(this.props.location.query.id);
  }

  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/goods-receipt/detail/" + id, null);
    if (result && result.status) {
      let company_id = "";
      let company_name = "";
      let fomat = [];
      let data = result.data.data;
      let warehouse_id = this.state.warehouselist.find((e) => e.warehouse_id === data.goods_receipt_list[0].all_stock_data.warehouse_id).warehouse_id;
      let warehouse_name = this.state.warehouselist.find((e) => e.warehouse_name === data.goods_receipt_list[0].all_stock_data.warehouse_name).warehouse_name;

      if (data.company_supplier_data) {
        company_id = this.state.companylist.find((e) => e.company_id === data.company_supplier_data.company_id).company_id;
        company_name = this.state.companylist.find((e) => e.company_id === data.company_supplier_data.company_id).company_name;
      }

      let datetime = Number(data.receive_date.split("T")[0].split("-")[0]) + "-" + ("0" + Number(data.receive_date.split("-")[1].split("/")[0])).toString().slice(-2) + "-" + data.receive_date.split("T")[0].split("-")[2];

      for (let i = 0; i < data.goods_receipt_list.length; i++) {
        let make_fomat = {
          product_code: data.goods_receipt_list[i].all_stock_data.product_main_data.product_code,
          product_name: data.goods_receipt_list[i].all_stock_data.product_main_data.product_name,
          product_color: data.goods_receipt_list[i].all_stock_data.product_main_data.product_color,
          product_design: data.goods_receipt_list[i].all_stock_data.product_main_data.product_design,
          qty: data.goods_receipt_list[i].qty,
          yard_qty: data.goods_receipt_list[i].yard_qty,
          price_by_item: data.goods_receipt_list[i].price_by_item,
          total_price_by_item: data.goods_receipt_list[i].total_price_by_item,
          check: true,
        };
        fomat.push(make_fomat);
      }

      this.setState({
        bill_receipt_code: data.bill_receipt_code,

        ref_code: data.ref_receipt_code,
        ref_codes: data.ref_receipt_code,

        company_supplier_id: company_id ? company_id : "",
        company: company_name ? company_name : "",

        warehouse_id: warehouse_id,
        warehouse: warehouse_name,

        date: datetime,
        reciept_date: datetime,

        data: fomat,
      });

      if (this.state.warehouse_id !== "") {
        this.GetProduct();
      }

      if (this.state.data) {
        this.setState({ total_price_all: total(this.state.data, "total_price_by_item") });
      }

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  GetWareHouse = async () => {
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);
    if (result && result.status) {
      this.setState({ warehouselist: result.data.data });
    }
  };
  GetCompany = async () => {
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/user-member/supplier/search/page/0", body);
    if (result && result.status) {
      this.setState({ companylist: result.data.data });
    }
  };
  GetProduct = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/filter-search/page/0?data_search" + this.state.search + "&status=" + this.state.status_url + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + this.state.warehouse_id + "&user_member_id=" + this.state.user_id + "&product_id=" + this.state.product_id + "&sort_name=" + this.state.sort_name + "&sort_yard=" + this.state.sort_yard + "&sort_color=" + this.state.sort_color + "&product_design=" + this.state.design_value.replaceAll("#", "%23") + "&color=" + this.state.product_color, null);

    if (result && result.status) {
      this.setState({ productlist: result.data.data.data_returns });
    }
    this.setState({ loading: false });
  };
  Upload_File = (e) => {
    try {
      let fileObj = e.target.files[0];
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          alert("warning", "เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง");
          return;
        } else {
          let data = resp.rows;
          let array_data = this.state.data;
          for (let i = 1; i < data.length; i++) {
            if (data[i].length !== 7 && data[i].length !== 0) {
              alert("warning", "แจ้งเตือน", "ฟอร์มไม่ถูกต้อง");
              return;
            }
            if (data[i].length > 0 && data[i] !== data[0]) {
              let product_list = {
                product_code: data[i][0],
                product_name: data[i][1],
                product_color: data[i][2],
                product_design: data[i][3],
                qty: Number.isFinite(data[i][4]) ? Number(data[i][4].toFixed(0)) : "",
                yard_qty: Number.isFinite(data[i][5]) ? Number(data[i][5]) : "",
                price_by_item: Number.isFinite(data[i][6]) ? Number(data[i][6]) : "",
                total_price_by_item: Number(data[i][5]) * Number(data[i][6]),
                check: true,
              };
              array_data.push(product_list);
            }
          }
          this.setState({
            data: array_data,
            file_name: fileObj.name,
          });
          setTimeout(() => {
            this.Calculate_Add_Price();
          }, 10);
          document.getElementById("file").value = "";
        }
      });
    } catch (error) {
      alert("warning", "เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง");
      document.getElementById("file").value = "";
    }
  };
  CheckAddProduct = () => {
    let data = this.state.data;
    let productlist = this.state.productlist;
    let check_data = true;

    data.forEach((e) => {
      if (e.product_code === undefined || e.product_name === undefined || e.qty === undefined || e.yard_qty === undefined || e.price_by_item === undefined || e.total_price_by_item === undefined) {
        alert("warning", "ไม่สามารถอัปโหลดข้อมูลได้", "พบข้อมูลบางรายการเป็นค่าว่าง\nกรุณาอัปโหลดไฟล์ใหม่อีกครั้ง");
        e.check = false;
        if (check_data) {
          check_data = false;
        }
      } else {
        let product_code = productlist.find((item) => item.product_main_data.product_code === e.product_code);
        if (product_code === undefined) {
          alert("warning", "ไม่สามารถอัปโหลดข้อมูลได้", "ไม่พบข้อมูลในระบบ\nกรุณาอัปโหลดไฟล์ใหม่อีกครั้ง");
          e.check = false;
          if (check_data) {
            check_data = false;
          }
        } else if (e.qty === "" || e.yard_qty === "" || e.price_by_item === "") {
          alert("warning", "ไม่สามารถอัปโหลดข้อมูลได้", "ข้อมูลบางรายการไม่ถูกต้อง\nกรุณาอัปโหลดไฟล์ใหม่อีกครั้ง");
          e.check = false;
          if (check_data) {
            check_data = false;
          }
        }
      }
    });
    this.setState({ data: data });
    setTimeout(() => {
      if (check_data) {
        this.AddReceipt();
      }
    }, 10);
  };
  Add_Product = () => {
    let d = this.state.data.filter((e) => e.product_code === this.state.product_value.product_code);

    if (d.length !== 0) {
      alert("warning", "แจ้งเตือน", "มีสินค้าชนิดนี้อยู่ในรายการ");
      return;
    } else {
      this.setState({
        data: [
          ...this.state.data,
          {
            product_code: this.state.product_value.product_code,
            product_name: this.state.product_value.product_name,
            product_color: this.state.product_value.product_color,
            product_design: this.state.product_value.product_design,
            qty: Number(this.state.qty),
            yard_qty: Number(this.state.yard_qty),
            price_by_item: Number(this.state.price_by_item),
            total_price_by_item: this.state.total_price_by_item,
            check: true,
          },
        ],
        product_value: null,
        qty: "",
        yard_qty: "",
        price_by_item: "",
        total_price_by_item: "0.00",
      });
      setTimeout(() => {
        this.Calculate_Add_Price();
      }, 10);
    }
  };
  UpdatePrice = async () => {
    this.setState({ loading: true });
    let data = this.state.data;
    let array_data = [];
    let date = this.state.date.split("-")[2].split("/")[0] + "/" + ("0" + Number(this.state.date.split("-")[1].split("/")[0])).toString().slice(-2) + "/" + Number(this.state.date.split("T")[0].split("-")[0]);

    let company_id = "";
    if (this.state.company) {
      company_id = this.state.companylist.find((e) => e.company_name === this.state.company).company_id;
    }

    data.forEach((e) => {
      let t = {
        product_code: e.product_code,
        price_by_item: Number(e.price_by_item),
        qty: Number(e.qty),
        yard_qty: Number(e.yard_qty),
        total_price_by_item: Number(e.total_price_by_item),
      };
      array_data.push(t);
    });

    setTimeout(async () => {
      let body = {
        g_rec_main_id: this.props.location.query.id,
        date_time_receipt: date,
        company_supplier_id: company_id,
        bill_receipt_code: this.state.bill_receipt_code,
        ref_receipt_code: this.state.ref_code,
        discount: 0,
        total_price_all: this.state.total_price_all,
        status: 1,
        warehouse_id: this.state.warehouse_id,
        all_stock_list: array_data,
        time_zone: this.state.timeZone,
      };

      let result = await PUT(tokens, "v1/goods-receipt/update", body);

      if (result && result.status) {
        alert_url("success", "บันทึกข้อมูลใบสินค้าเรียบร้อยแล้ว", "ใบสินค้าถูกเพิ่มในระบบแล้ว", "/product/receipt");
        this.setState({ loading: false, modal_add: false });

        this.ClearForm();
      }
    }, 10);
    this.setState({ loading: false });
  };
  ClearForm() {
    this.setState({
      page: 1,
      loading: false,
      data: [],
      search: "",

      user_id: "",
      product_id: "",
      type_product_id: "",
      status_url: "",
      file_name: "",
      yard_qty: "",
      price_by_item: "",
      total_final: 0,
      total_price_all: 0,
      total_price_by_item: "0.00",

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",

      ref_code: "",
      ref_codes: "",

      warehouse_id: "",

      warehouse: "",
      product_value: null,
      qty: "",

      total_qty: 0,
      total_yard_qty: 0,
    });
  }
  Calculate_Add_Price() {
    let data = this.state.data;
    let total_qty = 0;
    let total_yard_qty = 0;
    let total_price_all = 0;

    for (let i = 0; i < data.length; i++) {
      total_qty += Number(data[i].qty);
      total_yard_qty += Number(data[i].yard_qty);
      data[i].total_price_by_item = Number(data[i].yard_qty) * Number(data[i].price_by_item);
      total_price_all += data[i].total_price_by_item;
    }
    this.setState({
      total_qty: total_qty,
      total_yard_qty: total_yard_qty,
      total_price_all: total_price_all,
    });
  }
  ClearFile() {
    this.setState({
      file_name: "",
      data: [],
      total_qty: 0,
      total_yard_qty: 0,
      total_final: 0,
      total_price_all: 0,
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "100px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="จัดการสินค้า" page3="ใบรับสินค้า" />
            <div style={{ height: 60 }}></div>
            <div className="mx-4 mt-4 mb-3">
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between   mt-3">
                <h3>
                  <b>แก้ไขราคาที่รับมา</b>
                </h3>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 px-3 mr-1"
                    onClick={() => {
                      window.location.href = "/product/receipt";
                    }}
                  >
                    <span className="icon-brand text-18 mr-2">{"\uf177"}</span>ย้อนกลับ
                  </button>
                </div>
              </div>
              <div className="w-100 pt-3">
                <div className="card ">
                  <div className="card-header rounded-top-16 mx-0 px-2 p-3">
                    <p className=" text-16">
                      <b>รหัสใบรับสินค้า: {this.state.bill_receipt_code}</b>
                    </p>
                    <hr className="d-block d-xl-none" />
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <div className="row d-flex justify-content-center align-items-center">วันที่รับ: {format_date(this.state.date)}</div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 border-left border-right">
                        <hr className="d-block d-sm-none" />
                        <div className="row d-flex justify-content-center align-items-center">ผู้จำหน่าย: {this.state.company}</div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <hr className="d-block d-xl-none" />
                        <div className="row d-flex justify-content-center align-items-center">เอกสารอ้างอิง: {this.state.ref_code ? this.state.ref_code : "-"}</div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 border-left border-right">
                        <hr className="d-block d-xl-none" />
                        <div className="row d-flex justify-content-center align-items-center">โกดัง: {this.state.warehouse}</div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive tableFixHead" style={{ paddingBottom: 500 }}>
                      <table className="table table-striped table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <b>ลำดับ</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>รหัสสินค้า</b>
                            </th>
                            <th className="text-left">
                              <b>ชื่อสินค้า</b>
                            </th>
                            <th className="text-left">
                              <b>สี</b>
                            </th>
                            <th className="text-left">
                              <b>ดีไซน์</b>
                            </th>
                            <th className="text-center">
                              <b>จำนวน(ไม้)</b>
                            </th>
                            <th className="text-right">
                              <b>จำนวนหลา</b>
                            </th>
                            {this.state.user_type === "owner" ? (
                              <th className="text-right">
                                <b>ราคาที่รับมา</b>
                              </th>
                            ) : null}
                            {this.state.user_type === "owner" ? (
                              <th className="text-right">
                                <b>ยอดรวมสุทธิ</b>
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data &&
                            this.state.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className={item.check ? "text-center" : "text-center text-danger"}>{Number(index + 1)}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_code}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_name}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_color}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_design}</td>
                                <td className={item.check ? "text-center" : "text-left text-danger"}>{item.qty}</td>
                                <td className={item.check ? "text-center" : "text-left text-danger"}>{baht(item.yard_qty)}</td>
                                {this.state.user_type === "owner" ? (
                                  <td className={item.check ? "text-right" : "text-right text-danger"}>
                                    <input
                                      type="text"
                                      className="form-control-select border border-secondary text-right"
                                      maxLength={14}
                                      onChange={(e) => {
                                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                          let data = this.state.data;
                                          data[index].price_by_item = e.target.value;
                                          this.setState({ data: data });
                                          setTimeout(() => {
                                            this.Calculate_Add_Price();
                                          }, 10);
                                        } else {
                                          let data = this.state.data;
                                          data[index].price_by_item = "";
                                          this.setState({ data: data });
                                        }
                                      }}
                                      value={item.price_by_item}
                                    />
                                  </td>
                                ) : null}
                                {this.state.user_type === "owner" ? <td className={item.check ? "text-right" : "text-right text-danger"}>{item.total_price_by_item ? baht(item.total_price_by_item) : "0.00"}</td> : null}
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <th className="text-center"></th>
                          <th className="text-center"></th>
                          <th className="text-center"></th>
                          <th className="text-center"></th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>ยอดรวม</b>
                          </th>
                          <th className="text-center">
                            <b>{this.state.data && total(this.state.data, "qty")} ไม้</b>
                          </th>
                          <th className="text-right">
                            <b>{this.state.data && baht(total(this.state.data, "yard_qty"))} หลา</b>
                          </th>
                          {this.state.user_type === "owner" ? <th className="text-center"></th> : null}
                          {this.state.user_type === "owner" ? (
                            <th className="text-right">
                              <b>฿{this.state.data && baht(total(this.state.data, "total_price_by_item"))}</b>
                            </th>
                          ) : null}
                        </tfoot>
                      </table>
                      {this.state.alert === 1 && (
                        <div className="w-100 center">
                          <div className="card bg-yellow-warning mx-5 p-3 mb-3 wpx-300">
                            <div className=" row d-flex justify-content-center align-content-center p-2">
                              <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                              <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-fixed bottom-0 bg-white w-100  p-3" style={{ bottom: 0, right: 0, left: 0 }}>
          <div className="d-flex justify-content-end">
            <p className="mb-0 mr-3">
              รวมสินค้า {this.state.data.length} รายการ <b>฿{this.state.data && baht(total(this.state.data, "total_price_by_item"))}</b>
            </p>
            <div className="d-flex">
              <button
                className="btn btn-primary mx-2 px-3"
                onClick={() => {
                  let data = this.state.data;
                  let count = data.filter((item) => item.price_by_item === "");

                  if (count.length !== 0) {
                    alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                  } else {
                    if (this.state.alert === 0) {
                      this.setState({ alert: 1 });
                    } else {
                      this.UpdatePrice();
                    }
                  }
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
