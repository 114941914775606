/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, PUT, DELETE, tokens, type_member, role, number } from "../../components/CustomComponent.js";
import Select, { components } from "react-select";
import WARNING from "../../assets/images/warning.png";
import "../../assets/css/bootstrap.css";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);
// ----------------------------------------------

const customStylesTypeOptions = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOptions = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class MemberScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_credit_day: false,

      data: null,

      province_list: null,
      districts_list: [],
      subdistricts_list: [],
      zipcode_list: [],
      SUB_DISTRICT_ID: "",
      DISTRICT_ID: "",
      PROVINCE_ID: "",
      ZIPCODE_ID: "",

      districts_dropdown: null,
      fillter_districts: "",
      fillter_province: "",
      search: "",
      member_type: "",

      id: "",

      full_name: "",
      tax_invoice: "",
      phone: "",
      user_type: "",
      address: "",
      province: "",
      district: "",
      sub_district: "",
      post_code: "",
      credit_day: 0,
      credit_money: 0,

      alert: 0,
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }

  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search,
      user_type: this.state.member_type,
      fillter_province: this.state.fillter_province,
      fillter_districts: this.state.fillter_districts,
    };
    let result = await POST(tokens, "v1/user-member/search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data, province_list: result.data.province_list });
    }
    this.setState({ loading: false });
  };
  Fillter_Districts = async () => {
    let body = {
      PROVINCE_ID: Number(this.state.fillter_province),
    };
    let result = await POST(tokens, "v1/user-member/districts", body);
    if (result && result.status) {
      this.setState({
        districts_dropdown: result.data.data_list_districts,
      });
    }
  };
  Districts = async () => {
    let body = {
      PROVINCE_ID: this.state.province.value,
    };
    let result = await POST(tokens, "v1/user-member/districts", body);
    if (result && result.status) {
      this.setState({
        districts_list: result.data.data_list_districts,
      });
    }
  };
  SubDistricts = async () => {
    let body = {
      DISTRICT_ID: this.state.district.value,
    };
    let result = await POST(tokens, "v1/user-member/sub_districts", body);
    if (result && result.status) {
      this.setState({ subdistricts_list: result.data.data_list_sub_districts });
    }
  };
  ZipCode = async () => {
    let SUB_DISTRICT_COD = "";
    if (this.state.sub_district.item.SUB_DISTRICT_CODE === "0") {
      SUB_DISTRICT_COD = this.state.subdistricts_list.find((item) => item.SUB_DISTRICT_NAME === this.state.sub_district.label).SUB_DISTRICT_CODE;
    } else {
      SUB_DISTRICT_COD = this.state.sub_district.item.SUB_DISTRICT_CODE;
    }
    let body = {
      SUB_DISTRICT_CODE: SUB_DISTRICT_COD,
    };

    let result = await POST(tokens, "v1/user-member/post_code", body);

    if (result && result.status) {
      this.setState({
        zipcode_list: result.data.data_list_post_code,
      });
    }
  };
  AddMember = async () => {
    this.setState({ loading: true });
    let body = {
      full_name: this.state.full_name.trim(),
      tax_invoice: this.state.tax_invoice ? this.state.tax_invoice : "",
      phone: this.state.phone,
      user_type: this.state.user_type,
      address: this.state.address,
      province: this.state.province.item.PROVINCE_NAME,
      province_id: this.state.province.item.PROVINCE_ID,
      district: this.state.district.item.DISTRICT_NAME,
      district_id: this.state.district.item.DISTRICT_ID,
      sub_district: this.state.sub_district && this.state.sub_district.item.SUB_DISTRICT_NAME ? this.state.sub_district.item.SUB_DISTRICT_NAME : "",
      sub_district_id: this.state.sub_district && this.state.sub_district.item.SUB_DISTRICT_ID ? this.state.sub_district.item.SUB_DISTRICT_ID : "",
      post_code: this.state.post_code && this.state.post_code.item.ZIPCODE ? this.state.post_code.item.ZIPCODE : "",
      post_code_id: this.state.post_code && this.state.post_code.item.SUB_DISTRICT_CODE ? this.state.post_code.item.SUB_DISTRICT_CODE : "",
      credit_day: this.state.credit_day ? Number(this.state.credit_day) : 0,
      credit_money: this.state.credit_money ? Number(this.state.credit_money) : 0,
    };
    let result = await POST(tokens, "v1/user-member/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.full_name + " ' ถูกเพิ่มในระบบแล้ว");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  EditMember = async () => {
    this.setState({ loading: true });
    let body = {
      user_id: this.state.id,
      full_name: this.state.full_name.trim(),
      tax_invoice: this.state.tax_invoice ? this.state.tax_invoice : "",
      phone: this.state.phone,
      user_type: this.state.user_type,
      address: this.state.address,
      province: this.state.province.item.PROVINCE_NAME,
      province_id: this.state.province.item.PROVINCE_ID,
      district: this.state.district.item.DISTRICT_NAME,
      district_id: this.state.district.item.DISTRICT_ID,
      sub_district: this.state.sub_district && this.state.sub_district.item.SUB_DISTRICT_NAME ? this.state.sub_district.item.SUB_DISTRICT_NAME : "",
      sub_district_id: this.state.sub_district && this.state.sub_district.item.SUB_DISTRICT_ID ? this.state.sub_district.item.SUB_DISTRICT_ID : "",
      post_code: this.state.post_code && this.state.post_code.item.ZIPCODE ? this.state.post_code.item.ZIPCODE : "",
      post_code_id: this.state.post_code && this.state.post_code.item.SUB_DISTRICT_CODE ? this.state.post_code.item.SUB_DISTRICT_CODE : "",
      credit_day: this.state.credit_day ? Number(this.state.credit_day) : 0,
      credit_money: this.state.credit_money ? Number(this.state.credit_money) : 0,
    };
    let result = await PUT(tokens, "v1/user-member/update", body);

    if (result && result.status) {
      alert("success", "แก้ไขข้อมูลสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.full_name + " ' ถูกแก้ไขข้อมูลในระบบแล้ว");
      this.setState({ loading: false, modal_edit: false });
      this.ClearForm();
      this.GetAll();
    }

    this.setState({ loading: false });
  };
  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/user-member/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.full_name + " ' ถูกลบข้อมูลในระบบแล้ว");
      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ValidatePhone = (phone) => {
    return phone.match(/^^[0][0-9]*$/);
  };

  ClearForm() {
    this.setState({
      page: 1,

      fillter_province: "",
      search: "",
      id: "",

      full_name: "",
      tax_invoice: "",
      phone: "",
      user_type: "",
      address: "",
      province: "",
      district: "",
      sub_district: "",
      post_code: "",
      credit_day: "",
      credit_money: "",

      districts_list: [],
      subdistricts_list: [],
      zipcode_list: [],
      SUB_DISTRICT_ID: "",
      DISTRICT_ID: "",
      PROVINCE_ID: "",
      ZIPCODE_ID: "",

      alert: 0,
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายชื่อสมาชิกทั้งหมด" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายชื่อสมาชิกทั้งหมด</b>
              </h3>

              {role && role.user_member[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.setState({ modal_add: true });
                    }}
                  >
                    {" "}
                    เพิ่มสมาชิกใหม่{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-2 d-flex mb-2">
                    <div className="w-100">
                      <input
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <select
                        className="form-control-select pointer"
                        onChange={(e) => {
                          this.setState({ page: 1, member_type: e.target.value });

                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                        value={this.state.member_type}
                      >
                        <option value="" selected>
                          แสดงสมาชิกทุกประเภท
                        </option>
                        <option value="store_normol">ลูกค้าทั่วไป</option>
                        <option value="store_technician">ร้านค้าช่างเย็บ</option>
                        <option value="store_wholesale">ร้านค้าช่วง</option>
                        <option value="store_school">ลูกค้าโรงเรียน</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <Select
                        ref={(click) => (this.Input = click)}
                        className="px-0 pointer"
                        options={
                          this.state.province_list &&
                          this.state.province_list.map((item) => ({
                            value: item.PROVINCE_ID,
                            label: item.PROVINCE_NAME,
                          }))
                        }
                        components={{ Option: TypeOptions }}
                        styles={customStylesTypeOptions}
                        placeholder="แสดงทุกจังหวัด"
                        defaultValue={this.state.fillter_province}
                        isClearable
                        onChange={(e) => {
                          if (e === null) {
                            this.setState({ fillter_province: "", fillter_districts: "", page: 1 });
                            this.Inputdistricts.clearValue();
                            setTimeout(() => {
                              this.Fillter_Districts();
                              this.GetAll();
                            }, 20);
                          } else {
                            this.setState({ fillter_province: e.value, fillter_districtse: e.label, page: 1 });
                            setTimeout(() => {
                              this.Fillter_Districts();
                              this.GetAll();
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <Select
                        className="px-0 pointer"
                        options={
                          this.state.districts_dropdown &&
                          this.state.districts_dropdown.map((item) => ({
                            value: item.DISTRICT_ID,
                            label: item.DISTRICT_NAME,
                          }))
                        }
                        components={{ Option: TypeOptions }}
                        styles={customStylesTypeOptions}
                        placeholder="แสดงทุกอำเภอ"
                        defaultValue={this.state.fillter_districts}
                        isClearable
                        onChange={(e) => {
                          if (e === null) {
                            this.setState({ fillter_districts: "", page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          } else {
                            this.setState({ fillter_districts: e.value, page: 1 });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>รหัสสมาชิก</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 160 }}>
                            <b>ชื่อผู้ใช้</b>
                          </th>
                          <th className="text-left">
                            <b>จังหวัด</b>
                          </th>
                          <th className="text-left">
                            <b>อำเภอ</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>เลขที่กำกับภาษี</b>
                          </th>
                          <th className="text-left">
                            <b>เบอร์โทร</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 150 }}>
                            <b>ประเภทสมาชิก</b>
                          </th>
                          {role && (role.user_member[2] === 1 || role.user_member[3] === 1) && (
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={8} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.user_code}</td>
                              <td className="text-left">{item.full_name}</td>
                              <td className="text-left">{item.province ? item.province : ""}</td>
                              <td className="text-left">{item.district ? item.district : ""}</td>
                              <td className="text-left">{item.tax_invoice ? item.tax_invoice : ""}</td>
                              <td className="text-left">{item.phone ? item.phone : ""}</td>
                              <td className="text-center">{type_member(item.user_type)}</td>
                              <td className="text-center">
                                {item.user_code !== "ME000000001" && (
                                  <>
                                    {role && role.user_member[2] === 1 && (
                                      <button
                                        className="btn btn-link text-secondary pointer"
                                        onClick={() => {
                                          this.setState({ loading: true });

                                          let province = {
                                            value: item.province_id,
                                            label: item.province,
                                            item: {
                                              PROVINCE_NAME: item.province,
                                              PROVINCE_ID: item.province_id,
                                            },
                                          };

                                          let district = {
                                            value: item.district_id,
                                            label: item.district,
                                            item: {
                                              DISTRICT_NAME: item.district,
                                              DISTRICT_ID: item.district_id,
                                            },
                                          };

                                          let sub_district = {
                                            value: item.sub_district_id,
                                            label: item.sub_district,
                                            item: {
                                              SUB_DISTRICT_NAME: item.sub_district,
                                              SUB_DISTRICT_ID: item.sub_district_id,
                                              SUB_DISTRICT_CODE: item.post_code_id,
                                            },
                                          };

                                          let post_code = {
                                            value: item.post_code,
                                            label: item.post_code,
                                            item: {
                                              ZIPCODE: item.post_code,
                                            },
                                          };

                                          this.setState({
                                            full_name: item.full_name,
                                            credit_day: item.credit_day,
                                            credit_money: item.credit_money,
                                            tax_invoice: item.tax_invoice,
                                            user_type: item.user_type,
                                            address: item.address,
                                            phone: item.phone,
                                            id: item.user_id,
                                            alert: 0,
                                            province: province,
                                            district: district,
                                            sub_district: item.sub_district === "" ? "" : sub_district,
                                            post_code: item.post_code === "" ? "" : post_code,
                                            province_check: item.province_id,
                                          });

                                          setTimeout(async () => {
                                            if (this.state.province && this.state.province.label !== "") {
                                              await this.Districts();
                                            }
                                            if (this.state.district && this.state.district.label !== "") {
                                              await this.SubDistricts();
                                            }
                                            if (this.state.sub_district || item.sub_district_id) {
                                              await this.ZipCode();
                                            }

                                            this.setState({
                                              modal_edit: true,
                                              loading: false,
                                            });
                                          }, 10);
                                        }}
                                      >
                                        <label className="icon mr-2 pointer">{"\uf040"}</label>
                                      </button>
                                    )}

                                    {role && role.user_member[3] === 1 && (
                                      <button
                                        className="btn btn-link text-secondary pointer"
                                        onClick={() => {
                                          this.setState({
                                            full_name: item.full_name,
                                            id: item.user_id,
                                            modal_delete: true,
                                          });
                                        }}
                                      >
                                        <label className="icon mr-2 pointer">{"\uf2ed"}</label>
                                      </button>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white pointer mx-1"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* เพิ่มสมาชิกใหม่ */}
        <Modal
          show={this.state.modal_add}
          size="m"
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เพิ่มสมาชิกใหม่</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={100}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value });
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* เลขที่กำกับภาษี: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขที่กำกับภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={13}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice;
                    tax_invoice = e.target.value.replace(/\D/g, "");
                    this.setState({
                      tax_invoice: tax_invoice,
                    });
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone;
                    phone = e.target.value.replace(/\D/g, "");
                    this.setState({
                      phone: phone,
                    });
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100 ">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value });
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="store_normol">ลูกค้าทั่วไป</option>
                  <option value="store_technician">ร้านค้าช่างเย็บ</option>
                  <option value="store_wholesale">ร้านค้าช่วง</option>
                  <option value="store_school">ลูกค้าโรงเรียน</option>
                </select>
              </div>
            </div>
            <hr />
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  maxLength={100}
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* จังหวัด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>จังหวัด:</label>
              </div>
              <div className="w-100 ">
                <Select
                  ref={(click) => (this.province_list = click)}
                  className="px-0 pointer"
                  options={
                    this.state.province_list &&
                    this.state.province_list.map((item) => ({
                      value: item.PROVINCE_ID,
                      label: item.PROVINCE_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกจังหวัด --"
                  value={this.state.province}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({
                        province: {},
                        district: {},
                        post_code: "",
                        sub_district: "",
                        districts_list: [],
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                    } else if (this.state.province.value !== e.value) {
                      this.setState({
                        province: e,
                        district: "",
                        post_code: "",
                        sub_district: "",
                        districts_list: [],
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.Districts();
                      }, 10);
                    } else {
                      this.setState({ province: e });

                      setTimeout(() => {
                        this.Districts();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>
            {/* อำเภอ: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อำเภอ:</label>
              </div>
              <div className="w-100 ">
                <Select
                  ref={(click) => (this.districts_list = click)}
                  className="px-0 pointer"
                  options={
                    this.state.districts_list &&
                    this.state.districts_list.map((item) => ({
                      value: item.DISTRICT_ID,
                      label: item.DISTRICT_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกอำเภอ --"
                  value={this.state.district}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ district: {}, sub_district: "", post_code: "", subdistricts_list: [], zipcode_list: [] });
                    } else if (this.state.district.value !== e.value) {
                      this.setState({
                        district: e,
                        post_code: "",
                        sub_district: "",
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.SubDistricts();
                      }, 10);
                    } else {
                      this.setState({ district: e });

                      setTimeout(() => {
                        this.SubDistricts();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>

            {/* ตำบล: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ตำบล:</label>
              </div>
              <div className="w-100 ">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.subdistricts_list &&
                    this.state.subdistricts_list.map((item) => ({
                      value: item.SUB_DISTRICT_ID,
                      label: item.SUB_DISTRICT_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกตำบล --"
                  value={this.state.sub_district}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ sub_district: "", post_code: "", zipcode_list: [] });
                    } else if (this.state.sub_district.value !== e.value) {
                      this.setState({
                        sub_district: e,
                        post_code: "",
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    } else {
                      this.setState({ sub_district: e });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>

            {/* รหัสไปรษณีย์ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสไปรษณีย์:</label>
              </div>
              <div className="w-100 ">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.zipcode_list &&
                    this.state.zipcode_list.map((item) => ({
                      value: item.ZIPCODE_ID,
                      label: item.ZIPCODE,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกรหัสไปรษณีย์ --"
                  value={this.state.post_code}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ post_code: "" });
                    } else if (this.state.post_code.value !== e.label) {
                      this.setState({
                        post_code: e,
                      });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    } else {
                      this.setState({ post_code: e });
                    }
                  }}
                />
              </div>
            </div>

            <hr />
            {/* เครดิต(วัน) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(วัน):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={9}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                      let credit_day = this.state.credit_day;
                      credit_day = e.target.value.replace(/\D/g, "");
                      this.setState({
                        credit_day: credit_day,
                      });
                    }
                  }}
                  value={this.state.credit_day}
                />
              </div>
            </div>
            {/* เครดิต(ยอดซื้อ) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(ยอดซื้อ):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let credit_money = this.state.credit_money;
                    credit_money = e.target.value.replace(/\D/g, "");
                    this.setState({
                      credit_money: credit_money,
                    });
                  }}
                  value={this.state.credit_money}
                />
              </div>
            </div>

            {this.state.alert === 1 && (
              <div>
                <div className="card bg-yellow-warning w-100 p-3 mb-3">
                  <div className=" row d-flex justify-content-center align-content-center p-2">
                    <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                    <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                  </div>
                </div>
              </div>
            )}

            <div className="w-100 d-flex ">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.full_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                      return;
                    } else if (this.state.phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.phone && this.state.phone.length !== 10) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรให้ครบ 10 หลัก");
                      return;
                    } else if (this.state.phone && this.state.phone.trim() !== "" && !this.ValidatePhone(this.state.phone)) {
                      alert("warning", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง");
                      return;
                    } else if (this.state.user_type === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกประเภท");
                      return;
                    } else if (this.state.province.value === "" || this.state.province.value === undefined) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกจังหวัด");
                      return;
                    } else if (this.state.district.value === "" || this.state.district.value === undefined) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอำเภอ");
                      return;
                    }

                    if (this.state.alert === 0) {
                      this.setState({ alert: 1 });
                    } else {
                      this.AddMember();
                    }
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* แก้ไขสมาชิก */}
        <Modal
          show={this.state.modal_edit}
          size="m"
          onHide={() => {
            this.setState({ modal_edit: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขสมาชิก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={100}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value });
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* เลขที่กำกับภาษี: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขที่กำกับภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={13}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice;
                    tax_invoice = e.target.value.replace(/\D/g, "");
                    this.setState({
                      tax_invoice: tax_invoice,
                    });
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone;
                    phone = e.target.value.replace(/\D/g, "");
                    this.setState({
                      phone: phone,
                    });
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100 ">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value });
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="store_normol">ลูกค้าทั่วไป</option>
                  <option value="store_technician">ร้านค้าช่างเย็บ</option>
                  <option value="store_wholesale">ร้านค้าช่วง</option>
                  <option value="store_school">ลูกค้าโรงเรียน</option>
                </select>
              </div>
            </div>
            <hr />
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  maxLength={100}
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>

            {/* จังหวัด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>จังหวัด:</label>
              </div>
              <div className="w-100 ">
                <Select
                  ref={(click) => (this.edit_province_list = click)}
                  className="px-0 pointer"
                  options={
                    this.state.province_list &&
                    this.state.province_list.map((item) => ({
                      value: item.PROVINCE_ID,
                      label: item.PROVINCE_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกจังหวัด --"
                  value={this.state.province}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({
                        province: "",
                        district: "",
                        post_code: "",
                        sub_district: "",
                        districts_list: [],
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                    } else if (this.state.province.value !== e.value) {
                      this.setState({
                        province: e,
                        district: "",
                        post_code: "",
                        sub_district: "",
                        districts_list: [],
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.Districts();
                      }, 10);
                    } else {
                      this.setState({ province: e });

                      setTimeout(() => {
                        this.Districts();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>
            {/* อำเภอ: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อำเภอ:</label>
              </div>
              <div className="w-100 ">
                <Select
                  ref={(click) => (this.edit_districts_list = click)}
                  className="px-0 pointer"
                  options={
                    this.state.districts_list &&
                    this.state.districts_list.map((item) => ({
                      value: item.DISTRICT_ID,
                      label: item.DISTRICT_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกอำเภอ --"
                  value={this.state.district}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ district: "", sub_district: "", post_code: "", subdistricts_list: [], zipcode_list: [] });
                    } else if (this.state.district.value !== e.value) {
                      this.setState({
                        district: e,
                        post_code: "",
                        sub_district: "",
                        subdistricts_list: [],
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.SubDistricts();
                      }, 10);
                    } else {
                      this.setState({ district: e });

                      setTimeout(() => {
                        this.SubDistricts();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>

            {/* ตำบล: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ตำบล:</label>
              </div>
              <div className="w-100 pointer">
                <Select
                  className="px-0"
                  options={
                    this.state.subdistricts_list &&
                    this.state.subdistricts_list.map((item) => ({
                      value: item.SUB_DISTRICT_ID,
                      label: item.SUB_DISTRICT_NAME,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกตำบล --"
                  value={this.state.sub_district}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ sub_district: "", post_code: "", zipcode_list: [] });
                    } else if (this.state.sub_district.value !== e.value) {
                      this.setState({
                        sub_district: e,
                        post_code: "",
                        zipcode_list: [],
                      });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    } else {
                      this.setState({ sub_district: e });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    }
                  }}
                />
              </div>
            </div>

            {/* รหัสไปรษณีย์ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสไปรษณีย์:</label>
              </div>
              <div className="w-100 ">
                <Select
                  className="px-0 pointer"
                  options={
                    this.state.zipcode_list &&
                    this.state.zipcode_list.map((item) => ({
                      value: item.ZIPCODE_ID,
                      label: item.ZIPCODE,
                      item: item,
                    }))
                  }
                  components={{ Option: TypeOption }}
                  styles={customStylesTypeOption}
                  placeholder="-- เลือกรหัสไปรษณีย์ --"
                  value={this.state.post_code}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      this.setState({ post_code: "" });
                    } else if (this.state.post_code.value !== e.label) {
                      this.setState({
                        post_code: e,
                      });
                      setTimeout(() => {
                        this.ZipCode();
                      }, 10);
                    } else {
                      this.setState({ post_code: e });
                    }
                  }}
                />
              </div>
            </div>

            <hr />
            {/* เครดิต(วัน) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(วัน):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  maxLength={9}
                  onChange={(e) => {
                    let credit_day = this.state.credit_day;
                    credit_day = e.target.value.replace(/\D/g, "");
                    this.setState({
                      credit_day: credit_day,
                    });
                  }}
                  value={this.state.credit_day}
                />
              </div>
            </div>
            {/* เครดิต(ยอดซื้อ) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(ยอดซื้อ):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={9}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let credit_money = this.state.credit_money;
                    credit_money = e.target.value.replace(/\D/g, "");
                    this.setState({
                      credit_money: credit_money,
                    });
                  }}
                  value={this.state.credit_money}
                />
              </div>
            </div>

            {this.state.alert === 1 && (
              <div>
                <div className="card bg-yellow-warning w-100 p-3 mb-3">
                  <div className=" row d-flex justify-content-center align-content-center p-2">
                    <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                    <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                  </div>
                </div>
              </div>
            )}

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.full_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                      return;
                    } else if (this.state.phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.phone && this.state.phone.length !== 10) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรให้ครบ 10 หลัก");
                      return;
                    } else if (this.state.phone && this.state.phone.trim() !== "" && !this.ValidatePhone(this.state.phone)) {
                      alert("warning", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง");
                      return;
                    } else if (this.state.user_type === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกประเภท");
                      return;
                    } else if (this.state.province.value === "" || this.state.province.value === undefined) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกจังหวัด");
                      return;
                    } else if (this.state.district.value === "" || this.state.district.value === undefined) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอำเภอ");
                      return;
                    }

                    if (this.state.alert === 0) {
                      this.setState({ alert: 1 });
                    } else {
                      this.EditMember();
                    }
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal ลบผู้ใช้งาน */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการลบผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการลบผู้ใช้งาน “{this.state.full_name}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
