import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { Modal } from "react-bootstrap";
import { alert, GET, POST, PUT, tokens, status_sale_transaction2, baht, role, ModalDetails, total, ModalInstallment, format_date_sale, format_duedate_sale, report_3, report_1, report_2, ModalSaleHistory, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class SaleMemberScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.location.query.page ? Number(this.props.location.query.page) : 1,

      index_onClick: "",
      loading: false,
      data: null,

      details: null,
      search_detail: "",

      search: "",
      sort: "",

      id: this.props.location.query.customer_id ? this.props.location.query.customer_id : "",

      title_name: "",

      status_bill: 0,

      //details
      modal_detail: false,
      data_detail: "",

      //ยกเลิกรายการ
      text_cancle: "",
      order_main_number: "",
      modal_delete: false,
      id_delete: "",

      //detailsการผ่อนชำระ
      modal_installment: false,
      installment_detail: "",
      i_all_total_pay: 0.0,

      profile: JSON.parse(localStorage.getItem("profile")),

      history: null,
      modal_history: false,
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetAll();
    if (this.props.location.query.customer_id) {
      await this.GetById();
      // eslint-disable-next-line array-callback-return
      this.state.data.data.filter((item, i) => {
        if (item.user_id === this.props.location.query.customer_id) {
          this.setState({ title_name: item.full_name, index_onClick: i });
        }
      });
    }
  }

  GetAll = async () => {
    let body = {
      data_search: this.state.search,
      sort: this.state.sort,
    };
    let result = await POST(tokens, "v1/all-sale/customer/search", body);

    if (result && result.status) {
      this.setState({ data: result.data });
      setTimeout(() => {
        this.GetSaleAll(result.data);
      }, 100);
    }
  };
  GetSaleAll = async () => {
    let body = {
      data_search: "",
      member_type: "",
      status_bill: 0,
      start_date: "",
      end_date: "",
      sort_date: "",
      sort_om_number: "",
      date_created: "",
    };
    let result = await POST(tokens, "v1/all-sale/search-specific", body);
    if (result && result.status) {
      let data = this.state.data;
      for (let member of data.data) {
        member.total_balance =
          member.total_balance +
          total(
            result.data.data.filter((e) => e.cutomer_id === member.user_id && e.status_bill !== 1),
            "total_balance"
          );
      }
      this.setState({ data: data });
    }
    this.setState({ loading: false });
  };
  GetById = async () => {
    let body = {
      user_member_id: this.state.id,
      data_search: this.state.search_detail,
      status_bill: this.state.status_bill,
    };
    let result = await POST(tokens, "v1/all-sale/customer/detail/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ details: result.data });
    }
  };

  Get_by_id = async (id, type) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/all-sale/order/detail/" + id, null);

    if (result && result.status) {
      let data = result.data.data;
      let total_yard = 0;
      let total_wood = 0;
      let installment_detail = "";
      for (let i = 0; i < data.order_list_data.length; i++) {
        let sum_yard = 0;
        for (let sum of data.order_list_data[i].detail_yard_list) {
          sum_yard += Number(sum);
        }
        data.order_list_data[i].yard_qty = Number(sum_yard);
        total_yard += data.order_list_data[i].yard_qty;
        total_wood += data.order_list_data[i].qty;
        data.order_list_data[i].product_main = {};
        data.order_list_data[i].product_main.product_main_data = data.order_list_data[i].product_main_data;

        if (type === "installment") {
          installment_detail = data.order_list_data[0].payment_detail_data[0].installment_list;
          break;
        }
      }
      data.total_yard = total_yard;
      data.total_wood = total_wood;

      this.setState({ data_detail: data, installment_detail: installment_detail });

      setTimeout(() => {
        let i_all_total_pay = 0;

        if (type === "installment") {
          for (let i = 0; i < this.state.installment_detail.length; i++) {
            i_all_total_pay += this.state.installment_detail[i].pay;
          }

          this.setState({
            i_all_total_pay: i_all_total_pay,
            modal_installment: true,
          });
        } else {
          this.setState({
            modal_detail: true,
          });
        }
      }, 10);
    }
    this.setState({ loading: false });
  };
  GetHistoryById = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/all-sale/payment/history/" + id, null);
    if (result && result.status) {
      this.setState({ history: result.data.data });
      setTimeout(() => {
        this.setState({ modal_history: true });
      }, 10);
    }
    this.setState({ loading: false });
  };

  Get_by_id_print = async (id, text) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/all-sale/order/detail/" + id, null);
    if (result && result.status) {
      let data = result.data.data;
      let total_yard = 0;
      let total_wood = 0;
      for (let i = 0; i < data.order_list_data.length; i++) {
        let sum_yard = 0;
        for (let sum of data.order_list_data[i].detail_yard_list) {
          sum_yard += Number(sum);
        }
        data.order_list_data[i].yard_qty = Number(sum_yard);
        total_yard += data.order_list_data[i].yard_qty;
        total_wood += data.order_list_data[i].qty;
        data.order_list_data[i].product_main = {};
        data.order_list_data[i].product_main.product_main_data = data.order_list_data[i].product_main_data;
      }
      data.total_yard = total_yard;
      data.total_wood = total_wood;

      this.setState({ data_detail: data });

      if (text === "ใบกำกับภาษี" || text === "ใบส่งสินค้าชั่วคราว" || text === "ใบเสนอราคา" || text === "ใบส่งของสายส่ง") {
        let data_number = {
          tax_invoice_number: result.data.data.tax_invoice_number,
          input_vat_number: result.data.data.input_vat_number,
          ab_number: result.data.data.ab_number,
          est_number: result.data.data.est_number,
        };
        let data = {
          detail_print: result.data.data,
          order_list: result.data.data.order_list_data,
          member: result.data.data.member_data,
          machine_profile: result.data.data.admin_data.full_name,
          data_number: data_number,
        };

        if (data && text === "ใบกำกับภาษี") {
          report_3(data, "Transaction");
          this.setState({ loading: false });
        } else if (data && text === "ใบเสนอราคา") {
          report_1(data, "sale");
          this.setState({ loading: false });
        } else if (data && text === "ใบส่งสินค้าชั่วคราว") {
          report_2(data, "sale");
          this.setState({ loading: false });
        }
        // else if (data && text === "ใบส่งของสายส่ง") {
        //     report_5(data, "sale")
        //     this.setState({ loading: false });
        // }
      }

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  Delete = async (id) => {
    this.setState({ loading: true });
    let body = {
      order_main_id: id,
      remark_cancel: this.state.text_cancle,
    };
    let result = await PUT(tokens, "v1/pos-mgt/order/cancel", body);
    if (result && result.status) {
      alert("success", "แจ้งเตือน", "ยกเลิกรายการเรียบร้อยแล้ว");

      this.setState({ loading: false, modal_delete: false });

      this.GetById();
    }
    this.setState({ loading: false });
  };

  Retry = async (id, text) => {
    if (role && role.pos_system[0] === 1) {
      if (text === "รีบิลเป็นการขาย") {
        localStorage.setItem("bill", "รีบิลเป็นการขาย");
      } else {
        localStorage.removeItem("bill");
      }
      window.location.href = "/main?retry_id=" + id;
    } else {
      alert("warning", "แจ้งเตือน", "ไม่ได้รับอนุญาตให้เข้าถึง");
    }
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetById();
    }, 10);
  }

  ClearForm() {
    this.setState({
      index_onClick: "",
      search: "",
      sort: "",

      id: "",
      user_search: "",
      user_status: "",

      status_bill: "",

      search_detail: "",
      id_delete: "",

      text_cancle: "",
      order_main_number: "",
      modal_delete: false,

      status: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="ข้อมูลตามสมาชิก" />
            <div style={{ height: 60 }}></div>
            <div className="row w-100 mt-3 mx-0">
              {/* สมาชิก */}
              <div className="w-100 col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 mb-4">
                <div className="w-100 mb-3">
                  <h3>
                    <b>สมาชิก</b>
                  </h3>
                  <label className="text-msg text-14">ทั้งหมด {this.state.data && number(this.state.data.data.length)} รายการ</label>
                </div>
                <div className="w-100">
                  <div className="card">
                    <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                      <div className="col-6 col-sm-6 col-xl-8 d-flex mb-2">
                        <div className="w-100">
                          <input
                            maxLength={30}
                            className="form-control-search"
                            type="search"
                            placeholder="พิมพ์คำค้นหา"
                            value={this.state.search}
                            onChange={(e) => {
                              this.setState({ page: 1, search: e.target.value, loading: false });
                              if (e.target.value === "") {
                                this.setState({
                                  search: "",
                                  details: null,
                                  index_onClick: "",
                                  title_name: "",
                                  status_bill: 0,
                                });
                                // setTimeout(() => {
                                //     this.GetAll()
                                //     this.setState({ loading: false })
                                // }, 20)
                              } else {
                                // setTimeout(() => {
                                //     this.GetAll()
                                //     this.setState({ loading: false })
                                // }, 20)
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-xl-4 d-flex">
                        <div className="w-100">
                          <select
                            className="form-control-select pointer"
                            onChange={(e) => {
                              this.setState({
                                sort: e.target.value,
                                page: 1,
                                details: null,
                                index_onClick: "",
                                title_name: "",
                              });

                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }}
                            value={this.state.sort}
                          >
                            <option value="" selected>
                              จัดเรียง
                            </option>
                            <option value="desc">มากไปน้อย</option>
                            <option value="asc">น้อยไปมาก</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 ">
                      <div className="table-responsive rounded-16 height-vh overflow-auto">
                        <table className="table table-hover table-striped table-borderless">
                          <thead>
                            <tr className=" bg-white">
                              <th className="text-left" style={{ minWidth: 100 }}>
                                <b>สมาชิก</b>
                              </th>
                              <th className="text-right" style={{ minWidth: 150 }}>
                                <b>ยอดค้างชำระ</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                              <tr>
                                <td colSpan={2} className="text-center table-light height-vh">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}

                            {this.state.data &&
                              this.state.data.data
                                .filter((e) => this.state.search === "" || e.full_name.includes(this.state.search))
                                .map((item, index) => (
                                  <tr
                                    className="pointer"
                                    key={index}
                                    onClick={() => {
                                      this.setState({
                                        page: 1,
                                        details: null,

                                        search_detail: "",
                                        id: item.user_id,
                                        title_name: item.full_name,
                                        index_onClick: index,
                                        status_bill: 0,
                                      });
                                      setTimeout(async () => {
                                        await this.GetById();
                                      }, 10);
                                    }}
                                  >
                                    <td className={index === this.state.index_onClick ? "text-left text-white bg-primary" : "text-left"}>{item.full_name}</td>
                                    <td className={index === this.state.index_onClick ? "text-right text-white bg-primary" : "text-right"}>{baht(item.total_balance)}</td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* เรทสมาชิกตามรายการสินค้า */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 mb-4 w-100 ">
                <div className="w-100 d-flex justify-content-between">
                  <div className="d-flex flex-column w-100 mx-0">
                    <h3>
                      <b>{this.state.title_name ? "รายการขาย : " + this.state.title_name : "รายการขาย"}</b>
                    </h3>
                    {this.state.details && this.state.details.data.phone && (
                      <div className="mb-3">
                        <small className="bg-secondary-phone text-dark p-1 px-3 rounded-16">
                          <span className="icon-brand text-14 mr-2">{"\uf095"}</span>
                          {this.state.details.data.phone}
                        </small>
                      </div>
                    )}
                    <label className="text-msg mb-3 text-14">ทั้งหมด {this.state.details ? number(this.state.details.count) : "0"} รายการ</label>
                  </div>
                </div>
                {/* total rate */}
                <div className="row mx-0 text-white w-100">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className="card bg-cash-4 p-3 w-100 ">
                      <div className="row justify-content-between w-100 px-3 text-dark">
                        <div>
                          <span className="icon-brand text-16 ">{"\uf555"}</span> <b>OPEN</b>
                        </div>
                        <div>
                          <p className="mb-0">
                            <b>{(this.state.details && this.state.details.data && this.state.profile.user_type === "owner") || (this.state.details && this.state.details.data && this.state.profile.user_type === "admin_system") ? baht(this.state.details.data.open) : (this.state.details && this.state.details.data && this.state.profile.user_type !== "owner") || (this.state.details && this.state.details.data && this.state.profile.user_type !== "admin_system") ? number(this.state.details.data.open) : 0}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className="card bg-total-2 w-100 p-3">
                      <div className="row justify-content-between w-100 px-3">
                        <div>
                          <span className="icon-brand text-16">{"\uf555"}</span> <b>OVERDUE</b>
                        </div>
                        <div>
                          <p className="mb-0">
                            <b>{(this.state.details && this.state.details.data && this.state.profile.user_type === "owner") || (this.state.details && this.state.details.data && this.state.profile.user_type === "admin_system") ? baht(this.state.details.data.overdue) : (this.state.details && this.state.details.data && this.state.profile.user_type !== "owner") || (this.state.details && this.state.details.data && this.state.profile.user_type !== "admin_system") ? number(this.state.details.data.overdue) : 0}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* table detail */}
                <div className="card ">
                  <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16 ">
                    <div className="col-5 col-sm-5 col-md-5  col-lg-4  col-xl-4 d-flex mb-2">
                      <div className="w-100">
                        <input
                          maxLength={30}
                          className="form-control-search"
                          type="search"
                          placeholder="พิมพ์คำค้นหา"
                          value={this.state.search_detail}
                          onChange={(e) => {
                            this.setState({ page: 1, search_detail: e.target.value, loading: false });
                            if (e.target.value === "" && this.state.details !== null) {
                              this.setState({ search_detail: "" });
                              setTimeout(() => {
                                this.GetById();
                                this.setState({ loading: false });
                              }, 20);
                            } else {
                              setTimeout(() => {
                                this.GetById();
                                this.setState({ loading: false });
                              }, 20);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-5 col-sm-5 col-md-5 col-lg-4  col-xl-4 d-flex mb-2">
                      <div className="w-100">
                        <select
                          className="form-control-select pointer"
                          onChange={(e) => {
                            if (this.state.details !== null) {
                              this.setState({ status_bill: Number(e.target.value), page: 1 });

                              setTimeout(() => {
                                this.GetById();
                              }, 20);
                            }
                          }}
                          value={this.state.status_bill}
                        >
                          <option value="0" selected>
                            แสดงทุกสถานะ
                          </option>
                          <option value="1">ใบเสนอราคา</option>
                          <option value="7">รอดำเนินการ</option>
                          <option value="2">เปิดบิล</option>
                          <option value="3">ปิดบิล</option>
                          <option value="4">เกินกำหนด</option>
                          <option value="5">เกินกำหนด / ใบแจ้งหนี้</option>
                          <option value="6">ยกเลิก</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive rounded-16 height-vh overflow-auto">
                      <table className="table table-sm table-striped table-borderless table-sm table-hover">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 90 }}>
                              <b>เลขที่เอกสาร</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 95 }}>
                              <b>วันที่</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 95 }}>
                              <b>วันครบกำหนด</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90 }}>
                              <b>ยอดรวม</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90 }}>
                              <b>ชำระแล้ว</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90 }}>
                              <b>ยอดค้างชำระ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 150 }}>
                              <b>สถานะ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 80 }}>
                              <b>รายละเอียด</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.details && this.state.details.data.data_list.length === 0) || !this.state.details) && (
                            <tr>
                              <td colSpan={8} className="text-center table-light height-vh">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.details &&
                            this.state.details.data.data_list.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className="text-left">{item.order_main_number}</td>
                                <td className="text-left">{format_date_sale(item.datetime_create)}</td>
                                <td className="text-center">{format_duedate_sale(item.due_date)}</td>
                                <td className="text-right">{baht(item.total_price)}</td>
                                <td className="text-right">{baht(item.total_pay)}</td>
                                <td className="text-right">{baht(item.total_balance)}</td>
                                <td className="text-center">{status_sale_transaction2(item.status_bill)}</td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-dark">
                                        <h1 className="icon h2">{"\uf142"}</h1>
                                      </button>
                                    }
                                    transition
                                  >
                                    {item.status_bill === 1 && item.status_bill !== 3 && item.status_bill !== 2 && item.status_bill !== 4 && item.status_bill !== 6 && item.status_bill !== 7 && (
                                      <>
                                        <MenuItem
                                          onClick={async () => {
                                            this.Get_by_id_print(item.order_main_id, "ใบเสนอราคา");
                                          }}
                                        >
                                          <span className="icon h2 mr-1">{"\uf02f"} </span>
                                          พิมพ์ใบเสนอราคา
                                        </MenuItem>
                                      </>
                                    )}
                                    {item.status_bill !== 1 && item.status_bill !== 5 && item.status_bill !== 7 && (
                                      <>
                                        {item.vat === 0 ? (
                                          <MenuItem
                                            onClick={async () => {
                                              this.Get_by_id_print(item.order_main_id, "ใบส่งสินค้าชั่วคราว");
                                            }}
                                          >
                                            <span className="icon h2 mr-1"> {"\uf02f"} </span>
                                            พิมพ์ใบส่งสินค้าชั่วคราว
                                          </MenuItem>
                                        ) : (
                                          <MenuItem
                                            onClick={async () => {
                                              this.Get_by_id_print(item.order_main_id, "ใบกำกับภาษี");
                                            }}
                                          >
                                            <span className="icon mr-2 h2">{"\uf02f"}</span>
                                            พิมพ์ใบกำกับภาษี
                                          </MenuItem>
                                        )}
                                      </>
                                    )}
                                    {role && role.payment_receive[0] === 1 && (
                                      <>
                                        {(item.payment_type_layer_1 === 0 || (item.status_bill !== 1 && item.status_bill !== 3 && item.status_bill !== 5 && item.status_bill !== 6 && item.status_bill !== 7)) && (
                                          <MenuItem
                                            onClick={async () => {
                                              window.location.href = "/sale/payment-receive?id=" + item.order_main_number + "&page=" + this.state.page + "&page_name=/sale-member&customer_name=" + item.cutomer_name + "&customer_id=" + item.cutomer_id;
                                            }}
                                          >
                                            <span className="icon h2 mr-1">{"\uf0d6"} </span>
                                            ระบบรับชำระเงิน
                                          </MenuItem>
                                        )}
                                      </>
                                    )}
                                    {role && role.invoice[0] === 1 && (item.status_bill === 4 || item.status_bill === 2) && (
                                      <MenuItem
                                        onClick={async () => {
                                          window.location.href = "invoice/create";
                                        }}
                                      >
                                        <span className="icon h2 mr-1">{"\uf02f"} </span>
                                        สร้างใบแจ้งหนี้
                                      </MenuItem>
                                    )}
                                    {role && role.pos_system[0] === 1 && role.pos_system[1] === 1 && role.pos_system[2] === 1 && role.pos_system[3] === 1 && (item.status_bill === 1 || item.status_bill === 7) && (
                                      // แก้ได้เฉพาะ type ใบเสนอราคาและบันทึกรอชำระ
                                      <MenuItem
                                        onClick={async () => {
                                          if (item.status_bill === 1) {
                                            this.Retry(item.order_main_id, "รีบิลเป็นการขาย", item.status_bill);
                                          } else if (item.status_bill === 7) {
                                            this.Retry(item.order_main_id, "บันทึกรอชำระ", item.status_bill);
                                          }
                                        }}
                                      >
                                        <span className="icon mr-2">{"\uf31c"}</span>
                                        แก้ไข
                                      </MenuItem>
                                    )}
                                    {item.payment_type_layer_1 === 4 && (
                                      <MenuItem
                                        onClick={async () => {
                                          this.Get_by_id(item.order_main_id, "installment");
                                        }}
                                      >
                                        <span className="icon mr-2">{"\uf1c0"}</span>
                                        รายการผ่อนชำระ
                                      </MenuItem>
                                    )}
                                    <MenuItem
                                      onClick={async () => {
                                        this.Get_by_id(item.order_main_id, "");
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf15c"}</span>
                                      รายละเอียด
                                    </MenuItem>
                                    <MenuItem
                                      onClick={async () => {
                                        this.GetHistoryById(item.order_main_id);
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf1da"}</span>
                                      ประวัติการชำระเงิน
                                    </MenuItem>
                                    {role.all_sale[3] === 1 && (
                                      <>
                                        {item.status_bill !== 5 && item.status_bill !== 3 && item.status_bill !== 6 && (
                                          <MenuItem
                                            onClick={async () => {
                                              this.setState({
                                                modal_delete: true,
                                                id_delete: item.order_main_id,
                                                order_main_number: item.order_main_number,
                                                text_cancle: "",
                                              });
                                            }}
                                          >
                                            <span className="icon h2 mr-1">{"\uf2ed"} </span>
                                            ยกเลิกรายการ
                                          </MenuItem>
                                        )}
                                      </>
                                    )}
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* pagination */}
                {this.state.details && this.state.details.data.length !== 0 && (
                  <div className="row w-100 justify-content-between mx-0  my-4">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                      <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                        <label>
                          {" "}
                          แสดง {number(this.state.details.end_index)} รายการจาก {number(this.state.details.count)} รายการ{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                      <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                        <button
                          className="btn-circle-white mx-1 pointer"
                          onClick={() => {
                            this.HandlePageChange(1);
                          }}
                        >
                          <label className="icon pointer">{"\uf100"}</label>
                        </button>
                        <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.details.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                        <button
                          className="btn-circle-white mx-1 pointer"
                          onClick={() => {
                            this.HandlePageChange(this.state.details.page_number);
                          }}
                        >
                          <label className="icon pointer">{"\uf101"}</label>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* รายละเอียด modal */}
        <ModalDetails
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />

        {/* Modal ยกเลิกรายการ */}
        <Modal
          Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              {" "}
              <b className="text-18">ยกเลิกรายการ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการยกเลิกรายการ “{this.state.order_main_number}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
              {/* การยกเลิก */}
              <div className="w-100 mb-3">
                <textarea
                  className="form-control border-0"
                  placeholder="สาเหตุในการยกเลิก"
                  value={this.state.text_cancle}
                  onChange={(e) => {
                    this.setState({ text_cancle: e.target.value });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn  btn-outline-primary  w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ย้อนกลับ{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger  w-100"
                  onClick={() => {
                    this.Delete(this.state.id_delete);
                  }}
                >
                  {" "}
                  ยืนยัน{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียดการผ่อนชำระ */}
        <ModalInstallment
          i_all_total_pay={this.state.i_all_total_pay}
          value={this.state.data_detail}
          installment_detail={this.state.installment_detail}
          show={this.state.modal_installment}
          onHide={() => {
            this.setState({ modal_installment: false });
          }}
        />

        {/* Modal ประวัติการชำระเงิน */}
        <ModalSaleHistory
          history={this.state.history}
          show={this.state.modal_history}
          onHide={() => {
            this.setState({ modal_history: false });
          }}
        />
      </div>
    );
  }
}
