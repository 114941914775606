import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, DELETE, tokens, role, format_date, GET, baht, status_type_expenses, ModalDetailsExpenses } from "../../../components/CustomComponent.js";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

export default class ExpensesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      modal_detail: false,
      data_detail: "",

      data: null,

      start_date: "",
      end_date: "",
      id: "",
      search: "",
      type: "",

      //ยกเลิกรายการ
      text_cancle: "",
      order_main_number: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/expense-mgt/expense/filter-search/page/" + this.state.page + "?data_search=" + this.state.search + "&start_date=" + this.state.start_date + "&end_date=" + this.state.end_date + "&type=" + this.state.type, null);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };
  Get_By_ID = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/expense-mgt/expense/detail/" + id, null);
    if (result && result.status) {
      this.setState({ data_detail: result.data });
      setTimeout(() => {
        this.setState({
          modal_detail: true,
        });
      }, 10);
    }
    this.setState({ loading: false });
  };
  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/expense-mgt/expense/delete/" + id, null);
    if (result && result.status) {
      alert("success", "แจ้งเตือน", "ยกเลิกรายการเรียบร้อยแล้ว");
      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  ClearForm() {
    this.setState({
      page: 1,

      text_cancle: "",
      order_main_number: "",

      id: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="จัดการค่าใช้จ่าย" page3="บันทึกค่าใช้จ่าย" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>บันทึกค่าใช้จ่าย</b>
              </h3>

              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/expenses/create";
                  }}
                >
                  เพิ่มค่าใช้จ่าย
                </button>
              </div>
            </div>
            <div className="row w-100 mt-3 mx-0">
              <div className={"w-100 col-12 mb-4"}>
                <div className="card">
                  <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                    <div className={"col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 d-flex"}>
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>

                    <div className={"col-12 col-sm-12 col-md-8 col-lg-9 col-xl-6 d-flex justify-content-end"}>
                      <div className="d-flex row mx-0 w-100">
                        <div className="d-flex row mx-0 align-items-center">
                          <input
                            type="date"
                            className="form-control-select wpx-150 mb-2 pointer"
                            value={this.state.start_date}
                            placeholder="วันที่เริ่มต้น"
                            onChange={(e) => {
                              this.setState({ page: 1, start_date: e.target.value });

                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }}
                          ></input>
                          <label className="mx-2 ">ถึง</label>
                          <input
                            type="date"
                            className="form-control-select wpx-150 mb-2 pointer"
                            value={this.state.end_date}
                            placeholder="วันที่สิ้นสุด"
                            onChange={(e) => {
                              const date_start_date = new Date(this.state.start_date).getTime();
                              const date_end_date = new Date(e.target.value).getTime();

                              if (date_start_date <= date_end_date) {
                                this.setState({ page: 1, end_date: e.target.value });
                                setTimeout(() => {
                                  this.GetAll();
                                }, 20);
                              }
                            }}
                          ></input>
                          <select
                            className="form-control wpx-150 mb-2 ml-2 bg-white border-0"
                            value={this.state.type}
                            placeholder="วันที่เริ่มต้น"
                            onChange={(e) => {
                              this.setState({ page: 1, type: e.target.value });

                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }}
                          >
                            <option value=""></option>
                            <option value="1">ค่าใช้จ่าย</option>
                            <option value="2">ปรับสต๊อก</option>
                            <option value="3">ส่วนลด</option>
                          </select>
                          <button
                            type="button"
                            className="btn btn-outline-primary bg-white ml-2 mb-1"
                            onClick={() => {
                              this.setState({
                                page: 1,
                                search: "",

                                data: null,

                                start_date: "",
                                end_date: "",
                              });

                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }}
                          >
                            <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0 ">
                    <div className="table-responsive rounded-16">
                      <table className="table table-striped table-borderless table-hover table-sm">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>เลขที่เอกสาร</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 130 }}>
                              <b>วันที่</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 130 }}>
                              <b>ประเภท</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ยอดรวม</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 130 }}>
                              <b>เลขที่เอกสารอ้างอิง</b>
                            </th>
                            {role && role.expenses[0] && (
                              <th className="text-center">
                                <b>จัดการ</b>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={6} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className="text-left">{item.expense_main_number}</td>
                                <td className="text-left">{format_date(item.datetime_create)}</td>
                                <td className="text-center">{status_type_expenses(item.type)}</td>
                                <td className="text-right">{baht(item.total)}</td>
                                <td className="text-left">{item.ref_receipt_code}</td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-dark">
                                        <h1 className="icon h2">{"\uf142"}</h1>
                                      </button>
                                    }
                                    transition
                                  >
                                    {role && role.expenses[2] === 1 && item.type === 1 && (
                                      <MenuItem
                                        onClick={async () => {
                                          window.location.href = "/expenses/update?id=" + item.expense_main_id;
                                        }}
                                      >
                                        <span className="icon mr-2"> {"\uf040"}</span>
                                        แก้ไข
                                      </MenuItem>
                                    )}

                                    <MenuItem
                                      onClick={async () => {
                                        let id = item.expense_main_id;

                                        setTimeout(() => {
                                          this.Get_By_ID(id);
                                        }, 10);
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf15c"}</span>
                                      รายละเอียด
                                    </MenuItem>

                                    {role && role.expenses[3] === 1 && item.type === 1 && (
                                      <MenuItem
                                        onClick={async () => {
                                          this.setState({
                                            modal_delete: true,
                                            id: item.expense_main_id,
                                            order_main_number: item.expense_main_number,
                                            text_cancle: "",
                                          });
                                        }}
                                      >
                                        <span className="icon h2 mr-1">{"\uf2ed"} </span>
                                        ยกเลิกรายการ
                                      </MenuItem>
                                    )}
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {this.state.data.end_index} รายการจาก {this.state.data.count} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal ยกเลิกรายการ */}
        <Modal
          Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยกเลิกรายการ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการยกเลิกรายการ “{this.state.order_main_number}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn  btn-outline-primary  w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ย้อนกลับ{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger  w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ยืนยัน{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* รายละเอียด modal */}
        <ModalDetailsExpenses
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />
      </div>
    );
  }
}
