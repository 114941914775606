/* eslint-disable react/no-direct-mutation-state */
import NavbarComponent from "../../components/Navbarmain";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Select, { components } from "react-select";
import { Modal } from "react-bootstrap";
import { alert, alert_url, GET, POST, PUT, tokens, ModalDetails, status_pos_payment, date, date_overdue, ModalPrint, ModalInstallment, baht, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 75,
    padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};

const IconOption = (props) => (
  <Option {...props}>
    <div className="w-100 d-flex">
      {props.data.logo ? <img src={props.data.logo} style={{ width: 30, marginRight: 16, borderRadius: 20 }} alt={props.data.label} /> : <div className=" bg-gray hpx-30 wpx-30 rounded-circle" style={{ marginRight: 16 }}></div>}
      {"ชื่อธนาคาร: " + props.data.label}
    </div>
    <div className="w-100 d-flex">
      <div className="hpx-30 wpx-30"></div>
      <p className="text-16 text-login ml-3">{"เลขที่บัญชี: " + props.data.bank_number}</p>
    </div>
  </Option>
);

export default class AccruedScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_print: false,

      //payment
      modal_payment: false,
      calculate_type: "เงินสด",
      price: "00",
      payment_type: 1,
      bank_list: null,
      bank_name: "",
      installment: 0,
      installment_list: [],
      input: false,

      check_date: new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":")),

      //details
      modal_detail: false,
      data_detail: "",

      modal_installment: false,
      installment_detail: "",

      data: null,

      search: "",
      type: 2,

      text_cancle: "",
      order_main_number: "",

      status_order_main: "",
      status_bill: "",

      payment_detail: {
        credit_card_type: "",
        bank_number: "",
        bank_name: "",
        card_number: "",
        slip_number: "",
        remark_payment_s2: "",
        remark_payment_s3: "",
        full_name_transfer: "",
        datetime_transfer: "",
        reference_number: "",
        remark_payment_s4: "",
        remark_payment_s5: "",
        total_pay: 0.0,
        installment_list: [
          {
            total_pay: 0.0,
            pay: 0.0,
          },
        ],
      },

      total_pay: 0.0,
      i_all_total_pay: 0.0,

      order_data: "",

      //เงินทอน
      change: 0.0,
      text_modal: "",

      message_detail: { title: "", status_order_main: 1, calculate_type: "", action: "", detail_print: "", member: "", data_number: "", order_list: [], machine_profile: "" },

      number_of_installments: 0,

      order_id: "",

      check_tax: false,
      member_name: "",
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetBank();
    await this.GetAll();
    this.setState({ loading: false });
  }

  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search,
      status_order_main: this.state.status_order_main,
      status_bill: this.state.status_bill.toString(),
    };

    let result = await POST(tokens, "v1/pos-mgt/order/filter-search/page/" + this.state.page + "/" + this.state.type, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  Get_by_id = async (id, type) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + id, null);
    if (result && result.status) {
      let data = result.data.data;
      let total_yard = 0;
      let total_wood = 0;
      let installment_detail = "";
      for (let i = 0; i < data.order_list_data.length; i++) {
        let sum_yard = 0;
        for (let sum of data.order_list_data[i].detail_yard_list) {
          sum_yard += Number(sum);
        }
        data.order_list_data[i].yard_qty = Number(sum_yard);
        total_yard += data.order_list_data[i].yard_qty;
        total_wood += data.order_list_data[i].qty;
        data.order_list_data[i].product_main = {};
        data.order_list_data[i].product_main.product_main_data = data.order_list_data[i].product_main_data;

        if (type === "installment") {
          installment_detail = data.order_list_data[0].payment_detail_data[0].installment_list;
          break;
        }
      }
      data.total_yard = total_yard;
      data.total_wood = total_wood;

      this.setState({ data_detail: data, installment_detail: installment_detail });

      setTimeout(() => {
        let i_all_total_pay = 0;

        if (type === "installment") {
          for (let i = 0; i < this.state.installment_detail.length; i++) {
            i_all_total_pay += this.state.installment_detail[i].pay;
          }

          this.setState({
            i_all_total_pay: i_all_total_pay,
            modal_installment: true,
          });
        } else {
          this.setState({
            modal_detail: true,
          });
        }
      }, 10);
    }
    this.setState({ loading: false });
  };

  Payment = async () => {
    this.setState({ loading: true });
    let order = [this.state.order_data.order_main_id];

    if (this.state.type === 4) {
      if (Number(this.state.price) === 0) {
        this.setState({ loading: false });
        alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");
        return;
      }

      let installment = this.state.payment_detail.installment_list;
      for (let i = 0; i < installment.length; i++) {
        if (installment[i].pay === 0) {
          installment[i].pay = installment[i].total_pay;
          this.setState({
            price: installment[i].total_pay.toString(),
            total_pay: installment[i].total_pay,
          });
          break;
        }
      }

      let data = this.state.payment_detail;
      data.installment_list = installment;

      if (Number(this.state.price) === this.state.total_pay) {
        this.setState({ general: Number(this.state.price), text_modal: "ชำระเงินแล้ว ฿" });
      } else {
        alert("info", "แจ้งเตือน", "กรุณาชำระเงินเต็มจำนวน");
        this.setState({ price: this.state.total_pay.toString(), loading: false });
        return;
      }
    } else {
      if (this.state.calculate_type === "เงินโอน" || (this.state.calculate_type === "เงินสด" && this.state.status_order_main !== 4 && this.state.status_order_main !== 1)) {
        if (this.state.status_order_main === 5) {
          if (Number(this.state.price) < this.state.deposit) {
            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินมากกว่าค่ามัดจำ");
            this.setState({ price: this.state.deposit.toString(), loading: false });
            return;
          } else {
            let general = Number(this.state.price);
            this.setState({ general: general });
          }
        } else {
          if (Number(this.state.price) === 0) {
            this.setState({ loading: false });
            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน");

            return;
          }
          if (Number(this.state.price) >= this.state.total_pay) {
            let general = Number(Number(this.state.price) - this.state.total_pay);
            this.setState({ general: general, text_modal: "เงินทอน ฿" });
          } else {
            this.setState({ general: Number(this.state.price), text_modal: "ชำระเงินแล้ว ฿" });
          }
        }
      }
    }

    let body = {
      member_id: this.state.order_data.member_data.user_id,
      payment_type_layer_1: this.state.payment_type,
      payment_detail: this.state.payment_detail,
      order_main_list: order,
    };
    let result = await PUT(tokens, "v1/pos-mgt/order/overdue/update", body);
    if (result && result.status) {
      let result_get = await GET(tokens, "v1/pos-mgt/order/detail/" + this.state.order_id, null);
      if (result && result.status) {
        let details = result_get.data.data;
        let total_yard = 0;
        let total_wood = 0;
        for (let i = 0; i < details.order_list_data.length; i++) {
          let sum_yard = 0;
          for (let sum of details.order_list_data[i].detail_yard_list) {
            sum_yard += Number(sum);
          }
          details.order_list_data[i].yard_qty = Number(sum_yard);
          total_yard += details.order_list_data[i].yard_qty;
          total_wood += details.order_list_data[i].qty;
          details.order_list_data[i].product_main = {};
          details.order_list_data[i].product_main.product_main_data = details.order_list_data[i].product_main_data;
          details.payment_detail = this.state.payment_detail;
        }
        details.total_yard = total_yard;
        details.total_wood = total_wood;

        let data_number = {
          tax_invoice_number: result_get.data.data.tax_invoice_number,
          input_vat_number: result_get.data.data.input_vat_number,
          ab_number: result_get.data.data.ab_number,
          est_number: result_get.data.data.est_number,
        };

        this.setState({ data_detail: details, check_tax: Number(details.vat) === 0 ? false : true });
        switch (this.state.status_order_main) {
          case 1:
            this.setState({
              modal_print: true,
              message_detail: {
                title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "payment",
                detail_print: this.state.data_detail,
                member: result_get.data.data.member_data,
                data_number: data_number,
                order_list: result_get.data.data.order_list_data,
                machine_profile: result_get.data.data.admin_data,
              },
            });
            this.setState({ loading: false, modal_payment: false });
            break;
          case 2:
            this.setState({
              modal_print: true,
              message_detail: {
                title: "ชำระเงิน",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "payment",
                detail_print: this.state.data_detail,
                member: result_get.data.data.member_data,
                data_number: data_number,
                order_list: result_get.data.data.order_list_data,
                machine_profile: result_get.data.data.admin_data,
              },
            });
            this.setState({ loading: false, modal_payment: false });
            break;
          case 4:
            alert_url("success", "พักออเดอร์เรียบร้อยแล้ว", "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนู พักออเดอร์", "/main");
            this.setState({ loading: false, modal_pause_order: false });
            break;
          case 5:
            this.setState({
              modal_print: true,
              message_detail: {
                title: "ชำระค่ามัดจำ",
                calculate_type: this.state.calculate_type,
                status_order_main: this.state.status_order_main,
                action: "payment",

                detail_print: this.state.data_detail,
                member: result_get.data.data.member_data,
                data_number: data_number,
                order_list: result_get.data.data.order_list_data,
                machine_profile: result_get.data.data.admin_data,
              },
            });
            this.setState({ loading: false, modal_payment: false });
            break;
          default:
            this.setState({ loading: false });
            break;
        }
      }

      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  GetBank = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/bank_account/search/page/0", body);
    if (result && result.status) {
      this.setState({ bank_list: result.data });
    }
  };

  Delete = async () => {
    this.setState({ loading: true });
    let body = {
      order_main_id: this.state.id,
      remark_cancel: this.state.text_cancle,
    };
    let result = await PUT(tokens, "v1/pos-mgt/order/cancel", body);
    if (result && result.status) {
      alert("success", "ยกเลิกรายการเรียบร้อยแล้ว", "ท่านยังสามารถสั่งรายการแบบเดิมได้โดยกดที่ปุ่มเมนู", "“สั่งอีกครั้ง”");

      this.setState({ loading: false, modal_delete: false });

      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  Retry = async (id, text) => {
    if (text === "รีบิลเป็นการขาย") {
      localStorage.setItem("bill", "รีบิลเป็นการขาย");
    } else if (text === "บันทึกรอชำระ") {
      localStorage.setItem("bill", "บันทึกรอชำระ");
    } else {
      localStorage.removeItem("bill");
    }

    window.location.href = "/main?retry_id=" + id;
  };

  calculate = (number) => {
    const input = document.getElementById("price");
    input.focus();
    if (number === "delete") {
      const currentPosition = input.selectionStart;
      const textBeforeCursor = input.value.slice(0, currentPosition);
      const textAfterCursor = input.value.slice(currentPosition);
      input.value = textBeforeCursor.slice(0, -1) + textAfterCursor;
      input.selectionStart = currentPosition - 1;
      input.selectionEnd = currentPosition - 1;
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    } else if (number === 100) {
      if (Number(this.state.price) + 100 < 999999999) {
        this.setState({ price: Number(this.state.price) + 100 });
      }
    } else if (number === 500) {
      if (Number(this.state.price) + 500 < 999999999) {
        this.setState({ price: Number(this.state.price) + 500 });
      }
    } else if (number === 1000) {
      if (Number(this.state.price) + 1000 < 999999999) {
        this.setState({ price: Number(this.state.price) + 1000 });
      }
    } else if (number === "full") {
      this.setState({ price: this.state.total_pay.toString() });
    } else {
      input.setRangeText(number, input.selectionStart, input.selectionEnd, "end");
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    }
  };

  ClearForm() {
    this.setState({
      page: 1,

      text_cancle: "",
      order_main_number: "",

      status_order_main: "",
      status_bill: "",
      search: "",

      calculate_type: "เงินสด",
      payment_type: 1,
      order_id: "",
      member_name: "",
    });
  }

  payment_clear = () => {
    let data = this.state.payment_detail;
    data.credit_card_type = "";
    data.bank_number = "";
    data.bank_name = "";
    data.card_number = "";
    data.slip_number = "";
    data.remark_payment_s2 = "";
    data.remark_payment_s3 = "";
    data.full_name_transfer = "";
    data.datetime_transfer = "";
    data.reference_number = "";
    data.remark_payment_s4 = "";
    data.remark_payment_s5 = "";

    this.setState({
      payment_detail: data,
      input: false,
      installment: 0,
      payment_type: 1,
    });
  };

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="w-100">
          <NavbarComponent
            onChange={(e) => {
              this.setState({ status_bill: e });
            }}
          />
          <div style={{ height: 60 }}></div>

          <div className="mx-3 mt-3 mb-3">
            {/* header */}
            <div className="card w-100 p-4">
              <div className="mx-0 w-100 row justify-content-between">
                <h3>
                  <b>รายการค้างจ่าย</b>
                </h3>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 "
                    onClick={() => {
                      window.location.href = "/main";
                    }}
                  >
                    <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ
                  </button>
                </div>
              </div>
            </div>

            {/* table */}
            <div className="w-100 card mt-3 p-3">
              <div className="card">
                <div className="row d-flex w-100 justify-content-between px-0 mx-0">
                  <div className="col-12   col-md-6 col-lg-5 col-xl-5 d-flex align-content-center mb-3">
                    <div className="w-100 ">
                      <div className="w-100 d-flex align-items-center ">
                        <div className="border border-primary bg-white rounded w-100" style={{ padding: 6 }}>
                          <div className="d-flex w-100">
                            <div className="w-50">
                              <button
                                className={this.state.type === 2 ? "btn btn-primary w-100" : "btn btn-link w-100"}
                                onClick={() => {
                                  this.setState({ type: 2, page: 1 });

                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 10);
                                }}
                              >
                                เงินเชื่อ
                              </button>
                            </div>
                            <div className="w-50 pl-1">
                              <button
                                className={this.state.type === 0 ? "btn btn-primary w-100" : "btn btn-link w-100"}
                                onClick={() => {
                                  this.setState({ type: 0, page: 1 });

                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 10);
                                }}
                              >
                                รอชำระ
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12  col-md-6 col-lg-5 col-xl-4 d-flex mb-3 
                                    justify-content-sm-start justify-content-md-end"
                  >
                    {this.state.type === 0 && (
                      <div className="w-100 mr-1">
                        <select
                          className="form-control  border-0 mr-1 mb-1 mt-1 "
                          value={this.state.status_bill}
                          onChange={(e) => {
                            this.setState({
                              status_bill: e.target.value,
                              page: 1,
                            });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        >
                          <option value="" selected>
                            -- ประเภทบิล --
                          </option>
                          <option value="1">ใบเสนอราคา</option>
                          <option value="2">ใบรายการขาย</option>
                        </select>
                      </div>
                    )}

                    <input
                      className="form-control border-0 ml-1 mb-1 mt-1 wpx-200"
                      type="search"
                      maxLength={30}
                      placeholder="พิมพ์คำค้นหา"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ page: 1, search: e.target.value, loading: false });
                        if (e.target.value === "") {
                          this.setState({ search: "" });
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        } else {
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    {/* เงินเชื่อ */}
                    {this.state.type === 2 && (
                      <table className="table table-striped table-borderless table-sm table-hover">
                        <thead className="thead-dark-gray">
                          <tr>
                            <th className="text-left">
                              <b>เลขที่รายการ</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 150 }}>
                              <b>ชื่อลูกค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 170 }}>
                              <b>วันที่</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 120 }}>
                              <b>กำหนดจ่าย</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ยอดรวม</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ชำระแล้ว</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 120 }}>
                              <b>ยอดค้างชำระ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 150 }}>
                              <b>สถานะ</b>
                            </th>
                            <th className="text-center">
                              <b>จัดการ</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={8} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-left">{item.ab_number}</td>
                                <td className="text-left">{item.member_name}</td>
                                <td className="text-left">{date(item.datetime_create)}</td>
                                <td className="text-left">{date_overdue(item.payment_due_date, item.payment_countdown)}</td>
                                <td className="text-right">{baht(item.total_price_final)}</td>
                                <td className="text-right">{baht(item.total_pay)}</td>
                                <td className="text-right">{baht(item.total_balance)}</td>
                                <td className="text-center">{status_pos_payment(item.status_order_main)}</td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-dark">
                                        <h3 className="icon"> {"\uf141"}</h3>
                                      </button>
                                    }
                                    transition
                                  >
                                    <MenuItem
                                      onClick={async () => {
                                        this.payment_clear();

                                        this.setState({
                                          modal_payment: true,
                                          status_order_main: 2,
                                          price: item.total_balance.toFixed(2),
                                          total_pay: item.total_balance,
                                          order_data: item,
                                          order_id: item.order_main_id,
                                          member_name: item.member_name,
                                        });
                                      }}
                                    >
                                      <span className="icon mr-2 h2">{"\uf0d6"}</span>
                                      ชำระเงิน
                                    </MenuItem>

                                    <MenuItem
                                      onClick={async () => {
                                        this.Get_by_id(item.order_main_id, "");
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf15c"}</span>
                                      รายละเอียด
                                    </MenuItem>
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}

                    {/* แบ่งเป็นงวด */}
                    {/* {this.state.type === 4 && (
                                            <table className="table table-striped table-borderless table-hover">
                                                <thead className="thead-dark-gray">
                                                    <tr className="">
                                                        <th className="text-left">เลขที่รายการ</th>
                                                        <th className="text-left">ชื่อลูกค้า</th>
                                                        <th className="text-left">วันที่</th>
                                                        <th className="text-right">ยอดค้างชำระ</th>
                                                        <th className="text-right">ชำระแล้ว</th>
                                                        <th className="text-right">ยอดรวม</th>
                                                        <th className="text-center">สถานะ</th>
                                                        <th className="text-center">จัดการ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {((this.state.data &&
                                                        this.state.data.count === 0) ||
                                                        !this.state.data) && (
                                                            <tr>
                                                                <td colSpan={7} className="text-center table-light">-- ไม่มีข้อมูล --</td>
                                                            </tr>
                                                        )}
                                                    {this.state.data && this.state.data.data.map((item, index) => (
                                                        <tr key={index} >
                                                            <td className="text-left">{item.ab_number}</td>
                                                            <td className="text-left">{item.member_name}</td>
                                                            <td className="text-left">{date(item.datetime_create)}</td>
                                                            <td className="text-right">{baht(Number(item.total_balance))}</td>
                                                            <td className="text-right">{baht(item.total_pay)}</td>
                                                            < td className="text-right">{baht(item.total_price_final)}</td>
                                                            <td className="text-center">{status_pos_payment(item.status_order_main)}</td>
                                                            <td className="text-center">
                                                                <Menu
                                                                    menuButton={
                                                                        <button className="btn btn-link text-dark">
                                                                            <h3> className="icon h2">{"\uf141"}</h3>
                                                                        </button>
                                                                    }
                                                                    transition
                                                                >
                                                                    <MenuItem
                                                                        onClick={async () => {
                                                                            this.payment_clear()

                                                                            let installment = item.installment_list.installment_list
                                                                            for (let i = 0; i < installment.length; i++) {
                                                                                if (installment[i].pay === 0) {
                                                                                    let total_pay = installment[i].total_pay.toFixed(2)
                                                                                    this.setState({
                                                                                        number_of_installments: i + 1,
                                                                                        price: total_pay.toString(),
                                                                                        total_pay: Number(total_pay),
                                                                                    })
                                                                                    break
                                                                                }
                                                                            }

                                                                            let data = this.state.payment_detail
                                                                            data.installment_list = item.installment_list.installment_list

                                                                            this.setState({
                                                                                modal_payment: true,
                                                                                order_data: item,
                                                                                order_id: item.order_main_id,
                                                                                member_name: item.member_name
                                                                            })

                                                                        }}

                                                                    >
                                                                        <span className="icon mr-2 h2">{"\uf0d6"}</span>
                                                                        ชำระเงิน
                                                                    </MenuItem>



                                                                    <MenuItem
                                                                        onClick={async () => {
                                                                            this.Get_by_id(item.order_main_id, "")
                                                                        }}

                                                                    >
                                                                        <span className="icon mr-2">{"\uf15c"}</span>
                                                                        รายละเอียด
                                                                    </MenuItem>

                                                                    <MenuItem
                                                                        onClick={async () => {

                                                                            this.Get_by_id(item.order_main_id, "installment")
                                                                        }}

                                                                    >
                                                                        <span className="icon mr-2">{"\uf1c0"}</span>
                                                                        รายการผ่อนชำระ
                                                                    </MenuItem>

                                                                </Menu>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )} */}

                    {/* รอชำระ */}
                    {this.state.type === 0 && (
                      <table className="table table-striped table-borderless table-hover">
                        <thead className="thead-dark-gray">
                          <tr>
                            <th className="text-left">
                              <b>เลขที่รายการ</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 150 }}>
                              <b>ชื่อลูกค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 170 }}>
                              <b>วันที่</b>
                            </th>
                            <th className="text-right">
                              <b>ค่ามัดจำ</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ยอดรวม</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ชำระแล้ว</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 120 }}>
                              <b>ยอดค้างชำระ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 100 }}>
                              <b>ประเภทบิล</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 150 }}>
                              <b>สถานะ</b>
                            </th>
                            <th className="text-center">
                              <b>จัดการ</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={10} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-left">{item.ab_number || item.est_number}</td>
                                <td className="text-left">{item.member_name}</td>
                                <td className="text-left">{date(item.datetime_create)}</td>
                                <td className="text-right">{baht(item.deposit)}</td>
                                <td className="text-right">{baht(item.total_price_final)}</td>
                                <td className="text-right">{baht(item.total_pay)}</td>
                                <td className="text-right">{baht(item.total_balance)}</td>
                                <td className="text-center">{item.status_bill === 1 ? "ใบเสนอราคา" : "ใบรายการขาย"}</td>
                                <td className="text-center">{status_pos_payment(item.status_order_main)}</td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-dark">
                                        <h3 className="icon"> {"\uf141"}</h3>
                                      </button>
                                    }
                                    transition
                                  >
                                    {item.status_order_main !== 3 && (
                                      <MenuItem
                                        onClick={async () => {
                                          this.payment_clear();
                                          if (item.status_bill === 1) {
                                            this.Retry(item.order_main_id, "รีบิลเป็นการขาย", item.status_bill);
                                          } else if (item.status_bill === 7) {
                                            this.Retry(item.order_main_id, "บันทึกรอชำระ", item.status_bill);
                                          } else {
                                            this.setState({
                                              modal_payment: true,
                                              price: item.total_balance.toFixed(2),
                                              total_pay: item.total_balance,
                                              order_data: item,
                                              order_id: item.order_main_id,
                                              member_name: item.member_name,
                                            });
                                          }
                                        }}
                                      >
                                        <span className="icon mr-2 h2">{"\uf0d6"}</span>
                                        ชำระเงิน
                                      </MenuItem>
                                    )}

                                    {item.status_order_main !== 3 && (
                                      <>
                                        <MenuItem
                                          onClick={async () => {
                                            this.setState({
                                              modal_delete: true,
                                              id: item.order_main_id,
                                              order_main_number: item.ab_number ? item.ab_number : item.est_number,
                                              text_cancle: "",
                                            });
                                          }}
                                        >
                                          <span className="icon mr-2 h2">{"\uf057"}</span>
                                          ยกเลิกรายการ
                                        </MenuItem>
                                      </>
                                    )}
                                    <MenuItem
                                      onClick={async () => {
                                        this.Get_by_id(item.order_main_id, "");
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf15c"}</span>
                                      รายละเอียด
                                    </MenuItem>
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0 my-4">
                  <div className="col-12 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon">{"\uf104"}</label>} nextPageText={<label className="icon">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* ชำระเงิน modal */}
        <Modal
          size="m"
          show={this.state.modal_payment}
          onHide={() => {
            this.setState({ modal_payment: false });
            this.ClearForm();
          }}
        >
          <Modal.Body>
            {this.state.type === 4 ? (
              <div className="center rounded btn-select-member hpx-54 mb-0">
                <label>
                  <b>
                    งวดที่ {this.state.number_of_installments + "/" + this.state.payment_detail.installment_list.length} ค้างชำระ: {baht(this.state.total_pay)} บาท
                  </b>
                </label>
              </div>
            ) : (
              <div className="center rounded btn-select-member hpx-54 mb-0">
                <label>
                  <b>ยอดค้างชำระ: {baht(this.state.total_pay)} บาท</b>
                </label>
              </div>
            )}

            <div className="w-100 d-flex mt-3">
              {/* เงินสด status 1 */}
              <div className="w-50 px-1">
                <button
                  className={this.state.calculate_type === "เงินสด" ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative" : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"}
                  onClick={() => {
                    this.payment_clear();

                    this.setState({
                      calculate_type: "เงินสด",
                      price: this.state.total_pay.toString(),
                      payment_type: 1,
                      status_order_main: 2,
                    });
                  }}
                >
                  <label className={this.state.calculate_type === "เงินสด" ? "icon mb-0 pointer text-header" : "icon mb-0 pointer"}>{"\uf0d6"}</label>
                  <br />
                  <p className={this.state.calculate_type === "เงินสด" ? "text-header" : "text-detail-name"}>เงินสด</p>
                  {this.state.calculate_type === "เงินสด" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>

              {/* เงินโอน status 3 */}
              <div className="w-50 px-1">
                <button
                  className={this.state.calculate_type === "เงินโอน" ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative" : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"}
                  onClick={() => {
                    this.payment_clear();

                    this.setState({
                      calculate_type: "เงินโอน",
                      price: this.state.total_pay.toString(),
                      payment_type: 3,
                      status_order_main: 2,
                    });

                    let data = this.state.payment_detail;
                    data.full_name_transfer = this.state.member_name;
                    this.setState({
                      payment_detail: data,
                    });
                  }}
                >
                  <label className={this.state.calculate_type === "เงินโอน" ? "icon mb-0 pointer text-header" : "icon mb-0 pointer"}>{"\uf0ec"}</label>
                  <br />
                  <p className={this.state.calculate_type === "เงินโอน" ? "text-header" : "text-detail-name"}>เงินโอน</p>
                  {this.state.calculate_type === "เงินโอน" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>
            </div>

            {/* เงินสด */}
            {this.state.calculate_type === "เงินสด" && (
              <div className="w-100">
                <div className="d-flex py-2 ">
                  {/* ปุ่มลบ */}
                  <div
                    className="btn btn-link text-secondary mt-3 pointer"
                    onClick={() => {
                      this.setState({ price: "" });
                    }}
                  >
                    <label className="icon bg-dark text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                      {"\uf00d"}
                    </label>
                  </div>
                  {/* ช่องกรอก */}
                  <input
                    id="price"
                    className="text-right w-100 border-0 text-32"
                    style={{ fontWeight: "bold" }}
                    type="text"
                    placeholder="0.00"
                    maxLength={14}
                    onChange={(e) => {
                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                        this.setState({ price: e.target.value });
                      }
                    }}
                    value={this.state.price}
                    autoFocus
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        this.btn.click();
                      }
                    }}
                  />
                </div>
                <div className="row mx-0">
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(7);
                    }}
                  >
                    <div>
                      <h5>7</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(8);
                    }}
                  >
                    <div>
                      <h5>8</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(9);
                    }}
                  >
                    <div>
                      <h5>9</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(1000);
                    }}
                  >
                    <div className="primary">
                      <h5>1,000</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(4);
                    }}
                  >
                    <div>
                      <h5>4</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(5);
                    }}
                  >
                    <div>
                      <h5>5</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(6);
                    }}
                  >
                    <div>
                      <h5>6</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(500);
                    }}
                  >
                    <div className="primary">
                      <h5>500</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(1);
                    }}
                  >
                    <div>
                      <h5>1</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(2);
                    }}
                  >
                    <div>
                      <h5>2</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(3);
                    }}
                  >
                    <div>
                      <h5>3</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(100);
                    }}
                  >
                    <div className="primary">
                      <h5>100</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(".");
                    }}
                  >
                    <div>
                      <h5>.</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate(0);
                    }}
                  >
                    <div>
                      <h5>0</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate("delete");
                    }}
                  >
                    <div>
                      <h5 className="icon">{"\uf55a"}</h5>
                    </div>
                  </div>
                  <div
                    className="col-3 cal"
                    onClick={() => {
                      this.calculate("full");
                    }}
                  >
                    <div className="primary">
                      <h5>เต็ม</h5>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* เงินโอน */}
            {this.state.calculate_type === "เงินโอน" && (
              <div className="w-100">
                <div className="w-100">
                  <div className="d-flex py-2 ">
                    {/* ปุ่มลบ */}
                    <div
                      className="btn btn-link text-secondary mt-3 pointer"
                      onClick={() => {
                        this.setState({ price: "" });
                      }}
                    >
                      <label className="icon bg-dark text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                        {"\uf00d"}
                      </label>
                    </div>
                    {/* ช่องกรอก */}
                    <input
                      id="price"
                      className="text-right w-100 border-0 text-32"
                      style={{ fontWeight: "bold" }}
                      type="text"
                      placeholder="0.00"
                      maxLength={14}
                      onChange={(e) => {
                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                          this.setState({ price: e.target.value });
                        }
                      }}
                      value={this.state.price}
                      autoFocus
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>

                  <div className="w-100 text-center mb-3">
                    <label
                      className="text-primary pointer"
                      onClick={() => {
                        this.setState({ input: !this.state.input });
                      }}
                    >
                      กรอกตัวเลขด้วยตัวเอง
                      <span className="icon-brand ml-2">{this.state.input ? "\uf077" : "\uf078"}</span>
                    </label>
                  </div>
                  {this.state.input && (
                    <div className="row mx-0">
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(7);
                        }}
                      >
                        <div>
                          <h5>7</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(8);
                        }}
                      >
                        <div>
                          <h5>8</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(9);
                        }}
                      >
                        <div>
                          <h5>9</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(1000);
                        }}
                      >
                        <div className="primary">
                          <h5>1,000</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(4);
                        }}
                      >
                        <div>
                          <h5>4</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(5);
                        }}
                      >
                        <div>
                          <h5>5</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(6);
                        }}
                      >
                        <div>
                          <h5>6</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(500);
                        }}
                      >
                        <div className="primary">
                          <h5>500</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(1);
                        }}
                      >
                        <div>
                          <h5>1</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(2);
                        }}
                      >
                        <div>
                          <h5>2</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(3);
                        }}
                      >
                        <div>
                          <h5>3</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(100);
                        }}
                      >
                        <div className="primary">
                          <h5>100</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(".");
                        }}
                      >
                        <div>
                          <h5>.</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate(0);
                        }}
                      >
                        <div>
                          <h5>0</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate("delete");
                        }}
                      >
                        <div>
                          <h5 className="icon">{"\uf55a"}</h5>
                        </div>
                      </div>
                      <div
                        className="col-3 cal"
                        onClick={() => {
                          this.calculate("full");
                        }}
                      >
                        <div className="primary">
                          <h5>เต็ม</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="w-100 mt-3">
                  <Select
                    isSearchable={false}
                    className="px-0 mb-3 "
                    options={
                      this.state.bank_list &&
                      this.state.bank_list.data.map((item, index) => ({
                        value: item.bank_id,
                        label: item.bank_name,
                        logo: item.logo,
                        bank_number: item.bank_number,
                      }))
                    }
                    components={{ Option: IconOption }}
                    placeholder="เลือกธนาคาร"
                    styles={customStyles}
                    onChange={(e) => {
                      let data = this.state.payment_detail;
                      data.bank_name = e.label;
                      data.bank_number = e.bank_number;
                      this.setState({
                        payment_detail: data,
                      });

                      this.textInput.focus();
                    }}
                  />
                </div>

                <div className="row mt-3">
                  <div className="col-12 mb-3">
                    <input
                      maxLength={30}
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="ชื่อ-นามสกุลผู้โอน"
                      ref={(click) => (this.textInput = click)}
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.full_name_transfer = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.full_name_transfer}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-12 mb-3 pl-0 pr-1 ">
                    <input
                      className="form-control bg-secondary-light border-0"
                      type="datetime-local"
                      placeholder="วัน เดือน ปี"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.datetime_transfer = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                        this.dateInput.focus();
                      }}
                      min={this.state.check_date}
                      value={this.state.payment_detail.datetime_transfer}
                      ref={(click) => (this.dateInput = click)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      maxLength={30}
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="หมายเลขอ้างอิง"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.reference_number = e.target.value.replace(/\D/g, "");
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.reference_number}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      maxLength={50}
                      className="form-control bg-secondary-light border-0"
                      type="text"
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        let data = this.state.payment_detail;
                        data.remark_payment_s3 = e.target.value;
                        this.setState({
                          payment_detail: data,
                        });
                      }}
                      value={this.state.payment_detail.remark_payment_s3}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.btn.click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="w-100 my-1">
                  <label>คำแนะนำ: โปรดเก็บหลักฐานการชำระเงินทุกครั้ง หลังกรอกข้อมูลเสร็จสิ้นแล้ว</label>
                </div>
              </div>
            )}

            <div className="w-100 d-flex mt-3">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_payment: false });

                    this.ClearForm();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  ref={(node) => (this.btn = node)}
                  type="submit"
                  onClick={() => {
                    let payment_detail = this.state.payment_detail;
                    if (this.state.calculate_type === "เงินสด") {
                      this.setState({ status_order_main: 2, payment_type: 1 });

                      payment_detail.total_pay = Number(this.state.price);
                    } else if (this.state.calculate_type === "เงินโอน") {
                      this.setState({ status_order_main: 2, payment_type: 3 });

                      if (payment_detail.bank_name === "") {
                        alert("info", "แจ้งเตือน", "กรุณาเลือกธนาคาร");
                        return;
                      } else if (payment_detail.full_name_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุลผู้โอน");
                        return;
                      } else if (payment_detail.datetime_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกวัน-เวลาที่โอน");
                        return;
                      }

                      payment_detail.total_pay = Number(this.state.price);
                    }

                    this.setState({ payment_detail: payment_detail });

                    this.Payment();
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียด modal */}
        <ModalDetails
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />

        {/* รายละเอียดการผ่อนชำระ */}
        <ModalInstallment
          i_all_total_pay={this.state.i_all_total_pay}
          value={this.state.data_detail}
          installment_detail={this.state.installment_detail}
          show={this.state.modal_installment}
          onHide={() => {
            this.setState({ modal_installment: false });
          }}
        />

        {/* Modal ยกเลิกรายการ */}
        <Modal
          Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยกเลิกรายการ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการยกเลิกรายการ “{this.state.order_main_number}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
              {/* การยกเลิก */}
              <div className="w-100 mb-3">
                <textarea
                  className="form-control border-0"
                  placeholder="สาเหตุในการยกเลิก"
                  value={this.state.text_cancle}
                  onChange={(e) => {
                    this.setState({ text_cancle: e.target.value });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn  btn-outline-primary  w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ย้อนกลับ{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger  w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ยืนยัน{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ปริ้น modal */}
        <ModalPrint
          text_modal={this.state.text_modal}
          value={this.state.message_detail}
          general={this.state.general}
          show={this.state.modal_print}
          vat={this.state.check_tax}
          Screen={"AccruedScreen"}
          onHide={() => {
            this.setState({ modal_print: false });
            window.location.href = "/accrued";
          }}
        />
      </div>
    );
  }
}
