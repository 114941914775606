import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { alert, tokens, format_date, GET, POST_CACHE, alert_url, fieldSorter, baht, random, toFixed } from "../../../components/CustomComponent.js";
import Select from "react-select";
import { DownloadTableExcel } from "react-export-table-to-excel";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";
import "../../../assets/css/bootstrap.css";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
    width: 200,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
export default class LogAuditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_close: false,
      confirm: "",
      confirm_input: "",
      page: 1,

      loading: false,

      data: null,
      data_product: null,
      data_product2: null,
      data_type: [],
      data_room: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      data_pile: [],

      search: "",

      room_number: "",
      pile: "",
      cycle_number: this.props.location.query.id,
      count_array: [],
      no: "",
      count: "",
      number: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      tab: 1,
      type: "",

      remark: "",
      data_report: [],
      data_report2: [],

      array_product_all: [],
      array_count: [],
      array_product: [],
      array_count_3: [],
      array_product_3: [],
    };
    this.tableRef = React.createRef();
  }
  async componentDidMount() {
    let data_pile = [];
    for (let i = 1; i <= 100; i++) {
      data_pile.push(i.toString() + " - Front");
      data_pile.push(i.toString() + " - Side");
    }
    this.setState({ data_pile: data_pile });
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      cycle_number: this.state.cycle_number ? this.state.cycle_number : "",
      data_search: "",
    };
    let result = await GET(tokens, "v1/check-stock/cycle-detail", body);
    if (result && result.status) {
      this.setState({ array_product_all: result.data.data });
      setTimeout(() => {
        this.getData();
      }, 10);
    }
    this.setState({ loading: false });
  };
  getData = async () => {
    // tab 01 ประวัติการเช็คสต็อกรวม
    let product = [];
    let type = [];
    let array_product_all = [];
    for (let item of this.state.array_product_all) {
      let id = array_product_all.findIndex((e) => e.room_number === item.room_number && e.pile === item.pile);
      if (id === -1) {
        array_product_all.push(item);
      } else {
        array_product_all[id].product_list = [...array_product_all[id].product_list, ...item.product_list];
      }
    }
    for (let item of array_product_all) {
      let product_list = [];
      for (let pdl of item.product_list) {
        let index = product_list.findIndex((e) => e.product_code === pdl.product_code);
        if (index === -1) {
          product_list.push({
            ...pdl,
            data_list: [{ wood_qty: pdl.wood_qty, yard_qty: pdl.yard_qty }],
          });
        } else {
          product_list[index].data_list = [...product_list[index].data_list, { wood_qty: pdl.wood_qty, yard_qty: pdl.yard_qty }];
        }
      }
      item.product_list = product_list;
    }
    let maxLengthProduct = 0;
    for (let check_list of array_product_all) {
      for (let pdl of check_list.product_list) {
        if (pdl.data_list && maxLengthProduct < pdl.data_list.length) {
          maxLengthProduct = pdl.data_list.length;
        }
        product.push({
          type_product_id: pdl.type_product_id,
          type_product_name: pdl.type_product_name,
          product_name: pdl.product_name,
          product_code: pdl.product_code,
          product_color: pdl.product_color,
          product_design: pdl.product_design,
          pile: check_list.pile,
          room_number: check_list.room_number,
          data_list: pdl.data_list ? pdl.data_list : [],
        });
        if (!type.find((e) => e.value === pdl.type_product_id)) {
          type.push({
            value: pdl.type_product_id,
            label: pdl.type_product_name,
          });
        }
      }
    }
    product = product.sort(fieldSorter(["product_code", "room_number", "pile"])).filter((product) => (!this.state.type || product.type_product_id === this.state.type.value) && (!this.state.room_number || product.room_number === this.state.room_number) && (!this.state.pile || product.pile === this.state.pile));
    let count = [];
    for (let i = 0; i < maxLengthProduct; i++) {
      count.push("ครั้งที่ " + (i + 1));
    }
    for (let product_list of product) {
      for (let i = 0; i < maxLengthProduct; i++) {
        if (product_list.data_list.length < i + 1) {
          product_list.data_list.push("");
        }
      }
    }
    this.setState({
      array_count: count,
      array_product: product,
      data_type: type,
    });
    // tab 03 จำนวนรวมแยกตามสินค้า
    let array_list = [];
    for (let product_list of product) {
      let index = array_list.findIndex((e) => e.product_code === product_list.product_code);
      if (index === -1) {
        let arr = product.filter((e) => e.product_code === product_list.product_code);
        let data_list = [];
        for (let i = 0; i < maxLengthProduct; i++) {
          data_list.push({ wood_qty: 0, yard_qty: 0 });
        }
        for (let a of arr) {
          for (let i = 0; i < a.data_list.length; i++) {
            data_list[i].wood_qty += a.data_list[i] ? a.data_list[i].wood_qty : 0;
            data_list[i].yard_qty += a.data_list[i] ? a.data_list[i].yard_qty : 0;
          }
        }
        array_list.push({ ...product_list, data_list: data_list });
      }
    }
    this.setState({
      array_count_3: count,
      array_product_3: array_list,
    });

    // tab 02
    let array3 = [];
    let id = 1;
    for (let item of this.state.array_product_all) {
      for (let product of item.shoot_list) {
        // eslint-disable-next-line no-loop-func
        let index = array3.findIndex((e) => e.pile === item.pile && e.room_number === item.room_number && e.id === id);
        if (index === -1) {
          array3.push({
            id: id,
            product_code: product.product_code,
            product_name: product.product_name,
            type_product_id: product.type_product_id,
            type_product_name: product.type_product_name,
            pile: item.pile,
            room_number: item.room_number,
            count: array3.filter((e) => e.room_number === item.room_number && e.pile === item.pile).length + 1,
            array: [{ product_color: product.product_color, qty: product.yard_qty }],
          });
        } else {
          array3[index].array = [...array3[index].array, { product_color: product.product_color, qty: product.yard_qty }];
        }
      }
      id += 1;
    }
    array3 = array3.sort(fieldSorter(["room_number", "pile"])).filter((product) => (!this.state.type || product.type_product_id === this.state.type.value) && (!this.state.room_number || product.room_number === this.state.room_number) && (!this.state.pile || product.pile === this.state.pile));
    for (let item of array3) {
      for (let i = 0; i < 20; i++) {
        if (item.array.length % 20 !== 0) {
          item.array.push("");
        }
      }
    }
    this.setState({ data_report: array3 });
  };
  CloseRound = async () => {
    this.setState({ loading: true });

    let body = {
      date_time: format_date(new Date().toString(), "yyyy-mm-dd hh:mm", "en"),
      remark: this.state.remark,
    };
    let result = await POST_CACHE(tokens, "v1/check-stock/cycle", body);
    if (result && result.status) {
      this.setState({ loading: false });
      alert_url("success", "สำเร็จ", "ปิดไตรมาศสต็อกเรียบร้อยแล้ว", "/stock-cycle");
    }
    this.setState({ loading: false });
  };
  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  ClearForm() {
    this.setState({
      page: 1,

      id: "",
    });
  }
  print = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      FCIconic: {
        normal: "FCIconic-Regular.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Regular.ttf",
        bolditalics: "FCIconic-Regular.ttf",
      },
      THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
      "3OF9_NEW": {
        normal: "3OF9_NEW-normal.ttf",
        bold: "3OF9_NEW-normal.ttf",
        italics: "3OF9_NEW-normal.ttf",
        bolditalics: "3OF9_NEW-normal.ttf",
      },
    };
    // eslint-disable-next-line array-callback-return
    let text = this.state.data_report
      .filter((item) => (this.state.room_number === "" || this.state.room_number === item.room_number) && (this.state.pile === "" || this.state.pile === item.pile) && (this.state.type === "" || this.state.type.label === item.type_product_name))
      // eslint-disable-next-line array-callback-return
      .map((item, index) => {
        if (this.state.count === "" || "ครั้งที่ " + item.count === this.state.count) {
          return {
            stack: [
              {
                columns: [
                  { text: "ประเภทผ้า: ", margin: [0, 3, 0, 0], width: 55, fontSize: 16, bold: true },
                  { text: item.type_product_name, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                  { text: "ห้อง: ", margin: [0, 3, 0, 0], width: 25, fontSize: 16, bold: true },
                  { text: item.room_number, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                  { text: "กอง: ", margin: [0, 3, 0, 0], width: 25, fontSize: 16, bold: true },
                  { text: item.pile, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                  { text: "ครั้งที่: ", margin: [0, 3, 0, 0], width: 35, fontSize: 16, bold: true },
                  { text: item.count, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                  { text: "จำนวนไม้ทั้งหมด: ", margin: [0, 3, 0, 0], width: 80, fontSize: 16, bold: true },
                  { text: item.array.filter((e) => e !== "").length + " ไม้", width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                  { text: "คนเช็ค: ", margin: [0, 3, 0, 0], width: 35, fontSize: 16, bold: true },
                  { text: item.checker_name, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                ],
                alignment: "left",
              },
              {
                table: {
                  headerRows: 1,
                  widths: this.state.number.map((e, e_index) => {
                    return 100 / this.state.number.length + "%";
                  }),
                  margin: [0, 0, 0, 0],
                  body: [
                    this.state.number.map((e, e_index) => {
                      return { fillColor: "#333333", color: "#ffffff", text: (e_index + 1).toString(), alignment: "center", bold: true };
                    }),
                    ...item.array
                      .filter((item2, index2) => index2 % this.state.number.length === 0)
                      .map((item2, index2) => {
                        return [
                          ...item.array
                            .filter((item3, index3) => index3 < (index2 + 1) * this.state.number.length && index3 >= index2 * this.state.number.length)
                            .map((item3, index3) => {
                              return {
                                fillColor: index2 % 2 === 0 ? "#cccccc" : "#ffffff",
                                stack: [
                                  { text: item3.product_color ? item3.product_color : "", margin: [0, -3, 0, 0], lineHeight: 0.75, alignment: "center" },
                                  { text: item3.qty ? item3.qty + " Y" : "", lineHeight: 0.55, alignment: "center" },
                                  { text: item3.qty ? "_______\n " : "", lineHeight: 0.8, margin: [0, -20, 0, 0], fontSize: 12, alignment: "center" },
                                ],
                              };
                            }),
                        ];
                      }),
                  ],
                },
              },
              { text: " " },
            ],
          };
        }
      });
    var docDefinition = {
      content: text,
      defaultStyle: { font: "THSarabunNew", fontSize: 13 },
      pageMargins: [10, 10, 10, 10],
      pageOrientation: "landscape",
      pageSize: "A4",
    };
    pdfMake.createPdf(docDefinition).open();
    // print(docDefinition, "barcode.pdf")
  };
  total_count = (type, index) => {
    let data = this.state.data_report2.filter((item) => this.state.type === "" || this.state.type.value === item.type_product_id);
    let yard_qty = 0;
    let wood_qty = 0;
    for (let item of data) {
      for (let i = 0; i < item.array.length; i++) {
        if (index === i) {
          yard_qty += item.array[i].yard_qty;
          wood_qty += item.array[i].wood_qty;
        }
      }
    }
    if (type === "yard_qty") {
      return yard_qty;
    } else {
      return wood_qty;
    }
  };
  yard_total(count, data) {
    let total = 0;
    let yard = document.getElementsByName("yard");
    for (let item of yard) {
      if (item.id === count) {
        total += Number(item.innerText.replaceAll(",", ""));
      }
    }
    return toFixed(total);
  }
  wood_total(count, data) {
    let total = 0;
    let yard = document.getElementsByName("wood");
    for (let item of yard) {
      if (item.id === count) {
        total += Number(item.innerText.replaceAll(",", ""));
      }
    }
    return total;
  }
  yard_total2(count, data) {
    let total = 0;
    let yard = document.getElementsByName("yard2");
    for (let item of yard) {
      if (item.id === count) {
        total += Number(item.innerText.replaceAll(",", ""));
      }
    }
    return toFixed(total);
  }
  wood_total2(count, data) {
    let total = 0;
    let wood = document.getElementsByName("wood2");
    for (let item of wood) {
      if (item.id === count) {
        total += Number(item.innerText.replaceAll(",", ""));
      }
    }
    return total;
  }
  count(tab, type, index) {
    let total = 0;
    if (tab === "1") {
      for (let item of this.state.array_product) {
        for (let i = 0; i < item.data_list.length; i++) {
          if (item.data_list[i] && i === index) {
            if (type === "yard") {
              total += item.data_list[i].yard_qty;
            } else {
              total += item.data_list[i].wood_qty;
            }
          }
        }
      }
    } else if (tab === "3") {
      for (let item of this.state.array_product_3) {
        for (let i = 0; i < item.data_list.length; i++) {
          if (item.data_list[i] && i === index) {
            if (type === "yard") {
              total += item.data_list[i].yard_qty;
            } else {
              total += item.data_list[i].wood_qty;
            }
          }
        }
      }
    }
    return baht(total);
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="เช็คสต็อก" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>ประวัติการเช็คสต็อก {this.state.cycle_number ? " : " + this.state.cycle_number : ""}</b>
              </h3>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/check-stock";
                  }}
                >
                  <span className="icon-brand text-18 mr-2">{"\uf060"}</span>
                  ย้อนกลับ
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-3 ml-1"
                  onClick={() => {
                    window.location.href = "/stock-cycle";
                  }}
                >
                  รายการรอบไตรมาส
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16 align-items-end">
                  {(this.state.tab === 1 || this.state.tab === 2 || this.state.tab === 3) && (
                    <div className="wpx-200 mx-1 mb-2">
                      <label className="mพ-2 ">ประเภทผ้า : </label>
                      <Select
                        styles={customStyles}
                        options={this.state.data_type}
                        placeholder="พิมพ์ชื่อสินค้า"
                        onChange={(e) => {
                          this.setState({
                            type: e,
                            no: "",
                          });
                          setTimeout(() => {
                            this.getData();
                          }, 10);
                        }}
                        value={this.state.type}
                        isClearable
                      />
                    </div>
                  )}
                  {(this.state.tab === 1 || this.state.tab === 2) && (
                    <div className="wpx-200 mx-1 mb-2">
                      <label className="mพ-2 ">ห้อง : </label>
                      <select
                        className="form-control-select"
                        onChange={(e) => {
                          this.setState({ room_number: e.target.value, no: "" });
                          setTimeout(() => {
                            this.getData();
                          }, 10);
                        }}
                        value={this.state.room_number}
                      >
                        <option value=""></option>
                        {this.state.data_room.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  {(this.state.tab === 1 || this.state.tab === 2) && (
                    <div className="wpx-200 mx-1 mb-2">
                      <label className="mพ-2 ">กอง : </label>
                      <select
                        className="form-control-select"
                        onChange={(e) => {
                          this.setState({ pile: e.target.value, no: "" });
                          setTimeout(() => {
                            this.getData();
                          }, 10);
                        }}
                        value={this.state.pile}
                      >
                        <option value=""></option>
                        {this.state.data_pile.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  {(this.state.tab === 1 || this.state.tab === 2 || this.state.tab === 3) && (
                    <div className="wpx-200 mx-1 mb-2">
                      <label className="mพ-2 ">ครั้ง : </label>
                      <select
                        className="form-control-select"
                        onChange={(e) => {
                          this.setState({ no: e.target.value });
                        }}
                        value={this.state.no}
                      >
                        <option value=""></option>
                        {this.state.tab === 1 ? this.state.array_count.map((item, index) => <option value={index}>{item}</option>) : this.state.array_count_3.map((item, index) => <option value={index}>{item}</option>)}
                      </select>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-outline-primary bg-white ml-2 mb-2 mt-1"
                    onClick={() => {
                      this.setState({
                        room_number: "",
                        pile: "",
                        no: "",
                        type: "",
                      });
                    }}
                  >
                    <span className="icon-brand text-18 ">{"\uf2f9"}</span> รีเซ็ท
                  </button>
                  {this.state.tab === 2 && (
                    <button
                      type="button"
                      className="btn btn-primary px-4 mb-2 ml-2"
                      onClick={() => {
                        this.print();
                      }}
                    >
                      <span className="icon-brand text-18 mr-2">{"\uf02f"}</span>
                      พิมพ์
                    </button>
                  )}
                  {this.state.array_count && (this.state.tab === 1 || this.state.tab === 3) && (
                    <DownloadTableExcel filename={"ประวัติการเช็คสต็อก"} sheet={"ประวัติการเช็คสต็อก"} currentTableRef={this.tableRef.current}>
                      <button className="btn btn-primary ml-2 mb-2 mt-1">ส่งออก excel</button>
                    </DownloadTableExcel>
                  )}
                  {!this.state.cycle_number && (
                    <button
                      type="button"
                      className="btn btn-danger px-4 ml-auto mb-2"
                      onClick={() => {
                        this.setState({ modal_close: true, remark: "", confirm: random() });
                      }}
                    >
                      ปิดไตรมาสการเช็คสต็อก
                    </button>
                  )}
                </div>
                <div className="d-flex p-2">
                  <div className="card" style={{ borderStyle: "solid", borderWidth: 2, borderColor: "#4E6FFB", padding: 2, borderRadius: 12 }}>
                    <div className="d-flex flex-wrap">
                      <button
                        type="button"
                        className={this.state.tab === 1 ? "btn btn-primary px-4" : "btn bg-white px-4"}
                        onClick={() => {
                          this.setState({
                            tab: 1,
                            room_number: "",
                            pile: "",
                            no: "",
                            type: "",
                          });
                        }}
                      >
                        ประวัติการเช็คสต็อกรวม
                      </button>
                      <button
                        type="button"
                        className={this.state.tab === 2 ? "btn btn-primary px-4" : "btn bg-white px-4"}
                        onClick={() => {
                          this.setState({
                            tab: 2,
                            room_number: "",
                            pile: "",
                            no: "",
                            type: "",
                          });
                        }}
                      >
                        ประวัติการเช็คสต็อกรายละเอียด
                      </button>
                      <button
                        type="button"
                        className={this.state.tab === 3 ? "btn btn-primary px-4" : "btn bg-white px-4"}
                        onClick={() => {
                          this.setState({
                            tab: 3,
                            room_number: "",
                            pile: "",
                            no: "",
                            type: "",
                          });
                        }}
                      >
                        จำนวนรวมแยกตามสินค้า
                      </button>
                    </div>
                  </div>
                </div>
                {(this.state.tab === 1 || this.state.tab === 3) && (
                  <div className="card-body p-0 ">
                    <div className="table-responsive">
                      <table className="table table-striped table-borderless table-hover table-fixed3" ref={this.tableRef}>
                        <thead>
                          <tr className="bg-primary">
                            <th className="bg-primary"></th>
                            <th className="bg-primary"></th>
                            <th className="bg-primary"></th>
                            {this.state.tab === 1 && ( <th></th>)}
                            {this.state.tab === 1 && (<th className="border-right"></th>)}
                            {this.state.array_count &&
                              this.state.array_count.map(
                                (item, index) =>
                                  (this.state.no === "" || index === Number(this.state.no)) && (
                                    <th className="text-center border-right text-white" colSpan={2}>
                                      {item}
                                    </th>
                                  )
                              )}
                          </tr>
                          <tr className="bg-blue-light">
                            <th className="text-center wpx-70 bg-blue-light">
                              <b>ลำดับ</b>
                            </th>
                            <th className="text-left wpx-250 bg-blue-light">
                              <b>รายการ</b>
                            </th>
                            <th className="text-left wpx-150 bg-blue-light border-right">
                              <b>ประเภท</b>
                            </th>
                            {this.state.tab === 1 && (
                            <th className="text-center wpx-70">
                              <b>ห้อง</b>
                            </th>
                            )}
                             {this.state.tab === 1 && (
                            <th className="text-center wpx-70 border-right">
                              <b>กอง</b>
                            </th>
                             )}
                            {this.state.array_count &&
                              this.state.array_count.map(
                                (item, index) =>
                                  (this.state.no === "" || index === Number(this.state.no)) && (
                                    <>
                                      <th className="text-center border-right">
                                        หลา
                                        <br />
                                        {this.count("1", "yard", index)}
                                      </th>
                                      <th className="text-center border-right">
                                        ไม้
                                        <br />
                                        {this.count("1", "wood", index)}
                                      </th>
                                    </>
                                  )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tab === 1 && this.state.array_product && this.state.array_product.map(
                              (product, product_index) =>
                                (this.state.no === "" || product.data_list[Number(this.state.no)] !== "") && (
                                  <tr key={product_index}>
                                    <td className="text-center">{product_index + 1}</td>
                                    <td className="text-left">{product.product_name}</td>
                                    <td className="text-left border-right">{product.type_product_name}</td>
                                    {this.state.tab === 1 && (<td className="text-center">{product.room_number}</td>)}
                                    {this.state.tab === 1 && ( <td className="text-center">{product.pile}</td>)}
                                    {product.data_list.map(
                                      (check, check_index) =>
                                        (this.state.no === "" || check_index === Number(this.state.no)) && (
                                          <>
                                            <td className="text-center border-left">{check !== "" ? baht(check.yard_qty) : ""}</td>
                                            <td className="text-center border-left">{check.wood_qty}</td>
                                          </>
                                        )
                                    )}
                                  </tr>
                                )
                            )}
                            {this.state.tab === 3 && this.state.array_product_3 && this.state.array_product_3.map(
                              (product, product_index) =>
                                (this.state.no === "" || product.data_list[Number(this.state.no)] !== "") && (
                                  <tr key={product_index}>
                                    <td className="text-center">{product_index + 1}</td>
                                    <td className="text-left">{product.product_name}</td>
                                    <td className="text-left border-right">{product.type_product_name}</td>
                                    {product.data_list.map(
                                      (check, check_index) =>
                                        (this.state.no === "" || check_index === Number(this.state.no)) && (
                                          <>
                                            <td className="text-center border-left">{check !== "" && check.yard_qty ? baht(check.yard_qty) : ""}</td>
                                            <td className="text-center border-left">{check.wood_qty ? check.wood_qty : ""}</td>
                                          </>
                                        )
                                    )}
                                  </tr>
                                )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {this.state.tab === 2 && (
                  <div className="card-body p-4">
                    {this.state.data_report &&
                      this.state.data_report.map(
                        (item, index) =>
                          (this.state.no === "" || item.count === Number(this.state.no) + 1) && (
                            <div className="row">
                              <div className="col-12 my-3">
                                <div className="d-flex align-items-end">
                                  <h6>
                                    <b className="mr-2">ประเภทผ้า:</b>
                                  </h6>
                                  <h5 className="mr-5">{item.type_product_name}</h5>
                                  <h6>
                                    <b className="mr-2">ห้อง:</b>
                                  </h6>
                                  <h5 className="mr-5">{item.room_number}</h5>
                                  <h6>
                                    <b className="mr-2">กอง:</b>
                                  </h6>
                                  <h5 className="mr-5">{item.pile}</h5>
                                  <h6>
                                    <b className="mr-2">ครั้งที่:</b>
                                  </h6>
                                  <h5 className="mr-5">{item.count}</h5>
                                  <h6>
                                    <b className="mr-2">จำนวนไม้ทั้งหมด:</b>
                                  </h6>
                                  <h5 className="mr-5">{item.array.filter((e) => e !== "").length} ไม้</h5>
                                  <h6>
                                    <b className="mr-2">คนเช็ค:</b>
                                  </h6>
                                  <h5 className="mr-5">{item.checker_name}</h5>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr className="bg-dark">
                                        {this.state.number.map((num) => (
                                          <th className="text-center text-light">
                                            <b>{num + 1}</b>
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.array
                                        .filter((item2, index2) => index2 % this.state.number.length === 0)
                                        .map((item2, index2) => (
                                          <tr key={index}>
                                            {item.array
                                              .filter((item3, index3) => index3 < (index2 + 1) * this.state.number.length && index3 >= index2 * this.state.number.length)
                                              .map((item3, index3) => (
                                                <td className="text-center p-1 text-14">
                                                  {item3.product_color ? item3.product_color : ""}
                                                  <br />
                                                  {item3.product_color && <hr className="my-1 bg-dark" />}
                                                  {item3.qty ? item3.qty + " Y" : ""}
                                                </td>
                                              ))}
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal ลบสินค้า */}
        <Modal
          show={this.state.modal_close}
          onHide={() => {
            this.setState({ modal_close: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการปิดไตรมาศสต็อกหรือไม่</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className=" mb-3">หมายเหตุ</div>
              <textarea
                className="form-control mb-3"
                onChange={(e) => {
                  this.setState({ remark: e.target.value });
                }}
                value={this.state.remark}
                rows={5}
              />
              <div className=" mb-3">
                กรุณากรอกตัวเลข 6 หลัก เพื่อยืนยันการปิดไตรมาศ <b>"{this.state.confirm}"</b>
              </div>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  this.setState({ confirm_input: e.target.value });
                }}
                value={this.state.confirm_input}
                placeholder="000000"
              />
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_close: false });
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.confirm_input === this.state.confirm) {
                      setTimeout(() => {
                        this.CloseRound();
                      }, 10);
                    } else {
                      alert("warning", "แจ้งเตือน", "รหัสยืนยันไม่ตรงกัน กรุณาลองใหม่อีกครั้ง");
                    }
                  }}
                >
                  {" "}
                  ตกลง{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
