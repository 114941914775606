import React, { Component } from "react";
import { cookies, tokens } from "../components/CustomComponent.js";
import "../assets/css/bootstrap.css";
export default class LoadingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    let permission_page = localStorage.getItem("permission_page");

    if (tokens && permission_page) {
      window.location.href = permission_page;
    } else {
      localStorage.clear();

      cookies.remove("permission_page");
      cookies.remove("bill");
      cookies.remove("user_code");

      window.location.href = "/login";
    }
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    );
  }
}
