import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { tokens, format_date, GET } from "../../../components/CustomComponent.js";
import "../../../assets/css/bootstrap.css";
export default class LogAuditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,

      data: null,

      search: "",

      status: this.props.location.query.type ? this.props.location.query.type : "1",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }

  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/history-transaction/order/filter-search/" + this.state.page + "?data_type=" + this.state.status + "&data_search=" + this.state.search, null);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      id: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="ประวัติการตรวจสอบ" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>ประวัติการตรวจสอบ</b>
              </h3>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-6 col-sm-6 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <select
                        className="form-control-select pointer"
                        onChange={(e) => {
                          this.setState({ search: "", page: 1, status: e.target.value });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                        value={this.state.status}
                      >
                        <option value="1">รายการขาย</option>
                        <option value="2">ใบเสนอราคา</option>
                        <option value="3">ใบรับสินค้า</option>
                        <option value="4">คืนสินค้า</option>
                      </select>
                    </div>
                  </div>

                  {/* <div className="d-flex row mx-0 align-items-center">
                                        <input
                                            type="date"
                                            className="form-control-select wpx-150 mb-1 mt-1"
                                            value={this.state.start_date}
                                            placeholder="วันที่เริ่มต้น"
                                            onChange={(e) => {
                                                this.setState({ page: 1, start_date: e.target.value })

                                                setTimeout(() => {
                                                    this.GetAll()
                                                }, 20)
                                            }}
                                        ></input>
                                        <label className="mx-2 ">ถึง</label>
                                        <input
                                            type="date"
                                            className="form-control-select wpx-150 mb-1 mt-1 "
                                            value={this.state.end_date}
                                            placeholder="วันที่สิ้นสุด"
                                            onChange={(e) => {

                                                const date_start_date = new Date(this.state.start_date).getTime();
                                                const date_end_date = new Date(e.target.value).getTime();

                                                if (date_start_date <= date_end_date) {
                                                    this.setState({ page: 1, end_date: e.target.value })
                                                    setTimeout(() => {
                                                        this.GetAll()
                                                    }, 20)
                                                }
                                            }}
                                        ></input>


                                        <button type="button" className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                                            onClick={() => {
                                                this.Input.clearValue()
                                                this.setState({
                                                    page: 1,
                                                    search: "",
                                                    data: null,
                                                    type_product_id: "",
                                                    warehouse_id: "",
                                                    start_date: "",
                                                    end_date: "",
                                                    fillter_province_id: "",
                                                    fillter_province_name: ""
                                                })


                                                setTimeout(() => {
                                                    this.GetAll()
                                                }, 20)
                                            }}>
                                            <span className="icon-brand text-18 ">{"\uf2f9"}
                                            </span> รีเซ็ท
                                        </button>

                                    </div> */}
                </div>
                <div className="card-body p-0 ">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>เลขที่เอกสาร</b>
                          </th>
                          <th className="text-left">
                            <b>วันที่</b>
                          </th>
                          <th className="text-left">
                            <b>ชื่อลูกค้า</b>
                          </th>
                          <th className="text-center">
                            <b>รายละเอียด</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={4} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.number}</td>
                              <td className="text-left">{format_date(item.datetime_create)}</td>
                              <td className="text-left">{item.cutomer_name}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    window.location.href = "/logaudit/detail?id=" + item.number + "&type=" + this.state.status;
                                  }}
                                >
                                  <label className="text-dark">
                                    <b>
                                      <u>ดูข้อมูล</u>
                                    </b>
                                  </label>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {this.state.data.end_index} รายการจาก {this.state.data.count} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
