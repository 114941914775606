import React, { Component } from "react";
import { tokens } from "../components/CustomComponent.js";
import "../assets/css/bootstrap.css";

import PAGENOTFOUND from "../assets/images/pagenotfound.jpg";

export default class PageNotFoundScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 10);
  }

  GoToBack = async () => {
    let permission_page = localStorage.getItem("permission_page");

    if (tokens && permission_page) {
      window.location.href = permission_page;
    } else {
      localStorage.clear();
      window.location.href = "/";
    }
  };

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div style={{ height: 60 }}></div>
        <div className="row mx-0">
          <div className="w-100 p-5">
            <div className="card border border-grey border-dark">
              <div className="m-5">
                <div className="w-100 row d-flex justify-content-between align-content-center">
                  <div className="m-auto">
                    <p className="text-52 text-title mb-3">
                      <b>Sorry!</b>
                    </p>
                    <p className=" text-30 text-title">We can't seem to find the page you're looking for.</p>
                    <p className="text-18 text-title mb-4">Error code: 404</p>
                    <button
                      className="btn btn-primary px-4"
                      onClick={() => {
                        this.GoToBack();
                      }}
                    >
                      GO BACK
                    </button>
                  </div>

                  <img className="img-page-not-found m-auto" alt="logo" src={PAGENOTFOUND}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
