/* eslint-disable jsx-a11y/alt-text */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, PUT, DELETE, tokens, role, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class BankScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,

      search: "",
      bank_name: "",
      bank_NO: "",
      branch: "",
      logo: "",
      logo_name: "",

      id: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/bank_account/search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };
  AddBank = async () => {
    this.setState({ loading: true });
    let body = {
      bank_name: this.state.bank_name,
      bank_number: this.state.bank_NO,
      bank_branch: this.state.branch,
      logo: this.state.logo,
    };

    let result = await POST(tokens, "v1/bank_account/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มบัญชีธนาคารเรียบร้อยแล้ว", "บัญชีธนาคาร ' " + this.state.bank_name + " ' ถูกเพิ่มในระบบแล้ว");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  EditBank = async () => {
    this.setState({ loading: true });
    let body = {
      bank_id: this.state.id,
      bank_name: this.state.bank_name,
      bank_number: this.state.bank_NO,
      bank_branch: this.state.branch,
      logo: this.state.logo,
    };
    let result = await PUT(tokens, "/v1/bank_account/update", body);
    if (result && result.status) {
      alert("success", "แก้ไขข้อมูลบัญชีธนาคารเรียบร้อยแล้ว", "บัญชีธนาคาร ' " + this.state.bank_name + " ' ถูกแก้ไขข้อมูลในระบบแล้ว");
      this.setState({ loading: false, modal_edit: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  Upload_Logo = (e) => {
    try {
      if (e.target.files.length) {
        let fileObj = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.setState({
            logo: e.target.result,
            logo_name: fileObj.name,
          });
          document.getElementById("file").value = "";
        };
      }
    } catch (error) {
      alert("warning", "เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง");
      document.getElementById("file").value = "";
    }
  };

  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/bank_account/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลบัญชีเรียบร้อยแล้ว", "บัญชี ' " + this.state.bank_name + " ' ถูกลบข้อมูลในระบบแล้ว");
      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      search: "",
      bank_name: "",
      bank_NO: "",
      branch: "",
      logo: "",
      logo_name: "",

      id: "",
    });
  }
  ClearFormFile() {
    this.setState({
      logo_name: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="บัญชีธนาคาร" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>บัญชีธนาคาร</b>
              </h3>
              {role.bank_account[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.setState({ modal_add: true });
                    }}
                  >
                    {" "}
                    เพิ่มธนาคาร{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>ชื่อธนาคาร</b>
                          </th>
                          <th className="text-left">
                            <b>logo</b>
                          </th>
                          <th className="text-left">
                            <b>เลขบัญชี</b>
                          </th>
                          <th className="text-left">
                            <b>สาขา</b>
                          </th>
                          {(role.bank_account[2] === 1 || role.bank_account[3] === 1) && (
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={5} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.bank_name}</td>
                              <td className="text-left">
                                {item.logo ? (
                                  <div className="w-100 ">
                                    <img src={item.logo} className="hpx-48 wpx-48 rounded-circle" alt="logo" />
                                  </div>
                                ) : (
                                  <div className="w-100">
                                    <div className="bg-gray hpx-48 wpx-48 rounded-circle"></div>
                                  </div>
                                )}
                              </td>
                              <td className="text-left">{item.bank_number}</td>
                              <td className="text-left">{item.bank_branch}</td>
                              <td className="text-center">
                                {role.bank_account[2] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        id: item.bank_id,
                                        bank_name: item.bank_name,
                                        bank_NO: item.bank_number,
                                        branch: item.bank_branch,
                                        logo: item.logo,
                                        modal_edit: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf040"}</label>
                                  </button>
                                )}

                                {role.bank_account[3] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        bank_name: item.bank_name,
                                        id: item.bank_id,
                                        modal_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf2ed"}</label>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100  justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal เพิ่มบัญชีธนาคาร */}
        <Modal
          show={this.state.modal_add}
          size="m"
          onHide={() => {
            this.setState({ modal_add: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เพิ่มบัญชีธนาคาร</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อธนาคาร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อธนาคาร:</label>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ bank_name: e.target.value });
                  }}
                  value={this.state.bank_name}
                ></input>
              </div>
            </div>
            {/* เลขบัญชี */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขบัญชี:</label>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  maxLength={19}
                  onChange={(e) => {
                    let bank_NO = this.state.bank_NO;
                    bank_NO = e.target.value.replace(/\D/g, "");
                    this.setState({
                      bank_NO: bank_NO,
                    });
                  }}
                  value={this.state.bank_NO}
                ></input>
              </div>
            </div>
            {/* สาขา */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>สาขา:</label>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      branch: e.target.value,
                    });
                  }}
                  value={this.state.branch}
                ></input>
              </div>
            </div>

            {/* อัปโหลดโลโก้ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อัปโหลดโลโก้:</label>
              </div>
              <div className="w-100">
                {this.state.logo_name === "" ? (
                  <div
                    className="form-control-inputfile text-header"
                    onClick={() => {
                      this.refs.fileUploader.click();
                    }}
                  >
                    <label className="icon mr-1">{"\uf319"}</label>
                    <small>นำเข้าไฟล์</small>
                  </div>
                ) : (
                  <div className="form-control-inputfile text-dark">
                    <div className="d-flex justify-content-center align-content-center">
                      <p className="number-line-180px">{this.state.logo_name}</p>

                      <button
                        className="btn btn-link text-secondary"
                        onClick={() => {
                          this.ClearFormFile();
                        }}
                      >
                        <label
                          className="icon bg-danger text-14 text-white"
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: 12,
                            marginBottom: 16,
                          }}
                        >
                          {"\uf00d"}
                        </label>
                      </button>
                    </div>
                  </div>
                )}

                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpeg"
                  ref="fileUploader"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    this.Upload_Logo(e);
                  }}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.bank_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อธนาคาร");
                      return;
                    } else if (this.state.bank_NO === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขบัญชี");
                      return;
                    } else if (this.state.branch === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกสาขา");
                      return;
                    }

                    this.AddBank();
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal แก้ไขบัญชีธนาคาร */}
        <Modal
          show={this.state.modal_edit}
          size="m"
          onHide={() => {
            this.setState({ modal_edit: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขบัญชีธนาคาร</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อธนาคาร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อธนาคาร:</label>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ bank_name: e.target.value });
                  }}
                  value={this.state.bank_name}
                ></input>
              </div>
            </div>
            {/* เลขบัญชี */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขบัญชี:</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={19}
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let bank_NO = this.state.bank_NO;
                    bank_NO = e.target.value.replace(/\D/g, "");
                    this.setState({
                      bank_NO: bank_NO,
                    });
                  }}
                  value={this.state.bank_NO}
                ></input>
              </div>
            </div>
            {/* สาขา */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>สาขา:</label>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      branch: e.target.value,
                    });
                  }}
                  value={this.state.branch}
                ></input>
              </div>
            </div>
            {/* อัปโหลดโลโก้ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อัปโหลดโลโก้:</label>
              </div>
              <div className="w-100">
                {this.state.logo === "" ? (
                  <div
                    className="form-control-inputfile text-header"
                    onClick={() => {
                      this.refs.fileUploader.click();
                    }}
                  >
                    <label className="icon mr-1">{"\uf319"}</label>
                    <small>นำเข้าไฟล์</small>
                  </div>
                ) : (
                  <>
                    {this.state.logo_name === "" ? (
                      <div className="d-flex  align-content-center">
                        <img className="hpx-48 wpx-48 rounded-circle" alt="logo" src={this.state.logo} style={{ objectFit: "contain" }}></img>

                        <u
                          className="mt-3 ml-3 text-primary"
                          onClick={() => {
                            this.refs.fileUploader.click();
                          }}
                        >
                          เปลี่ยน
                        </u>
                      </div>
                    ) : (
                      <div className="form-control-inputfile text-dark">
                        <div className="d-flex justify-content-center align-content-center">
                          <p className="number-line-180px">{this.state.logo_name}</p>

                          <button
                            className="btn btn-link text-secondary"
                            onClick={() => {
                              this.ClearFormFile();
                            }}
                          >
                            <label
                              className="icon bg-danger text-14 text-white"
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: 12,
                                marginBottom: 16,
                              }}
                            >
                              {"\uf00d"}
                            </label>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpeg"
                  ref="fileUploader"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    this.Upload_Logo(e);
                  }}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.bank_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อธนาคาร");
                      return;
                    } else if (this.state.bank_NO === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขบัญชี");
                      return;
                    } else if (this.state.branch === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกสาขา");
                      return;
                    }

                    this.EditBank();
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal ยืนยันการลบบัญชีธนาคาร */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการบัญชีธนาคาร</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการบัญชีธนาคาร “{this.state.bank_name}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
