import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, PUT, DELETE, tokens, role, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class CompanyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,

      id: "",
      search: "",
    };
  }

  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
    };
    let result = await POST(tokens, "v1/user-member/supplier/search/page/" + this.state.page, body);

    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  AddCampany = async () => {
    this.setState({ loading: true });

    let body = {
      company_name: this.state.company_name,
      tax_invoice: this.state.tax_invoice,
      address: this.state.address,
      phone: this.state.phone,
      email: this.state.email,
      line_id: this.state.line_id,
    };

    let result = await POST(tokens, "v1/user-member/supplier/create", body);

    if (result && result.status) {
      alert("success", "เพิ่มบริษัทจำหน่ายเรียบร้อยแล้ว", "บริษัท ' " + this.state.company_name + " ' ถูกเพิ่มในระบบแล้ว");

      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  EditCampany = async () => {
    this.setState({ loading: true });

    let body = {
      company_id: this.state.id,
      company_name: this.state.company_name,
      tax_invoice: this.state.tax_invoice,
      address: this.state.address,
      phone: this.state.phone,
      email: this.state.email,
      line_id: this.state.line_id,
    };

    let result = await PUT(tokens, "v1/user-member/supplier/update", body);

    if (result && result.status) {
      alert("success", "แก้ไขข้อมูลบริษัทเรียบร้อยแล้ว", "บริษัท ' " + this.state.company_name + " ' ถูกแก้ไขข้อมูลในระบบแล้ว");

      this.setState({ loading: false, modal_edit: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/user-member/supplier/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลบริษัทจำหน่ายเรียบร้อยแล้ว", "บริษัท ' " + this.state.company_name + " ' ถูกลบข้อมูลในระบบแล้ว");

      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      loading: false,

      id: "",

      search: "",

      company_code: "",
      company_name: "",
      tax_invoice: "",
      address: "",
      phone: "",
      line_id: "",
      email: "",
    });
  }

  validateEmail = (email) => {
    return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  validatePhone = (phone) => {
    return phone.match(/^^[0][0-9]*$/);
  };

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายชื่อบริษัทจำหน่าย" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายชื่อบริษัทจำหน่าย</b>
              </h3>
              {role && role.company_supplier[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.ClearForm();
                      this.setState({ modal_add: true });
                    }}
                  >
                    {" "}
                    เพิ่มบริษัทจำหน่าย{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        maxLength={30}
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={this.state.data && this.state.data.data.length === 0 ? "card-body p-0 height-vh" : "card-body p-0 "}>
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>รหัสบริษัท</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 140 }}>
                            <b>ชื่อบริษัท</b>
                          </th>
                          <th className="text-left">
                            <b>เบอร์โทร</b>
                          </th>
                          <th className="text-left">
                            <b>Line ID</b>
                          </th>
                          <th className="text-left">
                            <b>อีเมล</b>
                          </th>
                          {role && (role.company_supplier[2] === 1 || role) && role.company_supplier[3] === 1 && (
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={6} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.company_code}</td>
                              <td className="text-left">{item.company_name ? item.company_name : ""}</td>
                              <td className="text-left">{item.phone ? item.phone : ""}</td>
                              <td className="text-left">{item.line_id ? item.line_id : ""}</td>
                              <td className="text-left">{item.email ? item.email : ""}</td>
                              <td className="text-center">
                                {role && role.company_supplier[2] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        company_code: item.company_code,
                                        company_name: item.company_name,
                                        tax_invoice: item.tax_invoice,
                                        address: item.address,
                                        phone: item.phone,
                                        line_id: item.line_id,
                                        email: item.email,
                                        id: item.company_id,
                                        modal_edit: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf040"}</label>
                                  </button>
                                )}

                                {role && role.company_supplier[3] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        company_name: item.company_name,
                                        id: item.company_id,
                                        modal_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf2ed"}</label>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* เพิ่มบริษัทจำหน่าย */}
        <Modal
          show={this.state.modal_add}
          size="m"
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className=" text-18">เพิ่มบริษัทจำหน่าย</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อบริษัท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อบริษัท:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={30}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ company_name: e.target.value });
                  }}
                  value={this.state.company_name}
                />
              </div>
            </div>

            {/* เลขกำกับภาษี */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขกำกับภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={13}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice;
                    tax_invoice = e.target.value.replace(/\D/g, "");
                    this.setState({
                      tax_invoice: tax_invoice,
                    });
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>

            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  maxLength={100}
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>

            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone;
                    phone = e.target.value.replace(/\D/g, "");
                    this.setState({
                      phone: phone,
                    });
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>

            {/* Line ID */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>Line ID:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={30}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ line_id: e.target.value });
                  }}
                  value={this.state.line_id}
                />
              </div>
            </div>

            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="email"
                  maxLength={40}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                  value={this.state.email}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.company_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท");
                      return;
                    } else if (this.state.tax_invoice.trim() !== "" && this.state.tax_invoice.length !== 13) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขกำกับภาษีให้ครบถ้วน");
                      return;
                    }

                    this.AddCampany();
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* แก้ไขรายชื่อบริษัทจำหน่าย */}
        <Modal
          show={this.state.modal_edit}
          size="m"
          onHide={() => {
            this.setState({ modal_edit: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขรายชื่อบริษัทจำหน่าย</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รหัสบริษัท: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสบริษัท:</label>
              </div>
              <div className="w-100">
                <label>{this.state.company_code}</label>
              </div>
            </div>

            {/* ชื่อบริษัท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อบริษัท:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  maxLength={30}
                  onChange={(e) => {
                    this.setState({ company_name: e.target.value });
                  }}
                  value={this.state.company_name}
                />
              </div>
            </div>

            {/* เลขกำกับภาษี */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขกำกับภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={13}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice;
                    tax_invoice = e.target.value.replace(/\D/g, "");
                    this.setState({
                      tax_invoice: tax_invoice,
                    });
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>

            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  maxLength={100}
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>

            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone;
                    phone = e.target.value.replace(/\D/g, "");
                    this.setState({
                      phone: phone,
                    });
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>

            {/* Line ID */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>Line ID:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={40}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ line_id: e.target.value });
                  }}
                  value={this.state.line_id}
                />
              </div>
            </div>

            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="email"
                  maxLength={40}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                  value={this.state.email}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                  }}
                >
                  {" "}
                  ย้อนกลับ{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.company_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท");
                      return;
                    } else if (this.state.tax_invoice.trim() !== "" && this.state.tax_invoice.length !== 13) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขกำกับภาษีให้ครบถ้วน");
                      return;
                    }

                    this.EditCampany();
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ยืนยันการลบบริษัทจำหน่าย */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการลบบริษัทจำหน่าย</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการลบบริษัท “{this.state.company_name}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
