/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { alert, alert_url, GET, POST, PUT, tokens, baht, role } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";

const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 70,
    padding: 8,
    paddingBottom: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const UserOption = (props) => (
  <Option {...props}>
    <div className="w-100 d-flex justify-content-between align-content-center">
      <label className="w-70">
        {props.data.value.product_main_data.product_code}, {props.data.value.product_main_data.product_name}
      </label>
    </div>
    <div className="w-100 d-flex justify-content-end">
      <p className="text-12 text-login mr-2">ไม้ในคลัง {props.data.value.stock_qty}</p>
      <p className="text-12 text-login ">หลาในคลัง {props.data.value.yard_qty}</p>
    </div>
  </Option>
);

export default class StockAdjustmentUpdateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_add: false,

      data: null,
      index: 0,

      document_code: "",

      date: "",
      profile: JSON.parse(localStorage.getItem("profile")),

      decision_status: null,

      warehouselist: null,
      product_list: null,
      warehouse_id: "",
      product: null,

      qty: 0,
      yard: 0,

      full_name: "",
      remark_title: "",
      remark_status: "",
      status: "0",

      id: this.props.location.query.id,

      user_id: "",
      product_id: "",
      type_product_id: "",
      search: "",
      status_url: "",

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",

      action_by: "",
      product_color: "",

      search_product: "",
    };
  }
  async componentDidMount() {
    await this.GetWareHouse();
    await this.Get_by_id(this.props.location.query.id);
  }

  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/stock-mgt/adjust/detail/" + id, null);
    if (result && result.status) {
      let date = result.data.data.datetime_create;
      let warehouse_id = this.state.warehouselist.find((e) => e.warehouse_name === result.data.data.warehouse_name).warehouse_id;
      let datetimes = date.split("T")[0].split("-")[2] + "/" + ("0" + Number(date.split("-")[1].split("/")[0])).toString().slice(-2) + "/" + (Number(date.split("T")[0].split("-")[0]) + 543).toString().slice(2, 4);
      let array = [];

      for (let data of result.data.data.data_list) {
        let d = {
          all_stock_id: data.all_stock_id,
          product_code: data.product_code,
          product_name: data.product_name,
          qty: data.qty,
          real_stock_qty: data.real_stock_qty,
          real_stock_yard_qty: data.real_stock_yard_qty,
          stock_adjust_id: data.stock_adjust_id,
          total_qty: data.total_qty,
          total_yard_qty: data.total_yard_qty ? data.total_yard_qty.toFixed(2) : data.total_yard_qty,
          yard_qty: data.yard_qty,
        };

        array.push(d);
      }

      setTimeout(() => {
        this.setState({
          warehouse_id: warehouse_id,
          remark_title: result.data.data.remark,
          full_name: result.data.data.action_by,
          document_code: result.data.data.document_code,
          date: datetimes,
          data: array,
          decision_status: result.data.data.decision_status.toString(),
          decision_remark: result.data.data.decision_remark,
          action_by: result.data.data.action_by,
        });

        if (this.state.warehouse_id !== "") {
          this.Get_Product_All(warehouse_id);
        }
      }, 10);

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  GetWareHouse = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);
    if (result && result.status) {
      this.setState({ warehouselist: result.data.data });
    }
    this.setState({ loading: false });
  };

  Get_Product_All = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/filter-search/page/0?data_search" + this.state.search + "&status=" + this.state.status_url + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + id + "&user_member_id=" + this.state.user_id + "&product_id=" + this.state.product_id + "&sort_name=" + this.state.sort_name + "&sort_yard=" + this.state.sort_yard + "&sort_color=" + this.state.sort_color + "&product_design=" + this.state.design_value.replaceAll("#", "%23") + "&color=" + this.state.product_color, null);
    if (result && result.status) {
      this.setState({ product_list: result.data });
    }
    this.setState({ loading: false });
  };

  Add_Product = () => {
    let d = this.state.data.filter((e) => e.all_stock_id === this.state.product.all_stock_id);
    if (d.length !== 0) {
      alert("warning", "แจ้งเตือน", "มีสินค้าชนิดนี้อยู่ในรายการ");
      return;
    } else {
      let array = this.state.data;
      let p = {
        stock_adjust_id: this.state.product.value.all_stock_id,
        product_code: this.state.product.value.product_main_data.product_code,
        product_name: this.state.product.value.product_main_data.product_name,
        all_stock_id: this.state.product.value.all_stock_id,
        real_stock_qty: Number(this.state.product.value.stock_qty),
        real_stock_yard_qty: Number(this.state.product.value.yard_qty),

        qty: 0,
        yard_qty: 0,

        total_qty: "",
        total_yard_qty: "",
      };

      array.push(p);
      this.setState({ data: array });
    }

    this.setState({ modal_add: false });
  };

  AddStockAdjustment = async () => {
    this.setState({ loading: true });
    let data = this.state.data;
    let array_data = [];

    data.forEach((e) => {
      let t = {
        all_stock_id: e.all_stock_id,

        // จำนวนไม้ที่นับจริงที่ต้องนำไปตัดออก (เลขติดลบ)
        qty: e.qty.toString(),
        yard_qty: e.yard_qty.toString(),

        // จำนวนไม้และหลา
        total_qty: e.total_qty.toString(),
        total_yard_qty: e.total_yard_qty.toString(),
      };
      array_data.push(t);
    });

    let body = {
      stock_adjust_main_id: this.state.id,
      status: 0,
      stock_type: 1,
      document_code: this.state.document_code,
      remark: this.state.remark_title,
      warehouse_id: this.state.warehouse_id,
      all_stock_list: array_data,
      user_approve: this.state.profile.full_name,
    };
    let result = await PUT(tokens, "v1/stock-mgt/adjust/update", body);
    if (result && result.status) {
      alert_url("success", "ปรับสต๊อกสินค้าเรียบร้อยแล้ว", "สต๊อกสินค้า ถูกปรับในในระบบแล้ว", " /stock-adjustment");
      this.setState({ loading: false, modal_add: false });

      this.ClearForm();
      this.ClearDataProduct();
    }
    this.setState({ loading: false });
  };

  ClearDataProduct() {
    this.setState({
      product: null,
    });
  }

  ClearForm() {
    this.setState({
      loading: false,
      remark_title: "",
      warehouse_id: "",

      user_id: "",
      product_id: "",
      type_product_id: "",
      search: "",
      status_url: "",
      index: 0,

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="รายการปรับสต๊อก" page3="จัดการการปรับสต๊อก" />
            <div style={{ height: 60 }}></div>
            <div className="mx-4 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between ">
                <h3>
                  <b>จัดการการปรับสต๊อก</b>
                </h3>

                {role && role.adjust_stock[0] === 1 && role.adjust_stock[2] === 0 && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 ml-2"
                      onClick={() => {
                        window.location.href = "/stock-adjustment";
                      }}
                    >
                      <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ
                    </button>
                  </div>
                )}
              </div>

              {/* title */}
              <div className="w-100 mx-0 mt-3">
                <div className="card p-3">
                  <div className="d-flex row align-items-center ">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3  d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">ผู้ปรับสต๊อก:</label>
                        <input type="text" className="form-control border-0 bg-secondary-light-disable text-disables pointer" value={this.state.full_name} readOnly></input>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-flex mb-3 ">
                      <div className="w-100 ">
                        <label className="w-100">เลขที่เอกสาร:</label>
                        <input type="text" className="form-control w-100 border-0 bg-secondary-light-disable text-disables pointer" value={this.state.document_code} readOnly></input>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-flex mb-3">
                      <div className="w-100">
                        <label className="w-100">วันที่:</label>
                        <input type="text" className="form-control w-100 border-0 bg-secondary-light-disable text-disables pointer" value={this.state.date} readOnly></input>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">สถานที่:</label>
                        <select
                          className="form-control w-100 border-0 bg-secondary-light-disable text-disables pointer"
                          onChange={(e) => {
                            this.setState({
                              warehouse_id: e.target.value,
                            });
                            this.Get_Product_All(e.target.value);
                          }}
                          value={this.state.warehouse_id}
                          disabled={this.state.data === null || this.state.data.length === 0 ? "" : "disabled"}
                        >
                          <option value="" selected disabled>
                            -- เลือก --
                          </option>
                          {this.state.warehouselist &&
                            this.state.warehouselist.map((item, index) => (
                              <option key={index} value={item.warehouse_id}>
                                {item.warehouse_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex row align-items-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  d-flex mb-3">
                      <div className="w-100 ">
                        <label className="w-100">หมายเหตุการปรับ:</label>
                        <input
                          type="text"
                          className="form-control w-100 border-0 pointer"
                          value={this.state.remark_title}
                          maxLength={50}
                          placeholder="อธิบายพอสังเขป"
                          onChange={(e) => {
                            this.setState({ remark_title: e.target.value });
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* table */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card" style={{ marginBottom: 32 }}>
                  <div className="card-header rounded-top-16">
                    <div className="w-100 d-flex justify-content-between align-items-center pt-2">
                      <h5>
                        <b>สินค้าที่ต้องการปรับ</b>
                      </h5>
                      <div>
                        <button
                          className="btn btn-outline-primary px-3 bg-white"
                          onClick={() => {
                            if (this.state.warehouse_id === "") {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกโกดัง");
                              return;
                            }
                            this.ClearDataProduct();

                            this.setState({
                              modal_add: true,
                            });
                          }}
                        >
                          + เพิ่มสินค้า
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body ">
                    <div className="table-responsive rounded-16 tableFixHead ">
                      <table className="table table-striped table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 90 }}>
                              <b>รหัสสินค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>จำนวนในคลัง(ไม้)</b>
                              </th>
                            )}
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนนับจริง(ไม้)</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>ปรับจำนวน(ไม้)</b>
                              </th>
                            )}
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>จำนวนในคลัง(หลา)</b>
                              </th>
                            )}
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนนับจริง(หลา)</b>
                            </th>
                            {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                              <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                                <b>ปรับจำนวน(หลา)</b>
                              </th>
                            )}
                            <th className="text-center">
                              <b>นำออก</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data && this.state.data.length === 0) || !this.state.data) && (
                            <tr>
                              <td colSpan={9} className="text-center table-light">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}

                          {this.state.data &&
                            this.state.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className="text-left">{item.product_code}</td>
                                <td className="text-left">{item.product_name}</td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{item.real_stock_qty}</td>}
                                <td className="text-center">
                                  <input
                                    type="text"
                                    className="border wpx-100 hpx-38 form-control bg-white text-right "
                                    maxLength={14}
                                    onChange={(e) => {
                                      if (/^\d*?\d{0}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                        let data = this.state.data;
                                        data[index].total_qty = e.target.value;
                                        data[index].qty = data[index].total_qty ? Number(data[index].total_qty) - Number(data[index].real_stock_qty) : 0;
                                        data[index].total_yard_qty = item.total_yard_qty;
                                        data[index].yard_qty = data[index].total_yard_qty ? Number(data[index].total_yard_qty) - Number(data[index].real_stock_yard_qty) : 0;
                                        this.setState({ data: data });
                                      }
                                    }}
                                    value={item.total_qty}
                                  />
                                </td>
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system" ? item.qty : item.qty.toString().includes("-") ? item.qty.toString().slice(1) : item.qty}</td>}
                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{baht(item.real_stock_yard_qty)}</td>}
                                <td className="text-center">
                                  <input
                                    type="text"
                                    maxLength={14}
                                    className="border wpx-100 hpx-38 form-control bg-white text-right "
                                    onChange={(e) => {
                                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                        let data = this.state.data;
                                        data[index].total_qty = item.total_qty;
                                        data[index].qty = data[index].total_qty ? Number(data[index].total_qty) - Number(data[index].real_stock_qty) : 0;

                                        data[index].total_yard_qty = e.target.value;
                                        data[index].yard_qty = data[index].total_yard_qty ? Number(data[index].total_yard_qty) - Number(data[index].real_stock_yard_qty) : 0;

                                        this.setState({ data: data });
                                      }
                                    }}
                                    value={item.total_yard_qty}
                                  />
                                </td>

                                {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && <td className="text-center">{this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system" ? Number(item.yard_qty).toFixed(2) : item.yard_qty.toString().includes("-") ? Number(item.yard_qty).toFixed(2).toString().slice(1) : Number(item.yard_qty).toFixed(2)}</td>}

                                {this.state.decision_status !== 1 && (
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        let data = this.state.data;
                                        data.splice(index, 1);
                                        this.setState({ data: data });
                                      }}
                                    >
                                      <label
                                        className="icon bg-danger text-12 text-white"
                                        style={{
                                          width: 20,
                                          height: 20,
                                          borderRadius: 12,
                                        }}
                                      >
                                        {"\uf00d"}
                                      </label>
                                    </button>
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="d-flex mr-4">
              <label>หมายเหตุ: {this.state.decision_remark ? this.state.decision_remark : "-"}</label>
            </div>
            {role && role.adjust_stock[2] === 1 && (
              <div className="d-flex">
                <div className="mr-1 w-50">
                  <button
                    className="btn btn-outline-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/stock-adjustment";
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>

                <div className="ml-1 w-50">
                  <button
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      if (this.state.warehouse_id === "") {
                        alert("info", "แจ้งเตือน", "กรุณาเลือกโกดัง");
                        return;
                      } else if (this.state.data === null) {
                        alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการปรับ");
                        return;
                      } else if (this.state.data.length === 0) {
                        alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการปรับ");
                        return;
                      }
                      let data = this.state.data;
                      let count = data.filter((item) => item.total_yard_qty === "" && item.total_qty === "");
                      if (count.length !== 0) {
                        alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                      } else {
                        this.AddStockAdjustment();
                      }
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearDataProduct();
          }}
        >
          <Modal.Body>
            <div className="d-flex w-100 justify-content-between align-items-center mb-3">
              <h3>
                <b className=" text-18">ค้นหาสินค้า</b>
              </h3>
              <button
                className="btn btn-link text-secondary"
                onClick={() => {
                  this.setState({ modal_add: false });
                  this.ClearDataProduct();
                }}
              >
                <label
                  className="icon bg-gray text-14 text-white"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                >
                  {"\uf00d"}
                </label>
              </button>
            </div>
            <hr />

            {this.state.product && (
              <div className="card rounded-8 btn-select-member p-3 mb-3">
                <div className="d-flex justify-content-between">
                  <h5 className="text-title">ชื่อสินค้า: {this.state.product.value.product_main_data.product_name}</h5>
                </div>
                <label className="text-detail-name text-16 ">รหัสสินค้า: {this.state.product.value.product_main_data.product_code}</label>
              </div>
            )}

            <div className="w-100 d-flex mb-3">
              <div className="w-100">
                <Select
                  components={{ Option: UserOption }}
                  styles={customStyles}
                  options={
                    this.state.product_list &&
                    this.state.product_list.data &&
                    this.state.product_list.data.data_returns.map((item, index) => ({
                      value: item,
                      label: item.product_main_data.product_name,
                    }))
                  }
                  placeholder="พิมพ์ชื่อสินค้า"
                  onChange={(e) => {
                    this.setState({
                      product: e,
                    });
                  }}
                  onInputChange={(e) => {
                    this.setState({ search_product: e });
                    setTimeout(() => {
                      if (this.state.search_product.includes("PD-") && !this.state.search_product.includes(".") && this.state.search_product.length >= 12) {
                        this.setState({ search_product: this.state.search_product.split("/")[0] });
                      } else if (this.state.search_product.includes("PD-") && this.state.search_product.includes(".") && this.state.search_product.length >= 14) {
                        this.setState({ search_product: this.state.search_product.split("/")[0] });
                      }
                    }, 10);
                  }}
                  inputValue={this.state.search_product}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearDataProduct();
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.product === null) {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการปรับ");
                      return;
                    }

                    this.Add_Product();
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
