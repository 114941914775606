import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { alert, alert_url, POST, tokens, status_sale_transaction2, role, format_date, GET_CACHE, number } from "../../components/CustomComponent.js";
import WARNING from "../../assets/images/warning.png";
import Select, { components } from "react-select";
import "../../assets/css/bootstrap.css";

const { Option } = components;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: 50,
    padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};

const UserOption = (props) => (
  <Option {...props}>
    <h5>{props.data.label}</h5>
  </Option>
);

const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class ListStatusDeliveryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,

      search: "",
      status_delivery: "",

      select_all: false,
      selected: false,

      //เลือกสายส่ง
      list_data_user: null,
      data_member: null,

      order_main_list: [],
      user_list: [],

      province_list: null,
      fillter_province_id: "",
      fillter_province_name: "",
      start_date: "",
      end_date: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
    this.GetProvince();
    this.GetMember();
  }
  GetMember = async () => {
    let body = {
      data_search: "",
      user_type: "emp_delivery",
    };
    let result = await POST(tokens, "v1/user-admin/filter-search/page/0", body);
    if (result && result.status) {
      this.setState({ list_data_user: result.data });
    }
  };
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search,
      status_delivery: this.state.status_delivery,
      cutomer_province: this.state.fillter_province_name,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    let result = await GET_CACHE(tokens, "v1/delivery-mgt/status_delivery/order/page/0", body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };
  GetProvince = async () => {
    let body = {
      data_search: "",
      user_type: "",
      fillter_province: "",
      fillter_districts: "",
    };
    let result = await POST(tokens, "v1/user-member/search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ province_list: result.data.province_list });
    }
  };

  CreateStatusDelivery = async () => {
    let user_list = [];
    for (let i = 0; i < this.state.user_list.length; i++) {
      let id = this.state.user_list[i].user_id;
      user_list.push(id);
    }

    this.setState({ loading: true });
    let body = {
      user_list: user_list,
      order_main_list: this.state.order_main_list,
    };

    let result = await POST(tokens, "v1/delivery-mgt/status_delivery/create", body);
    if (result && result.status) {
      alert_url("success", "สำเร็จ", "เพิ่มเลขที่ใบส่งของเรียบร้อยแล้ว", "/status-delivery/create");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
    }
    this.setState({ loading: false });
  };

  Cancle = async () => {
    this.setState({ loading: true });
    let body = {
      order_main_list: this.state.cancle_data,
      status_delivery: 0,
      delivery_number: "",
    };
    let result = await POST(tokens, "v1/delivery-mgt/update-delivery", body);
    if (result && result.status) {
      alert("success", "สำเร็จ", "ยกเลิกรายการเรียบร้อยแล้ว");
      this.setState({ loading: false, modal_cancle: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,
      search: "",
      status_delivery: "",

      select_all: false,
      selected: false,
      fillter_province_id: "",
      fillter_province_name: "",
    });
  }
  ClearFormMember() {
    this.setState({
      data_member: null,
      user_list: [],
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="สถานะส่ง" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <div>
                <h3>
                  <b>สถานะส่ง</b>
                </h3>
                <label className="text-dark text-18">เลือก {this.state.data && number(this.state.data.data.filter((e) => e.selected === true).length)} รายการ</label>
              </div>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/status-delivery/create";
                  }}
                >
                  {" "}
                  ไปยังหน้าสายส่ง{" "}
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-sm-3 col-md-4 col-lg-4 col-xl-2 d-flex mb-2">
                    <input
                      maxLength={30}
                      className="form-control-search  mb-1 mt-1"
                      type="search"
                      placeholder="พิมพ์คำค้นหา"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ page: 1, search: e.target.value, loading: false });
                        if (e.target.value === "") {
                          this.setState({ search: "" });
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        } else {
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-9 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end mb-2">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({ page: 1, start_date: e.target.value });

                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.Input.clearValue();
                            this.setState({
                              page: 1,
                              search: "",
                              data: null,
                              type_product_id: "",
                              warehouse_id: "",
                              start_date: "",
                              end_date: "",
                              fillter_province_id: "",
                              fillter_province_name: "",
                            });

                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                    <Select
                      ref={(click) => (this.Input = click)}
                      className="px-0"
                      options={
                        this.state.province_list &&
                        this.state.province_list.map((item) => ({
                          value: item.PROVINCE_ID,
                          label: item.PROVINCE_NAME,
                        }))
                      }
                      components={{ Option: TypeOption }}
                      styles={customStylesTypeOption}
                      placeholder="แสดงทุกจังหวัด"
                      defaultValue={this.state.fillter_province_id}
                      isClearable
                      onChange={(e) => {
                        if (e === null) {
                          this.setState({ fillter_province_id: "", fillter_province_name: "", page: 1 });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        } else {
                          this.setState({ fillter_province_id: e.value, fillter_province_name: e.label, page: 1 });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }
                      }}
                    />
                  </div>
                  <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-outline-primary bg-white w-100"
                      onClick={() => {
                        let data = this.state.data;
                        for (let d of data.data) {
                          d.selected = false;
                        }

                        setTimeout(() => {
                          this.setState({
                            data: data,
                            selected: false,
                            select_all: false,
                            order_main_list: [],
                          });
                        }, 10);
                      }}
                    >
                      เคลียร์การเลือก
                    </button>
                  </div>
                </div>

                <div className="card-body p-0">
                  <div className="table-responsive rounded-16 height-vh overflow-auto">
                    <table className="table table-hover table-striped table-borderless">
                      <thead>
                        <tr className=" bg-white">
                          <th className="text-center" style={{ minWidth: 50 }}>
                            <div>
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                onChange={() => {
                                  let data = this.state.data;
                                  for (let d of data.data) {
                                    d.selected = !this.state.select_all;
                                  }

                                  setTimeout(() => {
                                    this.setState({
                                      data: data,
                                      selected: !this.state.select_all,
                                      select_all: !this.state.select_all,
                                    });
                                  }, 10);
                                }}
                                checked={this.state.select_all}
                                value={this.state.select_all}
                              ></input>
                            </div>
                          </th>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>เลขที่ใบแจ้งหนี้/invoice</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>ชื่อลูกค้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 120 }}>
                            <b>จังหวัด</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>สถานะ</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data && this.state.data.data.length === 0 && (
                          <tr>
                            <td colSpan={6} className="text-center table-light height-vh">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer ">
                              <td className={item.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>
                                <div>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      let data = this.state.data;
                                      data.data[index].selected = !data.data[index].selected;
                                      if (!data.data[index].selected) {
                                        this.setState({ select_all: false });
                                      }
                                      setTimeout(() => {
                                        this.setState({ data: data });
                                      }, 10);
                                    }}
                                    checked={item.selected}
                                    value={item.selected}
                                  ></input>
                                </div>
                              </td>
                              <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{format_date(item.datetime_create)}</td>
                              <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{item.ab_number}</td>
                              <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{item.cutomer_name}</td>
                              <td className={item.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>{item.cutomer_province}</td>
                              <td className={item.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>{status_sale_transaction2(item.status_bill)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {role && (role.status_delivery[1] === 1 || role.status_delivery[3] === 1) && (
          <div className="position-fixed bottom-0 bg-white w-100 p-3 margin-left" style={{ bottom: 0, right: 0 }}>
            <div className="d-flex w-100 justify-content-center align-items-center">
              <div className="d-flex">
                {role && role.status_delivery[3] === 1 && (
                  <div className="mr-1 w-100">
                    <button
                      type="button"
                      className="btn btn-danger w-100"
                      onClick={() => {
                        let order = [];
                        for (let data of this.state.data.data) {
                          if (data.selected === true) {
                            order.push(data.order_main_id);
                          }
                        }
                        if (order.length === 0) {
                          alert("warning", "แจ้งเตือน", "กรุณาเลือกรายการ");
                          return;
                        }

                        this.setState({ modal_cancle: true, cancle_data: order });
                      }}
                    >
                      ยกเลิกบิลสายส่ง
                    </button>
                  </div>
                )}
                {role && role.status_delivery[1] === 1 && (
                  <div className="ml-1 w-100">
                    <button
                      type="button"
                      className="btn btn-primary w-100 px-4"
                      onClick={() => {
                        let order = [];
                        for (let data of this.state.data.data) {
                          if (data.selected === true) {
                            order.push(data.order_main_id);
                          }
                        }

                        if (order.length === 0) {
                          alert("warning", "แจ้งเตือน", "กรุณาเลือกรายการ");
                          return;
                        }

                        this.setState({ modal_add: true, order_main_list: order });
                      }}
                    >
                      เลือกสายส่ง
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Modal เลือกสายส่ง */}
        <Modal
          show={this.state.modal_add}
          size="m"
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearFormMember();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className=" text-18">เลือกสายส่ง</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.user_list &&
              this.state.user_list.map((item, index) => (
                <div className="card rounded-8 btn-select-member p-3 mb-3">
                  <div className="d-flex  align-items-center">
                    <h5 className="text-title">{item.full_name}</h5>
                  </div>
                  <label className="text-detail-name text-16 ">
                    เบอร์โทร: {item.phone ? item.phone : " - "} | รหัสสมาชิก: {item.user_code}
                  </label>

                  <div
                    className="run-number bg-danger text-14"
                    style={{ top: 10, right: 10, position: "absolute" }}
                    onClick={() => {
                      let data = this.state.user_list;
                      data.splice(index, 1);

                      this.setState({
                        user_list: data,
                      });
                    }}
                  >
                    <label className="icon mt-2">{"\uf00d"}</label>
                  </div>
                </div>
              ))}

            <div className="w-100  mt-3">
              <Select
                className="px-0 mb-3 "
                options={
                  this.state.list_data_user &&
                  this.state.list_data_user.data.map((item, index) => ({
                    value: item.user_id,
                    label: item.full_name,
                    item: item,
                  }))
                }
                components={{ Option: UserOption }}
                styles={customStyles}
                placeholder="พิมพ์ชื่อ"
                onChange={(e) => {
                  if (this.state.user_list.length >= 2) {
                    alert("warning", "แจ้งเตือน", "เลือกได้มากสุด 2 ท่าน");
                    return;
                  } else {
                    let data = this.state.user_list;
                    let find = data.find((item) => {
                      if (item.user_id === e.value) {
                        return true;
                      } else {
                        return false;
                      }
                    });

                    if (!find) {
                      this.setState({ user_list: [...data, e.item] });
                    } else {
                      alert("warning", "แจ้งเตือน", "ไม่สามารถเลือกซ้ำได้");
                      return;
                    }
                  }
                }}
              />
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });

                    this.ClearFormMember();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.user_list.length === 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกสายส่ง");
                      return;
                    }

                    this.CreateStatusDelivery();
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ยืนยันการยกเลิกบิลสายส่ง */}
        <Modal
          show={this.state.modal_cancle}
          size="m"
          onHide={() => {
            this.setState({ modal_cancle: false });
          }}
        >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={WARNING}></img>
              <h4>
                <b className="text-title ">ยืนยันการยกเลิกบิลสายส่งที่เลือก</b>
              </h4>
              <div className="w-75 text-center mx-auto text-detail-name mb-2 text-14">คุณแน่ใจว่าต้องการยกเลิกบิลสายส่ง ?</div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_cancle: false });
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Cancle();
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
