import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { alert, POST, PUT, tokens, total, baht, format_duedate_sale, role, GET } from "../../components/CustomComponent.js";
import Select, { components } from "react-select";

import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class PaymentReceiveScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      page: 1,

      data: null,
      data_bank: [],
      data_number: [],
      data_member: [],
      data_member_id: this.props.location.query.customer_id ? { label: this.props.location.query.customer_name, value: this.props.location.query.customer_id } : "",
      invoice_id: this.props.location.query.id ? { label: this.props.location.query.id, value: this.props.location.query.id } : "",
      date: new Date().getFullYear() + "-" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("00" + new Date().getDate()).slice(-2),

      total_pay: 0,
      disabled: false,
      title_name: "",

      sort: "",
      search: "",

      data_all: [],
      total_balance: 0,
      use_point: false,
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetBillAll1();
    await this.GetBillAll2();

    await this.GetAll_Member();
    await this.GetBankAll();

    if (this.state.invoice_id && this.props.location.query.id && this.props.location.query.customer_id) {
      this.setState({
        data_member_id: this.props.location.query.customer_id,
        title_name: this.props.location.query.customer_name,
        total_balance: Number(this.state.data_member.filter((e) => e.user_id === this.props.location.query.customer_id)[0].total_refund_point.toFixed(2)),
      });

      await this.GetNumber();
      await this.GetAll();
    }

    this.setState({ loading: false });
  }

  GetBillAll1 = async () => {
    let body = {
      data_search: "",
      status_order_main: 0,
      document_type: 0,
      start_date: "",
      end_date: "",
      sort: "",
      status_bill: 0,
      sort_date: "",
    };
    let result = await POST(tokens, "v1/all-sale/invoice/search/page/0", body);
    if (result && result.status) {
      this.setState({ data_all: [...this.state.data_all, ...result.data.data.filter((item) => item.status_bill === 1 || item.status_bill === 3)] });
    }
  };
  GetBillAll2 = async () => {
    let body = {
      data_search: "",
      member_type: "",
      status_bill: 0,
      start_date: "",
      end_date: "",
      sort_date: "",
      sort_om_number: "",
      date_created: "",
    };
    let result = await POST(tokens, "v1/all-sale/search/page/0", body);
    if (result && result.status) {
      this.setState({ data_all: [...this.state.data_all, ...result.data.data.filter((item) => item.status_bill === 2 || item.status_bill === 4 || item.status_bill === 5)] });
      // BI6600004
    }
  };
  GetAll_Member = async () => {
    let body = {
      data_search: this.state.search,
      sort: this.state.sort,
    };
    let result = await POST(tokens, "v1/all-sale/customer/search", body);
    if (result && result.status) {
      this.setState({ data_member: result.data.data.filter((item) => JSON.stringify(this.state.data_all).includes(item.user_id)) });
    }
  };
  GetNumber = async () => {
    let result = await GET(tokens, "v1/all-sale/invoice/by_user/" + this.state.data_member_id, null);
    if (result && result.status) {
      let array = [];
      array = [
        ...array,
        ...result.data.data
          .filter((e) => e.order_main_number)
          .map((e) => {
            return { label: e.order_main_number, value: e.order_main_number };
          }),
      ];
      array = [
        ...array,
        ...result.data.data
          .filter((e) => e.invoice_number)
          .map((e) => {
            return { label: e.invoice_number, value: e.invoice_number };
          }),
      ];

      setTimeout(() => {
        this.setState({ data_number: array });
      }, 10);
    }
  };
  GetAll = async () => {
    this.setState({
      data: null,
    });
    let body = {
      invoice_id: "",
      data_search: this.state.invoice_id ? this.state.invoice_id.value : "",
      start_date: this.state.start_date,
      history: 0,
    };
    let result = await POST(tokens, "v1/all-sale/invoice/detail", body);
    if (result && result.status) {
      result.data.data.order_main_list = result.data.data.order_main_list.map((i) => {
        return {
          ...i,
          refund_point: 0,
          payment_type: "",
          transfer_amount: 0,
          cash_amount: 0,
          discount: 0,
        };
      });
      this.setState({ data: result.data });
      this.setState({ loading: false });
    }
  };
  GetBankAll = async () => {
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/bank_account/search/page/0", body);

    if (result && result.status) {
      this.setState({ data_bank: result.data.data });
    }
  };

  Update = async () => {
    this.setState({ loading: true });
    let data = this.state.data.data.order_main_list;

    let order_main_list = [];
    for (let item of data) {
      if ((Number(item.payment_type) === 3 || Number(item.payment_type) === 5) && !item.bank_id) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือกธนาคาร");
        this.setState({ loading: false });
        return;
      }
      if ((Number(item.payment_type) === 3 || Number(item.payment_type) === 5) && Number(item.total_balance) < Number(item.transfer_amount) + Number(item.cash_amount)) {
        alert("warning", "แจ้งเตือน", "จำนวนเงินที่ชำระ ไม่ตรงกับยอดค้างชำระ");
        this.setState({ loading: false });
        return;
      }
      if (Number(item.refund_point) !== 0 && !item.payment_type) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการชำระ ของเอกสารที่ท่านต้องการชำระให้ครบถ้วน");
        this.setState({ loading: false });
        return;
      }

      order_main_list.push({
        order_main_id: item.order_main_id,
        bank_id: item.bank_id ? item.bank_id : "",
        payment_type: item.payment_type ? Number(item.payment_type) : 0,
        transfer_amount: item.transfer_amount ? Number(item.transfer_amount) : 0,
        cash_amount: item.cash_amount ? Number(item.cash_amount) : 0,
        refund_point: item.refund_point ? Number(item.refund_point) : 0,
        installment_list: [],
        discount: item.discount ? Number(item.discount) : 0,
        payment_remark: item.payment_remark,
      });
    }

    let body = {
      invoice_id: this.state.data.data.invoice_id ? this.state.data.data.invoice_id : "",
      record_date: this.state.date,
      order_main_list: order_main_list,
    };

    let result = await PUT(tokens, "v1/all-sale/invoice/update", body);
    if (result && result.status) {
      alert("success", "สำเร็จ", "ชำระเงินเรียบร้อย\nจำนวน ฿" + baht(Number(total(data, "transfer_amount") + total(data, "cash_amount") + total(data, "refund_point"))));
      this.setState({ loading: false });
      this.ClearForm();
    }
    this.setState({ loading: false });
  };

  ClearForm() {
    this.Input.clearValue();

    let data = this.state.data;
    data.data.due_date = "";
    this.setState({
      total_balance: 0,
      use_point: false,
      loading: false,
      invoice_id: "",
      bank_id: "",
      total_pay: 0,
      data: data,
      sort: "",
      search: "",
      data_member_id: "",
      date: new Date().getFullYear() + "-" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("00" + new Date().getDate()).slice(-2),
    });

    setTimeout(() => {
      this.setState({
        data: null,
      });
    }, 5);
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="การขายทั้งหมด" page3="ระบบรับชำระเงิน" />
            <div style={{ height: 60 }}></div>
            <div className="mx-4 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3 mb-3">
                <div className="row">
                  <div>
                    <h3>
                      <b>ระบบรับชำระเงิน</b>
                    </h3>
                  </div>
                </div>

                {role && role.all_product[1] === 1 && (
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100"
                      onClick={() => {
                        if (this.props.location.query.id) {
                          window.location.href = this.props.location.query.page_name + "?id=" + this.props.location.query.id + "&page=" + this.props.location.query.page + "&customer_name=" + this.props.location.query.customer_name + "&customer_id=" + this.props.location.query.customer_id;
                        }
                      }}
                    >
                      <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ
                    </button>
                  </div>
                )}
              </div>
              {/* title */}
              <div className="w-100 pt-2">
                <div className="card p-3">
                  <div className="d-flex row align-items-center justify-content-between">
                    <div className="col-6  col-xl-3">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">ค้นหาชื่อลูกค้า:</label>
                          <Select
                            className="px-0 mb-3 pointer"
                            options={
                              this.state.data_member &&
                              this.state.data_member.map((item) => ({
                                value: item.user_id,
                                label: item.full_name,
                                total_refund_point: item.total_refund_point,
                              }))
                            }
                            styles={customStylesTypeOption}
                            components={{ Option: TypeOption }}
                            placeholder="ชื่อลูกค้า"
                            defaultValue={this.state.data_member_id}
                            onChange={(e) => {
                              if (this.state.data) {
                                this.ClearForm();
                              }

                              this.setState({
                                data_member_id: e.value,
                                title_name: e.label,
                                total_balance: Number(e.total_refund_point.toFixed(2)),
                              });

                              setTimeout(() => {
                                this.GetNumber();
                              }, 10);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-xl-3">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">ค้นหาเลขที่เอกสาร:</label>
                          <Select
                            ref={(click) => (this.Input = click)}
                            className="px-0 mb-3 pointer"
                            options={this.state.data_number}
                            styles={customStylesTypeOption}
                            components={{ Option: TypeOption }}
                            placeholder="เลขที่เอกสาร"
                            defaultValue={this.state.invoice_id}
                            onChange={(e) => {
                              if (e !== null) {
                                if (e.label.includes("AB") && this.state.data) {
                                  let data = this.state.data;
                                  data.data.due_date = "";
                                  this.setState({ invoice_id: "", data: data });
                                }
                                this.setState({ invoice_id: e });
                                setTimeout(() => {
                                  this.GetAll();
                                }, 10);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-6  col-xl-3">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">วันที่:</label>
                          <input
                            type="date"
                            className="form-control border-0 bg-secondary-light mb-3 pointer"
                            value={this.state.date}
                            onChange={(e) => {
                              this.setState({
                                date: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-6  col-xl-3">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">วันที่กำหนดชำระใบแจ้งหนี้:</label>
                          <input type="text" placeholder="วันที่กำหนดชำระ" className="form-control border-0 bg-secondary-light mb-3 pointer" value={this.state.data && this.state.data.data.due_date} readOnly disabled={this.state.data && this.state.data.data.due_date ? true : false}></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* table */}
              <div className="w-100 pt-4">
                <div className="card" style={{ marginBottom: 32 }}>
                  <div className="card-header rounded-top">
                    <div className="w-100 d-flex justify-content-start align-items-center pt-2 rounded-top-16">
                      <h5>
                        <b>ใบเอกสาร</b>
                      </h5>
                      <h5 className="ml-3 ">
                        <b>ชื่อลูกค้า : {this.state.title_name}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive rounded-16">
                      <table className="table table-striped table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-left">
                              <b>เลขที่เอกสาร</b>
                            </th>
                            <th className="text-left">
                              <b>ประเภทเอกสาร</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 100 }}>
                              <b>กำหนดชำระ</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90 }}>
                              <b>จำนวนเงินทั้งหมด</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90 }}>
                              <b>ยอดค้างชำระ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 122 }}>
                              <b>วิธีการชำระ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 122 }}>
                              <b>เลือกธนาคาร</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 122 }}>
                              <b>จำนวนเงินที่ชำระ (เงินสด)</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 122 }}>
                              <b>จำนวนเงินที่ชำระ (เงินโอน)</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 122 }}>
                              <b>จำนวนเงินคืนสะสม</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 122 }}>
                              <b>ส่วนลด</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 122 }}>
                              <b>หมายเหตุ</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.data && this.state.data.data && this.state.data.data.order_main_list && this.state.data.data.order_main_list.length === 0) ||
                            (((this.state.data && this.state.data.data.order_main_list.length === 0) || !this.state.data) && (
                              <tr>
                                <td colSpan={11} className="text-center table-light">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            ))}

                          {this.state.data &&
                            this.state.data.data &&
                            this.state.data.data.order_main_list &&
                            this.state.data.data.order_main_list.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className="text-left">{item.order_main_number}</td>
                                <td className="text-left">{item.status_bill === 2 || item.status_bill === 4 ? "invoice" : item.status_bill === 5 ? "ใบแจ้งหนี้" : "-"}</td>
                                <td className="text-center">{item.due_date ? format_duedate_sale(item.due_date) : "-"}</td>
                                <td className="text-right">{baht(item.total_price)}</td>
                                <td className="text-right">{baht(item.total_balance)}</td>
                                <td className="text-center">
                                  <select
                                    className="form-control-select border border-secondary pointer"
                                    onChange={async (e) => {
                                      let data = this.state.data;
                                      let cash_amount = "";
                                      let transfer_amount = "";

                                      data.data.order_main_list[index].payment_type = e.target.value;
                                      data.data.order_main_list[index].bank_id = "";
                                      data.data.order_main_list[index].discount = 0;

                                      if (e.target.value === "1") {
                                        cash_amount = item.total_balance - item.refund_point;
                                      } else if (e.target.value === "3") {
                                        transfer_amount = item.total_balance - item.refund_point;
                                      } else if (e.target.value === "5") {
                                        let number = 0;

                                        if ((item.total_balance - item.refund_point).toString().split(".")[1] && (item.total_balance - item.refund_point).toString().split(".")[1].length === 2 && Number((item.total_balance - item.refund_point).toString().slice(-1)) % 2 === 1) {
                                          number = 0.01;
                                        }

                                        let cash = Number((item.total_balance - item.refund_point) / 2);
                                        cash_amount = Number(cash) - number;
                                        transfer_amount = cash;
                                      }

                                      data.data.order_main_list[index].cash_amount = cash_amount;
                                      data.data.order_main_list[index].transfer_amount = transfer_amount;

                                      this.setState({ data: data });
                                    }}
                                    value={item.payment_type ? item.payment_type : 0}
                                  >
                                    <option value="">เลือกวิธีการชำระ</option>
                                    <option value="3">โอน</option>
                                    <option value="1">เงินสด</option>
                                    <option value="5">โอน/เงินสด</option>
                                  </select>
                                </td>
                                <td className="text-center">
                                  <select
                                    className={Number(item.payment_type) === 1 ? "form-control pointer" : "form-control-select border border-secondary pointer"}
                                    onChange={async (e) => {
                                      let data = this.state.data;
                                      data.data.order_main_list[index].bank_id = e.target.value;
                                      this.setState({ data: data });
                                    }}
                                    value={item.bank_id}
                                    disabled={Number(item.payment_type) === 1 || Number(item.payment_type) === 0}
                                  >
                                    <option value="">เลือกธนาคาร</option>
                                    {this.state.data_bank.map((bank) => (
                                      <option value={bank.bank_id}>
                                        {bank.bank_name} ({bank.bank_number})
                                      </option>
                                    ))}
                                  </select>
                                </td>

                                <td className="text-right">
                                  <input
                                    maxLength={14}
                                    className={!(Number(item.payment_type) === 1 || Number(item.payment_type) === 5) ? "form-control text-right " : "form-control-select border border-secondary text-right "}
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                      let data = this.state.data;
                                      if (e.target.value === "") {
                                        data.data.order_main_list[index].cash_amount = 0;
                                      } else {
                                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                          data.data.order_main_list[index].discount = 0;
                                          if (Number(item.total_balance) - (Number(item.refund_point) + Number(item.transfer_amount)) > Number(e.target.value)) {
                                            data.data.order_main_list[index].cash_amount = e.target.value;
                                          } else {
                                            data.data.order_main_list[index].cash_amount = Number(item.total_balance) - (Number(item.transfer_amount) + Number(item.refund_point));
                                          }
                                        }
                                      }

                                      this.setState({ data: data });
                                    }}
                                    value={item.cash_amount}
                                    disabled={!(Number(item.payment_type) === 1 || Number(item.payment_type) === 5)}
                                  />
                                </td>
                                <td className="text-right">
                                  <input
                                    maxLength={14}
                                    className={!(Number(item.payment_type) === 3 || Number(item.payment_type) === 5) ? "form-control text-right " : "form-control-select border border-secondary text-right "}
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                      let data = this.state.data;
                                      if (e.target.value === "") {
                                        data.data.order_main_list[index].transfer_amount = 0;
                                      } else {
                                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                          data.data.order_main_list[index].discount = 0;
                                          if (Number(item.total_balance) - (Number(item.refund_point) + Number(item.cash_amount)) > Number(e.target.value)) {
                                            data.data.order_main_list[index].transfer_amount = e.target.value;
                                          } else {
                                            data.data.order_main_list[index].transfer_amount = Number(item.total_balance) - (Number(item.cash_amount) + Number(item.refund_point));
                                          }
                                        }
                                      }
                                      this.setState({ data: data });
                                    }}
                                    value={item.transfer_amount}
                                    disabled={!(Number(item.payment_type) === 3 || Number(item.payment_type) === 5)}
                                  />
                                </td>
                                <td className="text-right">
                                  <input className={"form-control text-right "} type="text" placeholder="กรอกข้อมูล" disabled value={item.refund_point ? item.refund_point : 0} />
                                </td>
                                <td className="text-center">
                                  <input
                                    maxLength={14}
                                    disabled={Number(item.cash_amount) === 0 && Number(item.transfer_amount) === 0}
                                    className={Number(item.cash_amount) === 0 && Number(item.transfer_amount) === 0 ? "form-control text-right" : "form-control-select border border-secondary text-right"}
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                      let data = this.state.data;
                                      if (e.target.value === "") {
                                        data.data.order_main_list[index].discount = 0;
                                      } else {
                                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                          if (Number(item.cash_amount) + Number(item.transfer_amount) === 0) {
                                            alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินที่ชำระ");
                                          } else if (Number(e.target.value) > Number(item.cash_amount) + Number(item.transfer_amount)) {
                                            data.data.order_main_list[index].discount = Number(item.cash_amount) + Number(item.transfer_amount);
                                            alert("info", "แจ้งเตือน", "ไม่สามารถกรอกส่วนลดเกินที่ชำระ");
                                          } else {
                                            data.data.order_main_list[index].discount = e.target.value;
                                          }
                                        }
                                      }
                                      this.setState({ data: data });
                                    }}
                                    value={item.discount}
                                  />
                                </td>
                                <td className="text-center">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                      let data = this.state.data;
                                      data.data.order_main_list[index].payment_remark = e.target.value;
                                      this.setState({ data: data });
                                    }}
                                    value={item.payment_remark}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* footer */}
              {this.state.data && this.state.data.data && this.state.data.data.order_main_list.length !== 0 && (
                <div className="w-100 pb-3">
                  <div className="card p-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex w-50">
                        <input
                          className="mr-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked && total(this.state.data.data.order_main_list, "total_balance") < this.state.total_balance) {
                              alert("warning", "แจ้งเตือน", "ไม่สามารถใช้เงินคืนสะสมได้ เนื่องจากยอดชำระน้อยกว่ายอดเงินคืนสะสม");
                              return;
                            }
                            this.setState({ use_point: e.target.checked });
                            if (e.target.checked) {
                              let data = this.state.data;
                              let total_refund_point = this.state.total_balance;
                              for (let item of data.data.order_main_list) {
                                if (item.total_balance <= total_refund_point && total_refund_point > 0) {
                                  item.refund_point = item.total_balance;
                                } else if (total_refund_point <= 0) {
                                  item.refund_point = 0;
                                } else if (total_refund_point < item.total_balance) {
                                  item.refund_point = total_refund_point;
                                } else {
                                  item.refund_point = item.total_balance;
                                }
                                item.refund_point = Number(item.refund_point.toFixed(2));
                                total_refund_point -= item.total_balance;
                                item.transfer_amount = Number(item.transfer_amount) - item.refund_point;
                                if (item.transfer_amount <= 0) {
                                  item.cash_amount = Number(item.cash_amount) + item.transfer_amount;
                                  item.transfer_amount = 0;
                                }
                                if (item.cash_amount < 0) {
                                  item.cash_amount = 0;
                                }
                                if (!item.payment_type) {
                                  item.payment_type = "1";
                                }
                              }
                              this.setState({ data: data });
                            } else {
                              let data = this.state.data;
                              for (let item of data.data.order_main_list) {
                                item.refund_point = 0;
                              }
                              this.setState({ data: data });
                            }
                          }}
                          checked={this.state.use_point}
                        />
                        ใช้เงินคืนสะสม {this.state.total_balance ? this.state.total_balance.toFixed(2) : "0.00"} บาท
                      </div>
                      <div className="align-items-center justify-content-end w-100 d-flex px-3 mb-2">
                        <p className="mb-0 mr-5">
                          <b>จำนวนเงินที่ชำระทั้งหมด:</b>
                        </p>
                        <p className="mb-0">฿{baht(Number(total(this.state.data.data.order_main_list, "transfer_amount") + total(this.state.data.data.order_main_list, "cash_amount") + total(this.state.data.data.order_main_list, "refund_point")) - total(this.state.data.data.order_main_list, "discount"))}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {role && role.payment_receive[2] === 1 && (
          <div className="position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
            <div className="d-flex w-100 justify-content-end align-items-center">
              <div className="d-flex">
                <button
                  className="btn btn-outline-primary w-100 px-4 mr-2"
                  onClick={() => {
                    if (this.props.location.query.id) {
                      window.location.href = "/sale/payment-receive?id=" + this.props.location.query.id + "&page=" + this.props.location.query.page + "&page_name=" + this.props.location.query.page_name + "&customer_name=" + this.props.location.query.customer_name + "&customer_id=" + this.props.location.query.customer_id;
                    } else {
                      window.location.href = "/sale/payment-receive";
                    }
                  }}
                >
                  ยกเลิก
                </button>

                <button
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {
                    let data = this.state.data.data.order_main_list;

                    let count = data.filter((item) => item.payment_type === "5" && item.transfer_amount === "" && item.cash_amount === "" && !item.refund_point);

                    if (this.state.invoice_id.value === "" || this.state.invoice_id.value === undefined) {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกรายการการชำระเงิน");
                      return;
                    } else if (Number(total(this.state.data.data.order_main_list, "transfer_amount") + total(this.state.data.data.order_main_list, "cash_amount") + total(this.state.data.data.order_main_list, "refund_point")) === 0) {
                      alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินที่ต้องการชำระ");
                      return;
                    } else if (count.length !== 0) {
                      alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินที่ต้องการชำระ");
                      return;
                    } else if (count.length !== 0) {
                      alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินที่ต้องการชำระ");
                      return;
                    }

                    this.Update();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
