import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { Modal } from "react-bootstrap";
import { alert, GET, POST, PUT, tokens, status_sale_transaction2, type_member, role, total, baht, ModalDetails, report_3, report_1, report_2, ModalInstallment, format_date_sale, format_duedate_sale, ModalSaleHistory, number } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import moment from "moment-timezone";
export default class SaleScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.location.query.page ? Number(this.props.location.query.page) : 1,
      loading: false,
      modal_update: false,
      modal_discount: false,
      data: null,

      search: "",

      discount: "",

      start_date: "",
      end_date: "",
      member_type: "",
      status_bill: "0",

      //details
      modal_detail: false,
      data_detail: "",

      //detailsการผ่อนชำระ
      modal_installment: false,
      installment_detail: "",
      i_all_total_pay: 0.0,

      //ยกเลิกรายการ
      text_cancle: "",
      order_main_number: "",
      modal_delete: false,
      id: "",

      //updatestatus
      status: "",
      disabled_status: false,

      date_created: "",
      sort_date: "",
      sort_om_number: "",
      profile: JSON.parse(localStorage.getItem("profile")),

      history: null,
      modal_history: false,

      discount_price: "",
      s_discount: "",
      timeZone: moment.tz.guess(),
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }

    this.setState({ loading: true });

    let body = {
      data_search: this.state.search.trim(),
      member_type: this.state.member_type,
      status_bill: Number(this.state.status_bill),
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      sort_date: this.state.sort_date,
      sort_om_number: this.state.sort_om_number,
      date_created: this.state.date_created,
    };
    let result = await POST(tokens, "v1/all-sale/search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };
  Get_by_id = async (id, type) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/all-sale/order/detail/" + id, null);

    if (result && result.status) {
      let data = result.data.data;
      let total_yard = 0;
      let total_wood = 0;
      let installment_detail = "";
      for (let i = 0; i < data.order_list_data.length; i++) {
        let sum_yard = 0;
        for (let sum of data.order_list_data[i].detail_yard_list) {
          sum_yard += Number(sum);
        }
        data.order_list_data[i].yard_qty = Number(sum_yard);
        total_yard += data.order_list_data[i].yard_qty;
        total_wood += data.order_list_data[i].qty;
        data.order_list_data[i].product_main = {};
        data.order_list_data[i].product_main.product_main_data = data.order_list_data[i].product_main_data;

        if (type === "installment") {
          installment_detail = data.order_list_data[0].payment_detail_data[0].installment_list;
          break;
        }
      }
      data.total_yard = total_yard;
      data.total_wood = total_wood;

      this.setState({ data_detail: data, installment_detail: installment_detail });

      setTimeout(() => {
        let i_all_total_pay = 0;

        if (type === "installment") {
          for (let i = 0; i < this.state.installment_detail.length; i++) {
            i_all_total_pay += this.state.installment_detail[i].pay;
          }

          this.setState({
            i_all_total_pay: i_all_total_pay,
            modal_installment: true,
          });
        } else {
          this.setState({
            modal_detail: true,
          });
        }
      }, 10);
    }
    this.setState({ loading: false });
  };
  GetHistoryById = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/all-sale/payment/history/" + id, null);
    if (result && result.status) {
      this.setState({ history: result.data.data });
      setTimeout(() => {
        this.setState({ modal_history: true });
      }, 10);
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let body = {
      order_main_id: this.state.id,
      remark_cancel: this.state.text_cancle,
    };
    let result = await PUT(tokens, "v1/pos-mgt/order/cancel", body);
    if (result && result.status) {
      alert("success", "แจ้งเตือน", "ยกเลิกรายการเรียบร้อยแล้ว");
      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  Retry = async (id, text) => {
    if (role.pos_system[0] === 1) {
      if (text === "รีบิลเป็นการขาย") {
        localStorage.setItem("bill", "รีบิลเป็นการขาย");
      } else if (text === "บันทึกรอชำระ") {
        localStorage.setItem("bill", "บันทึกรอชำระ");
      } else {
        localStorage.removeItem("bill");
      }
      window.location.href = "/main?retry_id=" + id;
    } else {
      alert("warning", "แจ้งเตือน", "ไม่ได้รับอนุญาตให้เข้าถึง");
    }
  };
  Get_by_id_print = async (id, text) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/all-sale/order/detail/" + id, null);

    if (result && result.status) {
      let data = result.data.data;
      let total_yard = 0;
      let total_wood = 0;
      for (let i = 0; i < data.order_list_data.length; i++) {
        let sum_yard = 0;
        for (let sum of data.order_list_data[i].detail_yard_list) {
          sum_yard += Number(sum);
        }
        data.order_list_data[i].yard_qty = Number(sum_yard);
        total_yard += data.order_list_data[i].yard_qty;
        total_wood += data.order_list_data[i].qty;
        data.order_list_data[i].product_main = {};
        data.order_list_data[i].product_main.product_main_data = data.order_list_data[i].product_main_data;
      }
      data.total_yard = total_yard;
      data.total_wood = total_wood;

      this.setState({ data_detail: data });

      if (text === "ใบกำกับภาษี" || text === "ใบส่งสินค้าชั่วคราว" || text === "ใบเสนอราคา" || text === "ใบส่งของสายส่ง") {
        let data_number = {
          tax_invoice_number: result.data.data.tax_invoice_number,
          input_vat_number: result.data.data.input_vat_number,
          ab_number: result.data.data.ab_number,
          est_number: result.data.data.est_number,
        };
        let data = {
          detail_print: result.data.data,
          order_list: result.data.data.order_list_data,
          member: result.data.data.member_data,
          machine_profile: result.data.data.admin_data.full_name,
          data_number: data_number,
        };

        if (data && text === "ใบกำกับภาษี") {
          report_3(data, "Transaction");
          this.setState({ loading: false });
        } else if (data && text === "ใบเสนอราคา") {
          report_1(data, "sale");
          this.setState({ loading: false });
        } else if (data && text === "ใบส่งสินค้าชั่วคราว") {
          report_2(data, "sale");
          this.setState({ loading: false });
        }
      }

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  Add_discount = async () => {
    this.setState({ loading: true });
    let body = {
      order_main_id: this.state.id,
      discount_item: Number(this.state.discount),
      time_zone: this.state.timeZone,
    };
    let result = await PUT(tokens, "v1/all-sale/discount-item", body);
    if (result && result.status) {
      alert("success", "บันทึกข้อมูล", "ส่วนลดให้กับ ' " + this.state.order_main_number + " ' เรียบร้อยแล้ว");
      this.setState({ loading: false, modal_discount: false, discount: "" });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  ClearForm() {
    this.setState({
      page: 1,

      text_cancle: "",
      order_main_number: "",
      modal_delete: false,
      search: "",
      id: "",

      status: "",
      disabled_status: false,

      sort_date: "",
      sort_om_number: "",
      discount: "",
    });
  }
  ClearFormUpdate() {
    this.setState({
      status: "",
      disabled_status: false,
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100 ">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="การขายทั้งหมด" />
            <div style={{ height: 60 }}></div>
            {/* header */}
            <div className="w-100 pt-3 d-flex justify-content-between">
              <div className="d-flex flex-column w-100 px-3">
                <h3>
                  <b>การขายทั้งหมด</b>
                </h3>
              </div>
            </div>
            {/* total */}
            <div className="w-100 pt-3 d-flex row text-white text-center mb-2 mx-0 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2">
                <div className="card bg-cash-2 w-100 p-3 h-100">
                  <div className="row justify-content-between px-3 text-dark">
                    <div>
                      <span className="icon-brand text-16">{"\uf555"}</span> <b>ESTIMATES</b>
                    </div>
                    <div>
                      <p className="mb-0">
                        <b>{(this.state.data && this.state.data.data && this.state.profile.user_type === "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type === "admin_system") ? baht(this.state.data.data["estimates"]) : (this.state.data && this.state.data.data && this.state.profile.user_type !== "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type !== "admin_system") ? this.state.data.data["estimates"] : 0}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2">
                <div className="card bg-orange w-100 p-3 h-100">
                  <div className="row justify-content-between px-3 ">
                    <div>
                      <span className="icon-brand text-16">{"\uf555"}</span> <b>OVERDUE/INVOICE</b>
                    </div>
                    <div>
                      <p className="mb-0">
                        <b>{(this.state.data && this.state.data.data && this.state.profile.user_type === "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type === "admin_system") ? baht(this.state.data.data["overdue-invoice"]) : (this.state.data && this.state.data.data && this.state.profile.user_type !== "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type !== "admin_system") ? this.state.data.data["overdue-invoice"] : 0}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2">
                <div className="card bg-total-2 w-100 p-3 h-100">
                  <div className="row justify-content-between px-3">
                    <div>
                      <span className="icon-brand text-16">{"\uf555"}</span> <b>OVERDUE</b>
                    </div>
                    <div>
                      <p className="mb-0">
                        <b>{(this.state.data && this.state.data.data && this.state.profile.user_type === "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type === "admin_system") ? baht(this.state.data.data["overdue"]) : (this.state.data && this.state.data.data && this.state.profile.user_type !== "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type !== "admin_system") ? this.state.data.data["overdue"] : 0}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2">
                <div className="card bg-content-2 w-100 p-3 h-100">
                  <div className="row justify-content-between px-3">
                    <div>
                      <span className="icon-brand text-16">{"\uf555"}</span> <b>OPEN INVOICE</b>
                    </div>
                    <div>
                      <p className="mb-0">
                        <b>{(this.state.data && this.state.data.data && this.state.profile.user_type === "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type === "admin_system") ? baht(this.state.data.data["open-invoice"]) : (this.state.data && this.state.data.data && this.state.profile.user_type !== "owner") || (this.state.data && this.state.data.data && this.state.profile.user_type !== "admin_system") ? this.state.data.data["open-invoice"] : 0}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* table detail */}
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-sm-2 col-md-4 col-lg-4 col-xl-2 d-flex  ">
                    <input
                      maxLength={30}
                      className="form-control-search  mb-1 mt-1"
                      type="search"
                      placeholder="พิมพ์คำค้นหา"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ page: 1, search: e.target.value, loading: false });
                        if (e.target.value === "") {
                          this.setState({ search: "" });
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        } else {
                          setTimeout(() => {
                            this.GetAll();
                            this.setState({ loading: false });
                          }, 20);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({ page: 1, start_date: e.target.value });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1 pointer"
                          onClick={() => {
                            this.setState({
                              search: "",
                              status_bill: "",
                              member_type: 0,
                              start_date: "",
                              end_date: "",
                              sort_date: "",
                              sort_om_number: "",
                              date_created: "",
                              page: 1,
                            });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        >
                          <span className="icon-brand text-18 ">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-6 col-xl-4 d-flex ">
                    <select
                      className="form-control-select mr-1 mb-1 mt-1 pointer"
                      onChange={(e) => {
                        this.setState({ page: 1, member_type: e.target.value });

                        setTimeout(() => {
                          this.GetAll();
                        }, 20);
                      }}
                      value={this.state.member_type}
                    >
                      <option value="" selected>
                        แสดงทุกประเภท
                      </option>
                      <option value="store_normol">ลูกค้าทั่วไป</option>
                      <option value="store_technician">ร้านค้าช่างเย็บ</option>
                      <option value="store_wholesale">ร้านค้าช่วง</option>
                      <option value="store_school">ลูกค้าโรงเรียน</option>
                    </select>

                    <select
                      className="form-control-select ml-1 mb-1 mt-1 pointer"
                      onChange={(e) => {
                        this.setState({ status_bill: Number(e.target.value), page: 1 });
                        setTimeout(() => {
                          this.GetAll();
                        }, 10);
                      }}
                      value={this.state.status_bill}
                    >
                      <option value="0" selected>
                        แสดงทุกสถานะ
                      </option>
                      <option value="1">ใบเสนอราคา</option>
                      <option value="7">รอดำเนินการ</option>
                      <option value="2">เปิดบิล</option>
                      <option value="3">ปิดบิล</option>
                      <option value="4">เกินกำหนด</option>
                      <option value="5">เกินกำหนด / ใบแจ้งหนี้</option>
                      <option value="6">ยกเลิก</option>
                    </select>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-sm table-striped table-borderless table-sm table-hover mb-0">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 122 }}>
                            <b>เลขที่เอกสาร</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20">
                                  <span className="icon-brand text-10">{"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_om_number: "desc", page: 1, sort_date: "", date_created: "" });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort_om_number === "desc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort_om_number === "asc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>มากไปน้อย</span>
                              </MenuItem>
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_om_number: "asc", page: 1, sort_date: "", date_created: "" });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort_om_number === "asc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort_om_number === "desc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>น้อยไปมาก</span>
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-left" style={{ minWidth: 150 }}>
                            <b>วันที่</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20">
                                  <span className="icon-brand text-10">{"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ date_created: "desc", page: 1, sort_om_number: "" });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.date_created === "desc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.date_created === "asc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>วันที่ล่าสุด</span>
                              </MenuItem>

                              <MenuItem
                                onClick={async () => {
                                  this.setState({ date_created: "asc", page: 1, sort_om_number: "" });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.date_created === "asc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.date_created === "desc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>วันที่เก่าสุด</span>
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-left" style={{ minWidth: 105 }}>
                            <b>ชื่อลูกค้า</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 120 }}>
                            <b>ประเภทลูกค้า</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 132 }}>
                            <b>วันครบกำหนด</b>
                            <Menu
                              menuButton={
                                <button className="btn btn-link text-dark hpx-20">
                                  <span className="icon-brand text-10"> {"\uf078"}</span>
                                </button>
                              }
                              transition
                            >
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_date: "desc", page: 1, sort_om_number: "" });
                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort_date === "desc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort_date === "asc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>วันที่ล่าสุด</span>
                              </MenuItem>
                              <MenuItem
                                onClick={async () => {
                                  this.setState({ sort_date: "asc", page: 1, sort_om_number: "" });

                                  setTimeout(() => {
                                    this.GetAll();
                                  }, 20);
                                }}
                              >
                                {this.state.sort_date === "asc" && <span className="icon mr-1 px-0 pointer">{"\uf00c"}</span>}
                                <span className={this.state.sort_date === "desc" ? "ml-3 mx-0 pointer" : "mr-0 mx-0 pointer"}>วันที่เก่าสุด</span>
                              </MenuItem>
                            </Menu>
                          </th>
                          <th className="text-right">
                            <b>ยอดรวม</b>
                          </th>
                          <th className="text-right">
                            <b>ชำระแล้ว</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 105 }}>
                            <b>ยอดค้างชำระ</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 155 }}>
                            <b>สถานะ</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 90 }}>
                            <b>รายละเอียด</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data && this.state.data.data && this.state.data.data.data_list.length === 0 && (
                          <tr>
                            <td colSpan={11} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data &&
                          this.state.data.data.data_list.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.order_main_number}</td>
                              <td className="text-left">{format_date_sale(item.datetime_create)}</td>
                              <td className="text-left">{item.cutomer_name}</td>
                              <td className="text-center">{type_member(item.cutomer_type)}</td>
                              <td className="text-center">{format_duedate_sale(item.due_date)}</td>
                              <td className="text-right">{baht(item.total_price)}</td>
                              <td className="text-right">{baht(item.total_pay)}</td>
                              <td className="text-right">{baht(item.total_balance)}</td>
                              <td className="text-center">{status_sale_transaction2(item.status_bill)}</td>
                              <td className="text-center">
                                <Menu
                                  menuButton={
                                    <button className="btn btn-link text-dark">
                                      <h1 className="icon h2">{"\uf142"}</h1>
                                    </button>
                                  }
                                  transition
                                >
                                  {this.state.profile && this.state.profile.user_type === "owner" && (item.status_bill === 2 || item.status_bill === 3 || item.status_bill === 4 || item.status_bill === 5) && (
                                    <>
                                      <MenuItem
                                        onClick={async () => {
                                          this.setState({
                                            modal_discount: true,

                                            id: item.order_main_id,
                                            order_main_number: item.order_main_number,
                                            total_price_check: item.total_price,

                                            discount: item.s_discount ? item.discount_price : "",
                                            s_discount: item.s_discount,
                                          });
                                        }}
                                      >
                                        <span className="icon h2 mr-1">{"\uf02c"} </span>
                                        ส่วนลด
                                      </MenuItem>
                                    </>
                                  )}

                                  {item.status_bill === 1 && item.status_bill !== 3 && item.status_bill !== 2 && item.status_bill !== 4 && item.status_bill !== 6 && item.status_bill !== 7 && (
                                    <>
                                      <MenuItem
                                        onClick={async () => {
                                          this.Get_by_id_print(item.order_main_id, "ใบเสนอราคา");
                                        }}
                                      >
                                        <span className="icon h2 mr-1">{"\uf02f"} </span>
                                        พิมพ์ใบเสนอราคา
                                      </MenuItem>
                                    </>
                                  )}

                                  {item.status_bill !== 1 && item.status_bill !== 5 && item.status_bill !== 7 && (
                                    <>
                                      {item.vat === 0 ? (
                                        <MenuItem
                                          onClick={async () => {
                                            this.Get_by_id_print(item.order_main_id, "ใบส่งสินค้าชั่วคราว");
                                          }}
                                        >
                                          <span className="icon h2 mr-1"> {"\uf02f"} </span>
                                          พิมพ์ใบส่งสินค้าชั่วคราว
                                        </MenuItem>
                                      ) : (
                                        <MenuItem
                                          onClick={async () => {
                                            this.Get_by_id_print(item.order_main_id, "ใบกำกับภาษี");
                                          }}
                                        >
                                          <span className="icon mr-2 h2">{"\uf02f"}</span>
                                          พิมพ์ใบกำกับภาษี
                                        </MenuItem>
                                      )}
                                    </>
                                  )}

                                  {role && role.payment_receive[0] === 1 && (
                                    <>
                                      {item.payment_type_layer_1 === 0 ||
                                        (item.status_bill !== 1 && item.status_bill !== 3 && item.status_bill !== 5 && item.status_bill !== 6 && item.status_bill !== 7 && (
                                          <MenuItem
                                            onClick={async () => {
                                              window.location.href = "/sale/payment-receive?id=" + item.order_main_number + "&page=" + this.state.page + "&page_name=/sale&customer_name=" + item.cutomer_name + "&customer_id=" + item.cutomer_id;
                                            }}
                                          >
                                            <span className="icon h2 mr-1">{"\uf0d6"} </span>
                                            ระบบรับชำระเงิน
                                          </MenuItem>
                                        ))}
                                    </>
                                  )}

                                  {role && role.invoice[0] === 1 && (item.status_bill === 4 || item.status_bill === 2) && (
                                    <MenuItem
                                      onClick={async () => {
                                        window.location.href = "invoice/create?id=" + item.cutomer_id;
                                      }}
                                    >
                                      <span className="icon h2 mr-1">{"\uf02f"} </span>
                                      สร้างใบแจ้งหนี้
                                    </MenuItem>
                                  )}

                                  {role && role.pos_system[0] === 1 && role.pos_system[1] === 1 && role.pos_system[2] === 1 && role.pos_system[3] === 1 && (item.status_bill === 1 || item.status_bill === 7) && (
                                    // แก้ได้เฉพาะ type ใบเสนอราคาและบันทึกรอชำระ
                                    <MenuItem
                                      onClick={async () => {
                                        if (item.status_bill === 1) {
                                          this.Retry(item.order_main_id, "รีบิลเป็นการขาย", item.status_bill);
                                        } else if (item.status_bill === 7) {
                                          this.Retry(item.order_main_id, "บันทึกรอชำระ", item.status_bill);
                                        }
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf31c"}</span>
                                      แก้ไข
                                    </MenuItem>
                                  )}

                                  {item.payment_type_layer_1 === 4 && (
                                    <MenuItem
                                      onClick={async () => {
                                        this.Get_by_id(item.order_main_id, "installment");
                                      }}
                                    >
                                      <span className="icon mr-2">{"\uf1c0"}</span>
                                      รายการผ่อนชำระ
                                    </MenuItem>
                                  )}

                                  <MenuItem
                                    onClick={async () => {
                                      this.Get_by_id(item.order_main_id, "");
                                    }}
                                  >
                                    <span className="icon mr-2">{"\uf15c"}</span>
                                    รายละเอียด
                                  </MenuItem>

                                  <MenuItem
                                    onClick={async () => {
                                      this.GetHistoryById(item.order_main_id);
                                    }}
                                  >
                                    <span className="icon mr-2">{"\uf1da"}</span>
                                    ประวัติการชำระเงิน
                                  </MenuItem>
                                  {role && role.all_sale[3] === 1 && (
                                    <>
                                      {item.status_bill !== 5 && item.status_bill !== 3 && item.status_bill !== 6 && (
                                        <MenuItem
                                          onClick={async () => {
                                            this.setState({
                                              modal_delete: true,
                                              id: item.order_main_id,
                                              order_main_number: item.order_main_number,
                                              text_cancle: "",
                                            });
                                          }}
                                        >
                                          <span className="icon h2 mr-1">{"\uf2ed"} </span>
                                          ยกเลิกรายการ
                                        </MenuItem>
                                      )}
                                    </>
                                  )}
                                </Menu>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {this.state.profile.user_type === "owner" && (
                        <tfoot>
                          <tr>
                            <td className="text-left">
                              <b>ยอดรวม</b>
                            </td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-right">
                              <b>฿{this.state.data && this.state.data.data && this.state.data.data.data_list && baht(total(this.state.data.data.data_list, "total_price"))}</b>
                            </td>
                            <td className="text-right">
                              <b>฿{this.state.data && this.state.data.data && this.state.data.data.data_list && baht(total(this.state.data.data.data_list, "total_pay"))}</b>
                            </td>
                            <td className="text-right">
                              <b>฿{this.state.data && this.state.data.data && this.state.data.data.data_list && baht(total(this.state.data.data.data_list, "total_balance"))}</b>
                            </td>
                            <td className="text-right"></td>
                            <td className="text-left"></td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.handlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={20} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.handlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.handlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* อัปเดตสถานะ */}
        <Modal
          show={this.state.modal_update}
          size="m"
          onHide={() => {
            this.setState({ modal_update: false });
            this.ClearFormUpdate();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">อัปเดตสถานะ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>สถานะ:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control mx-2 border-0"
                  onChange={(e) => {
                    this.setState({ status: e.target.value });
                  }}
                  value={this.state.status}
                >
                  <option value="" selected disabled>
                    -- เลือกประเภท --
                  </option>
                  <option disabled={this.state.disabled_status} value="1">
                    ใบเสนอราคา
                  </option>
                  <option value="2">ใบสั่งซื้อสินค้า</option>
                </select>
              </div>
            </div>
            <hr />
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_update: false });
                    this.ClearFormUpdate();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ modal_update: false });
                    this.UpdateStatus();
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ส่วนลดท้ายบิล */}
        <Modal
          Modal
          show={this.state.modal_discount}
          onHide={() => {
            this.setState({ modal_discount: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">กรอกส่วนลด “{this.state.order_main_number}”</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              {/* ส่วนลดท้ายบิล */}
              <div className="w-100 mb-3 d-flex center">
                <label>ส่วนลด</label>
                <input
                  maxLength={14}
                  className={this.state.s_discount ? "form-control text-disables bg-light-gray mx-2" : "form-control mx-2"}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                      if (Number(e.target.value) >= Number(this.state.total_price_check)) {
                        alert("warning", "แจ้งเตือน", "ต้องเหลือยอดรวมอย่างน้อย 1 บาท");
                        this.setState({ discount: Number(this.state.total_price_check) - 1 });
                        return;
                      }
                      this.setState({ discount: e.target.value });
                    }
                  }}
                  value={this.state.discount}
                  disabled={this.state.s_discount ? true : false}
                />
                <label>บาท</label>
              </div>
              {this.state.s_discount ? null : (
                <div className="w-100 justify-content-start">
                  <label className="text-danger text-12">หมายเหตุ: ต้องเหลือยอดรวมอย่างน้อย 1 บาท</label>
                </div>
              )}
            </div>
            <div className="w-100 d-flex center">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary  w-100"
                  onClick={() => {
                    this.setState({ modal_discount: false, discount: "" });
                    this.ClearForm();
                  }}
                >
                  {this.state.s_discount ? "ปิด" : "ย้อนกลับ"}{" "}
                </button>
              </div>
              {!this.state.s_discount && (
                <div className="w-50 p-1">
                  <button
                    className="btn btn-danger  w-100"
                    onClick={() => {
                      if (this.state.discount === "") {
                        alert("warning", "แจ้งเตือน", "กรุณากรอกส่วนลด");
                        return;
                      }

                      this.Add_discount();
                    }}
                  >
                    {" "}
                    ยืนยัน{" "}
                  </button>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียด modal */}
        <ModalDetails
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />

        {/* Modal ยกเลิกรายการ */}
        <Modal
          Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยกเลิกรายการ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการยกเลิกรายการ “{this.state.order_main_number}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
              {/* การยกเลิก */}
              <div className="w-100 mb-3">
                <textarea
                  className="form-control border-0"
                  placeholder="สาเหตุในการยกเลิก"
                  value={this.state.text_cancle}
                  onChange={(e) => {
                    this.setState({ text_cancle: e.target.value });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn  btn-outline-primary  w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ย้อนกลับ{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger  w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ยืนยัน{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* รายละเอียดการผ่อนชำระ */}
        <ModalInstallment
          i_all_total_pay={this.state.i_all_total_pay}
          value={this.state.data_detail}
          installment_detail={this.state.installment_detail}
          show={this.state.modal_installment}
          onHide={() => {
            this.setState({ modal_installment: false });
          }}
        />

        {/* Modal ประวัติการชำระเงิน */}
        <ModalSaleHistory
          history={this.state.history}
          show={this.state.modal_history}
          onHide={() => {
            this.setState({ modal_history: false });
          }}
        />
      </div>
    );
  }
}
