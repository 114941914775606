/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { alert, POST, PUT, tokens, baht, role } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class UpDatePriceScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,
      index: "",

      store_price: "",
      wholesale_price: "",
      cost_price: "",

      update_store_price: "",
      update_wholesale_price: "",
      update_cost_price: "",

      search: "",
      type_product_name: "",
      type_product_id: "",
      profile: JSON.parse(localStorage.getItem("profile")),
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }

  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/updated_price/filter-search/page/0", body);

    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  set_price = async (item, index) => {
    this.setState({
      index: index,
      type_product_id: item.type_product_id,
      type_product_name: item.type_product_name,
      store_price: item.store_price,
      wholesale_price: item.wholesale_price,
      cost_price: item.cost_price,
      // update_store_price: 0.00,
      // update_wholesale_price: 0.00,
      // update_cost_price: 0.00,
    });
  };

  UpdatePrice = async () => {
    this.setState({ loading: true });

    let body = {
      type_product_id: this.state.type_product_id,
      store_price: this.state.update_store_price ? Number(this.state.update_store_price) : this.state.store_price,
      wholesale_price: this.state.update_wholesale_price ? Number(this.state.update_wholesale_price) : this.state.wholesale_price,
      cost_price: this.state.update_cost_price ? Number(this.state.update_cost_price) : this.state.cost_price,
    };

    let result = await PUT(tokens, "v1/product-mgt/type_product/updated_price", body);

    if (result && result.status) {
      alert("success", "ปรับราคาตามประเภทผ้า", "ประเภทผ้า ' " + this.state.type_product_name + " ' ถูกอัปเดตในระบบแล้ว");

      this.setState({ loading: false, modal_add: false });
      this.ClearForm();

      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      id: "",
      index: "",

      store_price: "",
      wholesale_price: "",
      cost_price: "",

      update_store_price: "",
      update_wholesale_price: "",
      update_cost_price: "",

      type_product_name: "",
      type_product_id: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container bg-contain">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="ปรับราคาตามประเภทผ้า" />
            <div style={{ height: 60 }}></div>
            <div className="row w-100 mt-3 mx-0">
              <div className="w-100 col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 mb-4">
                <div className="w-100 mb-3">
                  <h3>
                    <b>ปรับราคาตามประเภทผ้า</b>
                  </h3>
                  <label className="text-msg text-14">ทั้งหมด {this.state.data && this.state.data.data.length} รายการ</label>
                </div>
                <div className="w-100">
                  <div className="card">
                    <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                      <div className="col-6 col-sm-6 col-xl-8 d-flex mb-2">
                        <div className="w-100">
                          <input
                            maxLength={30}
                            className="form-control-search"
                            type="search"
                            placeholder="พิมพ์คำค้นหา"
                            value={this.state.search}
                            onChange={(e) => {
                              this.setState({ page: 1, search: e.target.value, loading: false });
                              if (e.target.value === "") {
                                this.setState({ search: "" });
                                setTimeout(() => {
                                  this.GetAll();
                                  this.setState({ loading: false });
                                }, 20);
                              } else {
                                setTimeout(() => {
                                  this.GetAll();
                                  this.setState({ loading: false });
                                }, 20);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive rounded-16 height-vh overflow-auto">
                        <table className="table table-hover table-striped table-borderless">
                          <thead>
                            <tr className=" bg-white">
                              <th className="text-center">
                                <b>ประเภทผ้า</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                              <tr>
                                <td colSpan={1} className="text-center table-light ">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}
                            {this.state.data &&
                              this.state.data.data.map((item, index) => (
                                <tr
                                  key={index}
                                  className="pointer"
                                  onClick={async () => {
                                    this.ClearForm();

                                    await this.set_price(item, index);
                                  }}
                                >
                                  <td className={this.state.type_product_name && index === this.state.index ? "text-left text-white bg-primary" : "text-left"}>{item.type_product_name}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 mb-4">
                <div className="d-none d-md-block">
                  <div style={{ height: 80 }}></div>
                </div>

                <div className="card">
                  <div className="card-body p-0 p-3 d-flex justify-content-between">
                    <div className="w-100 mr-1">
                      <h3 className="mb-3">
                        <b>ราคาเดิมตามประเภทผ้า</b>
                      </h3>

                      <div className="w-100">
                        <div className="mb-3">
                          <p>ราคาขายหน้าร้าน:</p>
                          <label>{this.state.store_price ? "฿" + baht(this.state.store_price) : "฿0.00"}</label>
                        </div>

                        {this.state.profile && (this.state.profile.user_type === "manager" || this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                          <>
                            <div className="mb-3">
                              <p>ราคาขายส่ง:</p>
                              <label>{this.state.wholesale_price ? "฿" + baht(this.state.wholesale_price) : "฿0.00"}</label>
                            </div>
                            <div className="mb-3">
                              <p>ราคาต้นทุน:</p>
                              <label>{this.state.cost_price ? "฿" + baht(this.state.cost_price) : "฿0.00"}</label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="w-100 ml-1">
                      <h3>
                        <b>ปรับราคาตามประเภทผ้า</b>
                      </h3>
                      <div>
                        <div className="mb-3 w-100">
                          <div className="wpx-120">
                            <label>ราคาขายหน้าร้าน:</label>
                          </div>
                          <div className="w-100">
                            <input
                              maxLength={14}
                              className="form-control bg-secondary-light border-0"
                              type="text"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                  let update_store_price = this.state.update_store_price;
                                  update_store_price = e.target.value;

                                  this.setState({
                                    update_store_price: update_store_price,
                                  });
                                }
                              }}
                              value={this.state.update_store_price}
                            />
                          </div>
                        </div>
                        {this.state.profile && (this.state.profile.user_type === "manager" || this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                          <>
                            <div className="mb-3 w-100">
                              <div className="wpx-120">
                                <label>ราคาขายส่ง:</label>
                              </div>
                              <div className="w-100">
                                <input
                                  maxLength={14}
                                  type="text"
                                  className="form-control border-0 bg-secondary-light"
                                  placeholder="กรอกข้อมูล"
                                  onChange={(e) => {
                                    if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                      let update_wholesale_price = this.state.update_wholesale_price;
                                      update_wholesale_price = e.target.value;
                                      this.setState({
                                        update_wholesale_price: update_wholesale_price,
                                      });
                                    }
                                  }}
                                  value={this.state.update_wholesale_price}
                                ></input>
                              </div>
                            </div>
                            <div className="mb-3 w-100">
                              <div className="wpx-120">
                                <label>ราคาต้นทุน:</label>
                              </div>
                              <div className="w-100">
                                <input
                                  maxLength={14}
                                  type="text"
                                  className="form-control border-0 bg-secondary-light"
                                  placeholder="กรอกข้อมูล"
                                  onChange={(e) => {
                                    if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                      let update_cost_price = this.state.update_cost_price;
                                      update_cost_price = e.target.value;

                                      this.setState({
                                        update_cost_price: update_cost_price,
                                      });
                                    }
                                  }}
                                  value={this.state.update_cost_price}
                                ></input>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {role && role.update_price[2] === 1 && (
          <div className="position-fixed bottom-0 bg-white w-100 p-3 " style={{ bottom: 0, right: 0 }}>
            <div className="d-flex w-100 justify-content-end align-items-center">
              <div className="d-flex">
                <div className="mr-1 w-50">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.ClearForm();
                    }}
                  >
                    เคลียร์ข้อมูล
                  </button>
                </div>
                <div className="ml-1 w-50 mr-2">
                  <button
                    className="btn btn-primary mx-2 px-4"
                    onClick={() => {
                      if (this.state.type_product_name === "") {
                        alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทผ้า");
                        this.ClearForm();

                        return;
                      } else if (this.state.update_store_price === "" || Number(this.state.update_store_price) === 0) {
                        alert("warning", "แจ้งเตือน", "กรุณากรอกราคาขายหน้าร้าน");
                        this.ClearForm();

                        return;
                      }

                      this.UpdatePrice();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
