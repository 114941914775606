import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { GET, POST, tokens, role, ModalDetailsReceipt, baht, status_adjustment, format_date_notime, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class ReceiptScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_detail: false,
      modal_edit: false,

      data: null,
      data_detail: "",

      search: "",
      profile: JSON.parse(localStorage.getItem("profile")),

      start_date: "",
      end_date: "",
      user_type: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).user_type : "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    let result = await POST(tokens, "v1/goods-receipt/search/page/" + this.state.page, body);

    if (result && result.status) {
      this.setState({ data: result.data });
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/goods-receipt/detail/" + id, null);
    if (result && result.status) {
      this.setState({ data_detail: result.data.data });

      setTimeout(() => {
        this.setState({
          modal_detail: true,
        });
      }, 10);
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  Add = async () => {};

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,
      loading: false,
      data: [],
      search: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="ใบรับสินค้า" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>ใบรับสินค้า</b>
              </h3>

              {role && role.goods_receipt[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4 ml-1"
                    onClick={() => {
                      window.location.href = "/product/receipt/receipt-create";
                    }}
                  >
                    เพิ่มใบรับสินค้า
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search "
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.start_date}
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({ page: 1, start_date: e.target.value });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          value={this.state.end_date}
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ page: 1, end_date: e.target.value });
                              setTimeout(() => {
                                this.GetAll();
                              }, 20);
                            }
                          }}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.setState({
                              search: "",
                              status_bill: "",
                              member_type: 0,
                              start_date: "",
                              end_date: "",
                              sort_date: "",
                              sort_om_number: "desc",
                              page: 1,
                            });
                            setTimeout(() => {
                              this.GetAll();
                            }, 10);
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.data && this.state.data.data.length === 0 ? "card-body p-0 height-vh" : "card-body p-0 "}>
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 105 }}>
                            <b>รหัสใบรับสินค้า</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>วันเวลา</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>รายการสินค้า</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>จำนวนไม้</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>จำนวนหลา</b>
                          </th>
                          {this.state.user_type === "owner" ? (
                            <th className="text-right" style={{ minWidth: 130 }}>
                              <b>จำนวนเงินรวม</b>
                            </th>
                          ) : null}
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>สถานะ</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>{this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system" ? "จัดการ" : "รายละเอียด"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={8} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.bill_receipt_code}</td>
                              <td className="text-left">{item.receive_date ? format_date_notime(item.receive_date) : "-"}</td>
                              <td className="text-center">{number(item.total_item_list)}</td>
                              <td className="text-center">{number(item.total_qty)}</td>
                              <td className="text-center">{item.total_yard_qty ? baht(item.total_yard_qty) : ""}</td>
                              {this.state.user_type === "owner" ? <td className="text-right">{item.total_price ? baht(item.total_price) : "0.00"}</td> : null}
                              <td className="text-center">
                                <b>{status_adjustment(item.decision_status)}</b>
                              </td>
                              <td className="text-center">
                                {item.decision_status === 0 && (
                                  <>
                                    {role && role.goods_receipt[2] === 1 && (
                                      <button
                                        className="btn btn-link text-secondary pointer"
                                        onClick={() => {
                                          window.location.href = "/product/receipt/receipt-update?id=" + item.g_rec_main_id;
                                        }}
                                      >
                                        <label className="icon mr-2 pointer">{"\uf040"}</label>
                                      </button>
                                    )}
                                  </>
                                )}

                                {item.decision_status === 1 && (
                                  <>
                                    {role && role.goods_receipt[2] === 1 && (
                                      <button
                                        className="btn btn-link text-secondary pointer"
                                        onClick={() => {
                                          window.location.href = "/product/receipt/receipt-update-costprice?id=" + item.g_rec_main_id;
                                        }}
                                      >
                                        <label className="icon mr-2 pointer">{"\uf040"}</label>
                                      </button>
                                    )}
                                  </>
                                )}

                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    let id = item.g_rec_main_id;
                                    this.Get_by_id(id);
                                  }}
                                >
                                  <label className="icon mr-2">{"\uf570"}</label>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* รายละเอียด modal */}
        <ModalDetailsReceipt
          value={this.state.data_detail}
          show={this.state.modal_detail}
          uset_type={this.state.user_type}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
        />
      </div>
    );
  }
}
