import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { tokens, GET, fieldSorter } from "../../../components/CustomComponent.js";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";
import "../../../assets/css/bootstrap.css";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
    width: 200,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
export default class LogAuditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,

      data: null,
      data_type: [],
      number: [],
      data_room: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      data_pile: [],
      search: "",

      room_number: "",
      pile: "",
      cycle_number: this.props.location.query.id,
      type: "",
    };
  }
  async componentDidMount() {
    let data_pile = [];
    for (let i = 1; i <= 100; i++) {
      data_pile.push(i.toString() + " - Front");
      data_pile.push(i.toString() + " - Side");
    }
    this.setState({ data_pile: data_pile });
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      cycle_number: this.state.cycle_number ? this.state.cycle_number : "",
      data_search: "",
    };
    let result = await GET(tokens, "v1/check-stock/cycle-detail", body);
    if (result && result.status) {
      let data = result.data.data;
      let array2 = [];
      let id = 1;
      let number = [];
      let array_type = [];
      for (let item of data) {
        for (let product of item.shoot_list) {
          // eslint-disable-next-line no-loop-func
          let index = array2.findIndex((e) => e.pile === item.pile && e.room_number === item.room_number && e.id === id);
          if (index === -1) {
            array2.push({
              id: id,
              checker_name: item.checker_name,
              product_code: product.product_code,
              product_name: product.product_name,
              product_design: product.product_design,
              type_product_name: product.type_product_name,
              pile: item.pile,
              room_number: item.room_number,
              count: array2.filter((e) => e.type_product_name === product.type_product_name && e.room_number === item.room_number && e.pile === item.pile).length + 1,
              array: [{ product_color: product.product_color, qty: product.yard_qty }],
            });
          } else {
            array2[index].array = [...array2[index].array, { product_color: product.product_color, qty: product.yard_qty }];
          }

          if (array_type.findIndex((e) => e.value === product.type_product_id) === -1) {
            array_type.push({ value: product.type_product_id, label: product.type_product_name });
          }
        }
        id += 1;
      }
      let length = 20;
      for (let i = 0; i < length; i++) {
        number.push(i);
      }
      for (let item of array2) {
        if (item.array.length % length !== 0) {
          for (let i = item.array.length % length; i < length; i++) {
            item.array.push("");
          }
        }
      }
      array2 = array2.sort(fieldSorter(["room_number", "pile", "count", "product_code"]));
      this.setState({ data: array2, number: number, data_type: array_type });
    }
    this.setState({ loading: false });
  };
  print = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      FCIconic: {
        normal: "FCIconic-Regular.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Regular.ttf",
        bolditalics: "FCIconic-Regular.ttf",
      },
      THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
      "3OF9_NEW": {
        normal: "3OF9_NEW-normal.ttf",
        bold: "3OF9_NEW-normal.ttf",
        italics: "3OF9_NEW-normal.ttf",
        bolditalics: "3OF9_NEW-normal.ttf",
      },
    };
    let text = this.state.data
      .filter((item) => (this.state.room_number === "" || this.state.room_number === item.room_number) && (this.state.pile === "" || this.state.pile === item.pile) && (this.state.type === "" || this.state.type.label === item.type_product_name))
      .map((item, index) => {
        return {
          stack: [
            {
              columns: [
                { text: "ประเภทผ้า: ", margin: [0, 3, 0, 0], width: 55, fontSize: 16, bold: true },
                { text: item.type_product_name, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                { text: "ห้อง: ", margin: [0, 3, 0, 0], width: 25, fontSize: 16, bold: true },
                { text: item.room_number, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                { text: "กอง: ", margin: [0, 3, 0, 0], width: 25, fontSize: 16, bold: true },
                { text: item.pile, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                { text: "ครั้งที่: ", margin: [0, 3, 0, 0], width: 35, fontSize: 16, bold: true },
                { text: index + 1, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                { text: "จำนวนไม้ทั้งหมด: ", margin: [0, 3, 0, 0], width: 80, fontSize: 16, bold: true },
                { text: item.array.filter((e) => e !== "").length + " ไม้", width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
                { text: "คนเช็ค: ", margin: [0, 3, 0, 0], width: 35, fontSize: 16, bold: true },
                { text: item.checker_name, width: "auto", margin: [0, 0, 25, 0], fontSize: 20, bold: true },
              ],
              alignment: "left",
            },
            {
              table: {
                headerRows: 1,
                widths: this.state.number.map((e, e_index) => {
                  return 100 / this.state.number.length + "%";
                }),
                margin: [0, 0, 0, 0],
                body: [
                  this.state.number.map((e, e_index) => {
                    return { fillColor: "#333333", color: "#ffffff", text: (e_index + 1).toString(), alignment: "center", bold: true };
                  }),
                  ...item.array
                    .filter((item2, index2) => index2 % this.state.number.length === 0)
                    .map((item2, index2) => {
                      return [
                        ...item.array
                          .filter((item3, index3) => index3 < (index2 + 1) * this.state.number.length && index3 >= index2 * this.state.number.length)
                          .map((item3, index3) => {
                            return {
                              fillColor: index2 % 2 === 0 ? "#cccccc" : "#ffffff",
                              stack: [
                                { text: item3.product_color ? item3.product_color : "", margin: [0, -3, 0, 0], lineHeight: 0.75, alignment: "center" },
                                { text: item3.qty ? item3.qty + " Y" : "", lineHeight: 0.55, alignment: "center" },
                                { text: item3.qty ? "_______\n " : "", lineHeight: 0.8, margin: [0, -20, 0, 0], fontSize: 12, alignment: "center" },
                              ],
                            };
                          }),
                      ];
                    }),
                ],
              },
            },
            { text: " " },
          ],
        };
      });
    var docDefinition = {
      content: text,
      defaultStyle: { font: "THSarabunNew", fontSize: 13 },
      pageMargins: [10, 10, 10, 10],
      pageOrientation: "landscape",
      pageSize: "A4",
    };
    pdfMake.createPdf(docDefinition).open();
    // print(docDefinition, "barcode.pdf")
  };

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="เช็คสต็อก" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายงานการเช็คสต็อก {this.state.cycle_number ? " : " + this.state.cycle_number : ""}</b>
              </h3>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/stock-cycle";
                  }}
                >
                  <span className="icon-brand text-18 mr-2">{"\uf060"}</span>
                  ย้อนกลับ
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16 align-items-end">
                  <div className="wpx-200 mx-1 mb-2">
                    <label className="mพ-2 ">ประเภทผ้า : </label>
                    <Select
                      styles={customStyles}
                      options={this.state.data_type}
                      placeholder="พิมพ์ชื่อสินค้า"
                      onChange={(e) => {
                        this.setState({
                          type: e,
                        });
                      }}
                      value={this.state.type}
                    />
                  </div>
                  <div className="wpx-200 mx-1 mb-2">
                    <label className="mพ-2 ">ห้อง : </label>
                    <select
                      className="form-control-select"
                      onChange={(e) => {
                        this.setState({ room_number: e.target.value });
                        setTimeout(() => {
                          this.GetAll();
                        }, 20);
                      }}
                      value={this.state.room_number}
                    >
                      <option value=""></option>
                      {this.state.data_room.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <div className="wpx-200 mx-1 mb-2">
                    <label className="mพ-2 ">กอง : </label>
                    <select
                      className="form-control-select"
                      onChange={(e) => {
                        this.setState({ pile: e.target.value });
                        setTimeout(() => {
                          this.GetAll();
                        }, 20);
                      }}
                      value={this.state.pile}
                    >
                      <option value=""></option>
                      {this.state.data_pile.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary bg-white ml-2 mb-2 mt-1"
                    onClick={() => {
                      this.setState({
                        room_number: "",
                        pile: "",
                        type: "",
                      });
                    }}
                  >
                    <span className="icon-brand text-18 ">{"\uf2f9"}</span> รีเซ็ท
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary px-4 mb-2 ml-auto"
                    onClick={() => {
                      this.print();
                    }}
                  >
                    <span className="icon-brand text-18 mr-2">{"\uf02f"}</span>
                    พิมพ์
                  </button>
                </div>
                <div className="card-body p-4">
                  {this.state.data &&
                    this.state.data
                      .filter((item) => (this.state.room_number === "" || this.state.room_number === item.room_number) && (this.state.pile === "" || this.state.pile === item.pile) && (this.state.type === "" || this.state.type.label === item.type_product_name))
                      .map((item, index) => (
                        <div className="row">
                          <div className="col-12 my-3">
                            <div className="d-flex align-items-end">
                              <h6>
                                <b className="mr-2">ประเภทผ้า:</b>
                              </h6>
                              <h5 className="mr-5">{item.type_product_name}</h5>
                              <h6>
                                <b className="mr-2">ห้อง:</b>
                              </h6>
                              <h5 className="mr-5">{item.room_number}</h5>
                              <h6>
                                <b className="mr-2">กอง:</b>
                              </h6>
                              <h5 className="mr-5">{item.pile}</h5>
                              <h6>
                                <b className="mr-2">ครั้งที่:</b>
                              </h6>
                              <h5 className="mr-5">{item.count}</h5>
                              <h6>
                                <b className="mr-2">จำนวนไม้ทั้งหมด:</b>
                              </h6>
                              <h5 className="mr-5">{item.array.filter((e) => e !== "").length} ไม้</h5>
                              <h6>
                                <b className="mr-2">คนเช็ค:</b>
                              </h6>
                              <h5 className="mr-5">{item.checker_name}</h5>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table table-striped table-bordered">
                                <thead>
                                  <tr className="bg-dark">
                                    {this.state.number.map((num) => (
                                      <th className="text-center text-light">
                                        <b>{num + 1}</b>
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.array
                                    .filter((item2, index2) => index2 % this.state.number.length === 0)
                                    .map((item2, index2) => (
                                      <tr key={index}>
                                        {item.array
                                          .filter((item3, index3) => index3 < (index2 + 1) * this.state.number.length && index3 >= index2 * this.state.number.length)
                                          .map((item3, index3) => (
                                            <td className="text-center p-1 text-14">
                                              {item3.product_color ? item3.product_color : ""}
                                              <br />
                                              {item3.product_color && <hr className="my-1 bg-dark" />}
                                              {item3.qty ? item3.qty + " Y" : ""}
                                            </td>
                                          ))}
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
