/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { alert, alert_url, GET, POST, PUT_CACHE, tokens } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
import WARNING from "../../assets/images/warning.png";
import Select, { components } from "react-select";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#f5f5f5",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class ProductUpdateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      product_name: "",
      product_details: "",
      product_type: null,
      type_product_id: "",
      product_color: "",
      product_design: "",

      stock_alert: null,
      store_price: "",
      wholesale_price: "",
      cost_price: "",

      profile: JSON.parse(localStorage.getItem("profile")),

      alert: 0,
    };
  }
  async componentDidMount() {
    await this.GetAllProductType();

    await this.Get_by_id(this.props.location.query.id);
  }

  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/detail/" + id, null);

    if (result && result.status) {
      let type_product_name = result.data.data.relation_type_product;
      let type_product_id = {
        value: this.state.product_type.data.find((e) => e.type_product_name === type_product_name).type_product_id,
        label: type_product_name,
      };
      setTimeout(() => {
        this.setState({
          product_name: result.data.data.product_name,
          product_details: result.data.data.product_detail,
          type_product_id: type_product_id,
          product_color: result.data.data.product_color,
          product_design: result.data.data.product_design,
          stock_alert: result.data.data.safety_stock_noti,
          store_price: result.data.data.store_price,
          wholesale_price: result.data.data.wholesale_price === 0 ? "" : result.data.data.wholesale_price,
          cost_price: result.data.data.cost_price === 0 ? "" : result.data.data.cost_price,
        });
      }, 10);

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  GetAllProductType = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", body);

    if (result && result.status) {
      this.setState({ product_type: result.data });
    }
    this.setState({ loading: false });
  };

  EditProduct = async () => {
    this.setState({ loading: true });

    let type_product_id = "";

    if (this.state.type_product_id.value) {
      type_product_id = this.state.type_product_id.value;
    } else {
      type_product_id = this.state.type_product_id;
    }

    let body = {
      product_id: this.props.location.query.id,
      type_product_id: type_product_id,
      product_name: this.state.product_name,
      safety_stock_noti: Number(this.state.stock_alert),
      store_price: Number(this.state.store_price),
      wholesale_price: Number(this.state.wholesale_price),
      cost_price: Number(this.state.cost_price),
      product_detail: this.state.product_details,
      product_color: this.state.product_color,
      product_design: this.state.product_design,
    };

    let result = await PUT_CACHE(tokens, "v1/product-mgt/all/update", body);

    if (result && result.status) {
      alert_url("success", "แก้ไขสินค้าเรียบร้อยแล้ว", "สินค้า ' " + this.state.product_name + " ' ถูกแก้ไขข้อมูลในระบบแล้ว", "/product");

      this.setState({ loading: false });
      this.ClearForm();
    }
    this.setState({ loading: false });
  };

  ClearForm() {
    this.setState({
      loading: false,

      product_name: "",
      product_details: "",
      product_type: "",
      product_color: "",
      product_design: "",
      stock_alert: "",
      store_price: "",
      wholesale_price: "",
      cost_price: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="สินค้าทั้งหมด" page3="แก้ไขสินค้า" />
            <div style={{ height: 60 }}></div>

            <div className="row mx-1 mt-3">
              <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-3"></div>
              <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6">
                {/* header */}
                <div className="w-100 row mx-0 d-flex align-items-center justify-content-between ">
                  <h3>
                    <b>แก้ไขสินค้าสินค้า</b>
                  </h3>
                </div>

                {/* detail */}
                <div className="w-100 mt-3">
                  <div className="card rounded p-4">
                    <h5>
                      <b>รายละเอียด</b>
                    </h5>

                    <div className="row mt-3">
                      <div className="col-12 mb-3">
                        <label>ชื่อสินค้า:</label>
                        <input
                          maxLength={30}
                          type="text"
                          className="form-control border-0 bg-secondary-light"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            this.setState({ product_name: e.target.value });
                          }}
                          value={this.state.product_name}
                        ></input>
                      </div>

                      <div className="col-12 mb-3">
                        <label>รายละเอียด:</label>
                        <textarea
                          type="text"
                          maxLength={50}
                          className="form-control border-0 bg-secondary-light"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            this.setState({ product_details: e.target.value });
                          }}
                          value={this.state.product_details}
                        ></textarea>
                      </div>

                      <div className="col-6 mb-3">
                        <label>ประเภทผ้า:</label>
                        <Select
                          ref={(click) => (this.Input = click)}
                          className="px-0"
                          options={
                            this.state.product_type &&
                            this.state.product_type.data.map((item) => ({
                              value: item.type_product_id,
                              label: item.type_product_name,
                            }))
                          }
                          components={{ Option: TypeOption }}
                          styles={customStylesTypeOption}
                          placeholder="แสดงทุกประเภท"
                          isClearable
                          value={this.state.type_product_id}
                          defaultValue={this.state.type_product_id}
                          onChange={(e) => {
                            if (e === null) {
                              this.setState({ type_product_id: "" });
                            } else if (this.state.type_product_id.value !== e.value) {
                              this.setState({ type_product_id: e });
                            } else {
                              this.setState({ type_product_id: e.value });
                            }
                          }}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <label>สี:</label>
                        <input
                          maxLength={30}
                          type="text"
                          className="form-control border-0 bg-secondary-light"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            this.setState({ product_color: e.target.value });
                          }}
                          value={this.state.product_color}
                        ></input>
                      </div>
                      <div className="col-6 mb-3">
                        <label>ดีไซน์:</label>
                        <input
                          type="text"
                          maxLength={30}
                          className="form-control border-0 bg-secondary-light"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            this.setState({ product_design: e.target.value });
                          }}
                          value={this.state.product_design}
                        ></input>
                      </div>
                      <div className="col-6 mb-3">
                        <label>แจ้งเตือนสต็อกสินค้า:</label>
                        <input
                          maxLength={14}
                          type="text"
                          className="form-control border-0 bg-secondary-light"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            if (Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                              let stock_alert = this.state.stock_alert;
                              stock_alert = e.target.value.replace(/\D/g, "");
                              this.setState({
                                stock_alert: stock_alert,
                              });
                            }
                          }}
                          value={this.state.stock_alert}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>

                {/* price */}
                <div className="w-100 mt-3 mb-3">
                  <div className="card rounded p-4">
                    <h5>
                      <b>ราคาสินค้า</b>
                    </h5>

                    <div className="row mt-3">
                      <div className="col-12 mb-3">
                        <label>ราคาขายหน้าร้าน:</label>
                        <input
                          maxLength={14}
                          type="text"
                          className="form-control border-0 bg-secondary-light"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                              let store_price = this.state.store_price;
                              store_price = e.target.value;
                              this.setState({
                                store_price: store_price,
                              });
                            }
                          }}
                          value={this.state.store_price}
                        ></input>
                      </div>

                      {this.state.profile && (this.state.profile.user_type === "owner" || this.state.profile.user_type === "admin_system") && (
                        <>
                          <div className="col-12 mb-3">
                            <label>ราคาขายส่ง:</label>
                            <input
                              maxLength={14}
                              type="text"
                              className="form-control border-0 bg-secondary-light"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                  let wholesale_price = this.state.wholesale_price;
                                  wholesale_price = e.target.value;
                                  this.setState({
                                    wholesale_price: wholesale_price,
                                  });
                                }
                              }}
                              value={this.state.wholesale_price}
                            ></input>
                          </div>

                          <div className="col-12 mb-3">
                            <label>ราคาต้นทุน:</label>
                            <input
                              maxLength={14}
                              type="text"
                              className="form-control border-0 bg-secondary-light"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                  let cost_price = this.state.cost_price;
                                  cost_price = e.target.value;
                                  this.setState({
                                    cost_price: cost_price,
                                  });
                                }
                              }}
                              value={this.state.cost_price}
                            ></input>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {this.state.alert === 1 && (
                  <div>
                    <div className="card bg-yellow-warning w-100 p-3 mb-3">
                      <div className=" row d-flex justify-content-center align-content-center p-2">
                        <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                        <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-100 mt-3">
                  <div className="row mx-0">
                    <div className="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 ml-auto mb-3">
                      <div className="d-flex">
                        <div className="mr-1 w-50">
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 px-4"
                            onClick={() => {
                              window.location.href = "/product";
                            }}
                          >
                            ยกเลิก
                          </button>
                        </div>
                        <div className="ml-1 w-50">
                          <button
                            type="button"
                            className="btn btn-primary w-100 px-4"
                            onClick={() => {
                              if (this.state.product_name === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อสินค้า");
                                return;
                              } else if (this.state.type_product_id === "") {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทผ้า");
                                return;
                              } else if (this.state.product_color === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกสี");
                                return;
                              } else if (this.state.product_design === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกดีไซน์");
                                return;
                              } else if (this.state.store_price === "" || Number(this.state.store_price) === 0) {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกราคาขาย");
                                return;
                              }

                              if (this.state.alert === 0) {
                                this.setState({ alert: 1 });
                              } else {
                                this.EditProduct();
                              }
                            }}
                          >
                            เพิ่ม
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
