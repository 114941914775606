/* eslint-disable array-callback-return */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { alert, alert_url, GET, POST, tokens, format_date, baht, total, PUT } from "../../components/CustomComponent.js";
import { ExcelRenderer } from "react-excel-renderer";
import Select from "react-select";
import WARNING from "../../assets/images/warning.png";
import "../../assets/css/bootstrap.css";
import moment from "moment-timezone";
export default class ReceiptUpdateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      loading: false,
      modal_date: false,
      modal_supplier: false,
      modal_ref: false,
      modal_warehouse: false,

      date: "",

      data: [],
      warehouselist: [],
      companylist: [],
      productlist: [],

      company: "",
      company_supplier_id: "",
      ref_code: "",
      ref_codes: "",

      warehouse_id: "",

      warehouse: "",
      product_value: null,
      qty: "",
      yard_qty: "",
      price_by_item: "",
      total_price_by_item: "0.00",

      total_qty: 0,
      total_yard_qty: 0,
      total_final: 0,
      total_price_all: 0,

      search: "",

      user_id: "",
      product_id: "",
      type_product_id: "",
      status_url: "",
      file_name: "",

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",

      status: "0",
      bill_receipt_code: "",

      alert: 0,
      product_color: "",
      timeZone: moment.tz.guess(),
    };
  }
  async componentDidMount() {
    await this.GetWareHouse();
    await this.GetCompany();

    await this.Get_by_id(this.props.location.query.id);
  }

  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/goods-receipt/detail/" + id, null);
    if (result && result.status) {
      let company_id = "";
      let company_name = "";
      let fomat = [];
      let data = result.data.data;
      let warehouse_id = this.state.warehouselist.find((e) => e.warehouse_id === data.goods_receipt_list[0].all_stock_data.warehouse_id).warehouse_id;
      let warehouse_name = this.state.warehouselist.find((e) => e.warehouse_name === data.goods_receipt_list[0].all_stock_data.warehouse_name).warehouse_name;

      if (data.company_supplier_data) {
        company_id = this.state.companylist.find((e) => e.company_id === data.company_supplier_data.company_id).company_id;
        company_name = this.state.companylist.find((e) => e.company_id === data.company_supplier_data.company_id).company_name;
      }

      let datetime = Number(data.receive_date.split("T")[0].split("-")[0]) + "-" + ("0" + Number(data.receive_date.split("-")[1].split("/")[0])).toString().slice(-2) + "-" + data.receive_date.split("T")[0].split("-")[2];

      for (let i = 0; i < data.goods_receipt_list.length; i++) {
        let make_fomat = {
          product_code: data.goods_receipt_list[i].all_stock_data.product_main_data.product_code,
          product_name: data.goods_receipt_list[i].all_stock_data.product_main_data.product_name,
          product_color: data.goods_receipt_list[i].all_stock_data.product_main_data.product_color,
          product_design: data.goods_receipt_list[i].all_stock_data.product_main_data.product_design,
          qty: data.goods_receipt_list[i].qty,
          yard_qty: data.goods_receipt_list[i].yard_qty,
          price_by_item: data.goods_receipt_list[i].price_by_item,
          total_price_by_item: data.goods_receipt_list[i].total_price_by_item,
          check: true,
        };
        fomat.push(make_fomat);
      }

      this.setState({
        bill_receipt_code: data.bill_receipt_code,

        ref_code: data.ref_receipt_code,
        ref_codes: data.ref_receipt_code,

        company_supplier_id: company_id ? company_id : "",
        company: company_name ? company_name : "",

        warehouse_id: warehouse_id,
        warehouse: warehouse_name,

        date: datetime,
        reciept_date: datetime,

        data: fomat,
      });

      if (this.state.warehouse_id !== "") {
        this.GetProduct();
      }

      if (this.state.data) {
        this.setState({ total_price_all: total(this.state.data, "total_price_by_item") });
      }

      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  GetWareHouse = async () => {
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/0", body);
    if (result && result.status) {
      this.setState({ warehouselist: result.data.data });
    }
  };
  GetCompany = async () => {
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/user-member/supplier/search/page/0", body);
    if (result && result.status) {
      this.setState({ companylist: result.data.data });
    }
  };
  GetProduct = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/filter-search/page/0?data_search" + this.state.search + "&status=" + this.state.status_url + "&type_product_id=" + this.state.type_product_id + "&warehouse_id=" + this.state.warehouse_id + "&user_member_id=" + this.state.user_id + "&product_id=" + this.state.product_id + "&sort_name=" + this.state.sort_name + "&sort_yard=" + this.state.sort_yard + "&sort_color=" + this.state.sort_color + "&product_design=" + this.state.design_value.replaceAll("#", "%23") + "&color=" + this.state.product_color, null);
    if (result && result.status) {
      this.setState({ productlist: result.data.data.data_returns });
    }
    this.setState({ loading: false });
  };
  Upload_File = (e) => {
    try {
      let fileObj = e.target.files[0];
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          alert("warning", "เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง");
          return;
        } else {
          let data = resp.rows;
          let array_data = this.state.data;
          for (let i = 1; i < data.length; i++) {
            if (data[i].length !== 7 && data[i].length !== 0) {
              alert("warning", "แจ้งเตือน", "ฟอร์มไม่ถูกต้อง");
              return;
            }
            if (data[i].length > 0 && data[i] !== data[0]) {
              let product_list = {
                product_code: data[i][0],
                product_name: data[i][1],
                product_color: data[i][2],
                product_design: data[i][3],
                qty: Number.isFinite(data[i][4]) ? Number(data[i][4].toFixed(0)) : "",
                yard_qty: Number.isFinite(data[i][5]) ? Number(data[i][5]) : "",
                price_by_item: Number.isFinite(data[i][6]) ? Number(data[i][6]) : "",
                total_price_by_item: Number(data[i][5]) * Number(data[i][6]),
                check: true,
              };
              array_data.push(product_list);
            }
          }
          this.setState({
            data: array_data,
            file_name: fileObj.name,
          });
          setTimeout(() => {
            this.Calculate_Add_Price();
          }, 10);
          document.getElementById("file").value = "";
        }
      });
    } catch (error) {
      alert("warning", "เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง");
      document.getElementById("file").value = "";
    }
  };
  CheckAddProduct = () => {
    let data = this.state.data;
    let productlist = this.state.productlist;
    let check_data = true;

    data.forEach((e) => {
      if (e.product_code === undefined || e.product_name === undefined || e.qty === undefined || e.yard_qty === undefined || e.price_by_item === undefined || e.total_price_by_item === undefined) {
        alert("warning", "ไม่สามารถอัปโหลดข้อมูลได้", "พบข้อมูลบางรายการเป็นค่าว่าง\nกรุณาอัปโหลดไฟล์ใหม่อีกครั้ง");
        e.check = false;
        if (check_data) {
          check_data = false;
        }
      } else {
        let product_code = productlist.find((item) => item.product_main_data.product_code === e.product_code);
        if (product_code === undefined) {
          alert("warning", "ไม่สามารถอัปโหลดข้อมูลได้", "ไม่พบข้อมูลในระบบ\nกรุณาอัปโหลดไฟล์ใหม่อีกครั้ง");
          e.check = false;
          if (check_data) {
            check_data = false;
          }
        } else if (e.qty === "" || e.yard_qty === "" || e.price_by_item === "") {
          alert("warning", "ไม่สามารถอัปโหลดข้อมูลได้", "ข้อมูลบางรายการไม่ถูกต้อง\nกรุณาอัปโหลดไฟล์ใหม่อีกครั้ง");
          e.check = false;
          if (check_data) {
            check_data = false;
          }
        }
      }
    });
    this.setState({ data: data });
    setTimeout(() => {
      if (check_data) {
        this.AddReceipt();
      }
    }, 10);
  };
  Add_Product = () => {
    let d = this.state.data.filter((e) => e.product_code === this.state.product_value.product_code);

    if (d.length !== 0) {
      alert("warning", "แจ้งเตือน", "มีสินค้าชนิดนี้อยู่ในรายการ");
      return;
    } else {
      this.setState({
        data: [
          ...this.state.data,
          {
            product_code: this.state.product_value.product_code,
            product_name: this.state.product_value.product_name,
            product_color: this.state.product_value.product_color,
            product_design: this.state.product_value.product_design,
            qty: Number(this.state.qty),
            yard_qty: Number(this.state.yard_qty),
            price_by_item: Number(this.state.price_by_item),
            total_price_by_item: this.state.total_price_by_item,
            check: true,
          },
        ],
        product_value: null,
        qty: "",
        yard_qty: "",
        price_by_item: "",
        total_price_by_item: "0.00",
      });
      setTimeout(() => {
        this.Calculate_Add_Price();
      }, 10);
    }
  };
  UpdateAddProduct = async () => {
    this.setState({ loading: true });
    let data = this.state.data;
    let array_data = [];
    let date = this.state.date.split("-")[2].split("/")[0] + "/" + ("0" + Number(this.state.date.split("-")[1].split("/")[0])).toString().slice(-2) + "/" + Number(this.state.date.split("T")[0].split("-")[0]);

    let company_id = "";
    if (this.state.company) {
      company_id = this.state.companylist.find((e) => e.company_name === this.state.company).company_id;
    }

    data.forEach((e) => {
      let t = {
        product_code: e.product_code,
        price_by_item: Number(e.price_by_item),
        qty: Number(e.qty),
        yard_qty: Number(e.yard_qty),
        total_price_by_item: Number(e.total_price_by_item),
      };
      array_data.push(t);
    });

    setTimeout(async () => {
      let body = {
        g_rec_main_id: this.props.location.query.id,
        date_time_receipt: date,
        company_supplier_id: company_id,
        bill_receipt_code: this.state.bill_receipt_code,
        ref_receipt_code: this.state.ref_code,
        discount: 0,
        total_price_all: this.state.total_price_all,
        status: Number(this.state.status),
        warehouse_id: this.state.warehouse_id,
        all_stock_list: array_data,
        time_zone: this.state.timeZone,
      };

      let result = await PUT(tokens, "v1/goods-receipt/update", body);

      if (result && result.status) {
        alert_url("success", "บันทึกข้อมูลใบสินค้าเรียบร้อยแล้ว", "ใบสินค้าถูกเพิ่มในระบบแล้ว", "/product/receipt");
        this.setState({ loading: false, modal_add: false });

        this.ClearForm();
      }
    }, 10);
    this.setState({ loading: false });
  };
  ClearForm() {
    this.setState({
      page: 1,
      loading: false,
      data: [],
      search: "",

      user_id: "",
      product_id: "",
      type_product_id: "",
      status_url: "",
      file_name: "",
      yard_qty: "",
      price_by_item: "",
      total_final: 0,
      total_price_all: 0,
      total_price_by_item: "0.00",

      sort_yard: "",
      sort_name: "",
      sort_color: "",
      design_value: "",

      ref_code: "",
      ref_codes: "",

      warehouse_id: "",

      warehouse: "",
      product_value: null,
      qty: "",

      total_qty: 0,
      total_yard_qty: 0,
    });
  }
  Calculate_Add_Price() {
    let data = this.state.data;
    let total_qty = 0;
    let total_yard_qty = 0;
    let total_price_all = 0;

    for (let i = 0; i < data.length; i++) {
      total_qty += Number(data[i].qty);
      total_yard_qty += Number(data[i].yard_qty);
      data[i].total_price_by_item = Number(data[i].yard_qty) * Number(data[i].price_by_item);
      total_price_all += data[i].total_price_by_item;
    }
    this.setState({
      total_qty: total_qty,
      total_yard_qty: total_yard_qty,
      total_price_all: total_price_all,
    });
  }
  ClearFile() {
    this.setState({
      file_name: "",
      data: [],
      total_qty: 0,
      total_yard_qty: 0,
      total_final: 0,
      total_price_all: 0,
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "100px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="จัดการสินค้า" page3="ใบรับสินค้า" />
            <div style={{ height: 60 }}></div>
            <div className="mx-4 mt-4 mb-3">
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between   mt-3">
                <h3>
                  <b>ใบรับสินค้า</b>
                </h3>

                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 px-3 mr-1"
                    onClick={() => {
                      window.location.href = "/product/receipt";
                    }}
                  >
                    <span className="icon-brand text-18 mr-2">{"\uf177"}</span>ย้อนกลับ
                  </button>

                  <div
                    className="btn btn-primary ml-1"
                    onClick={() => {
                      if (this.state.company === "") {
                        alert("warning", "แจ้งเตือน", "กรุณาเลือกผู้จำหน่าย");
                        return;
                      } else if (this.state.warehouse === "") {
                        alert("warning", "แจ้งเตือน", "กรุณาเลือกโกดังสินค้า");
                        return;
                      } else if (this.state.file_name !== "") {
                        this.ClearFile();
                        return;
                      }
                      this.refs.fileUploader.click();
                    }}
                  >
                    <label className="mt-2 pointer">
                      {" "}
                      <span className="icon-brand text-18 mr-2 pointer">{this.state.file_name ? "\uf2ed" : "\uf56f"}</span>
                      {this.state.file_name ? "เคลียร์ข้อมูล" : "นำเข้าสินค้า Excel"}
                    </label>
                  </div>

                  <input
                    type="file"
                    id="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref="fileUploader"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      this.Upload_File(e);
                    }}
                  />
                </div>
              </div>

              <div className="w-100 pt-3">
                <div className="card ">
                  <div className="card-header rounded-top-16 mx-0 px-2 p-3">
                    <p className=" text-16">
                      <b>รหัสใบรับสินค้า: {this.state.bill_receipt_code}</b>
                    </p>
                    <hr className="d-block d-xl-none" />
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <div className="row d-flex justify-content-center align-items-center">
                          วันที่รับ: {format_date(this.state.date)}
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => {
                              this.setState({
                                modal_date: true,
                                reciept_date: this.state.date,
                              });
                            }}
                          >
                            <span className="icon-brand mr-1 text-14 pointer">{"\uf304"}</span>
                            เปลี่ยน
                          </button>
                        </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 border-left border-right">
                        <hr className="d-block d-sm-none" />
                        <div className="row d-flex justify-content-center align-items-center">
                          ผู้จำหน่าย:{this.state.company}
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => {
                              let company_id = "";
                              if (this.state.company) {
                                company_id = this.state.companylist.find((e) => e.company_name === this.state.company).company_id;
                              }
                              this.setState({
                                company_supplier_id: company_id,
                                modal_supplier: true,
                              });
                            }}
                          >
                            <span className="icon-brand mr-1 text-14 pointer">{"\uf304"}</span>
                            เปลี่ยน
                          </button>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <hr className="d-block d-xl-none" />
                        <div className="row d-flex justify-content-center align-items-center">
                          เอกสารอ้างอิง: {this.state.ref_code}
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => {
                              this.setState({
                                modal_ref: true,
                              });
                            }}
                          >
                            <span className="icon-brand mr-1 text-14 pointer">{"\uf304"}</span>
                            เปลี่ยน
                          </button>
                        </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 border-left border-right">
                        <hr className="d-block d-xl-none" />
                        <div className="row d-flex justify-content-center align-items-center">
                          โกดัง: {this.state.warehouse}
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => {
                              if (this.state.data.length === 0) {
                                if (this.state.company === "") {
                                  alert("warning", "แจ้งเตือน", "กรุณาเลือกผู้จำหน่าย");
                                  return;
                                }

                                let warehouse_id = "";
                                if (this.state.warehouse) {
                                  warehouse_id = this.state.warehouselist.find((e) => e.warehouse_name === this.state.warehouse).warehouse_id;
                                }

                                this.setState({
                                  warehouse_id: warehouse_id,
                                  modal_warehouse: true,
                                });
                              } else {
                                alert("warning", "แจ้งเตือน", "ไม่สามารถเปลี่ยนโกดังได้เนื่องจากมีรายการสินค้าอยู่");
                              }
                            }}
                          >
                            <span className="icon-brand mr-1 text-14 pointer">{"\uf304"}</span>
                            เปลี่ยน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive tableFixHead" style={{ paddingBottom: 500 }}>
                      <table className="table table-striped table-borderless table-sm">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <b>ลำดับ</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>รหัสสินค้า</b>
                            </th>
                            <th className="text-left">
                              <b>ชื่อสินค้า</b>
                            </th>
                            <th className="text-left">
                              <b>สี</b>
                            </th>
                            <th className="text-left">
                              <b>ดีไซน์</b>
                            </th>
                            <th className="text-right">
                              <b>จำนวนหลา</b>
                            </th>
                            <th className="text-center">
                              <b>จำนวน(ไม้)</b>
                            </th>
                            <th className="text-right">
                              <b>ราคาที่รับมา</b>
                            </th>
                            <th className="text-right">
                              <b>ยอดรวมสุทธิ</b>
                            </th>
                            <th className="text-center">
                              <b>นำออก</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data &&
                            this.state.data.map((item, index) => (
                              <tr key={index} className="pointer">
                                <td className={item.check ? "text-center" : "text-center text-danger"}>{Number(index + 1)}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_code}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_name}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_color}</td>
                                <td className={item.check ? "text-left" : "text-left text-danger"}>{item.product_design}</td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>
                                  <input
                                    type="text"
                                    className="form-control-select border border-secondary text-right"
                                    maxLength={14}
                                    onChange={(e) => {
                                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 1 && Number(e.target.value) < 999999999) {
                                        let data = this.state.data;
                                        data[index].yard_qty = e.target.value;
                                        this.setState({ data: data });

                                        setTimeout(() => {
                                          this.Calculate_Add_Price();
                                        }, 10);
                                      } else {
                                        let data = this.state.data;
                                        data[index].yard_qty = "";
                                        this.setState({ data: data });
                                      }
                                    }}
                                    value={item.yard_qty}
                                  />
                                </td>
                                <td className={item.check ? "text-center" : "text-center text-danger"}>
                                  <input
                                    maxLength={14}
                                    className="form-control-select border border-secondary text-right"
                                    type="text"
                                    placeholder="กรอกข้อมูล"
                                    onChange={(e) => {
                                      if (/^\d*?\d{0}$/.test(e.target.value) && Number(e.target.value) >= 1 && Number(e.target.value) < 999999999) {
                                        let data = this.state.data;
                                        data[index].qty = Number(e.target.value);
                                        this.setState({ data: data });
                                        setTimeout(() => {
                                          this.Calculate_Add_Price();
                                        }, 10);
                                      } else {
                                        let data = this.state.data;
                                        data[index].qty = "";
                                        this.setState({ data: data });
                                      }
                                    }}
                                    value={item.qty}
                                  />
                                </td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>
                                  <input
                                    type="text"
                                    className="form-control-select border border-secondary text-right"
                                    maxLength={14}
                                    onChange={(e) => {
                                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                        let data = this.state.data;
                                        data[index].price_by_item = e.target.value;
                                        this.setState({ data: data });
                                        setTimeout(() => {
                                          this.Calculate_Add_Price();
                                        }, 10);
                                      } else {
                                        let data = this.state.data;
                                        data[index].price_by_item = "";
                                        this.setState({ data: data });
                                      }
                                    }}
                                    value={item.price_by_item}
                                  />
                                </td>
                                <td className={item.check ? "text-right" : "text-right text-danger"}>{item.total_price_by_item ? baht(item.total_price_by_item) : "0.00"}</td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-link text-secondary"
                                    onClick={() => {
                                      let data = this.state.data;
                                      data.splice(index, 1);
                                      if (data.length === 0) {
                                        this.setState({ file_name: "" });
                                        document.getElementById("file").value = "";
                                      }
                                      this.setState({ data: data });

                                      setTimeout(() => {
                                        this.Calculate_Add_Price();
                                      }, 10);
                                    }}
                                  >
                                    <label
                                      className="icon bg-danger text-14 text-white"
                                      style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: 12,
                                      }}
                                    >
                                      {"\uf00d"}
                                    </label>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td className="text-center">{Number(this.state.data.length + 1)}</td>
                            <td className="text-center" colSpan={4}>
                              <Select
                                ref={(node) => (this.inputRef = node)}
                                className="form-control-select px-0 select-search text-left"
                                options={
                                  this.state.warehouse_id
                                    ? this.state.productlist &&
                                      this.state.productlist
                                        .filter((item) => item.warehouse_id === this.state.warehouse_id)
                                        .map((item) => {
                                          return {
                                            value: item.product_main_data.product_code,
                                            label: item.product_main_data.product_code + " : " + item.product_main_data.product_name + ", " + item.product_main_data.product_color + ", " + item.product_main_data.product_design,
                                            product_code: item.product_main_data.product_code,
                                            product_name: item.product_main_data.product_name,
                                            product_color: item.product_main_data.product_color,
                                            product_design: item.product_main_data.product_design,
                                          };
                                        })
                                    : []
                                }
                                placeholder={"กรุณาเลือก"}
                                onChange={(e) => {
                                  this.setState({ product_value: e });

                                  setTimeout(() => {
                                    document.getElementById("yard_qty").focus();
                                  }, 100);
                                }}
                                value={this.state.product_value}
                                autoFocus
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                id="yard_qty"
                                className="form-control text-right"
                                maxLength={14}
                                onChange={(e) => {
                                  if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 1 && Number(e.target.value) < 999999999) {
                                    this.setState({ yard_qty: e.target.value });
                                    setTimeout(() => {
                                      this.Calculate_Add_Price();
                                    }, 10);
                                  } else {
                                    this.setState({ yard_qty: "" });
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    document.getElementById("qty").focus();
                                  }
                                }}
                                value={this.state.yard_qty}
                              />
                            </td>
                            <td className="text-center ">
                              <input
                                id="qty"
                                type="text"
                                className="form-control text-right"
                                maxLength={14}
                                onChange={(e) => {
                                  if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 1 && Number(e.target.value) < 999999999) {
                                    this.setState({ qty: e.target.value });
                                    setTimeout(() => {
                                      this.Calculate_Add_Price();
                                    }, 10);
                                  } else {
                                    this.setState({ qty: "" });
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    document.getElementById("price_by_item").focus();
                                  }
                                }}
                                value={this.state.qty}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                id="price_by_item"
                                className="form-control text-right"
                                maxLength={14}
                                onChange={(e) => {
                                  if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                    this.setState({ price_by_item: e.target.value });
                                    setTimeout(() => {
                                      this.Calculate_Add_Price();
                                    }, 10);
                                  } else {
                                    this.setState({ price_by_item: "" });
                                  }
                                }}
                                value={this.state.price_by_item}
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    if (this.state.product_value && this.state.qty && this.state.yard_qty && this.state.total_price_by_item) {
                                      document.getElementById("btn").click();
                                    } else {
                                      alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                                    }
                                  }
                                }}
                              />
                            </td>
                            <td className="text-right">{this.state.total_price_by_item}</td>
                            <td className="text-center">
                              <button
                                id="btn"
                                className="btn btn-link text-secondary d-flex justify-content-center align-items-center"
                                style={{
                                  width: 24,
                                  height: 24,
                                  borderRadius: 12,
                                  backgroundColor: "#4E6FFB",
                                }}
                                onClick={() => {
                                  if (this.state.product_value && this.state.qty) {
                                    if (this.state.data.length === 0) {
                                      this.setState({
                                        data: [
                                          ...this.state.data,
                                          {
                                            product_code: this.state.product_value.product_code,
                                            product_name: this.state.product_value.product_name,
                                            product_color: this.state.product_value.product_color,
                                            product_design: this.state.product_value.product_design,
                                            qty: Number(this.state.qty),
                                            yard_qty: Number(this.state.yard_qty),
                                            price_by_item: Number(this.state.price_by_item),
                                            total_price_by_item: Number(this.state.total_price_by_item),
                                            check: true,
                                          },
                                        ],
                                        product_value: null,
                                        qty: "",
                                        yard_qty: "",
                                        price_by_item: "",
                                        total_price_by_item: "0.00",
                                      });
                                      setTimeout(() => {
                                        this.Calculate_Add_Price();
                                      }, 10);
                                    } else {
                                      this.Add_Product();
                                    }

                                    setTimeout(() => {
                                      this.inputRef.focus();
                                    }, 200);
                                  } else {
                                    alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                                  }
                                }}
                                ref={(node) => (this.btn = node)}
                                type="submit"
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <th className="text-center"></th>
                          <th className="text-center"></th>
                          <th className="text-center"></th>
                          <th className="text-center"></th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>ยอดรวม</b>
                          </th>
                          <th className="text-right">
                            <b>{this.state.data && baht(total(this.state.data, "yard_qty"))} หลา</b>
                          </th>
                          <th className="text-center">
                            <b>{this.state.data && total(this.state.data, "qty")} ไม้</b>
                          </th>
                          <th className="text-center"></th>
                          <th className="text-right">
                            <b>฿{this.state.data && baht(total(this.state.data, "total_price_by_item"))}</b>
                          </th>
                          <th className="text-center"></th>
                        </tfoot>
                      </table>
                      {this.state.alert === 1 && (
                        <div className="w-100 center">
                          <div className="card bg-yellow-warning mx-5 p-3 mb-3 wpx-300">
                            <div className=" row d-flex justify-content-center align-content-center p-2">
                              <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                              <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-fixed bottom-0 bg-white w-100  p-3" style={{ bottom: 0, right: 0, left: 0 }}>
          <div className="d-flex justify-content-end">
            <p className="mb-0 mr-3">
              รวมสินค้า {this.state.data.length} รายการ <b>฿{this.state.data && baht(total(this.state.data, "total_price_by_item"))}</b>
            </p>
            <div className="d-flex">
              <div className="w-100 d-flex ">
                <p className="mb-0 mx-auto">การอนุมัติ: </p>
                <div className="wpx-150 ml-4">
                  <select
                    className="form-control border-0 pointer"
                    onChange={(e) => {
                      this.setState({ status: e.target.value });
                    }}
                    value={this.state.status}
                  >
                    <option value="1">Approved</option>
                    <option value="0">Pending</option>
                  </select>
                </div>

                <button
                  className="btn btn-primary mx-2 px-3"
                  onClick={() => {
                    let data = this.state.data;
                    let count = data.filter((item) => item.qty === "" || item.yard_qty === "" || item.price_by_item === "");

                    if (this.state.date === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกวันที่รับเข้า");
                      return;
                    } else if (this.state.company === "") {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกผู้จำหน่าย");
                      return;
                    } else if (this.state.warehouse === "") {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกโกดังสินค้า");
                      return;
                    } else if (this.state.data.length <= 0) {
                      alert("warning", "แจ้งเตือน", "กรุณานำเข้าสินค้า");
                      return;
                    } else if (this.state.product_value !== null || this.state.qty !== "" || this.state.price_by_item !== "" || this.state.total_price_by_item !== "0.00") {
                      alert("warning", "แจ้งเตือน", "ยังไม่ได้เพิ่มรายการสินค้า กรุณาสอบสอบข้อมูลก่อนการบันทึก");
                      return;
                    }

                    if (count.length !== 0) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                    } else {
                      if (this.state.alert === 0) {
                        this.setState({ alert: 1 });
                      } else {
                        this.UpdateAddProduct();
                      }
                    }
                  }}
                >
                  ยืนยันการรับสินค้า
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* modal */}
        {/* DATE modal */}
        <Modal
          show={this.state.modal_date}
          onHide={() => {
            this.setState({ modal_date: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขวันที่รับ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* วันที่รับ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>วันที่รับ:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control border-0"
                  type="date"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ reciept_date: e.target.value });
                  }}
                  value={this.state.reciept_date}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_date: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({
                      date: this.state.reciept_date,
                      modal_date: false,
                    });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Company modal */}
        <Modal
          show={this.state.modal_supplier}
          onHide={() => {
            this.setState({ modal_supplier: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขบริษัทจำหน่าย</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* เลือกบริษัทจำหน่าย */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลือกบริษัทจำหน่าย</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ company_supplier_id: e.target.value });
                  }}
                  value={this.state.company_supplier_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.companylist &&
                    this.state.companylist.map((item, index) => (
                      <option value={item.company_id} key={index}>
                        {item.company_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_supplier: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.company_supplier_id === "") {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกบริษัทจำหน่าย");
                      return;
                    }
                    let company = this.state.companylist.find((e) => e.company_id === this.state.company_supplier_id).company_name;

                    this.setState({
                      company: company,
                      modal_supplier: false,
                    });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* refcode modal */}
        <Modal
          show={this.state.modal_ref}
          onHide={() => {
            this.setState({ modal_ref: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขเอกสารอ้างอิง</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* เลขเอกสารอ้างอิง */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label className="mr-2">เลขเอกสารอ้างอิง</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={20}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ ref_codes: e.target.value });
                  }}
                  value={this.state.ref_codes}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_ref: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({
                      ref_code: this.state.ref_codes,
                      modal_ref: false,
                    });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Warehouse modal */}
        <Modal
          show={this.state.modal_warehouse}
          onHide={() => {
            let warehouse_id = "";
            if (this.state.warehouse) {
              warehouse_id = this.state.warehouselist.find((e) => e.warehouse_name === this.state.warehouse).warehouse_id;
            }

            this.setState({
              warehouse_id: warehouse_id,
            });

            setTimeout(() => {
              this.GetProduct();
            }, 10);
            this.setState({ modal_warehouse: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขโกดังสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* เลือกโกดังสินค้า */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label className="mr-2">เลือกโกดังสินค้า</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ warehouse_id: e.target.value });
                  }}
                  value={this.state.warehouse_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.warehouselist &&
                    this.state.warehouselist.map((item, index) => (
                      <option value={item.warehouse_id} key={index}>
                        {item.warehouse_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    let warehouse_id = "";
                    if (this.state.warehouse) {
                      warehouse_id = this.state.warehouselist.find((e) => e.warehouse_name === this.state.warehouse).warehouse_id;
                    }

                    this.setState({
                      warehouse_id: warehouse_id,
                    });

                    setTimeout(() => {
                      this.GetProduct();
                    }, 10);
                    this.setState({ modal_warehouse: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.warehouse_id === "") {
                      alert("info", "แจ้งเตือน", "กรุณาเลือกโกดังสินค้า");
                      return;
                    }

                    let warehouse = this.state.warehouselist.find((e) => e.warehouse_id === this.state.warehouse_id).warehouse_name;

                    this.setState({
                      warehouse: warehouse,
                      modal_warehouse: false,
                    });

                    this.GetProduct();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
