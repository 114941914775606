import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { POST, tokens, type_member, baht, number } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Select, { components } from "react-select";

const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);
export default class RateMemberScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index_onClick: "",
      page: 1,
      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      //member
      data: null,
      search_member: "",
      member_type: "",

      //retemember
      details: null,
      search: "",
      id: "",

      //dropdown
      product_type: null,
      type_product_id: "",

      height: window.innerHeight,
    };
  }
  async componentDidMount() {
    await this.GetProductType();
    await this.GetMember();
  }
  GetProductType = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
    };
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", body);

    if (result && result.status) {
      this.setState({ product_type: result.data });
    }
    this.setState({ loading: false });
  };

  GetMember = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      user_type: this.state.member_type,
    };
    let result = await POST(tokens, "v1/user-member/user_rate_info/search/page/0", body);

    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  GetRateMember = async () => {
    this.setState({ loading: true });
    let body = {
      user_member_id: this.state.id,
      type_product_id: this.state.type_product_id,
      data_search: "",
    };

    let result = await POST(tokens, "v1/user-member/user_rate/search/page/0", body);

    if (result && result.status) {
      this.setState({ details: result.data });
    }
    this.setState({ loading: false });
  };

  ClearForm() {
    this.setState({
      page: 1,

      index_onClick: "",

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      search_member: "",
      search: "",
    });
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container bg-contain">
          <SidebarComponent />
          <div className="bodyw-100 bg-contain">
            <NavbarComponent page1="หน้าหลัก" page2="เรทสมาชิกตามรายการสินค้า" />
            <div style={{ height: 60 }}></div>
            <div className="row w-100 mt-3 mx-0">
              <div className="w-100 col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 mb-4">
                <div className="w-100 mb-3">
                  <h3>
                    <b>สมาชิก</b>
                  </h3>
                  <label className="text-msg text-14">ทั้งหมด {this.state.data && number(this.state.data.data.length)} รายการ</label>
                </div>
                <div className="w-100">
                  <div className="card">
                    <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16">
                      <div className="col-6 col-sm-6 col-xl-6 d-flex mb-2">
                        <div className="w-100">
                          <input
                            className="form-control-search "
                            type="search"
                            placeholder="พิมพ์คำค้นหา"
                            maxLength={30}
                            value={this.state.search_member}
                            onChange={(e) => {
                              this.setState({ page: 1, search_member: e.target.value, details: null, index_onClick: "", loading: false });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-xl-6 d-flex">
                        <div className="w-100">
                          <select
                            className="form-control-select pointer"
                            onChange={(e) => {
                              this.setState({
                                page: 1,
                                type_product_id: "",
                                member_type: e.target.value,
                                details: null,
                                index_onClick: "",
                              });

                              setTimeout(() => {
                                this.GetMember();
                              }, 20);
                            }}
                            value={this.state.member_type}
                          >
                            <option value="" selected>
                              ทุกประเภท
                            </option>
                            <option value="store_normol">ลูกค้าทั่วไป</option>
                            <option value="store_technician">ร้านค้าช่างเย็บ</option>
                            <option value="store_wholesale">ร้านค้าช่วง</option>
                            <option value="store_school">ลูกค้าโรงเรียน</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive rounded-16 height-vh overflow-auto">
                        <table className="table table-hover table-striped table-borderless">
                          <thead>
                            <tr className=" bg-white">
                              <th className="text-left" style={{ minWidth: 100 }}>
                                <b>สมาชิก</b>
                              </th>
                              <th className="text-center" style={{ minWidth: 150 }}>
                                <b>ประเภทสมาชิก</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                              <tr>
                                <td colSpan={2} className="text-center table-light height-vh">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}
                            {this.state.data &&
                              this.state.data.data
                                .filter((e) => e.full_name.includes(this.state.search_member))
                                .map((item, index) => (
                                  <tr
                                    key={index}
                                    className="pointer"
                                    onClick={() => {
                                      this.Input.clearValue();

                                      this.setState({
                                        details: null,
                                        id: item.user_id,
                                        index_onClick: index,
                                        page: 1,
                                        type_product_id: "",
                                        search: "",
                                      });

                                      setTimeout(() => {
                                        this.GetRateMember();
                                      }, 20);
                                    }}
                                  >
                                    <td className={index === this.state.index_onClick ? "text-left text-white bg-primary" : "text-left"}>{item.full_name}</td>
                                    <td className={index === this.state.index_onClick ? "text-center text-white bg-primary" : "text-center"}>{type_member(item.user_type)}</td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 mb-4 ">
                <div className="w-100 row mx-0 d-flex align-items-center justify-content-between mb-3">
                  <h3>
                    <b>เรทสมาชิกตามรายการสินค้า</b>
                  </h3>
                </div>
                <div className="card">
                  <div className="card-header row d-flex mx-0 px-2 justify-content-between rounded-top-16 ">
                    <div className="col-5 col-sm-5 col-md-5  col-lg-4  col-xl-4 d-flex mb-2">
                      <div className="w-100">
                        <input
                          maxLength={30}
                          className="form-control-search"
                          type="search"
                          placeholder="พิมพ์คำค้นหา"
                          value={this.state.search}
                          onChange={(e) => {
                            this.setState({ page: 1, search: e.target.value, loading: false });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-5 col-sm-5 col-md-5 col-lg-4  col-xl-4 d-flex mb-2">
                      <div className="w-100">
                        <Select
                          ref={(click) => (this.Input = click)}
                          className="px-0"
                          options={
                            this.state.product_type &&
                            this.state.product_type.data.map((item) => ({
                              value: item.type_product_id,
                              label: item.type_product_name,
                            }))
                          }
                          components={{ Option: TypeOption }}
                          styles={customStylesTypeOption}
                          placeholder="แสดงทุกประเภท"
                          defaultValue={this.state.type_product_id}
                          isClearable
                          onChange={(e) => {
                            if (this.state.details !== null) {
                              if (e === null) {
                                this.setState({ type_product_id: "", page: 1 });
                                setTimeout(() => {
                                  this.GetRateMember();
                                }, 20);
                              } else {
                                this.setState({ type_product_id: e.value, page: 1 });
                                setTimeout(() => {
                                  this.GetRateMember();
                                }, 20);
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0 min-vh-100">
                    <div className="table-responsive rounded-16 height-vh overflow-auto">
                      <table className="table table-striped table-borderless table-sm table-hover">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>รหัสสินค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 140 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>ประเภทผ้า</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>สี</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100 }}>
                              <b>ดีไซน์</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ราคาหน้าร้าน</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 100 }}>
                              <b>ราคาพิเศษ</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.details && this.state.details.data.length === 0) || !this.state.details) && (
                            <tr>
                              <td colSpan={7} className="text-center table-light height-vh">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                          {this.state.details &&
                            this.state.details.data
                              .filter((e) => e.relation_product.product_code.toLowerCase().includes(this.state.search.toLowerCase()) || e.relation_product.product_name.toLowerCase().includes(this.state.search.toLowerCase()) || e.relation_product.relation_type_product.includes(this.state.search))
                              .map((item, index) => (
                                <tr key={index} className="pointer">
                                  <td className="text-left">{item.relation_product.product_code}</td>
                                  <td className="text-left">{item.relation_product.product_name}</td>
                                  <td className="text-left">{item.relation_product.relation_type_product}</td>
                                  <td className="text-left">{item.relation_product.product_color}</td>
                                  <td className="text-left">{item.relation_product.product_design}</td>
                                  <td className="text-right">฿{baht(item.store_price)}</td>
                                  <td className="text-right">฿{baht(item.price)}</td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
