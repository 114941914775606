import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { alert, POST, tokens, alert_url, GET, baht, total, date_invoice_s, status_sale_transaction } from "../../../components/CustomComponent.js";
import WARNING from "../../../assets/images/warning.png";
import Select, { components } from "react-select";
import moment from "moment-timezone";
import "../../../assets/css/bootstrap.css";
const { Option } = components;
const customStylesTypeOption = {
  option: (provided, state) => ({
    ...provided,
    height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#F5F5F5",
    borderColor: state.isFocused ? "#F5F5F5" : "#F5F5F5",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#E1E1E1" : "#E1E1E1",
    },
    borderRadius: 8,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const TypeOption = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class RefundCreateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      return_warehouse: false,
      not_return_warehouse: false,

      select_all: false,
      selected: false,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      date_time: ("00" + new Date().getDate()).slice(-2) + " " + ("00" + (new Date().getMonth() + 1)).slice(-2) + " " + (new Date().getFullYear() + 543).toString().slice(-2) + "," + new Date().toTimeString("en-US"),
      bill_code: "PC" + new Date().getTime(),

      data: null,

      data_member: [],
      data_member_id: null,

      invoice_number_id: null,
      invoice_number: [],

      search: "",
      decision_remark: "",
      status_return_store: null,

      alert: 0,
      count: 0,
      timeZone: moment.tz.guess(),
    };
  }

  async componentDidMount() {
    await this.GetAll_Member();
  }
  GetAll_Member = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/refund/member-list", null);

    if (result && result.status) {
      this.setState({ data_member: result.data.data });
    }
    this.setState({ loading: false });
  };
  Get_All = async () => {
    this.setState({ loading: true });
    let member_id = this.state.data_member_id.value;
    let invoice_number_id = "";
    let result = await GET(tokens, "v1/refund/invoice-detail?member_id=" + member_id + "&invoice_number=" + invoice_number_id, null);
    if (result && result.status) {
      let data = result.data;
      let invoice_number = result.data.data.map(function (a) {
        let d = {
          label: a.invoice_number,
          value: a.invoice_number,
        };
        return d;
      });

      for (let d of data.data) {
        for (let order of d.order_list) {
          order.yard_detail = [""];
          order.wood_qty_refund = "";
          order.total_yard_qty = 0;
          order.wood_qty_check = order.wood_qty;
          order.yard_qty_check = order.yard_qty;
          order.price_by_item_check = order.price_by_item;
          order.total_price_check = order.total_price;
        }
      }

      this.setState({ data: data, invoice_number: invoice_number });
    }
    this.setState({ loading: false });
  };
  AddRefund = async () => {
    this.setState({ loading: true });
    let data = this.state.data.data;
    let array_data = [];
    let date_time = new Date().getFullYear() + "-" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("00" + new Date().getDate()).slice(-2) + " " + new Date().getHours() + ":" + ("00" + new Date().getMinutes()).slice(-2);
    let status_return_store = this.state.return_warehouse ? 1 : this.state.not_return_warehouse ? 2 : 0;

    // for (let item of data) {
    //     for (let order of item.order_list) {
    //         if (order.selected === true) {
    //             if (order.yard_qty - Number(order.total_yard_qty) < order.wood_qty - Number(order.wood_qty_refund)) {
    //                 alert("warning", "แจ้งเตือน", "จำนวนไม้ที่คืนไม่สอดคล้องกับจำนวนหลาที่คืน");
    //                 this.setState({ loading: false });
    //                 return
    //             }
    // if (order.wood_qty === Number(order.wood_qty_refund) && order.yard_qty - Number(order.total_yard_qty) !== 0) {
    //     alert("warning", "แจ้งเตือน", "จำนวนหลาที่คืนไม่สอดคล้องกับจำนวนไม้ที่คืน");
    //     this.setState({ loading: false });
    //     return
    // }
    //         }
    //     }
    // }
    data.forEach((item) => {
      item.order_lists = item.order_list
        .filter((order) => {
          return order.selected === true;
        })
        .map((d) => {
          return {
            yard_detail: d.yard_detail,
            warehouse_id: d.warehouse_id,
            product_id: d.product_id,
            product_name: d.product_name,
            product_code: d.product_code,
            cost_price: Number(d.cost_price),
            wholesale_price: Number(d.wholesale_price),
            wood_qty: Number(d.wood_qty_refund),
            yard_qty: Number(d.total_yard_qty),
            price_by_item: Number(d.price_by_item),
            total_price: Number(d.total_price),
          };
        });
      item.net_total = total(item.order_lists, "total_price");
    });
    data.forEach((e) => {
      if (e.order_lists.length !== 0) {
        let t = {
          invoice_number: e.invoice_number,
          member_id: e.member_id,
          member_name: e.member_name,
          net_total: e.net_total,
          order_list: e.order_lists,
        };
        array_data.push(t);
      }
    });
    setTimeout(async () => {
      let body = {
        remark: this.state.decision_remark,
        pick_up_datetime: date_time,
        status_return_store: status_return_store,
        refund_list: array_data,
        time_zone: this.state.timeZone,
      };
      let result = await POST(tokens, "v1/refund/create", body);
      if (result && result.status) {
        alert_url("success", "บันทึกข้อมูลสำเร็จ", "", " /refund");
        this.setState({ loading: false, modal_add: false });
      }
      this.setState({ loading: false });
    }, 10);
  };
  ClearForm() {
    this.setState({
      page: 1,

      select_all: false,
      selected: false,
      return_warehouse: false,
      not_return_warehouse: false,

      data_member: [],
      data_member_id: null,

      invoice_number_id: null,
      invoice_number: [],

      id: "",
      decision_remark: "",
      alert: 0,
    });
  }

  total_all = (type) => {
    let total_yard_qty = 0;
    let price_by_item = 0;
    let total_price = 0;

    let data =
      this.state.data &&
      this.state.data.data
        .filter((item) => item.invoice_number.includes(this.state.invoice_number_id && this.state.invoice_number_id.value) || this.state.invoice_number_id === null)
        .filter((item) => item.member_id === (this.state.data_member_id && this.state.data_member_id.value) || this.state.data_member_id === null)
        .filter(
          (item) =>
            JSON.stringify(
              item.order_list.map((e) => {
                return e.product_name && e.product_name !== "ส่วนลด";
              })
            ).includes(this.state.search) || this.state.search === ""
        );
    if (data) {
      for (let item of data) {
        let order =
          item.order_list &&
          item.order_list
            .filter((data) => data.product_name.includes(this.state.search) || this.state.search === "")
            .filter((item) => {
              return item.product_code !== "PD-0000";
            });

        for (let order_list of order) {
          total_yard_qty += Number(order_list.total_yard_qty);
          price_by_item += Number(order_list.wood_qty_refund);
          total_price += Number(order_list.total_yard_qty * order_list.price_by_item);
        }
      }
    }

    if (type === "total_yard_qty") {
      return baht(total_yard_qty);
    } else if (type === "price_by_item") {
      return baht(price_by_item);
    } else if (type === "total_price") {
      return baht(total_price);
    }
  };
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100 position-relative" style={{ height: "100%", paddingBottom: "76px" }}>
            <NavbarComponent page1="หน้าหลัก" page2="คืนสินค้า" page3="เพิ่มรายการคืนสินค้า" />
            <div style={{ height: 60 }}></div>
            <div className="mx-4 mt-3 mb-3">
              {/* header */}
              <div className="w-100 row mx-0 d-flex align-items-center justify-content-between ">
                <h3>
                  <b>คืนสินค้า</b>
                </h3>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 px-4"
                    onClick={() => {
                      window.location.href = "/refund";
                    }}
                  >
                    {" "}
                    <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ{" "}
                  </button>
                </div>
              </div>

              {/* date */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card-header row d-flex mx-0 justify-content-start align-items-center bg-secondary rounded-top-16">
                  <div className="d-flex row align-items-center justify-content-start">
                    <div className="mx-5 ">
                      <label>วันที่: {date_invoice_s(this.state.date_time)}</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* title */}
              <div className="w-100 mx-0 mt-3 mb-3">
                <div className="card p-3">
                  <div className="d-flex row align-items-center justify-content-between">
                    <div className="col-6 col-lg-6 col-xl-6">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">ค้นหาชื่อลูกค้า:</label>
                          <Select
                            ref={(click) => (this.Input_member = click)}
                            className="px-0 mb-3"
                            options={
                              this.state.data_member &&
                              this.state.data_member.map((item) => ({
                                value: item.member_id,
                                label: item.member_name,
                              }))
                            }
                            styles={customStylesTypeOption}
                            components={{ Option: TypeOption }}
                            placeholder="ชื่อลูกค้า"
                            isClearable
                            defaultValue={this.state.data_member_id && this.state.data_member_id.value ? this.state.data_member_id.value : null}
                            onChange={(e) => {
                              if (e !== null) {
                                this.setState({ data_member_id: e, alert: 0 });
                                setTimeout(() => {
                                  this.Get_All();
                                }, 10);
                              } else {
                                this.Input_number.clearValue();
                                this.setState({
                                  data_member_id: null,
                                  data: null,
                                  invoice_number_id: null,
                                  invoice_number: [],
                                  alert: 0,
                                  search: "",
                                  count: 0,
                                });
                                setTimeout(() => {
                                  this.GetAll_Member();
                                }, 10);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6">
                      <div className="w-100 mt-3">
                        <div className="w-100">
                          <label className="w-100">ค้นหาเลขที่เอกสาร:</label>
                          <Select
                            ref={(click) => (this.Input_number = click)}
                            className="px-0 mb-3"
                            options={this.state.invoice_number}
                            styles={customStylesTypeOption}
                            components={{ Option: TypeOption }}
                            placeholder="ค้นหาเลขที่เอกสาร"
                            defaultValue={this.state.invoice_number_id}
                            isClearable
                            onChange={(e) => {
                              if (e !== null) {
                                this.setState({ invoice_number_id: e, alert: 0 });
                              } else {
                                this.setState({ invoice_number_id: null, alert: 0, count: 0, search: "" });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 pt-3">
                <div className="card ">
                  <div className="card-header row d-flex mx-0 px-2 rounded-top-16  justify-content-between">
                    <div className="col-6 col-sm-6 col-xl-3 ">
                      <div className="w-100 mb-2">
                        <input
                          maxLength={30}
                          className="form-control-search"
                          type="search"
                          placeholder="พิมพ์คำค้นหา"
                          value={this.state.search}
                          onChange={(e) => {
                            this.setState({ search: e.target.value, loading: false });
                          }}
                        />
                      </div>
                      <label className="text-dark text-18">เลือก {this.state.count ? this.state.count : 0} รายการ</label>
                    </div>

                    {this.state.alert === 1 && (
                      <div className="card bg-yellow-warning mx-5 p-3 mb-3 wpx-300">
                        <div className=" row d-flex justify-content-center align-content-center p-2">
                          <img className="warning-icon mr-2" alt="logo" src={WARNING}></img>
                          <label className="text-yellow-warning text-16">กรุณาตรวจสอบข้อมูลก่อนการบันทึก</label>
                        </div>
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-primary bg-white"
                      onClick={() => {
                        if (this.state.data) {
                          let data = this.state.data;
                          for (let d of data.data) {
                            for (let order of d.order_list) {
                              order.yard_detail = [""];
                              order.total_yard_qty = 0;
                              order.wood_qty_refund = "";
                              order.total_price = order.total_price_check;

                              order.selected = false;
                            }
                          }
                          setTimeout(() => {
                            this.setState({
                              data: data,
                              selected: false,
                              select_all: false,
                              alert: 0,
                              search: "",
                              count: 0,
                            });
                          }, 10);
                        }
                      }}
                    >
                      เคลียร์การเลือก
                    </button>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive rounded-16 " style={{ maxHeight: 300 }}>
                      <table className="table table-striped table-borderless table-sm tableFixHead">
                        <thead>
                          <tr>
                            <th className="text-center" style={{ minWidth: 50 }}>
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                onChange={() => {
                                  if (this.state.data && this.state.data.data.length !== 0) {
                                    let array = [];
                                    let data = this.state.data;
                                    for (let d of data.data) {
                                      if (
                                        (d.invoice_number.includes(this.state.invoice_number_id && this.state.invoice_number_id.value) || this.state.invoice_number_id === null) &&
                                        (d.member_id === (this.state.data_member_id && this.state.data_member_id.value) || this.state.data_member_id === null) &&
                                        (JSON.stringify(
                                          d.order_list.map((e) => {
                                            return e.product_name;
                                          })
                                        ).includes(this.state.search) ||
                                          this.state.search === "")
                                      ) {
                                        for (let order of d.order_list) {
                                          order.yard_detail = [""];
                                          order.total_yard_qty = 0;
                                          order.wood_qty_refund = "";
                                          order.total_price = order.total_price_check;
                                          order.selected = !this.state.select_all;

                                          array.push(order);
                                        }
                                      }
                                    }

                                    let selected = array.filter((item) => item.selected === true).length;

                                    setTimeout(() => {
                                      this.setState({
                                        data: data,
                                        selected: !this.state.select_all,
                                        select_all: !this.state.select_all,
                                        count: selected,
                                      });
                                    }, 10);
                                  }
                                }}
                                checked={this.state.select_all}
                                value={this.state.select_all}
                              ></input>
                            </th>
                            <th className="text-left" style={{ minWidth: 80, maxHeight: 80 }}>
                              <b>เลขที่เอกสาร</b>
                            </th>
                            <th className="text-left" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>ชื่อสินค้า</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>จำนวนหลาที่ซื้อ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 100, maxHeight: 100 }}>
                              <b>จำนวนไม้ที่ซื้อ</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนหลาที่คืน</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>จำนวนไม้ที่คืน</b>
                            </th>
                            <th className="text-center" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>รวม</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>ราคาต่อหน่วย</b>
                            </th>
                            <th className="text-right" style={{ minWidth: 90, maxHeight: 80 }}>
                              <b>ยอดรวมสุทธิ</b>
                            </th>
                          </tr>
                        </thead>

                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tbody>
                            <tr>
                              <td colSpan={10} className="text-center table-light ">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          </tbody>
                        )}
                        {this.state.data &&
                          this.state.data.data
                            .filter((item) => item.invoice_number.includes(this.state.invoice_number_id && this.state.invoice_number_id.value) || this.state.invoice_number_id === null)
                            .filter((item) => item.member_id === (this.state.data_member_id && this.state.data_member_id.value) || this.state.data_member_id === null)
                            .filter(
                              (item) =>
                                JSON.stringify(
                                  item.order_list.map((e) => {
                                    return e.product_name;
                                  })
                                ).includes(this.state.search) ||
                                item.invoice_number.includes(this.state.search) ||
                                this.state.search === ""
                            )
                            .map((item, index) => (
                              <tbody>
                                <tr key={index}>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray pointer"} style={{ minWidth: 100, maxHeight: 100 }}>
                                    {item.invoice_number}
                                  </td>
                                  <td className={"text-left bg-light-gray"} style={{ minWidth: 200 }}>
                                    {status_sale_transaction(item.status_bill)}
                                  </td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"} style={{ minWidth: 110 }}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                  <td className={"text-center bg-light-gray"}></td>
                                </tr>
                                {item.order_list &&
                                  item.order_list
                                    .filter((data) => data.product_name.includes(this.state.search) || item.invoice_number.includes(this.state.search) || this.state.search === "")
                                    .map((order_list, order_list_index) => (
                                      <tr key={order_list_index} className="pointer">
                                        <td className={order_list.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>
                                          <input
                                            type="checkbox"
                                            onChange={() => {
                                              let data = this.state.data;

                                              let id_invoice = this.state.data.data.findIndex((e) => e.invoice_number === item.invoice_number);
                                              let id_product = this.state.data.data[id_invoice].order_list.findIndex((e) => e.product_id === order_list.product_id);

                                              data.data[id_invoice].order_list[id_product].selected = !data.data[id_invoice].order_list[id_product].selected;
                                              data.data[id_invoice].order_list[id_product].yard_detail = [""];
                                              data.data[id_invoice].order_list[id_product].total_yard_qty = 0;
                                              data.data[id_invoice].order_list[id_product].wood_qty_refund = "";
                                              data.data[id_invoice].order_list[id_product].total_price = data.data[id_invoice].order_list[id_product].total_price_check;

                                              if (!data.data[id_invoice].order_list[id_product].selected) {
                                                this.setState({ select_all: false });
                                              }

                                              let array = [];
                                              for (let d of data.data) {
                                                for (let order of d.order_list) {
                                                  array.push(order);
                                                }
                                              }
                                              let selected = array.filter((item) => item.selected === true).length;

                                              setTimeout(() => {
                                                this.setState({ data: data, alert: 0, count: selected });
                                              }, 10);
                                            }}
                                            checked={order_list.selected}
                                            value={order_list.selected}
                                          ></input>
                                        </td>
                                        <td className={order_list.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}></td>
                                        <td className={order_list.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"} style={{ minWidth: 100 }}>
                                          {order_list.product_name}
                                        </td>
                                        <td className={order_list.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>{baht(order_list.yard_qty)}</td>
                                        <td className={order_list.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>{order_list.wood_qty}</td>
                                        <td className={order_list.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>
                                          <div className="row w-100 d-flex justify-content-center mx-0" style={{ minWidth: 200, maxWidth: 50 }}>
                                            {order_list.yard_detail &&
                                              order_list.yard_detail.map((yard, i) => (
                                                <>
                                                  <input
                                                    className="border wpx-40 hpx-40 form-control bg-secondary-light mx-1 mb-1 px-1 text-center"
                                                    key={i}
                                                    type="text"
                                                    placeholder="ระบุ"
                                                    disabled={order_list.selected ? false : true}
                                                    value={yard}
                                                    autoFocus
                                                    onChange={(e) => {
                                                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                                        let data = this.state.data;
                                                        let id_invoice = this.state.data.data.findIndex((e) => e.invoice_number === item.invoice_number);
                                                        let id_product = this.state.data.data[id_invoice].order_list.findIndex((e) => e.product_id === order_list.product_id);

                                                        const old = data.data[id_invoice].order_list[id_product].yard_detail[i];
                                                        data.data[id_invoice].order_list[id_product].yard_detail[i] = e.target.value;
                                                        let total_yard_qty = total(data.data[id_invoice].order_list[id_product].yard_detail);
                                                        if (Number(total_yard_qty) > Number(data.data[id_invoice].order_list[id_product].yard_qty)) {
                                                          alert("warning", "แจ้งเตือน", "คืนสินสินค้าได้ไม่เกิน " + baht(data.data[id_invoice].order_list[id_product].yard_qty) + " หลา");
                                                          data.data[id_invoice].order_list[id_product].yard_detail[i] = old;
                                                          setTimeout(() => {
                                                            this.setState({ data: data, alert: 0 });
                                                          }, 10);
                                                          return;
                                                        }
                                                        data.data[id_invoice].order_list[id_product].total_yard_qty = total_yard_qty;
                                                        data.data[id_invoice].order_list[id_product].total_price = Number(total_yard_qty) * Number(data.data[id_invoice].order_list[id_product].price_by_item);
                                                        if (order_list.yard_qty - total_yard_qty < order_list.wood_qty) {
                                                          if (order_list.yard_qty - total_yard_qty === 0) {
                                                            data.data[id_invoice].order_list[id_product].wood_qty_refund = order_list.wood_qty;
                                                          } else {
                                                            data.data[id_invoice].order_list[id_product].wood_qty_refund = order_list.wood_qty - (order_list.yard_qty - total_yard_qty);
                                                          }
                                                        }
                                                        setTimeout(() => {
                                                          this.setState({ data: data, alert: 0 });
                                                        }, 10);
                                                      }
                                                    }}
                                                    onKeyUp={(e) => {
                                                      if (e.key === "Enter") {
                                                        let data = this.state.data;
                                                        let id_invoice = this.state.data.data.findIndex((e) => e.invoice_number === item.invoice_number);
                                                        let id_product = this.state.data.data[id_invoice].order_list.findIndex((e) => e.product_id === order_list.product_id);
                                                        // if (data.data[index].order_list[order_list_index].yard_detail.length >= data.data[index].order_list[order_list_index].wood_qty) {
                                                        //     alert("warning", "แจ้งเตือน", "คืนสินสินค้าได้ไม่เกิน " + data.data[index].order_list[order_list_index].wood_qty_check + " ไม้")
                                                        //     return
                                                        // }
                                                        data.data[id_invoice].order_list[id_product].yard_detail = [...data.data[id_invoice].order_list[id_product].yard_detail, ""];

                                                        setTimeout(() => {
                                                          this.setState({ data: data, alert: 0 });
                                                        }, 10);
                                                      }
                                                    }}
                                                  />
                                                  <div style={{ marginTop: -10, marginLeft: -14 }}>
                                                    <label
                                                      onClick={() => {
                                                        let data = this.state.data;
                                                        let id_invoice = this.state.data.data.findIndex((e) => e.invoice_number === item.invoice_number);
                                                        let id_product = this.state.data.data[id_invoice].order_list.findIndex((e) => e.product_id === order_list.product_id);

                                                        if (data.data[id_invoice].order_list[id_product].yard_detail.length > 1) {
                                                          data.data[id_invoice].order_list[id_product].yard_detail.splice(i, 1);
                                                          let total_yard_qty = total(data.data[index].order_list[id_product].yard_detail);
                                                          data.data[id_invoice].order_list[id_product].total_yard_qty = total_yard_qty;
                                                          data.data[id_invoice].order_list[id_product].total_price = Number(total_yard_qty) * Number(data.data[id_invoice].order_list[id_product].price_by_item);
                                                          setTimeout(() => {
                                                            this.setState({ data: data, alert: 0 });
                                                          }, 10);
                                                        }
                                                      }}
                                                      className="icon bg-danger text-12 text-white text-center pb-0"
                                                      style={{ width: 16, height: 16, borderRadius: 16 }}
                                                    >
                                                      {"\uf00d"}
                                                    </label>
                                                  </div>
                                                </>
                                              ))}
                                          </div>
                                        </td>
                                        <td className={order_list.selected ? "text-left bg-primary-lights border-bottom border-secondary" : "text-left"}>
                                          <input
                                            maxLength={14}
                                            type="text"
                                            className="border wpx-100 hpx-38 form-control bg-white text-right "
                                            onChange={(e) => {
                                              if (/^\d*?\d{0}$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) < 999999999) {
                                                let data = this.state.data;
                                                let id_invoice = this.state.data.data.findIndex((e) => e.invoice_number === item.invoice_number);
                                                let id_product = this.state.data.data[id_invoice].order_list.findIndex((e) => e.product_id === order_list.product_id);

                                                data.data[id_invoice].order_list[id_product].wood_qty_refund = e.target.value;
                                                if (Number(e.target.value) > Number(data.data[id_invoice].order_list[id_product].wood_qty_check)) {
                                                  alert("warning", "แจ้งเตือน", "คืนสินสินค้าได้ไม่เกิน " + data.data[id_invoice].order_list[id_product].wood_qty_check + " ไม้");
                                                  data.data[id_invoice].order_list[id_product].wood_qty_refund = data.data[id_invoice].order_list[id_product].wood_qty_check;
                                                  setTimeout(() => {
                                                    this.setState({ data: data, alert: 0 });
                                                  }, 10);
                                                  return;
                                                }
                                                setTimeout(() => {
                                                  this.setState({ data: data, alert: 0 });
                                                }, 10);
                                              }
                                            }}
                                            value={order_list.wood_qty_refund}
                                            disabled={order_list.selected ? false : true}
                                          />
                                        </td>
                                        <td className={order_list.selected ? "text-center bg-primary-lights border-bottom border-secondary" : "text-center"}>{baht(order_list.total_yard_qty)}</td>
                                        <td className={order_list.selected ? "text-right bg-primary-lights border-bottom border-secondary" : "text-right"}>{baht(order_list.price_by_item)}</td>
                                        <td className={order_list.selected ? "text-right bg-primary-lights border-bottom border-secondary" : "text-right"}>{baht(order_list.total_yard_qty * order_list.price_by_item)} </td>
                                      </tr>
                                    ))}
                              </tbody>
                            ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bodyw-100 position-fixed bottom-0 bg-white w-100 p-3 px-5" style={{ bottom: 0, right: 0 }}>
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="d-flex">
              <h6>สรุปรายการคืน :</h6>
              <h6 className="ml-3">{this.total_all("total_yard_qty")} หลา</h6>
              <h6 className="ml-3">{this.total_all("price_by_item")} ไม้</h6>
              <h6 className="ml-3">{this.total_all("total_price")} บาท</h6>
            </div>
            <div className="d-flex w-100 justify-content-end align-items-center">
              <div className="d-flex justify-content-between">
                <div className="d-flex w-100 justify-content-end">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ return_warehouse: !this.state.return_warehouse, not_return_warehouse: false });
                    }}
                    checked={this.state.return_warehouse}
                  />
                  <label className="text-dark mx-2 ">คืนเข้าโกดัง</label>

                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ not_return_warehouse: !this.state.not_return_warehouse, return_warehouse: false });
                    }}
                    checked={this.state.not_return_warehouse}
                  />
                  <label className="text-dark ml-2">ไม่คืนเข้าโกดัง</label>
                </div>
              </div>

              <div className="d-flex justify-content-between mx-2 ">
                <label className="text-dark mx-2 mt-2">หมายเหตุ</label>
                <div className="d-flex w-100 justify-content-end">
                  <input
                    type="text"
                    className="form-control border-0 "
                    placeholder="อธิบายพอสังเขป"
                    maxLength={50}
                    onChange={(e) => {
                      this.setState({ decision_remark: e.target.value, alert: 0 });
                    }}
                    value={this.state.decision_remark}
                  ></input>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {
                    if (this.state.data) {
                      let data = this.state.data;
                      let array = [];
                      for (let d of data.data) {
                        for (let order of d.order_list) {
                          array.push(order);
                        }
                      }
                      let selected = array.filter((item) => item.selected === true);
                      let count = array.filter((item) => item.selected === true).filter((item) => item.yard_detail.indexOf("") !== -1 || item.yard_detail.indexOf("0") !== -1);

                      if (selected.length === 0) {
                        alert("warning", "แจ้งเตือน", "กรุณาเลือกรายการ");
                        return;
                      } else if (count.length !== 0) {
                        alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน");
                      } else if (this.state.not_return_warehouse === false && this.state.return_warehouse === false) {
                        alert("warning", "แจ้งเตือน", "กรุณาเลือกการคืนสินค้าเข้าโกดัง หรือไม่คืนเข้าโกดัง");
                        return;
                      } else {
                        if (this.state.alert === 0) {
                          this.setState({ alert: 1 });
                        } else {
                          this.AddRefund();
                        }
                      }
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
