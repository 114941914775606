import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, PUT, DELETE, tokens, role, GET } from "../../../components/CustomComponent.js";
import "../../../assets/css/bootstrap.css";
export default class ExpensesTypeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,

      expense_category_name: "",
      expense_category_description: "",

      id: "",
      search: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }
  GetAll = async () => {
    this.setState({ loading: true });

    let result = await GET(tokens, "v1/expense-mgt/expense_category/filter-search/page/" + this.state.page + "?data_search=" + this.state.search, null);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  AddExpensesType = async () => {
    this.setState({ loading: true });
    let body = {
      expense_category_name: this.state.expense_category_name,
      expense_category_description: this.state.expense_category_description,
    };
    let result = await POST(tokens, "v1/expense-mgt/expense_category/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มหมวดหมู่ค่าใช้จ่ายเรียบร้อยแล้ว", "หมวดหมู่ค่าใช้จ่าย ' " + this.state.expense_category_name + " ' ถูกเพิ่มในระบบแล้ว");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  EditExpensesType = async () => {
    this.setState({ loading: true });
    let body = {
      expense_category_id: this.state.id,
      expense_category_name: this.state.expense_category_name,
      expense_category_description: this.state.expense_category_description,
    };
    let result = await PUT(tokens, "v1/expense-mgt/expense_category/update", body);
    if (result && result.status) {
      alert("success", "แก้ไขหมวดหมู่ค่าใช้จ่ายเรียบร้อยแล้ว", "หมวดหมู่ค่าใช้จ่าย ' " + this.state.expense_category_name + " ' ถูกแก้ไขในระบบแล้ว");
      this.setState({ loading: false, modal_edit: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/expense-mgt/expense_category/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลโกดังสินค้าเรียบร้อยแล้ว", "โกดังสินค้า ' " + this.state.expense_category_name + " ' ถูกลบข้อมูลในระบบแล้ว");

      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      expense_category_name: "",
      expense_category_description: "",

      id: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="จัดการค่าใช้จ่าย" page3="หมวดหมู่ค่าใช้จ่าย" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>หมวดหมู่ค่าใช้จ่าย</b>
              </h3>
              {role.type_expenses[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.setState({ modal_add: true });
                    }}
                  >
                    {" "}
                    เพิ่มหมวดหมู่ค่าใช้จ่าย{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        type="search"
                        className="form-control-search"
                        placeholder="พิมพ์คำค้นหา"
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                          if (e.target.value === "") {
                            this.setState({ page: 1, search: "" });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }
                        }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            this.setState({
                              page: 1,
                            });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }
                        }}
                        value={this.state.search}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0 ">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-hover table-sm">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>ชื่อหมวดหมู่</b>
                          </th>
                          <th className="text-left">
                            <b>รายละเอียด</b>
                          </th>

                          {(role.type_expenses[2] === 1 || role.type_expenses[3] === 1) && (
                            <th className="text-center">
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={3} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.expense_category_name}</td>
                              <td className="text-left">{item.expense_category_description}</td>
                              <td className="text-center">
                                {item.expense_category_name !== "ปรับสต๊อก" && role.type_expenses[2] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary"
                                    onClick={() => {
                                      this.setState({
                                        expense_category_name: item.expense_category_name,
                                        expense_category_description: item.expense_category_description,
                                        id: item.expense_category_id,
                                        modal_edit: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2">{"\uf040"}</label>
                                  </button>
                                )}

                                {item.expense_category_name !== "ปรับสต๊อก" && role.type_expenses[3] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary"
                                    onClick={() => {
                                      this.setState({
                                        expense_category_name: item.expense_category_name,
                                        id: item.expense_category_id,
                                        modal_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2">{"\uf2ed"}</label>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {this.state.data.end_index} รายการจาก {this.state.data.count} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* เพิ่มประเภทค่าใช้จ่าย */}
        <Modal
          show={this.state.modal_add}
          size="m"
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เพิ่มหมวดหมู่ค่าใช้จ่าย</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อหมวดหมู่:</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ expense_category_name: e.target.value });
                  }}
                  value={this.state.expense_category_name}
                />
              </div>
            </div>
            {/* รายละเอียด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายละเอียด:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      expense_category_description: e.target.value,
                    });
                  }}
                  value={this.state.expense_category_description}
                ></textarea>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.expense_category_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อหมวดหมู่");
                      return;
                    } else if (this.state.expense_category_description === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรายละเอียด");
                      return;
                    }

                    this.AddExpensesType();
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* แก้ไขหมวดหมู่ค่าใช้จ่าย */}
        <Modal
          show={this.state.modal_edit}
          size="m"
          onHide={() => {
            this.setState({ modal_edit: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขหมวดหมู่ค่าใช้จ่าย</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อหมวดหมู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อหมวดหมู่:</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ expense_category_name: e.target.value });
                  }}
                  value={this.state.expense_category_name}
                />
              </div>
            </div>
            {/* รายละเอียด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายละเอียด:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      expense_category_description: e.target.value,
                    });
                  }}
                  value={this.state.expense_category_description}
                ></textarea>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.expense_category_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อหมวดหมู่");
                      return;
                    } else if (this.state.expense_category_description === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรายละเอียด");
                      return;
                    }

                    this.EditExpensesType();
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ยืนยันการลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          size="m"
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ลบหมวดหมู่ค่าใช้จ่าย</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ใจว่าต้องการลบหมวดหมู่ค่าใช้จ่ายนี้หรือไม่</div>
              <div className="col-12 mb-3">หมายเหตุ: ไม่สามารถย้อนคืนข้อมูลได้หลักจากลบ</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
