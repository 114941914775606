/* eslint-disable no-mixed-operators */
import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, PUT, DELETE, tokens, type_user, role, number } from "../../components/CustomComponent.js";

import "../../assets/css/bootstrap.css";

export default class UserScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,
      data_role: null,

      search: "",
      filter_type: "",

      id: "",

      user_code: "",

      user_type: "",
      role_id: "",
      full_name: "",
      address: "",
      email: "",
      phone: "",
      password: "",
      picture: "",

      user_type_login: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).user_type : "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
    await this.GetRole();
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search.trim(),
      user_type: this.state.filter_type,
    };
    let result = await POST(tokens, "v1/user-admin/filter-search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };
  GetRole = async () => {
    this.setState({ loading: true });
    let body = {
      search: this.state.search,
    };
    let result = await POST(tokens, "v1/user-admin/role/filter-search/page/0", body);
    if (result && result.status) {
      this.setState({ data_role: result.data });
    }
    this.setState({ loading: false });
  };
  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/user-admin/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลผู้ใช้งานเรียบร้อยแล้ว", "ผู้ใช้งาน ' " + this.state.full_name + " ' ถูกลบข้อมูลในระบบแล้ว");
      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  AddUser = async () => {
    this.setState({ loading: true });
    let body = {
      user_type: this.state.user_type,
      role_id: this.state.role_id,
      full_name: this.state.full_name,
      address: this.state.address,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      picture: "",
    };
    let result = await POST(tokens, "v1/user-admin/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มผู้ใช้งานเรียบร้อยแล้ว", "ผู้ใช้งาน ' " + this.state.full_name + " ' ถูกเพิ่มในระบบแล้ว");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  EditUser = async () => {
    this.setState({ loading: true });
    let body = {
      user_id: this.state.id,
      user_type: this.state.user_type,
      role_id: this.state.role_id,
      full_name: this.state.full_name,
      address: this.state.address,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      picture: "",
    };
    let result = await PUT(tokens, "v1/user-admin/update", body);
    if (result && result.status) {
      alert("success", "แก้ไขข้อมูลผู้ใช้งานเรียบร้อยแล้ว", "ผู้ใช้งาน ' " + this.state.full_name + " ' ถูกแก้ไขข้อมูลในระบบแล้ว");
      this.setState({ loading: false, modal_edit: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  ClearForm() {
    this.setState({
      loading: false,

      filter_type: "",

      user_id: "",
      user_type: "",
      role_id: "",
      full_name: "",
      address: "",
      email: "",
      phone: "",
      password: "",
      picture: "",
      search: "",

      page: 1,
    });
  }
  GenPassword = () => {
    let result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.setState({ password: result });
  };
  ValiateEmail = (email) => {
    return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };
  ValidatePhone = (phone) => {
    return phone.match(/^^[0][0-9]*$/);
  };

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายชื่อผู้ใช้ทั้งหมด" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายชื่อผู้ใช้ทั้งหมด</b>
              </h3>

              {role.user_admin[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.ClearForm();
                      this.setState({ modal_add: true });
                    }}
                  >
                    {" "}
                    เพิ่มผู้ใช้งาน{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-3 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        className="form-control-search"
                        type="search"
                        maxLength={30}
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-sm-3 col-xl-3 d-flex mb-2">
                    <div className="w-100">
                      <select
                        className="form-control-select pointer"
                        onChange={(e) => {
                          this.setState({ page: 1, filter_type: e.target.value });
                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                        value={this.state.filter_type}
                      >
                        <option value="" selected>
                          แสดงผู้ใช้ทุกประเภท
                        </option>
                        <option value="emp_sale">พนักงานขาย</option>
                        <option value="emp_stock">พนักงานสต๊อก</option>
                        <option value="emp_delivery">พนักงานสายส่ง</option>
                        <option value="manager">ผู้จัดการ</option>
                        <option value="owner">เจ้าของร้าน</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>รหัสสมาชิก</b>
                          </th>
                          <th className="text-left">
                            <b>ชื่อผู้ใช้</b>
                          </th>
                          <th className="text-left">
                            <b>เบอร์โทร</b>
                          </th>
                          <th className="text-center">
                            <b>บทบาท</b>
                          </th>
                          <th className="text-center">
                            <b>ประเภทผู้ใช้</b>
                          </th>
                          {((role && role.user_admin[2] === 1) || (role && role.user_admin[3] === 1)) && (
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={6} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{item.user_code}</td>
                              <td className="text-left">{item.full_name}</td>
                              <td className="text-left">{item.phone}</td>
                              <td className="text-center" style={{ minWidth: 130 }}>
                                {item.role_data.role_name}
                              </td>
                              <td className="text-center" style={{ minWidth: 130 }}>
                                {type_user(item.user_type)}
                              </td>
                              <td className="text-center">
                                {role && role.user_admin[2] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        id: item.user_id,
                                        user_code: item.user_code,
                                        full_name: item.full_name,
                                        user_type: item.user_type,
                                        role_id: item.role_data.role_id,
                                        address: item.address,
                                        email: item.email,
                                        phone: item.phone,
                                        picture: item.picture,
                                        modal_edit: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2">{"\uf040"}</label>
                                  </button>
                                )}
                                {role && role.user_admin[3] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        full_name: item.full_name,
                                        id: item.user_id,
                                        modal_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2">{"\uf2ed"}</label>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        <label className="icon pointer">{"\uf100"}</label>
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* เพิ่มผู้ใช้งาน */}
        <Modal
          show={this.state.modal_add}
          size="m"
          onHide={() => {
            this.setState({ modal_add: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เพิ่มผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100 ">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value });
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="emp_sale">พนักงานขาย</option>
                  <option value="emp_stock">พนักงานสต๊อก</option>
                  <option value="emp_delivery">พนักงานสายส่ง</option>
                  <option value="manager">ผู้จัดการ</option>
                  {this.state.user_type_login === "owner" ? <option value="owner">เจ้าของร้าน</option> : null}
                </select>
              </div>
            </div>
            {/* บทบาท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>บทบาท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ role_id: e.target.value });
                  }}
                  value={this.state.role_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_role &&
                    this.state.data_role.data.map((item, index) => (
                      <option value={item.role_id} key={index}>
                        {item.role_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  maxLength={30}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value });
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  maxLength={50}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone;
                    phone = e.target.value.replace(/\D/g, "");
                    this.setState({
                      phone: phone,
                    });
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="email"
                  maxLength={50}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                  value={this.state.email}
                />
              </div>
            </div>
            {/* รหัสผ่าน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสผ่าน:</label>
              </div>

              <div className="w-100">
                {this.state.password === "" ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray pointer"
                    onClick={() => {
                      this.GenPassword();
                    }}
                  >
                    <h5>
                      <b className="text-primary">คลิกที่นี่เพื่อสุ่มรหัสผ่าน</b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    className="form-control bg-secondary-light border-0"
                    type="text"
                    placeholder="สุ่มรหัสผ่าน"
                    readOnly
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                    value={this.state.password}
                  />
                )}
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.user_type === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกประเภท");
                      return;
                    } else if (this.state.role_id === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกบทบาท");
                      return;
                    } else if (this.state.full_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                      return;
                    } else if (this.state.address === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกที่อยู่");
                      return;
                    } else if (this.state.phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์");
                      return;
                    } else if (this.state.phone.trim() !== "" && !this.ValidatePhone(this.state.phone)) {
                      alert("warning", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง");
                      return;
                    } else if (this.state.phone.trim() !== "" && this.state.phone.length !== 10) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรให้ครบ 10 หลัก");
                      return;
                    } else if (this.state.email === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                      return;
                    } else if (this.state.email.trim() !== "" && !this.ValiateEmail(this.state.email)) {
                      alert("warning", "แจ้งเตือน", "รูปแบบอีเมลไม่ถูกต้อง");
                      return;
                    } else if (this.state.password === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน");
                      return;
                    }

                    this.AddUser();
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* แก้ไขผู้ใช้งาน */}
        <Modal
          show={this.state.modal_edit}
          size="m"
          onHide={() => {
            this.setState({ modal_edit: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              {" "}
              <b className="text-18">แก้ไขผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสผู้ใช้งาน:</label>
              </div>

              <label>{this.state.user_code}</label>
            </div>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control bg-secondary-light border-0"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value });
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="emp_sale">พนักงานขาย</option>
                  <option value="emp_stock">พนักงานสต๊อก</option>
                  <option value="emp_delivery">พนักงานสายส่ง</option>
                  <option value="manager">ผู้จัดการ</option>
                  {this.state.user_type_login === "owner" ? <option value="owner">เจ้าของร้าน</option> : null}
                </select>
              </div>
            </div>
            {/* บทบาท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>บทบาท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control bg-secondary-light border-0 pointer"
                  onChange={(e) => {
                    this.setState({ role_id: e.target.value });
                  }}
                  value={this.state.role_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_role &&
                    this.state.data_role.data.map((item, index) => (
                      <option value={item.role_id} key={index}>
                        {item.role_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  maxLength={30}
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value });
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  maxLength={50}
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control bg-secondary-light border-0"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone;
                    phone = e.target.value.replace(/\D/g, "");
                    this.setState({
                      phone: phone,
                    });
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>

            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={50}
                  className="form-control bg-secondary-light border-0"
                  type="email"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                  value={this.state.email}
                />
              </div>
            </div>

            {/* รหัสผ่าน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสผ่าน:</label>
              </div>

              <div className="w-100">
                {this.state.password === "" ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray pointer"
                    onClick={() => {
                      this.GenPassword();
                    }}
                  >
                    <h5>
                      <b className="text-primary">คลิกที่นี่เพื่อสุ่มรหัสผ่าน</b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    className="form-control"
                    type="text"
                    placeholder="กรอกข้อมูล"
                    readOnly
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                    value={this.state.password}
                  />
                )}
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.user_type === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกประเภท");
                      return;
                    } else if (this.state.role_id === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกบทบาท");
                      return;
                    } else if (this.state.full_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                      return;
                    } else if (this.state.address === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกที่อยู่");
                      return;
                    } else if (this.state.phone.trim() === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์");
                      return;
                    } else if (this.state.phone.trim() !== "" && this.state.phone.length !== 10) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรให้ครบ 10 หลัก");
                      return;
                    } else if (this.state.phone.trim() !== "" && !this.ValidatePhone(this.state.phone)) {
                      alert("warning", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง");
                      return;
                    } else if (this.state.email === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                      return;
                    } else if (this.state.email.trim() !== "" && !this.ValiateEmail(this.state.email)) {
                      alert("warning", "แจ้งเตือน", "รูปแบบอีเมลไม่ถูกต้อง");
                      return;
                    }

                    this.EditUser();
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal ลบผู้ใช้งาน */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
            this.ClearForm();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการลบผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการลบผู้ใช้งาน “{this.state.full_name}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
