import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { tokens, format_date, ModalDetailsLog2, GET, ModalDetailsReceiptLog2, total, ModalDetailsRefundLog2 } from "../../../components/CustomComponent.js";
import "../../../assets/css/bootstrap.css";
export default class LogAuditdetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,

      id: this.props.location.query.id,

      history: null,
      modal_history: false,
      modal_receip: false,
      modal_refund: false,

      start_date: "",
      end_date: "",

      search: "",
    };
  }
  async componentDidMount() {
    await this.Get_by_id(this.state.id);
  }
  Get_by_id = async (id) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/history-transaction/filter-search/" + id, null);
    if (result && result.status) {
      this.setState({ data: result.data });
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };

  GetHistoryById = async (id, item) => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/history-transaction/detail/" + id, null);
    if (result && result.status) {
      let value = {};
      let data = result.data.data ? result.data.data : value;
      data.detail = item;

      // หาว่าตัวไหน ถูกสร้าง อัพเดท หรือลบ
      if (item.bf_order_main_number.includes("RI")) {
        data.warehouse_name = data.history_transaction_list.af_history_transaction_list.length !== 0 ? data.history_transaction_list.af_history_transaction_list[0].relation_all_stock.warehouse_name : data.history_transaction_list.bf_history_transaction_list[0].relation_all_stock.warehouse_name;
        data.warehouse_name_check = data.history_transaction_list.af_history_transaction_list.length !== 0 ? (data.history_transaction_list.af_history_transaction_list[0].relation_all_stock.warehouse_name === data.history_transaction_list.bf_history_transaction_list[0].relation_all_stock.warehouse_name ? false : true) : false;
        data.date_time_receipt = data.af_date_time_receipt === null ? data.bf_date_time_receipt : data.bf_date_time_receipt.includes(data.af_date_time_receipt) ? data.bf_date_time_receipt : data.af_date_time_receipt;
        data.date_time_receipt_check = data.af_date_time_receipt === null ? false : data.bf_date_time_receipt.includes(data.af_date_time_receipt) ? false : true;
        data.ref_receipt_code = data.af_ref_receipt_code === "" ? data.bf_ref_receipt_code : data.bf_ref_receipt_code.includes(data.af_ref_receipt_code) ? data.bf_ref_receipt_code : data.af_ref_receipt_code;

        data.ref_receipt_code_check = data.af_ref_receipt_code === "" ? false : data.bf_ref_receipt_code.includes(data.af_ref_receipt_code) ? false : true;

        let company_supplier = data.af_company_supplier === "" ? false : data.af_company_supplier.company_id.includes(data.bf_company_supplier.company_id) ? false : true;
        data.company_supplier = data.af_company_supplier === "" ? data.bf_company_supplier.company_name : company_supplier ? data.af_company_supplier.company_name : data.bf_company_supplier.company_name;
        data.company_supplier_check = data.af_company_supplier === "" ? false : company_supplier ? true : false;

        // หาว่าตัวไหน ถูกสร้าง อัพเดท หรือลบ
        let array = [];
        if (data.history_transaction_list.af_history_transaction_list) {
          array = data.history_transaction_list.af_history_transaction_list
            ? data.history_transaction_list.af_history_transaction_list.map((item) => {
                return { ...item, time: "after", delete: false, create: false };
              })
            : [];
        } else {
          array = data.history_transaction_list.bf_history_transaction_list.map((item) => {
            return { ...item, time: "before", delete: true, update: false, create: false };
          });
        }
        let data_table = Object.values(
          array.reduce((p, v) => {
            const old = p[v.relation_all_stock.product_main_data.product_id];
            if (!old) {
              if (v.time === "before") {
                p[v.relation_all_stock.product_main_data.product_id] = { ...v };
              } else {
                p[v.relation_all_stock.product_main_data.product_id] = { ...v, create: true, update: true };
              }
            } else {
              if (old.relation_all_stock.warehouse_id === v.relation_all_stock.warehouse_id && old.relation_all_stock.product_main_data.product_id === v.relation_all_stock.product_main_data.product_id && old.qty === v.qty && old.yard_qty === v.yard_qty && old.price_by_item === v.price_by_item && old.total_price_by_item === v.total_price_by_item) {
                p[v.relation_all_stock.product_main_data.product_id] = { ...old, update: false, delete: false, time: "after" };
              } else {
                p[v.relation_all_stock.product_main_data.product_id] = { ...v, update: true };
              }
            }
            return p;
          }, {})
        );
        // if (data_table.length === 1 && data_table[0].time === "before") {
        //     data_table[0].delete = false;
        //     data_table[0].create = true;
        // }

        // for (let dt of data_table) {
        //     if (dt.time === "before") {
        //         dt.delete = false;
        //         dt.create = true;
        //     }
        // }
        data.history_transaction_list.transaction_list = data_table;
        this.setState({ history: data, modal_receip: true });
      } else if (item.bf_order_main_number.includes("RC")) {
        data.user_member = data.bf_relation_user_member || data.af_relation_user_member;
        data.datetime_created = data.af_datetime_created === null ? data.bf_datetime_created : data.bf_datetime_created.includes(data.af_datetime_created) ? data.bf_datetime_created : data.af_datetime_created;
        data.datetime_created_check = data.af_datetime_created === null ? false : data.bf_datetime_created.includes(data.af_datetime_created) ? false : true;
        // let bf_history_transaction_list = data.history_transaction_list.bf_history_transaction_list
        // let af_history_transaction_list = data.history_transaction_list.af_history_transaction_list
        let data_table = [];
        let array = [];
        // for (let bf of bf_history_transaction_list) {
        //     for (let refund_list of bf.refund_list) {
        //         array.push({
        //             ...refund_list,
        //             invoice_number: bf.invoice_number,
        //             time: "before",
        //             delete: true,
        //             update: false,
        //             create: false
        //         })
        //     }
        // }

        // for (let af of af_history_transaction_list) {
        //     for (let refund_list of af.refund_list) {
        //         array.push({
        //             ...refund_list,
        //             invoice_number: af.invoice_number,
        //             time: "after",
        //             delete: false,
        //             create: false
        //         })
        //     }
        // }
        // let array = [];
        if (data.history_transaction_list.af_history_transaction_list) {
          array = data.history_transaction_list.af_history_transaction_list
            ? data.history_transaction_list.af_history_transaction_list.map((item) => {
                return { ...item, time: "after", delete: false, create: false };
              })
            : [];
        } else {
          array = data.history_transaction_list.bf_history_transaction_list.map((item) => {
            return { ...item, time: "before", delete: true, update: false, create: false };
          });
        }

        data_table = Object.values(
          array.reduce((p, v) => {
            const old = p[v.product_id];
            if (!old) {
              if (v.time === "before") {
                p[v.product_id] = { ...v };
              } else {
                p[v.product_id] = { ...v, create: true, update: true };
              }
            } else {
              if (old.product_id === v.product_id && old.yard_qty === v.yard_qty && old.wood_qty === v.wood_qty && old.yard_detail === v.yard_detail) {
                p[v.product_id] = { ...old, update: false, delete: false, time: "after" };
              } else {
                p[v.product_id] = { ...v, update: true };
              }
            }
            return p;
          }, {})
        );
        // if (data_table.length === 1 && data_table[0].time === "before") {
        //     data_table[0].delete = false;
        //     data_table[0].create = true;
        // }

        for (let dt of data_table) {
          if (dt.time === "before") {
            dt.delete = false;
            dt.create = true;
          }
        }
        let array_2 = [];
        for (let a of data_table) {
          let index = array_2.findIndex((i) => i.invoice_number === a.invoice_number);
          if (index === -1) {
            array_2.push({
              invoice_number: a.invoice_number,
              refund_list: [a],
            });
          } else {
            array_2[index].refund_list.push(a);
          }
        }

        data.history_transaction_list.transaction_list = array_2;

        this.setState({ history: data, modal_refund: true });
      } else {
        if (data.af_relation_user_menber) {
          let user_member = data.af_relation_user_menber ? (data.af_relation_user_menber.user_id.includes(data.bf_relation_user_menber.user_id) ? false : true) : false;
          data.user_name_member = data.af_relation_user_menber ? (user_member ? data.af_relation_user_menber.full_name : data.bf_relation_user_menber.full_name) : data.bf_relation_user_menber.full_name;
          data.user_name_member_check = data.af_relation_user_menber ? (user_member ? true : false) : false;
        }
        if (data.relation_user_admin) {
          let user_admin = data.relation_user_admin ? (data.relation_user_admin.user_id.includes(data.relation_user_admin.user_id) ? false : true) : false;
          data.user_admin = data.relation_user_admin ? (user_admin ? data.relation_user_admin.full_name : data.relation_user_admin.full_name) : data.relation_user_admin.full_name;
          data.user_admin_code = data.relation_user_admin ? (user_admin ? data.relation_user_admin.user_code : data.relation_user_admin.user_code) : data.relation_user_admin.user_code;
          data.user_admin_check = data.relation_user_admin ? (user_admin ? true : false) : false;
          data.user_admin_code_check = data.relation_user_admin ? (user_admin ? true : false) : false;
        }
        if (data.af_relation_user_admin) {
          let user_admin = data.af_relation_user_admin ? (data.af_relation_user_admin.user_id.includes(data.bf_relation_user_admin.user_id) ? false : true) : false;
          data.user_admin = data.af_relation_user_admin ? (user_admin ? data.af_relation_user_admin.full_name : data.bf_relation_user_admin.full_name) : data.bf_relation_user_admin.full_name;
          data.user_admin_code = data.af_relation_user_admin ? (user_admin ? data.af_relation_user_admin.user_code : data.bf_relation_user_admin.user_code) : data.bf_relation_user_admin.user_code;
          data.user_admin_check = data.af_relation_user_admin ? (user_admin ? true : false) : false;
          data.user_admin_code_check = data.af_relation_user_admin ? (user_admin ? true : false) : false;
        }
        if (data.af_datetime_created) {
          let datetime_created = data.af_datetime_created === null ? false : data.af_datetime_created.includes(data.bf_datetime_created) ? false : true;
          data.datetime_created = data.af_datetime_created === null ? data.bf_datetime_created : datetime_created ? data.af_datetime_created : data.bf_datetime_created;
          data.datetime_created_check = data.af_datetime_created === null ? false : datetime_created ? true : false;
        }
        if (data.af_delivery_address) {
          let delivery_address = data.af_delivery_address !== "" && data.bf_delivery_address === "" ? true : data.af_delivery_address === "" && data.bf_delivery_address !== "" ? false : data.af_delivery_address.includes(data.bf_delivery_address) ? false : true;
          data.delivery_address = data.af_delivery_address !== "" && data.bf_delivery_address === "" ? data.af_delivery_address : data.af_delivery_address === "" && data.bf_delivery_address !== "" ? data.bf_delivery_address : delivery_address ? data.af_delivery_address : data.bf_delivery_address;
          data.delivery_address_check = data.af_delivery_address !== "" && data.bf_delivery_address === "" ? true : data.af_delivery_address === "" && data.bf_delivery_address !== "" ? false : delivery_address ? true : false;
        }
        if (data.af_remark) {
          let remark = data.af_remark !== "" && data.bf_remark === "" ? true : data.af_remark === "" && data.bf_remark !== "" ? false : data.af_remark.includes(data.bf_remark) ? false : true;
          data.remark = data.af_remark !== "" && data.bf_remark === "" ? data.af_remark : data.af_remark === "" && data.bf_remark !== "" ? data.bf_remark : remark ? data.af_remark : data.bf_remark;
          data.remark_check = data.af_remark !== "" && data.bf_remark === "" ? true : data.af_remark === "" && data.bf_remark !== "" ? false : remark ? true : false;
        }
        // หาว่าตัวไหน ถูกสร้าง อัพเดท หรือลบ
        // let array = [
        //     ...(data.history_transaction_list.bf_history_transaction_list.map(item => { return { ...item, time: "before", delete: true, update: false, create: false } })),
        //     ...(data.history_transaction_list.af_history_transaction_list ? data.history_transaction_list.af_history_transaction_list.map(item => { return { ...item, time: "after", delete: false, create: false } }) : [])
        // ];
        let array = [];
        if (data.history_transaction_list.af_history_transaction_list) {
          array = data.history_transaction_list.af_history_transaction_list
            ? data.history_transaction_list.af_history_transaction_list.map((item) => {
                return { ...item, time: "after", delete: false, create: false };
              })
            : [];
        } else {
          array = data.history_transaction_list.bf_history_transaction_list.map((item) => {
            return { ...item, time: "before", delete: true, update: false, create: false };
          });
        }
        let data_table = Object.values(
          array.reduce((p, v) => {
            const old = p[v.product_main_id];
            if (!old) {
              if (v.time === "before") {
                p[v.product_main_id] = { ...v };
              } else {
                p[v.product_main_id] = { ...v, create: true, update: true };
              }
            } else {
              if (old.product_main_id === v.product_main_id && old.warehouse_id === v.warehouse_id && old.price_by_item === v.price_by_item && old.qty === v.qty && old.yard_qty === v.yard_qty && total(old.detail_yard_list) === total(v.detail_yard_list) && old.total_price === v.total_price && old.total_price_cost === v.total_price_cost && old.total_pay === v.total_pay) {
                p[v.product_main_id] = { ...old, update: false, delete: false, time: "after" };
              } else {
                p[v.product_main_id] = { ...v, update: true };
              }
            }
            return p;
          }, {})
        );
        // for (let dt of data_table) {
        //     if (dt.time === "before") {
        //         dt.delete = false;
        //         dt.create = true;
        //     }
        // }
        data.history_transaction_list.transaction_list = data_table;
        this.setState({ history: data, modal_history: true });
      }
    }
    this.setState({ loading: false });
  };
  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }
  ClearForm() {
    this.setState({
      page: 1,

      id: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="ประวัติการตรวจสอบ" page3="ประวัติการตรวจสอบ" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>ประวัติการตรวจสอบ</b>
              </h3>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/logaudit?type=" + this.props.location.query.type;
                  }}
                >
                  <span className="icon-brand text-18 mr-2">{"\uf060"}</span> ย้อนกลับ
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className=" d-flex">
                <p>
                  History of the transection : <label className="text-header">No. {this.state.id && this.state.id ? this.state.id : "-"}</label>
                </p>
              </div>
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="d-flex row mx-0 w-100">
                    <div className="d-flex row mx-0 align-items-center">
                      <input
                        type="date"
                        className="form-control-select wpx-150 mb-1 mt-1 pointer"
                        value={this.state.start_date}
                        placeholder="วันที่เริ่มต้น"
                        onChange={(e) => {
                          this.setState({ page: 1, start_date: e.target.value });

                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                      ></input>
                      <label className="mx-2 ">ถึง</label>
                      <input
                        type="date"
                        className="form-control-select wpx-150 mb-1 mt-1 pointer"
                        value={this.state.end_date}
                        placeholder="วันที่สิ้นสุด"
                        onChange={(e) => {
                          const date_start_date = new Date(this.state.start_date).getTime();
                          const date_end_date = new Date(e.target.value).getTime();

                          if (date_start_date <= date_end_date) {
                            this.setState({ page: 1, end_date: e.target.value });
                            setTimeout(() => {
                              this.GetAll();
                            }, 20);
                          }
                        }}
                      ></input>

                      <button
                        type="button"
                        className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                        onClick={() => {
                          this.Input.clearValue();
                          this.setState({
                            page: 1,
                            search: "",
                            data: null,
                            type_product_id: "",
                            warehouse_id: "",
                            start_date: "",
                            end_date: "",
                            fillter_province_id: "",
                            fillter_province_name: "",
                          });

                          setTimeout(() => {
                            this.GetAll();
                          }, 20);
                        }}
                      >
                        <span className="icon-brand text-18 ">{"\uf2f9"}</span> รีเซ็ท
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0 ">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>วันที่</b>
                          </th>
                          <th className="text-left">
                            <b>เขตเวลา</b>
                          </th>
                          <th className="text-left">
                            <b>ผู้แก้ไข</b>
                          </th>
                          <th className="text-center">
                            <b>รายละเอียด</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={4} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">{format_date(item.datetime_create)}</td>
                              <td className="text-left">{item.time_zone}</td>
                              <td className="text-left">{item.username_create}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={async () => {
                                    this.GetHistoryById(item.history_transaction_id, item);
                                  }}
                                >
                                  <label className="icon mr-2">{"\uf570"}</label>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal ประวัติการตรวจสอบการชำระเงิน */}
        <ModalDetailsLog2
          value={this.state.history}
          show={this.state.modal_history}
          onHide={() => {
            this.setState({ modal_history: false });
          }}
        />

        {/* Modal ประวัติการตรวจสอบใบรับสินค้า */}
        <ModalDetailsReceiptLog2
          value={this.state.history}
          show={this.state.modal_receip}
          onHide={() => {
            this.setState({ modal_receip: false });
          }}
        />

        {/* Modal ประวัติการตรวจสอบคืนสินค้า */}
        <ModalDetailsRefundLog2
          value={this.state.history}
          show={this.state.modal_refund}
          onHide={() => {
            this.setState({ modal_refund: false });
          }}
        />
      </div>
    );
  }
}
