import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { alert, POST, PUT, DELETE, tokens, role, number } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class WarehouseScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,

      main_warehouse: false,

      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_delete: false,

      data: null,

      search: "",

      id: "",

      warehouse_name: "",
      warehouse_description: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }

  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.search,
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/filter/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  AddWareHouse = async () => {
    this.setState({ loading: true });
    let body = {
      warehouse_name: this.state.warehouse_name,
      warehouse_description: this.state.warehouse_description,
      is_active: this.state.main_warehouse,
    };
    let result = await POST(tokens, "v1/stock-mgt/warehouse/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มโกดังสินค้าเรียบร้อยแล้ว", "โกดังสินค้า ' " + this.state.warehouse_name + " ' ถูกเพิ่มในระบบแล้ว");
      this.setState({ loading: false, modal_add: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  EditWareHouse = async () => {
    this.setState({ loading: true });
    let body = {
      warehouse_id: this.state.id,
      warehouse_name: this.state.warehouse_name,
      warehouse_description: this.state.warehouse_description,
      is_active: this.state.main_warehouse,
    };
    let result = await PUT(tokens, "v1/stock-mgt/warehouse/update", body);
    if (result && result.status) {
      alert("success", "แก้ไขข้อมูลโกดังสินค้าเรียบร้อยแล้ว", "โกดังสินค้า ' " + this.state.warehouse_name + " ' ถูกแก้ไขข้อมูลในระบบแล้ว");

      this.setState({ loading: false, modal_edit: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  Delete = async (id) => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/stock-mgt/warehouse/delete/" + id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลโกดังสินค้าเรียบร้อยแล้ว", "โกดังสินค้า ' " + this.state.warehouse_name + " ' ถูกลบข้อมูลในระบบแล้ว");

      this.setState({ loading: false, modal_delete: false });
      this.ClearForm();
      this.GetAll();
    }
    this.setState({ loading: false });
  };
  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      loading: false,

      page: 1,

      search: "",
      warehouse_name: "",
      warehouse_description: "",

      id: "",
      main_warehouse: false,
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="โกดังสินค้า" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>โกดังสินค้า</b>
              </h3>
              {role && role.warehouse[1] === 1 && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.ClearForm();
                      this.setState({ modal_add: true });
                    }}
                  >
                    {" "}
                    เพิ่มโกดังสินค้า{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          } else {
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={this.state.data && this.state.data.data.length === 0 ? "card-body p-0 height-vh" : "card-body p-0 "}>
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm table-hover">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>ชื่อโกดัง</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>รายละเอียด</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>จำนวนชนิดผ้า</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>จำนวนไม้</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 100 }}>
                            <b>จำนวนหลา</b>
                          </th>
                          {role && (role.warehouse[2] === 1 || role.warehouse[3] === 1) && (
                            <th className="text-center" style={{ minWidth: 120 }}>
                              <b>จัดการ</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.data.length === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={6} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index} className="pointer">
                              <td className="text-left">
                                {item.warehouse_name} {item.is_active === true ? <b>[ โกดังหลัก ]</b> : ""}
                              </td>
                              <td className="text-left">{item.warehouse_description}</td>
                              <td className="text-center">{item.product_type}</td>
                              <td className="text-center">
                                {
                                  parseFloat(item.stock_qty)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    .split(".")[0]
                                }
                              </td>
                              <td className="text-center">
                                {parseFloat(item.yard_qty)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </td>
                              <td className="text-center">
                                {role && role.warehouse[2] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        warehouse_name: item.warehouse_name,
                                        warehouse_description: item.warehouse_description,
                                        id: item.warehouse_id,
                                        main_warehouse: item.is_active,
                                        modal_edit: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf040"}</label>
                                  </button>
                                )}

                                {role && role.warehouse[3] === 1 && (
                                  <button
                                    className="btn btn-link text-secondary pointer"
                                    onClick={() => {
                                      this.setState({
                                        warehouse_name: item.warehouse_name,
                                        id: item.warehouse_id,
                                        modal_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="icon mr-2 pointer">{"\uf2ed"}</label>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100  justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {number(this.state.data.end_index)} รายการจาก {number(this.state.data.count)} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon pointer">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon pointer">{"\uf104"}</label>} nextPageText={<label className="icon pointer">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1 pointer"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon pointer">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal เพิ่มโกดังสินค้า */}
        <Modal
          show={this.state.modal_add}
          size="m"
          onHide={() => {
            this.setState({ modal_add: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">เพิ่มโกดังสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อโกดัง */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อโกดัง:</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ warehouse_name: e.target.value });
                  }}
                  value={this.state.warehouse_name}
                />
              </div>
            </div>
            {/* รายละเอียด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายละเอียด:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      warehouse_description: e.target.value,
                    });
                  }}
                  value={this.state.warehouse_description}
                ></textarea>
              </div>
            </div>
            {/* โกดังหลัก: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>โกดังหลัก:</label>
              </div>
              <div className="w-100">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    this.setState({ main_warehouse: !this.state.main_warehouse });
                  }}
                  checked={this.state.main_warehouse}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.warehouse_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อโกดัง");
                      return;
                    }

                    this.AddWareHouse();
                  }}
                >
                  {" "}
                  เพิ่ม{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal แก้ไขโกดังสินค้า */}
        <Modal
          show={this.state.modal_edit}
          size="m"
          onHide={() => {
            this.setState({ modal_edit: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">แก้ไขโกดังสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อโกดัง */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อโกดัง:</label>
              </div>
              <div className="w-100">
                <input
                  maxLength={30}
                  className="form-control bg-secondary-light border-0"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ warehouse_name: e.target.value });
                  }}
                  value={this.state.warehouse_name}
                />
              </div>
            </div>
            {/* รายละเอียด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายละเอียด:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control bg-secondary-light border-0"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      warehouse_description: e.target.value,
                    });
                  }}
                  value={this.state.warehouse_description}
                ></textarea>
              </div>
            </div>

            {/* โกดังหลัก: */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>โกดังหลัก:</label>
              </div>
              <div className="w-100">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    this.setState({ main_warehouse: !this.state.main_warehouse });
                  }}
                  checked={this.state.main_warehouse}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false });
                    this.ClearForm();
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.warehouse_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อโกดัง");
                      return;
                    }

                    this.EditWareHouse(this.state.id);
                  }}
                >
                  {" "}
                  บันทึก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal ยืนยันการลบโกดังสินค้า */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">ยืนยันการลบโกดังสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row mx-0 d-flex mb-3">
              <div className="col-12 mb-3">ท่านแน่ในว่าต้องการลบโกดังสินค้า “{this.state.warehouse_name}” </div>
              <div className="col-12 mb-3">หลังจากท่านยืนยันการยกเลิกแล้วจะไม่สามารถย้อนคืนข้อมูลได้</div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete(this.state.id);
                  }}
                >
                  {" "}
                  ลบถาวร{" "}
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  {" "}
                  ยกเลิก{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
