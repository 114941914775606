import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { tokens, format_date, GET } from "../../../components/CustomComponent.js";
import "../../../assets/css/bootstrap.css";
export default class LogAuditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_remark: false,
      page: 1,

      loading: false,

      data: null,

      search: "",

      status: "",
      remark: "",
    };
  }
  async componentDidMount() {
    await this.GetAll();
    if (this.multilineTextarea) {
      this.multilineTextarea.style.height = "auto";
    }
  }
  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/check-stock/filter-search/page/" + this.state.page + "?data_search&type_product_id", null);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,
      id: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รอบการนับสต๊อก" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รอบการนับสต๊อก</b>
              </h3>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/stock-cycle-detail";
                  }}
                >
                  <span className="icon-brand text-18 mr-2">{"\uf060"}</span>
                  ย้อนกลับ
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="col-6 col-sm-6 col-xl-3 d-flex">
                    <div className="w-100">
                      <input
                        maxLength={30}
                        className="form-control-search"
                        type="search"
                        placeholder="พิมพ์คำค้นหา"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ page: 1, search: e.target.value, loading: false });
                          if (e.target.value === "") {
                            this.setState({ search: "" });
                            setTimeout(() => {
                              this.GetAll();
                              this.setState({ loading: false });
                            }, 20);
                          }
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            this.GetAll();
                            this.setState({ loading: false });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1"
                    onClick={() => {
                      this.Input.clearValue();
                      this.setState({
                        page: 1,
                        search: "",
                      });
                      setTimeout(() => {
                        this.GetAll();
                      }, 20);
                    }}
                  >
                    <span className="icon-brand text-18 ">{"\uf2f9"}</span> รีเซ็ท
                  </button>
                </div>
                <div className="card-body p-0 ">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-hover table-sm">
                      <thead>
                        <tr>
                          <th className="text-left">วัน/เดือน/ปี</th>
                          <th className="text-left">เลขที่</th>
                          <th className="text-center wpx-200">หมายเหตุ</th>
                          <th className="text-center">ข้อมูล</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.count === 0) || !this.state.data) && (
                          <tr>
                            <td colSpan={4} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        )}

                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td className="text-left">{format_date(item.datetime_create)}</td>
                              <td className="text-left">{item.cycle_number}</td>
                              <td className="text-center">
                                {item.remark && (
                                  <label
                                    className="wpx-200 pointer"
                                    style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                    onClick={() => {
                                      this.setState({ modal_remark: true, remark: item.remark });
                                      setTimeout(() => {
                                        this.multilineTextarea.style.height = "auto";
                                        this.multilineTextarea.style.height = this.multilineTextarea.scrollHeight + "px";
                                      }, 100);
                                    }}
                                  >
                                    {item.remark}
                                  </label>
                                )}
                              </td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    window.location.href = "/stock-cycle-detail?id=" + item.cycle_number;
                                  }}
                                >
                                  <label className="icon">{"\uf06e"}</label>
                                </button>
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    window.location.href = "/report-stock-cycle?id=" + item.cycle_number;
                                  }}
                                >
                                  <span className="icon mr-2">{"\uf15c"}</span>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="row w-100 justify-content-between mx-0  my-4">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex">
                    <div className="d-flex w-100 justify-content-xl-start justify-content-md-start justify-content-sm-start">
                      <label>
                        {" "}
                        แสดง {this.state.data.end_index} รายการจาก {this.state.data.count} รายการ{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 d-flex ">
                    <div className="d-flex w-100 justify-content-xl-end justify-content-md-end justify-content-sm-end">
                      <button
                        className="btn-circle-white mx-1"
                        onClick={() => {
                          this.HandlePageChange(1);
                        }}
                      >
                        {" "}
                        <label className="icon">{"\uf100"}</label>{" "}
                      </button>
                      <Pagination activePage={this.state.page} itemsCountPerPage={10} totalItemsCount={this.state.data.count} pageRangeDisplayed={5} onChange={this.HandlePageChange.bind(this)} prevPageText={<label className="icon">{"\uf104"}</label>} nextPageText={<label className="icon">{"\uf105"}</label>} hideFirstLastPages={true} />
                      <button
                        className="btn-circle-white mx-1"
                        onClick={() => {
                          this.HandlePageChange(this.state.data.page_number);
                        }}
                      >
                        <label className="icon">{"\uf101"}</label>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal ลบประเภทผ้า */}
        <Modal
          show={this.state.modal_remark}
          onHide={() => {
            this.setState({ modal_remark: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b className="text-18">หมายเหตุ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mx-0 mb-3">
              <textarea disabled className="bg-white border-0 w-100" style={{ resize: "none", height: "100%" }} ref={(ref) => (this.multilineTextarea = ref)}>
                {this.state.remark}
              </textarea>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <button
                className="btn btn-outline-primary px-5"
                onClick={() => {
                  this.setState({ modal_remark: false });
                }}
              >
                {" "}
                ตกลง{" "}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
