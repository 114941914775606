import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { POST, tokens, baht, total, report_customer, alert } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class ReportSalesCustomersScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,

      data: [],
      data_year: [],

      data_search: "",
      year: "",
      start_date: "",
      end_date: "",
      status_order_main: 0,
      document_type: 0,
    };
  }
  async componentDidMount() {
    let year = [];
    for (let i = 0; i < 10; i++) {
      year.push(new Date().getFullYear() - i);
    }
    this.setState({ data_year: year });
  }
  GetAll = async () => {
    this.setState({ loading: true });
    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }
    let body = {
      data_search: this.state.data_search,
      year: this.state.year ? Number(this.state.year) : 0,
      start_date: this.state.start_date && this.state.end_date ? this.state.start_date : "",
      end_date: this.state.start_date && this.state.end_date ? this.state.end_date : "",
      status_order_main: this.state.status_order_main ? Number(this.state.status_order_main) : 0,
    };
    let result = await POST(tokens, "v1/report/sale-customer/summary/page/0", body);
    if (result && result.status) {
      let data = result.data.data;
      for (let i = 0; i < data.length; i++) {
        data[i].total_price = total(data[i].invoice_list, "total_price");
        data[i].total_pay = total(data[i].invoice_list, "total_pay");
        data[i].total_balance = total(data[i].invoice_list, "total_balance");
      }
      this.setState({ data: data });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,

      data_search: "",
      year: "",
      start_date: "",
      end_date: "",
      status_bill: 0,
      data: [],
    });
  }
  status_bill = (status) => {
    switch (status) {
      case 1:
        return "ยังไม่ได้ชำระ";
      case 2:
        return "ยังไม่ได้ชำระ";
      case 3:
        return "ชำระเงินแล้ว";
      case 4:
        return "ยังไม่ได้ชำระ";
      case 5:
        return "ยังไม่ได้ชำระ";
      case 6:
        return "ยกเลิก";
      case 7:
        return "ยังไม่ได้ชำระ";
      default:
        return "ยังไม่ได้ชำระ";
    }
  };
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายงานสรุปยอดขายจากลูกค้ารายบุคคล" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายงานสรุปยอดขายจากลูกค้ารายบุคคล</b>
              </h3>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    if (this.state.data.length === 0) {
                      alert("warning", "แจ้งเตือน", "ไม่มีข้อมูลรายงาน");
                      return;
                    }
                    report_customer(this.state.data, this.state.start_date, this.state.end_date, this.state.year);
                  }}
                >
                  <span className="icon-brand text-18 ">{"\uf6dd"}</span> ออกรายงาน
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-0 rounded-top-16 ">
                  <div className="col-12 col-sm-2 col-md-4 col-lg-4 col-xl-2 d-flex  ">
                    <select
                      className="form-control-select mb-1 mt-1 pointer"
                      onChange={(e) => {
                        this.setState({
                          year: e.target.value,
                        });
                      }}
                      value={this.state.year}
                    >
                      <option value="0">เลือกปี</option>
                      {this.state.data_year.map((item, index) => (
                        <option value={item}>ปี {item + 543}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end">
                    <div className="d-flex row mx-0 w-100">
                      <div className="d-flex row mx-0 align-items-center">
                        <input
                          type="date"
                          className="form-control-select wpx-150 mb-1 mt-1 pointer"
                          placeholder="วันที่เริ่มต้น"
                          onChange={(e) => {
                            this.setState({
                              start_date: e.target.value,
                            });
                          }}
                          value={this.state.start_date}
                        ></input>
                        <label className="mx-2 ">ถึง</label>
                        <input
                          type="date"
                          className="form-control-select wpx-150  mb-1 mt-1 pointer"
                          placeholder="วันที่สิ้นสุด"
                          onChange={(e) => {
                            const date_start_date = new Date(this.state.start_date).getTime();
                            const date_end_date = new Date(e.target.value).getTime();

                            if (date_start_date <= date_end_date) {
                              this.setState({ end_date: e.target.value });
                            }
                          }}
                          value={this.state.end_date}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-primary ml-2 mb-1 mt-1"
                          onClick={() => {
                            this.GetAll();
                          }}
                        >
                          {" "}
                          ค้นหา
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary bg-white ml-2 mb-1 mt-1 pointer"
                          onClick={() => {
                            this.ClearForm();
                          }}
                        >
                          <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4 col-lg-6 col-xl-4 d-flex ">
                    <select
                      className="form-control-select mb-1 mt-1 pointer"
                      onChange={(e) => {
                        this.setState({ status_order_main: Number(e.target.value) });
                      }}
                      value={this.state.status_order_main}
                    >
                      <option value="0" selected>
                        ทั้งหมด
                      </option>
                      <option value="1">ยังไม่ได้ชำระ</option>
                      <option value="2">ชำระเงินแล้ว</option>
                    </select>

                    <input
                      type="search"
                      className="form-control-select  mx-2  mb-1 mt-1"
                      placeholder="ค้นหาชื่อ"
                      onChange={(e) => {
                        this.setState({
                          data_search: e.target.value,
                        });
                      }}
                      value={this.state.data_search}
                    ></input>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 170 }}>
                            <b>วันที่</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 130 }}>
                            <b>เลขที่เอกสาร</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>รวมทั้งหมด</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>ชำระแล้ว</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>
                            <b>ยอดค้างชำระ</b>
                          </th>
                          <th className="text-right" style={{ minWidth: 100 }}>
                            <b>ส่วนลด</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 130 }}>
                            <b>สถานะ</b>
                          </th>
                        </tr>
                      </thead>

                      {((this.state.data && this.state.data.length === 0) || !this.state.data) && (
                        <tbody>
                          <tr>
                            <td colSpan={7} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {this.state.data &&
                        this.state.data.map((item, index) => (
                          <tbody>
                            <tr
                              key={index}
                              className=" table-light pointer "
                              onClick={() => {
                                let data = this.state.data;
                                if (data[index].expand) {
                                  data[index].expand = !data[index].expand;
                                } else {
                                  data[index].expand = true;
                                }
                                this.setState({ data: data });
                              }}
                            >
                              <td className="text-left ">
                                {item.member_name}
                                <span className="icon ml-2">{item.expand ? "\uf077" : "\uf078"}</span>
                              </td>
                              <td className="text-left"></td>
                              <td className="text-left"></td>
                              <td className="text-left"></td>
                              <td className="text-left"></td>
                              <td className="text-center"></td>
                              <td className="text-center"></td>
                            </tr>
                            {item.expand &&
                              item.invoice_list.map((invoice, invoice_index) => (
                                <tr key={invoice_index}>
                                  <td className="text-left"></td>
                                  <td className="text-left">{invoice.ab_number ? invoice.ab_number : "-"}</td>
                                  <td className="text-right">{baht(invoice.total_price)}</td>
                                  <td className="text-right">{baht(invoice.total_pay)}</td>
                                  <td className="text-right">{baht(invoice.total_balance)}</td>
                                  <td className="text-right">{invoice.discount ? baht(invoice.discount) : "0.00"}</td>
                                  <td className="text-center">{this.status_bill(invoice.status_bill)}</td>
                                </tr>
                              ))}

                            <tr key={index} className="pointer">
                              <td className="text-left">
                                <b>ยอดรวม</b>
                              </td>
                              <td className="text-left"></td>
                              <td className="text-right">
                                <b>{baht(item.total_price)}</b>
                              </td>
                              <td className="text-right">
                                <b>{baht(item.total_pay)}</b>
                              </td>
                              <td className="text-right">
                                <b>{baht(item.total_balance)}</b>
                              </td>
                              <td className="text-right">
                                <b>{baht(item.total_discount)}</b>
                              </td>
                              <td className="text-center"></td>
                            </tr>
                          </tbody>
                        ))}

                      <tfoot>
                        <td className="text-left">
                          <b>ยอดรวมทั้งหมด</b>
                        </td>
                        <td className="text-left"></td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "total_price"))}</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "total_pay"))}</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "total_balance"))}</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "total_discount"))}</b>
                        </td>
                        <td className="text-center"></td>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
