/* eslint-disable array-callback-return */
import SidebarComponent from "../../../components/Sidebar";
import NavbarComponent from "../../../components/Navbar";
import React, { Component } from "react";
import { alert, GET, POST, tokens, float, toFixed } from "../../../components/CustomComponent.js";
import Select, { components, createFilter } from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";
import "../../../assets/css/bootstrap.css";

const { Option } = components;
const customStylesTypeOptions = {
  option: (provided, state) => ({
    ...provided,
    // height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
    width: "100%",
  }),
  container: (base, state) => ({
    ...base,
    width: "100%",
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 10,
  }),
};
const customStylesTypeOptions2 = {
  option: (provided, state) => ({
    ...provided,
    // height: 34,
    // padding: 8,
  }),
  control: (base, state) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
    },
    borderRadius: 8,
    height: 44,
    width: 200,
  }),
};
const TypeOptions = (props) => (
  <Option {...props}>
    <label>{props.data.label}</label>
  </Option>
);

export default class GenerateBarcodeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      data_type: null,
      fillter_product: null,
      fillter_product_type: null,
      array: [],
    };
  }
  async componentDidMount() {
    await this.GetAll();
    await this.GetTypeAll();
  }

  GetAll = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "v1/product-mgt/all/for-check-stock", null);
    if (result && result.status) {
      this.setState({
        data: result.data.data,
      });
    }
    this.setState({ loading: false });
  };
  GetTypeAll = async () => {
    this.setState({ loading: true });
    let result = await POST(tokens, "v1/product-mgt/type_product/filter-search/page/0", { data_search: "" });
    if (result && result.status) {
      this.setState({
        data_type: result.data.data.map((item) => {
          return { value: item.type_product_id, label: item.type_product_name };
        }),
      });
    }
    this.setState({ loading: false });
  };
  print = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      FCIconic: {
        normal: "FCIconic-Regular.ttf",
        bold: "FCIconic-Bold.ttf",
        italics: "FCIconic-Regular.ttf",
        bolditalics: "FCIconic-Regular.ttf",
      },
      THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
      "3OF9_NEW": {
        normal: "3OF9_NEW-normal.ttf",
        bold: "3OF9_NEW-normal.ttf",
        italics: "3OF9_NEW-normal.ttf",
        bolditalics: "3OF9_NEW-normal.ttf",
      },
    };
    let text = this.state.array.map((item, index) => {
      return index === this.state.array.length - 1
        ? {
            stack: [
              { text: " ", alignment: "center" },
              { text: "*" + (item.color ? item.color.value : "") + "/" + ("000" + toFixed(item.qty)).slice(-6) + "*", font: "3OF9_NEW", fontSize: 22, lineHeight: 0.9, alignment: "center", width: "100%" },
              { text: "*" + (item.color ? item.color.value : "") + "/" + ("000" + toFixed(item.qty)).slice(-6) + "*", font: "3OF9_NEW", fontSize: 22, alignment: "center", width: "100%" },
              { text: item.color ? item.color.label : "", alignment: "center" },
              { text: "จำนวน " + item.qty + " หลา", alignment: "center" },
            ],
          }
        : {
            stack: [
              { text: " ", alignment: "center" },
              { text: "*" + (item.color ? item.color.value : "") + "/" + ("000" + toFixed(item.qty)).slice(-6) + "*", font: "3OF9_NEW", fontSize: 22, lineHeight: 0.9, alignment: "center", width: "100%" },
              { text: "*" + (item.color ? item.color.value : "") + "/" + ("000" + toFixed(item.qty)).slice(-6) + "*", font: "3OF9_NEW", fontSize: 22, alignment: "center", width: "100%" },
              { text: item.color ? item.color.label : "", alignment: "center" },
              { text: "จำนวน " + item.qty + " หลา", alignment: "center" },
            ],
            pageBreak: "after",
          };
    });
    var docDefinition = {
      content: text,
      defaultStyle: { font: "FCIconic", fontSize: 8, lineHeight: 1 },
      pageMargins: [2, 8, 2, 8],
      pageOrientation: "landscape",
      pageSize: { width: 220, height: 100 },
    };
    pdfMake.createPdf(docDefinition).open();
    // print(docDefinition, "barcode.pdf")
  };

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="สร้างบาร์โค้ด" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>สร้างบาร์โค้ด</b>
              </h3>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="w-100 d-flex justify-content-between">
                    <div className="w-100 d-flex row mx-0">
                      <Select
                        className="px-0 mb-2 mx-1"
                        filterOption={createFilter({ ignoreAccents: false })}
                        options={this.state.data_type && this.state.data_type}
                        components={{ Option: TypeOptions }}
                        styles={customStylesTypeOptions2}
                        placeholder="ประเภท"
                        value={this.state.fillter_product_type}
                        isClearable
                        onChange={(e) => {
                          this.setState({ array: [], fillter_product_type: e });
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-success px-5 hpx-70 mb-2"
                      onClick={() => {
                        if (this.state.array.length === 0) {
                          alert("warning", "แจ้งเตือน", "ยังไม่มีบาร์โค้ด");
                          return;
                        }
                        this.print();
                      }}
                    >
                      {" "}
                      พิมพ์บาร์โค้ด{" "}
                    </button>
                  </div>
                </div>
                <div className="card-body p-2">
                  {this.state.fillter_product_type ? (
                    <button
                      type="button"
                      className="btn btn-success px-5 mb-2 mr-1"
                      onClick={() => {
                        let array = this.state.array;
                        array.push({ color: "", qty: "" });
                        this.setState({ array: array });
                      }}
                    >
                      {" "}
                      เพิ่ม{" "}
                    </button>
                  ) : null}
                  {this.state.array.length !== 0 ? (
                    <button
                      type="button"
                      className="btn btn-danger px-5 mb-2 ml-1"
                      onClick={() => {
                        let array = this.state.array;
                        for (let item of array) {
                          item.qty = "";
                        }
                        this.setState({ array: array });
                      }}
                    >
                      {" "}
                      ล้างข้อมูลหลา{" "}
                    </button>
                  ) : null}
                  <div className="row mx-0">
                    {this.state.array.map((item, index) => (
                      <div className="col-12 col-md-6 col-lg-12 col-xl-4 mb-2">
                        <div className="card bg-contain p-3">
                          <div className="d-flex justify-content-between mx-0 w-100">
                            <div className="d-flex align-items-center mx-0 pr-1 w-100">
                              <label className="mr-2 mt-2">{index + 1}</label>
                              <Select
                                className="px-0 mb-2 mx-1"
                                filterOption={createFilter({ ignoreAccents: false })}
                                options={
                                  this.state.data &&
                                  this.state.data
                                    .filter((e) => this.state.fillter_product_type === null || (this.state.fillter_product_type && this.state.fillter_product_type.value === e.type_product_id))
                                    .map((e) => ({
                                      value: e.product_code,
                                      label: e.product_code + " : " + e.product_name,
                                    }))
                                }
                                components={{ Option: TypeOptions }}
                                styles={customStylesTypeOptions}
                                placeholder="สินค้า"
                                value={item.color}
                                onChange={(e) => {
                                  let array = this.state.array;
                                  array[index].color = e;
                                  this.setState({ array: array });
                                }}
                              />
                              <input
                                className="form-control border-0 bg-white mb-2 mx-1 wpx-100"
                                type="text"
                                placeholder="จำนวนหลา"
                                id={"barcode" + index}
                                onChange={(e) => {
                                  if (!float(e.target.value)) {
                                    return;
                                  }
                                  if (Number(e.target.value) < 1000) {
                                    let array = this.state.array;
                                    array[index].qty = e.target.value;
                                    this.setState({ array: array });
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    document.getElementById("barcode" + (index + 1)).focus();
                                  }
                                }}
                                value={item.qty}
                              ></input>
                            </div>
                            <label
                              className="icon text-danger text-20 mx-2"
                              onClick={() => {
                                // Swal.fire({
                                //     icon: "warning",
                                //     title: "ยืนยันการลบ",
                                //     text: "ยืนยันการลบบาร์โค้ดนี้หรือไม่",
                                //     showCancelButton: true,
                                //     confirmButtonText: "ตกลง",
                                //     cancelButtonText: "ยกเลิก",
                                // }).then((result) => {
                                //     if (result.isConfirmed) {
                                //         let array = this.state.array;
                                //         array.splice(index, 1);
                                //         this.setState({ array: array });
                                //     }
                                // });
                                let array = this.state.array;
                                array.splice(index, 1);
                                this.setState({ array: array });
                              }}
                            >
                              {"\uf00d"}
                            </label>
                          </div>
                          <div className="text-center mx-auto card bg-white w-100 p-3">
                            <p className="barcode mb-1" style={{ minWidth: 360, fontSize: 44 }}>
                              *{item.color ? item.color.value : ""}/{("000" + toFixed(item.qty)).slice(-6)}*
                            </p>
                            <label>
                              {item.color ? item.color.label : ""} <br />
                              จำนวน {item.qty} หลา
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
