import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { POST, tokens, baht, total, report_inventory, alert } from "../../components/CustomComponent.js";
import { DownloadTableExcel } from "react-export-table-to-excel";

import "../../assets/css/bootstrap.css";
export default class ReportInventoryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,

      data: [],

      start_date: "",
    };
    this.tableRef = React.createRef();
  }
  componentDidMount() {}
  GetAll = async () => {
    this.setState({ loading: true });
    let body = {
      start_date: this.state.start_date,
    };

    let result = await POST(tokens, "v1/report/inventory/summary/page/0", body);

    if (result && result.status) {
      let data = result.data.data;
      for (let i = 0; i < data.length; i++) {
        data[i].wood_qty = total(data[i].product_list, "wood_qty");
        data[i].yard_qty = total(data[i].product_list, "yard_qty");
        data[i].asset_value = total(data[i].product_list, "asset_value");
      }
      this.setState({ data: data });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,
      start_date: "",
      data: [],
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายงานสรุปมูลค่าสินค้าคงคลัง" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายงานสรุปมูลค่าสินค้าคงคลัง</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-2"
                  onClick={() => {
                    let data = this.state.data;
                    data = data.map((e) => {
                      return { ...e, expand: true };
                    });
                    this.setState({ data: data });
                    setTimeout(() => {
                      document.getElementById("export-excel").click();
                      setTimeout(() => {
                        let data = this.state.data;
                        data = data.map((e) => {
                          return { ...e, expand: false };
                        });
                        this.setState({ data: data });
                      }, 10);
                    }, 10);
                  }}
                >
                  ส่งออก excel
                </button>
                <DownloadTableExcel filename={"รายงานสรุปมูลค่าสินค้าคงคลัง"} sheet={"รายงานสรุปมูลค่าสินค้าคงคลัง"} currentTableRef={this.tableRef.current}>
                  <button className="btn btn-primary mx-2 d-none" id="export-excel">
                    ส่งออก excel
                  </button>
                </DownloadTableExcel>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    if (this.state.data.length === 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกข้อมูลที่ต้องการออกรายงาน");
                      return;
                    }
                    report_inventory(this.state.data, this.state.start_date);
                  }}
                >
                  <span className="icon-brand text-18 ">{"\uf6dd"}</span> ออกรายงาน
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="d-flex row mx-0 justify-content-between w-100">
                    <div className="d-flex row mx-0 align-items-center">
                      <input
                        type="date"
                        className="form-control-select wpx-180 mx-2 mb-2 pointer"
                        placeholder="วันที่เริ่มต้น"
                        onChange={(e) => {
                          this.setState({
                            start_date: e.target.value,
                          });
                        }}
                        value={this.state.start_date}
                      ></input>

                      <button
                        type="button"
                        className="btn btn-primary ml-2 mb-1 "
                        onClick={() => {
                          this.GetAll();
                        }}
                      >
                        {" "}
                        ค้นหา
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary bg-white mx-2 mb-2 pointer"
                        onClick={() => {
                          this.ClearForm();
                        }}
                      >
                        <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm" ref={this.tableRef}>
                      <thead>
                        <tr>
                          <th className="text-left">
                            <b>Product</b>
                          </th>
                          <th className="text-right">
                            <b>จำนวนหลา</b>
                          </th>
                          <th className="text-right">
                            <b>จำนวนไม้คงเหลือ</b>
                          </th>
                          <th className="text-right">
                            <b>Asset Value</b>
                          </th>
                        </tr>
                      </thead>
                      {((this.state.data && (this.state.data.length === 0 || this.state.data.data === "")) || !this.state.data) && (
                        <tbody>
                          <tr>
                            <td colSpan={4} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {this.state.data &&
                        this.state.data.map((item, index) => (
                          <tbody>
                            <tr
                              key={index}
                              className=" table-light pointer"
                              onClick={() => {
                                let data = this.state.data;
                                if (data[index].expand) {
                                  data[index].expand = !data[index].expand;
                                } else {
                                  data[index].expand = true;
                                }
                                this.setState({ data: data });
                              }}
                            >
                              <td className="text-left">
                                {item.type_product_name}
                                <span className="icon ml-2">{item.expand ? "\uf077" : "\uf078"}</span>
                              </td>
                              <td className="text-left"></td>
                              <td className="text-left"></td>
                              <td className="text-center"></td>
                            </tr>
                            {item.expand &&
                              item.product_list.map((product, product_index) => (
                                <tr key={product_index}>
                                  <td className="text-left">{product.product_name}</td>
                                  <td className="text-right">{baht(product.yard_qty)}</td>
                                  <td className="text-right">{product.wood_qty}</td>
                                  <td className="text-right">{baht(product.asset_value)}</td>
                                </tr>
                              ))}
                            <tr key={index} className="table-light pointer">
                              <td className="text-left">
                                <b>ยอดรวม</b>
                              </td>

                              <td className="text-right">
                                <b>{baht(item.yard_qty)}</b>
                              </td>
                              <td className="text-right">
                                <b>{item.wood_qty}</b>
                              </td>
                              <td className="text-right">
                                <b>{baht(item.asset_value)}</b>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      <tfoot>
                        <td className="text-left">
                          <b>ยอดรวมทั้งหมด</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "yard_qty"))}</b>
                        </td>
                        <td className="text-right">
                          <b>{total(this.state.data, "wood_qty")}</b>
                        </td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "asset_value"))}</b>
                        </td>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
